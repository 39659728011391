.ym-hlist ul li {
	position: static;
	ul {
		position: absolute;
		background-color: #fff;
		display: block;
	}
}

.ym-g625 {
	width: 625px;
}

.ym-g300 {
	width: 300px;
	padding-left: 20px;
}

.ym-g420 {
	width: 420px;
}

.ym-g460 {
	width: 452px;
	padding-left: 20px;
	padding-right: 8px;
}

.ym-g46 {
	width: 46px;
}

.ym-g17 {
	width: 17px;
}

