@import '../utilities/lib.less';
.jquery_tabs {
	ul.tabs-list {
		top: 1px;
		overflow: hidden;
		li {
			display: table-cell;
			border-radius: 0;
			margin: 0 1px -50px 0;
			background: @AudiSchwarz;
			border: 1px solid @AudiSchwarz;
			max-width: 235px;
			/* I FEEL SO DIRTY */
			padding-bottom: 54px;
			overflow: hidden;

			&:hover, &:active, &:focus {
				background: @InteractiveBackgroundColorHover;
				color: @AudiWeiss;
				border-color: @InteractiveBackgroundColorHover
			}

			&.current {
				border: 1px solid @VisualLayoutingColor;
				border-bottom-color: @AudiWeiss;
				border-radius: 0;
				background-color: @AudiWeiss;

				> a {
					&, & > *, &:hover, &:active, &:focus {
						border: 0;
						color: @AudiSchwarz;
						border-radius: 0;
						font-family: @AudiTypeExtendedBold;
						font-weight: bold;
						cursor: default;
					}
				}
			}

			> a {
				padding: 5px 12px 25px;
				display: inline-block;
				cursor: pointer;
				border-radius: 0;
				border: 0;
				/* I FEEL SO DIRTY */
				margin-bottom: -25px;

				&, & > * {
					font-family: @AudiTypeExtendedNormal;
					line-height: 20px;
					color: @AudiWeiss;
					font-size: 11px;
				}
				&:hover, &:active, &:focus {
					background: @InteractiveBackgroundColorHover;
					color: @AudiWeiss;
					border-color: @InteractiveBackgroundColorHover
				}
			}
		}
	}

	.tabContent {
		border-top: 1px solid @VisualLayoutingColor;
	}
}