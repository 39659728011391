@import "../../../../variableProvider";

.webtm {
	.webtm-attributeTupel {
		display: table;
		padding: 5px 0;
		width: 100%;

		label {
			width: 150px;
		}

		> * {
			display: table-cell;
		}
	}

	div.bas-formBox, .field {
		padding: 0 0 0 0;
		margin: 5px 0 5px 10px;
	}

	#editMaskContainer {
		div.bas-formBox {
			position: relative;
		}

		.bas-formSection {

			.hover {
				background: @LichtGrau30;
				box-shadow: 0px -1px 0 4px @LichtGrau30;

				> *:not(.webtm-iconContainer) {
					opacity: 0.5;
				}
			}

			.locked {
				background: @InputInactiveColor;
				box-shadow: 0px -1px 0 4px @InputInactiveColor;

				> *:not(.webtm-iconContainer) {
					opacity: 0.5;
				}

				&:not(.hover) {
					.item.horizontal.ym-clearfix.webtm-iconContainer {
						display: block;

						.edit_small {
							display: none;
						}
					}
				}
			}

			.item.horizontal.ym-clearfix.webtm-iconContainer {
				display: none;
				position: absolute;
				top: -2px;
				right: -2px;
				transition: all 0.1s linear;
				width: auto;
			}
		}
	}

	&.dialogWrapper {
		overflow-y: hidden;
		overflow-x: hidden;

		input[type=text], input[type=password], select:not([size]), textarea, .inlineckeditor {
			margin-bottom: 0;
			width: 346px;
		}

		input[type="file"] {
			display: block;
		}

		input[type=checkbox] {
			vertical-align: bottom;
		}

		div.inputSeparator {
			margin-bottom: 5px;

			&.last {
				display: none;
			}
		}
	}

	.dialogHeader .destroyDialog {
		right: 0;
	}
}

*[data-webtmid].hover .webtm-iconContainer {
	display: block ! important;
}

.childrenAttrEditArea {
	display: inline-block;

	> * {
		display: block;
	}
}