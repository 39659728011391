@import '../../utilities/lib';
.legend {
	font-size: 11px;
	line-height: 18px;
	margin-top: 15px;
	width: 100%;
	border-collapse: separate;
	border-spacing: 0 5px;
	&.vertical {
		width: auto;
		.legendHeader , .legendBody .legendItem {
			float: none;
		}
	}

	&.horizontal {
		display: inherit;
	}
}

.legendElement , .legendText {
	display: table-cell;
	padding-left: 5px;
	&.mtpage_current {
		border-bottom: 2px solid @AudiRot
	}
}

.legendElement {
	padding-right: 3px;
	padding-left: 2px;
	vertical-align: top;
	text-align: center;
	.selectTreeLegendInvisible {
		.doubleStrike (0 , #9f9f9f , 30%);
	}

	.selectTreeLegendEditTemplatesAll {
		.doubleStrike (0 , #000 , 30%);
	}
}

.legendItem {
	.legendElement {
		&.calendar_valid , &.calendar_invalid &.calendar_today , &.calendar_early {
			height: 15px;
			width: 20px;
			font-weight: bold;
			background-color: @AudiWeiss;
			border: 1px solid @Schwarz;
		}

		&.calendar_today {
			color: @AudiWeiss;
			background-color: @Schwarz;
		}
		&.calendar_invalid {
			color: @HellGrau60;
			border: 1px solid @HellGrau60;
		}

		&.calendar_early {
			color: @PrimaryCiColor;
		}

		&.calendar_booked {
			width: 22px;
			height: 15px;
		}
	}
}

.legendGroup {
	&.horizontal .legend {
		width: auto;
	}
}

