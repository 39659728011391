@import 'variab.less';
.defaultButton () {
	& , &:focus , &:active {
		background-color: @InteractiveBackgroundColor;
		padding: 0 12px;
		margin-right: 10px;
		min-height: 32px;
		color: @InteractiveFontColor;
		display: inline-block;
		font-family: @AudiTypeBold;
		font-size: 13px;
		line-height: 32px;
		cursor: pointer;
		&:hover {
			text-decoration: none;
			background-color: @InteractiveBackgroundColorHover;
			color: @InteractiveFontColor;
		}
	}
}

.defaultButtonHighlight () {
	& , &:focus , &:active {
		background-color: @InteractiveBackgroundColorHighlight;
		color: @InteractiveFontColor;
		&:hover {
			background-color: @InteractiveBackgroundColorHighlightHover;
			color: @InteractiveFontColor;
		}
	}
}

