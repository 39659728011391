@import '../utilities/lib.less';
.image-border {
	border: 1px solid @LichtGrau;
}

.cms-edit-image-preview {
	border: 1px solid @LichtGrau;
	&.cols5.pictureinfo {
		padding-right: 10px;
	}
}

.cms-formattedlink {
	margin: 3px 0;
}

div.greyText {
	font-family: @AudiTypeNormal;
	font-weight: normal;
	color: @MittelGrau;
	padding-left: 16px;
}

#text-and-links {
	padding-top: 15px;
	.two-columns {
		&-left {
			padding: 0 20px 10px 5px;
			width: 50%;
			border-right: 1px solid @LichtGrau;
		}

		&-right {
			padding: 0 20px 10px 25px;
			width: 50%;
			td {
				border-bottom: 0 none;
			}
		}
	}
}

