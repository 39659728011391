@import "../../utilities/lib.less";

.itemHeadline {
	font-size: 14px;
	font-family: @AudiTypeBold;
}

.itemHeadline {
	margin-top: 3px;
}

.highlightFlash {
	box-shadow: 0 0 10px 0 @PrimaryCiColor;
}

.groupSeparator[data-toggleable="true"] {
	cursor: pointer;
	margin-top: 0;
}
