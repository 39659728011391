@import '../../utilities/lib.less';
@iconPath: '../../../img/icons';

.marketing-planer-kalender {
	&.sticky .cal_header_sticky {
		background-color: @DefaultBackgroundColor;
		border-bottom: 1px solid grey;
	}

	.cal_header {
		.next {
			background-position: 0 0;
		}

		.cal_row.header.rough {
			font-family: @AudiTypeExtendedBold;

			.cal_cell {
				border-left-color: @SecondaryFontColor;
			}
		}
	}

	.cal_today_font {
		color: @PrimaryCiColor;
		font-family: @AudiTypeExtendedBold;
	}

	.cal_today_indicator {
		border-right-color: @PrimaryCiColor;
	}


	.calender {
		border-color: @SecondaryFontColor;

		.expand.icon {
			&.openAll {
				background-image: url("@{iconPath}/plus.png");

				&:hover {
					background-image: url("@{iconPath}/plus_hover.png");
				}
			}

			&.closeAll {
				background-image: url("@{iconPath}/minus.png");

				&:hover {
					background-image: url("@{iconPath}/minus_hover.png");
				}
			}
		}

		.cal_row {

			.cal_campaign,
			.cal_werbemittel {
				border-color: @SecondaryFontColor;

				&.info .defaultLink:not(.icon) {
					padding-left: 0;
					background: transparent;
				}

				>div {
					border-color: @SecondaryFontColor;
				}
			}

			.cal_cell {
				border-color: @SecondaryFontColor;
			}
		}
	}
}

.cal_legende,
.marketing-planer-kalender {
	&:not(.cannot_follow) {

		.wm,
		.kampagne,
		.cal_legende_type .cal_legende_type_preview {
			&.normal:not(.teilgenommen) {
				background: @NormalNotFollowedGanttBackground;
			}
		}
	}

	.kampagne {
		&.own {
			border-style: solid;
			border-color: #1a1a1a;
			background-color: @OwnGanttBackground;
		}
	}

	.wm,
	.cal_legende_type .cal_legende_type_preview {
		&.werbezeitraum {
			border-color: black;
			background-color: transparent !important;
		}

		&.normal {
			background-color: @NormalGanttBackground;
		}

		&.national {
			background-color: @NationalGanttBackground;
		}

		&.sperrfrist {
			background-color: @SperrfristGanttBackground;
		}

		&.own {
			border-style: solid;
			border-color: #1a1a1a;
			background-color: @OwnGanttBackground;
		}

		&.erscheinungstermin {
			background-color: @Yellow;
		}
	}
}

.marketing-planer-kalender .calender .expand.icon {

	&.openAll,
	&.closeAll {
		margin-left: @ExpandIconOffset;
	}
}