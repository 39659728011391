@import '../utilities/lib';
@imgBreadPath : '../../img/breadcrumb';
#breadcrumb {
	border-bottom: 1px solid @VisualLayoutingColor;
	height: 30px;
	white-space: nowrap;
	margin-bottom: 30px;
	a span , div.currentNode span {
		line-height: 30px;
	}

	div.currentNode {
		font-family: @AudiTypeExtendedBold;
		div.leftEdge {
			background: url('@{imgBreadPath}/b_leftEdge_white.png');
		}

		div.centerPiece {
			background: url('@{imgBreadPath}/centerPiece_white.png');
		}

		div.rightEdge {
			background: url('@{imgBreadPath}/rightEdge_white.png');
			width: 23px;
		}
	}

	a {
		background: none;
		color: #fff;
		padding: 0;
		font-family: @AudiTypeExtendedNormal;
		&:hover {
			div.leftEdge {
				background: url('@{imgBreadPath}/b_leftEdge_hover.png');
			}

			div.centerPiece {
				background: url('@{imgBreadPath}/centerPiece_hover.png');
			}

			div.rightEdge {
				background: url('@{imgBreadPath}/rightEdge_hover.png');
			}
		}

		&.first {
			div.leftEdge {
				background: url('@{imgBreadPath}/leftEdge_first_black.png');
				margin-left: 0;
			}

			div.centerPiece {
				padding-left: 0;
			}

			&:hover div.leftEdge {
				background: url('@{imgBreadPath}/leftEdge_first_hover.png');
			}
		}
	}

	div.leftEdge {
		margin-left: -9px;
		background: url('@{imgBreadPath}/b_leftEdge_black.png');
		width: 12px;
	}

	div.leftEdge , div.rightEdge , div.centerPiece {
		float: left;
	}

	div.leftEdge , div.rightEdge , div.centerPiece , div.currentNode {
		height: 30px;
		display: inline-block;
	}

	div.leftEdge , div.rightEdge {
		background-repeat: no-repeat;
	}

	div.centerPiece {
		background: url("@{imgBreadPath}/centerPiece_black.png") repeat-x;
		padding-left: 12px;
	}

	div.rightEdge {
		background: url('@{imgBreadPath}/rightEdge_black.png');
		width: 23px;
	}

	.scrollable div.currentNode div.rightEdge {
		background: url('@{imgBreadPath}/rightEdge_last_white.png');
		width: 12px;
	}

	.control {
		&.next , &.previous {
			width: 30px;
			height: 30px;
			background-position: left center;
			cursor: pointer;
		}

		&.next {
			background-image: url('@{imgBreadPath}/pfad-rechts-blaettern.png');
			&:hover {
				background-image: url('@{imgBreadPath}/pfad-rechts-blaettern-rollover.png');
				&.inactive {
					background-image: url('@{imgBreadPath}/pfad-rechts-blaettern-inaktiv.png');
				}
			}

			&.inactive {
				background-image: url('@{imgBreadPath}/pfad-rechts-blaettern-inaktiv.png');
			}
		}

		&.previous {
			background-image: url('@{imgBreadPath}/pfad-links-blaettern.png');
			&:hover {
				background-image: url('@{imgBreadPath}/pfad-links-blaettern-rollover.png');
				&.inactive {
					background-image: url('@{imgBreadPath}/pfad-links-blaettern-inaktiv.png');
				}
			}

			&.inactive {
				background-image: url('@{imgBreadPath}/pfad-links-blaettern-inaktiv.png');
			}
		}
	}

	.breadcrumbContainer {
		overflow: hidden;
	}

	.controlPane {
		&.left {
			padding-right: 5px;
		}

		&.right {
			padding-left: 5px;
		}
	}

	.ym-col1 , .ym-col2 {
		width: 35px;
	}

	.ym-col3 {
		margin: 0 35px;
	}
}

#breadcrumb , #breadcrumb a {
	font-size: 11px;
}

