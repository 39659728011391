@import '../../utilities/lib.less';
.important, .selected {
	color: @PrimaryCiColor;
}

.secondaryText {
	color: @SecondaryFontColor;
}

.bold {
	font-family: @AudiTypeBold;
}


span {

	&.requiredMarker:after{
		content: ' *)';
	}
}

sup[data-footnote-id] > a {
	color: @PrimaryCiColor;
	text-decoration: none;
}

.hint.inText {
	margin-top: 0;
}
.inactiveText{
	color: @MittelGrau;
}
