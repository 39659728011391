@import '../utilities/lib';

#page {
	width: 1280px;
	padding-top: 30px;
}

#main {
	width: 100%;
}

#content {
	&:not(.fullWidth) {
		width: @ColsInput6;
	}

	&.fullWidth {
		width: @ContentFullWidth;
	}

	float: right;
}

.input-prompt {
	margin-top: 0;
}

div.placeholderpicture {
	background-color: @HellGrau30;
	margin-bottom: 0;
}

td div.placeholderpicture {
	padding: 0;
	margin: 0;
	background-color: @AudiWeiss;
}

span + .dummyLink,
.dummyLink + span,
.cms-formattedlink span + .defaultLink,
.cms-formattedlink .defaultLink + span {
	margin-left: @GuttingDefault;
}

.svg_info {
	height: 1.5em;
	enable-background: new 0 0 26 26;

	circle {
		fill: black;
	}

	.i_icon {
		fill: white;
	}
}

.mobile-login &.bas-inputArea &.cols2 {
	width: 140px;
}
