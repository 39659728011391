@import '../../utilities/lib.less';

div.bas-inputButton {
	&.withOptions {
		.optionTrigger.icon {
			> svg.preferences_scheduled .cog {
				.firefox & {
					.transform-origin(50% 50%);
				}

				.chrome &,
				.ie & {
					.transform-origin(16px 16px);
				}
			}

			> svg.preferences .cog {
				.transform-origin(16px 16px);
			}
		}
	}
}

.button, .button.dummy {
	&, a& {
		.defaultButton ();
	}
	&.defaultLink {
		background-image: none;
	}
}

.bas-inputButton {
	#login {
		background-image: url("../../../img/icons/doppelpfeil-weiss.png");
		background-repeat: no-repeat;
		background-position: right center;
		background-color: @InteractiveBackgroundColor;
		padding-right: 23px;
		border-right: 12px solid @InteractiveBackgroundColor;
		&:hover , &:focus {
			background-color: @InteractiveBackgroundColorHover;
			border-right: 12px solid @InteractiveBackgroundColorHover;
		}
	}

	&.inactive {
		.button , .button:focus , .button:active , .button:hover {
			background-color: @InteractiveBackgroundColorInactive;
			cursor: default;
		}
	}

	&.dummy .button {
		&:hover , &:focus , &:active {
			background-color: @InteractiveBackgroundColor;
		}
	}

	&.withOptions {
		.button.withOptions , .optionTrigger {
			border: 2px solid @InteractiveBackgroundColor;
			background: @InteractiveFontColor;
			float: left;
			height: 32px;
		}

		.button.withOptions {
			border-right: 0 solid transparent;
			color: @PrimaryFontColor;
			line-height: 28px;
			margin-right: 0;
		}

		&.active {
			.button.withOptions , .optionTrigger {
				border-color: @PrimaryCiColor;
			}
			
			.button.withOptions {
				color: @PrimaryCiColor;
			}
		}

		.optionTrigger {
			border-left: 0 solid transparent;
			padding-right: 0;

			&.icon {
				width: 28px;
			}
			
			> svg.preferences .cog, > svg.preferences_scheduled .cog {
				transition-duration: 0.8s;
				transition-property: transform;
			}
			
			&:hover {
				svg.preferences .cog, svg.preferences_scheduled .cog{
					.rotate (360deg);
				}
				.hoverContainer {
					padding-bottom: 10px;
					bottom: 28px;
					left: -11px;
				}
			}

			.hoverContainer {
				.buttonOptions {
					background-color: @AudiWeiss;
					border: 2px solid @PrimaryCiColor;
					z-index: @Index_AjaxOverlay - 1;
					&:before , &:after {
						width: 0;
						height: 0;
						z-index: @Index_AjaxOverlay - 1;
						border-style: solid;
					}

					&:before {
						left: 2px;
						bottom: -10px;
						border-width: 10px 10px 0 10px;
						border-color: @PrimaryCiColor transparent transparent transparent;
					}

					&:after {
						left: 4px;
						bottom: -8px;
						border-width: 8px 8px 0 8px;
						border-color: @InteractiveFontColor transparent transparent transparent;
					}

					.buttonOption {
						display: block;
						padding-right: 10px;
					}
				}
			}
		}
	}
}

html.edge {
	.bas-inputButton.withOptions .optionTrigger > svg.preferences {
		padding: 0;
	}
}

.highlight .button {
	.defaultButtonHighlight ();
}

.button.inactive, .button.inactive:hover{
	background-color: @InteractiveBackgroundColorInactive;
}