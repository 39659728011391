@import '../utilities/lib';
.hasUrlChecker {
	display: block;
}

.browserOutdatedImg {
	float: left;
	margin-top: 40px;
	margin-left: 20px;
}

.browserOutdatedText {
	margin-top: 20px;
	div {
		margin-top: 20px;
	}
}

.browserOutdatedWarn {
	color: @Color_Error;
	font-weight: bold;
	text-decoration: none;
}

.extraBottomSpacing {
	padding-bottom: 20px;
}

.ym-grid.layoutGrid.contentWidth {
	input , textarea {
		margin-right: 10px;
	}
}

.ym-equalize {
	overflow: auto;
	> [class *= "ym-g"] > [class *= "ym-gbox"] {
		margin-bottom: 0;
		padding-bottom: 0;
	}
}

@media print {
	.navigationRow , #sidebar , #breadCrumb , .field.buttonline , #footer {
		display: none;
	}

	#header {
		width: auto !important;
	}

	#content {
		float: none;
		width: 100% !important;
		margin-left: 0 !important;
	}
}

.affiliationFrame {
	border: 1px solid @VisualLayoutingColor;
	padding: 10px;
	&.hidden {
		border: 0 none;
		padding: 0;
	}
}

.whitespace {
	&_bottom {
		margin-bottom: 30px;
	}
}

div.bas-inputArea {
	img {
		&.cols3 {
			width: auto;
			max-width: @ColsInput3;
		}
	}

	.pictureinfo.cols3 {
		width: @ColsInput3;
		box-sizing: border-box;
		padding: 10px 10px;
	}
}

.layoutFooter{
	z-index: -1;
}
