@import '../../utilities/lib.less';
@iconPath: '../../../img/icons';
.panel {
	&.general {
		margin-bottom: 2px;
	}

	&.searchmask {
		margin-bottom: 15px;
	}

	&.containsErrors {
		border: 1px solid @AudiRot;
		.panelHeader {
			.panelLabel label, .counter {
				color: @AudiRot
			}
		}
	}
}

div.panel.selecttree select {
	visibility: inherit;
	height: auto;
}

.panelHeader {
	background-color: @HellGrau30;
	vertical-align: middle;
	padding: 5px 10px;
	font-family: @AudiTypeExtendedBold;
	font-size: 15px;
	line-height: 20px;
	.toggleIcon {
		margin-top: 5px;
		background-repeat: no-repeat;
		background-position: 0 0;
		background-image: url("@{iconPath}/plus.png");
	}

	&:hover {
		.toggleIcon {
			background-image: url("@{iconPath}/plus_hover.png");
		}
	}

	&.expand {
		.toggleIcon {
			background-image: url("@{iconPath}/minus.png");
		}

		&:hover .toggleIcon {
			background-image: url("@{iconPath}/minus_hover.png");
		}
	}

	.counter {
		margin-left: 5px;
	}
}

.toggle .panelHeader:hover {
	color: @PrimaryCiColor;
}

.toggleIcon {
	height: 10px;
	width: 10px;
	float: left;
	margin-right: 10px;
}

.panelContent {
	padding: 5px 10px;
	&.searchmask {
		padding: 0 0 0 0;
		.bas-formBox {
			padding: 5px 10px;
		}
	}

	.bas-formBox {
		border: none;
		display: block;
		width: auto;
	}

	.field.buttonline {
		margin-top: 0;
		padding-top: 10px;
		border-top: 1px solid @VisualLayoutingColor;
	}
}

.lt-ie9 .panelContent {
	.marginContainer {
		margin-bottom: 0;
		.chosen-container {
			position: relative;
		}
	}
}

.toggleAllPanelWrapper {
	height: 40px;
	.bas-inputButton {
		> .button {
			position: absolute;
			right: 0;
			margin-right: 0;
			margin-bottom: 0;
			padding: 0 12px;
		}
	}
}

