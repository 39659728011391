@import '../../utilities/lib';
.picturePreview {
	margin-left: 15px;
	.itemBox {
		padding-right: 0;
	}

	.buttonline {
		border: 1px solid @VisualLayoutingColor;
		border-top: 0 none;
	}
}

.pictureinfo {
	border: 1px solid @VisualLayoutingColor;
	padding: 10px;
	span {
		display: block;
	}

	img.lightboxPreview, embed#overview {
		width: 100%;
		display: block;
		margin: 0 auto
	}
}

