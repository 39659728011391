@import "../../utilities/lib.less";

@imgPathEdit: "../../../img/icons/edit";
@bgOptions: no-repeat center center;

div.overviewButton {
	cursor: pointer;
	margin-top: 5px;
	margin-right: 5px;
}

.overviewArea .bas-inputText {
	margin-right: 5px;
}

div.zoomIn {
	background: url("@{imgPathEdit}/lupe-vergroessern.png") @bgOptions;

	&:hover {
		background: url("@{imgPathEdit}/lupe-vergroessern-rollover.png") @bgOptions;
	}
}

div.zoomOut {
	background: url("@{imgPathEdit}/lupe-verkleinern.png") @bgOptions;

	&:hover {
		background: url("@{imgPathEdit}/lupe-verkleinern-rollover.png") @bgOptions;
	}
}

div.zoomDynamic {
	background: url("@{imgPathEdit}/ausschnittvergroessern.png") @bgOptions;

	&:hover {
		background: url("@{imgPathEdit}/ausschnittvergroessern-rollover.png") @bgOptions;
	}

	&.active {
		background: url("@{imgPathEdit}/ausschnittvergroessern-rollover.png") @bgOptions;
	}
}

div.scaleX {
	background: url("@{imgPathEdit}/skalieren-breite.png") @bgOptions;

	&:hover {
		background: url("@{imgPathEdit}/skalieren-breite-rollover.png") @bgOptions;
	}
}

div.scaleY {
	background: url("@{imgPathEdit}/skalieren-hoehe.png") @bgOptions;

	&:hover {
		background: url("@{imgPathEdit}/skalieren-hoehe-rollover.png") @bgOptions;
	}
}

div.allrectangles {
	background: url("@{imgPathEdit}/individualisierung.png") @bgOptions;

	&.active,
	&.active:hover,
	&:hover {
		background: url("@{imgPathEdit}/individualisierung-rollover.png") @bgOptions;
	}
}

div.pdfDownload {
	background: url("@{imgPathEdit}/pdf.png") @bgOptions;

	&:hover {
		background: url("@{imgPathEdit}/pdf-rollover.png") @bgOptions;
	}
}

div.pdfDownload_all {
	background: url("@{imgPathEdit}/pdf-alle.png") @bgOptions;

	&:hover {
		background: url("@{imgPathEdit}/pdf-alle-rollover.png") @bgOptions;
	}
}

div.pdfsuperview,
div.pdfsuperview_all {
	background: url("@{imgPathEdit}/superview.png") @bgOptions;

	&:hover {
		background: url("@{imgPathEdit}/superview-rollover.png") @bgOptions;
	}
}

div.minimize {
	background: url("@{imgPathEdit}/superview_minimize.png") @bgOptions;

	&:hover {
		background: url("@{imgPathEdit}/superview_minimize-rollover.png") @bgOptions;
	}
}

div.reset {
	background: url("@{imgPathEdit}/reset.png") @bgOptions;

	&:hover {
		background: url("@{imgPathEdit}/reset-rollover.png") @bgOptions;
	}
}

div.reload {
	background: url("@{imgPathEdit}/refresh.png") @bgOptions;

	&:hover {
		background: url("@{imgPathEdit}/refresh-rollover.png") @bgOptions;
	}
}

a.defaultLink.icon.reload {
	width: 20px;
	height: 20px;
	background: url("@{imgPathEdit}/refresh.png") @bgOptions;

	&:hover {
		background: url("@{imgPathEdit}/refresh-rollover.png") @bgOptions;
	}
}

.overviewButtons input.zoomInput {
	margin-top: 7px;
	margin-right: 5px;
}

#editMask .superView .overviewButtons .bas-inputText input.zoomInput {
	margin-top: 2px;
}

a.icon.reload {
	background: url("@{imgPathEdit}/refresh.png") no-repeat;
	width: 20px;
	height: 20px;
	margin: 5px 5px 0 0;
	padding-right: 0;

	&:hover {
		background: url("@{imgPathEdit}/refresh-rollover.png") no-repeat;
	}
}

#editMask {
	margin-bottom: 30px;

	#overviewArea {
		width: 100%;
	}

	div.groupSeparator:first-child {
		margin-top: 0;
	}

	.ym-gl #overviewContainer {
		padding-right: 10px;

		.field.noLabel {
			display: block;
		}
	}

	#editMaskContainer {
		#editMaskBox {
			padding-left: 10px;
		}

		.imgButton {
			&:not(.longImgButtonLine) {
				width: 175px;
			}
		}
	}

	.field {
		.ym-gl {
			padding-right: 0;
		}

		.ym-gr {
			padding-left: 0;
		}

		.ym-gl,
		.ym-gr {
			padding-bottom: 10px;
			clear: right;
		}
	}

	.bas-inputArea {
		> span {
			display: inline-block;
		}
	}

	input,
	textarea {
		margin-bottom: 3px;
	}

	div.bas-inputCheck {
		span {
			line-height: 23px;
		}

		div.radio {
			background-position: left 4px;
			height: 21px;
		}
	}

	.tooltipTrigger.infoIcon {
		display: inline-block;
		vertical-align: top;
	}

	.itemBox .bas-inputCheck {
		margin-right: 0;
	}
}

.overviewArea {
	background: transparent;
}

.overviewImage {
	background: transparent;
	border: 0 none;

	.image,
	embed#overview {
		border: 1px solid @VisualLayoutingColor;
	}

	&.standard,
	&.pos,
	&.newsletter,
	&.newslettertm,
	&.some {
		height: 600px;
	}

	div.image {
		position: absolute;
	}

	&.newsletter div.image {
		border: none;
	}

	&.newslettertm {
		border: 1px solid @VisualLayoutingColor;

		div.image {
			border: none;
		}
	}

	&.some {
		.image {
			width: calc(100% - 2px);
			height: calc(100% - 2px);

			.videoNotReadyHint {
				border-bottom: 1px solid @VisualLayoutingColor;
			}
		}
	}
}

.overviewButtons {
	background: @HellGrau30;
	border: 0 none;

	div.verticalSeparator {
		margin-right: @GuttingDefault;
	}
}

.superviewContainer {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: 50px;
	padding: 30px 22px;
	background: #ffffff;

	.overviewArea {
		position: relative;
		width: 100%;
		height: 100% ! important;
		.box-sizing (border-box);
	}

	.overviewButtons {
		position: relative;
		bottom: auto;
		border-radius: 0;
		box-shadow: 0 0 0 transparent;
		height: 30px;
		text-align: left;
		margin-bottom: 10px;
	}

	.hasMultiPagePreview .overviewButtons {
		margin-left: 150px;
	}

	.close {
		background: url("../../../img/icons/layer-schliessen-passiv.png");
		width: 32px;
		height: 32px;

		&:hover {
			background: url("../../../img/icons/layer-schliessen-rollover.png");
		}
	}
}

.overlay {
	.superviewContainer {
		.overviewImage {
			height: 100%;
			width: 100%;
			position: absolute;
			margin-left: 0;
		}

		.hasMultiPagePreview .overviewImage {
			border-left: solid 150px transparent;
		}

		.overviewArea {
			padding: 0;
		}

		.jScrollPaneScrollable {
			position: absolute;
			top: 0;
			overflow: hidden;
		}
	}

	.jspVerticalBar {
		width: 10px;
		background: transparent;
	}

	.jspTrack {
		background: transparent;
	}

	.jspDrag {
		border-radius: 5px;
		background: @MittelGrau;
		opacity: 0.75;
	}

	.multiPageSelect {
		width: 130px;
		overflow: hidden;

		&.loading {
			background: @HellGrau30;
		}

		.imageContainer {
			margin: 0 14px 7px 0;

			img {
				border: solid 1px @MittelGrau;
				opacity: 0.75;

				&:hover,
				&.current {
					border-color: @PrimaryCiColor;
					opacity: 1;
				}
			}
		}
	}
}

.overviewContainer {
	height: 100%;
}

div.overlay div.superviewContainer div.multiPageSelect {
	bottom: 24px;
}

#templateTitle {
	font-family: @AudiTypeExtendedBold;
	font-size: 18px;
	line-height: 25px;
}

.hasMultiPagePreview #templateTitle {
	margin-left: 150px;
}

#editMaskContainer {
	.ym-grid select.co2-select-filter {
		width: 174px ! important;
	}

	.charcounter {
		width: 100%;
	}

	.bas-formSection .bas-label label div.item.horizontal.ym-clearfix {
		display: block;
	}

	input[type=text],
	textarea,
	select.combo,
	.inlineckeditor {
		&:not([class*="cols"]) {
			width: 100% ! important;
		}
	}

	.item.horizontal {

		input[type=text],
		textarea,
		.inlineckeditor {
			width: 100% ! important;
		}

		input[type=text].cols3,
		select.cols3,
		textarea.cols3,
		.inlineckeditor.cols3 {
			width: 335px ! important;
		}

		> .itemBox + .itemBox > .bas-inputCheck {
			margin-left: 15px;
		}
	}

	input[type=text][size],
	textarea[cols] {
		width: auto ! important;
	}

	.item.vertical {
		.item.horizontal > .itemBox {
			&:not(.contains-tooltip) {
				padding-right: 0;
			}
		}

		> .itemBox:not(.first) {
			margin-top: 15px;
		}
	}

	.buttonline {
		padding-top: 0;

		.icon {
			padding-right: 0;
		}

		.defaultLink.inactive.icon.text.lock_button_inactive.lockCheckboxes {
			margin-right: 10px;
		}

		.defaultLink.inactive.icon.text.unlock_button_inactive.unlockCheckboxes {
			margin-left: 20px;
		}
	}

	.bas-inputCheck.bas-inputRadio {
		margin-right: 0;
	}

	div.bas-formBox {
		&.admin {
			background-color: @VisualLayoutingColor;
		}

		&.familylibrary {
			background-color: #96ffff;
		}
	}

	.itemBox:has(input[type="checkbox"]) + .itemBox.contains-input {
		flex-grow: 1;
	}
}

.imgButton_container {
	width: auto;
	outline: 2px solid @InteractiveBackgroundColor;

	img {
		background-color: @InteractiveBackgroundColor;
		width: 100%;
	}

	&:hover {
		outline-color: @InteractiveBackgroundColorHighlightHover;

		img {
			background-color: @InteractiveBackgroundColorHighlightHover;
		}
	}

	&.selected,
	&.selected[data-disabled]:hover {
		outline-color: @InteractiveBackgroundColorHighlightHover;

		img {
			background-color: @InteractiveBackgroundColorHighlightHover;
		}
	}

	.isSelected {
		background-color: @DefaultBackgroundColor;
		border-color: @DefaultBackgroundColor;
	}
}

div.charcounter {
	font-size: 11px;
	padding-right: 0px !important;

	&.error {
		background: none;
	}
}

#overviewContainer .overviewImage .overlay,
.overlay.editView .overlay {
	position: absolute;
}

span.label-after-trigger {
	margin-left: 5px;
}

div[id ^="caret:"].active {

	> div,
	div[id ^="caret:"].active {
		border-color: @PrimaryCiColor;
	}

	&.focus > div,
	div[id ^="caret:"].inactive:hover > div,
	&:hover > div {
		border-color: @SuccessColor;
	}
}