.headerWrapper {
	width: 100%;
	height: 120px;
	position: relative;
	top: 0;
	left: 0;
	.backgroundContainer {
		background: #E5EAEE;
		height: 100%;
		width: 50%;
		float: left;
		position: absolute;
		top: 0;
		left: 0;
	}
}

#header {
	width: 1185px;
	margin: 0 auto;
	height: 120px;
	background: url("../../../img/header/verlauf.png") repeat-y;
}

#rings {
	position: absolute;
	right: 65px;
	top: 30px;
}

#logo {
	left: 58px;
	top: 40px;
	position: absolute;
	a.defaultLink {
		padding-left: 0;
	}
}

.navigationRow {
	position: absolute;
	bottom: 0;
	width: 1185px;
	min-height: 17px;
}

.headerSubContainer {
	margin-right: 15px;
	float: right;
	position: absolute;
	right: 0;
	top: -3px;
	a {
		font-size: 12px;
	}
}

