.jstree-default {
	li.jstree-checked a.jstree-hovered {
		ins.jstree-checkbox {
			background-position: -38px -37px;
		}
	}

	ins {
		&.jstree-checkbox , &.jstree-icon {
			background-image: url("../../img/audi-jstree-icons-sprite.png");
		}
	}
}