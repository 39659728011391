@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "./style/variableProvider.less";

.cdk-global-scrollblock bas-root{
	position: relative;
	z-index: 0;
}

.cdk-overlay-dark-backdrop {
	background: rgba(0, 0, 0, 0.7);
}

.cdk-overlay-container{
	z-index: @Index_OverlayContainer;
}

.cdk-overlay-pane {
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: 100%;

	mat-dialog-container {
		grid-column: 1;
		grid-row: 1;
		font-family: @AudiTypeNormal;
	}

	.mat-dialog-actions {
		margin-bottom: -22px;
	}
}

.dialog-custom-padding {
	mat-dialog-container {
		padding:50px;
	}
}

.mat-mdc-progress-spinner circle, .mdc-circular-progress, .mat-spinner circle {
	stroke: @AudiSchwarz;
}


.mat-dialog-title {
	font-family: inherit;
}

.mat-dialog-container
{
	max-height: unset !important;
}


svg.no-transition:not(:hover) {
	.svg-transition {
		display: none;
	}
}
svg.no-transition:hover {
	.svg-hide-transition {
		display: none;
	}
}
svg:not(.no-transition):not(:hover) {
	.svg-transition {
		fill: transparent;
	}
}

//Das Drecksteil von IE kann das nicht. Pech gehabt.
//Das Team hat entschieden, dass IE-NutzerInnen keine sticky Images brauchen.
.stickyImage{
	position: sticky;
	top: 0;
	z-index: 1;
}

// keine sticky Images beim userpicture-choose
form[action="/edit-userpicture-choose.do"] .stickyImage,
form[action="/audi/edit-userpicture-choose.do"] .stickyImage {
	position: static;
}
