@import '../utilities/lib.less';
@buttonPath: '../../img/btn';
@frameworkImagePath: '../../img';
.paginationFastJump , .paginationPageJump , .paginationCurrentPage , .paginationDots {
	height: 30px;
	min-width: 32px;
	line-height: 30px;
	display: inline-block;
	text-align: center;
	vertical-align: top;
	padding: 0 2px;
	background: none;
}

.paginationFastJump {
	background-repeat: no-repeat;
	&.previous {
		padding-right: 1px;
		background-image: url('@{buttonPath}/pfad-links-blaettern.png');
		&:hover {
			background-image: url('@{buttonPath}/pfad-links-blaettern-rollover.png');
		}

		&.disabled , &.disabled:hover {
			background-image: url('@{buttonPath}/pfad-links-blaettern-inaktiv.png');
		}
	}

	&.next {
		margin-left: 1px;
		background-image: url('@{buttonPath}/pfad-rechts-blaettern.png');
		&:hover {
			background-image: url('@{buttonPath}/pfad-rechts-blaettern-rollover.png');
		}

		&.disabled , &.disabled:hover {
			background-image: url('@{buttonPath}/pfad-rechts-blaettern-inaktiv.png');
		}
	}
}

.paginationPageJump.predecessorToCurrent , .paginationPageJump.last {
	border-right: none;
}

.paginationPageJump , .paginationFastJump.first , .paginationDots {
	border-right: dotted 1px @HellGrau60;
	font-family: @AudiTypeNormal;
}

.paginationCurrentPage {
	font-family: @AudiTypeBold;
	background-color: @LichtGrau30;
}

.tablePagination, .pagination {
	border-bottom: 1px solid @HellGrau30;
	padding: 5px 5px;
	height: 50px;
	font-weight: bold;
	div.linkGroup {
		display: inline-block;
		float: left;
	}

	.resultsPerPageWrapper {
		float: right;
		display: inline-block;
		margin: 10px 0;
		> * {
			display: inline-block;
			vertical-align: top;
		}
		.bas-inputSelect {
			margin-left: 5px;
		}
	}

	.pagelength {
		width: 55px;
	}
}

.item.linkGroup {
	.editPage {
		background: @AudiWeiss;
		border-bottom: 2px solid @HellGrau60;
		padding: 8px 5px 2px 28px;
		margin: 0 6px 5px 0;
		&.notCustomizable {
			background: @AudiWeiss url('@{frameworkImagePath}/mtpage_notcustomizable.png') 0 2px no-repeat;
		}

		&.complete {
			background: @AudiWeiss url('@{frameworkImagePath}/mtpage_customizable.png') 0 2px no-repeat;
			&_accepted {
				background: @AudiWeiss url('@{frameworkImagePath}/mtpage_complete.png') 0 2px no-repeat;
			}
		}
	}

	.mtPageCurrent {
		border-bottom: 2px solid @PrimaryCiColor;
		cursor: default;
		display: inline-block;
	}

	.editPage.incomplete , span.mtPageCurrentError {
		background: @AudiWeiss url('@{frameworkImagePath}/mtpage_error.png') 0 2px no-repeat;
	}
}

