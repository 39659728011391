@import "../utilities/lib.less";

#basket {
	right: 203px;
	top: -11px;
	position: absolute;
}

.lt-ie9 {
	#basket {
		top: -13px;
	}

	#basketTrigger {
		height: 32px;
		background: #ffffff url("../../img/icons/icon_basket.png") no-repeat center 11px;
	}

	#basketContent {
		top: 33px;
	}
}

#basketTrigger {
	position: relative;
	height: 30px;
	width: 50px;
	border: 1px solid transparent;
	background: #ffffff url("../../img/icons/icon_basket.png") no-repeat center 9px;
	z-index: @Index_QuicknavTrigger;

	&.hover, &:hover {
		border-left-color: @VisualLayoutingColor;
		border-top-color: @VisualLayoutingColor;
		border-right-color: @VisualLayoutingColor;
		background-image: url("../../img/icons/icon_basket_hover.png");
		box-shadow: 0 -2px 5px -3px @VisualLayoutingColor;
		z-index: @Index_QuicknavTrigger + 2;
		border-bottom-right-radius: 2px;

		+ #basketContent {
			border-top: 1px solid @VisualLayoutingColor;
			z-index: @Index_Quicknav + 1 !important;
		}
	}

	&:not(.isFilled), &:not(.isFilled):hover {
		background-image: url("../../img/icons/icon_basket_inactive.png");
		opacity: 0.4;
		border: 1px solid transparent;
		color: @PrimaryFontColor;
	}
}

#basketContent {
	top: 31px;
	right: 0;
	display: none;
	border: 1px solid @VisualLayoutingColor;
	background-color: #ffffff;
	z-index: @Index_Quicknav !important;
	padding: 0 10px;
	box-shadow: 0 -2px 5px -3px @VisualLayoutingColor;

	.basketElement {
		padding: 5px;
		border-bottom: 1px solid @VisualLayoutingColor;
		min-width: 150px;

		span {
			font-weight: bold;
			font-size: 11px;
		}

		&:last-of-type {
			border-bottom: none;
		}
	}

}

