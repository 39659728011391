@import '../utilities/lib.less';
#notification_selected {
	.circle {
		fill: @DefaultBackgroundColor;
	}
}

svg {
	&.favorite_star {
		.outer {
			fill: @AudiSchwarz;
		}

		.inner {
			fill: transparent;
		}

		&:hover .outer {
			fill: @AudiRot;
		}
	}

	&.svg_scalable_template {
		.scale (1.3);
		&:hover {
			fill: @AudiRot;
		}
	}

	&.svg_info circle:hover {
		fill: @AudiRot;
	}

	&#mp_calendar , &#changed_by_user, &#home {
		width: 22px;
		height: 22px;
		padding: 2px;
		rect {
			width: inherit;
			height: inherit;
		}
		.line , .arrow {
			fill: none;
			stroke-miterlimit: 10;
		}
	}

	&#mp_calendar {
		stroke: @InteractiveFontColor;
		.line {
			stroke-width: 2px;
		}

		.cross {
			display: none;
		}
	}

	&#changed_by_user {
		stroke: @AudiSchwarz;
		stroke-width: 2px;
		margin-bottom: -9px;
		margin-top: -4px;
		&:hover {
			stroke: @InteractiveBackgroundColorHighlightHover;
		}
		.arrow {
			stroke-linecap: round;
		}
	}
	
	&#home {
		margin-top: -5px;
		margin-left: -5px;
		path {
			fill: none;
			stroke: @InteractiveBackgroundColorHover;
		}
	}
	
	&#upload-image, &#default-image, &#db-image, &#cancel:hover, &#cancel-large:hover{
		.stroke{
			fill:none;
			stroke:@AudiSchwarz;
			stroke-miterlimit:10;
		}
		
		&:hover {
			.stroke {
				stroke:@PrimaryCiColor;
			}
		}
	}
	
	&#no-image {
		.stroke{
			fill:none;
			stroke:@AudiSchwarz;
			stroke-miterlimit:10;
		}
		.picture{
			fill:none;
			stroke:@MittelGrau;
			stroke-miterlimit:10;
		}
		.circle{
			fill:@AudiWeiss;
			stroke:@AudiSchwarz;
			stroke-miterlimit:10;
		}
		&:hover {
			.stroke, .circle{
				stroke:@PrimaryCiColor;
			}
		}
	}
}

.bas-inputCheck div.checkbox.checked + label svg#mp_calendar {
	.plus {
		display: none;
	}

	.cross {
		display: block;
	}
}

.optionTrigger svg {
	&.preferences {
		width: 26px;
		padding: 3px;
	}

	&.preferences_scheduled {
		width: 27px;
	}

	&.preferences , &.preferences_scheduled {
		path , line {
			fill: @InteractiveBackgroundColor;
		}

		&:hover path , &:hover line {
			fill: @PrimaryCiColor;
		}

		.blocker {
			fill: @DefaultBackgroundColor;
		}
	}
}

.optionerror .optionTrigger svg.preferences_scheduled path {
	fill: @Color_Error;
}

html.ie11 .optionTrigger svg {
	padding: 1px;
	&.preferences , &.preferences_scheduled {
		padding: 0;
	}
}

