 @import '../../utilities/lib.less';

/*!
 * ClockPicker v0.0.7 for jQuery (http://weareoutman.github.io/clockpicker/)
 * Copyright 2014 Wang Shenwei.
 * Licensed under MIT (https://github.com/weareoutman/clockpicker/blob/gh-pages/LICENSE)
 * 
 * Bootstrap v3.1.1 (http://getbootstrap.com)
 * Copyright 2011-2014 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

/* Picked from bootstrap: .popover, .btn, .text-primary */

@White : @AudiWeiss;
@SecondaryCiColor : @PrimaryCiColor;
@PrimaryLayoutingColor : @Schwarz;
@SecondaryLayoutingColor : @VisualLayoutingColor;
@TertiaryLayoutingColor : @VisualLayoutingColor;

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1010;
  display: none;
  max-width: 276px;
  padding: 1px;
  text-align: left;
  white-space: normal;
  background-color: @White;
  background-clip: padding-box;
  border: 1px solid @PrimaryLayoutingColor;
  border-radius: 6px;
}
.popover.top {
  margin-top: -10px;
}
.popover.right {
  margin-left: 10px;
}
.popover.bottom {
  margin-top: 10px;
}
.popover.left {
  margin-left: -10px;
}
.popover-title {
  padding: 8px 14px;
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
  background-color: @White;
  border-bottom: 1px solid @PrimaryLayoutingColor;
  border-radius: 5px 5px 0 0;
}
.popover-content {
  padding: 9px 14px;
}
.popover > .arrow,
.popover > .arrow:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  /* The following are set in WordPress (wp-admin/css/revisions.css) - reset them to initial values */
  overflow:visible;
  margin:0;
  padding:0;
  z-index:auto;
  background-color:transparent;
  -webkit-box-shadow:none;
  box-shadow:none;
  bottom:auto;
  left:auto;
  right:auto;
  top:auto;
  -webkit-transform:none;
  -ms-transform:none;
  transform:none;
}
.popover > .arrow {
  border-width: 11px;
}
.popover > .arrow:after {
  content: "";
  border-width: 10px;
}
.popover.top > .arrow {
  bottom: -11px;
  left: 50%;
  margin-left: -11px;
  border-top-color: @PrimaryLayoutingColor;
  border-bottom-width: 0;
}
.popover.top > .arrow:after {
  bottom: 1px;
  margin-left: -10px;
  content: " ";
  border-top-color: @White;
  border-bottom-width: 0;
}
.popover.right > .arrow {
  top: 50%;
  left: -11px;
  margin-top: -11px;
  border-right-color: @PrimaryLayoutingColor;
  border-left-width: 0;
}
.popover.right > .arrow:after {
  bottom: -10px;
  left: 1px;
  content: " ";
  border-right-color: @White;
  border-left-width: 0;
}
.popover.bottom > .arrow {
  top: -11px;
  left: 50%;
  margin-left: -11px;
  border-top-width: 0;
  border-bottom-color: @PrimaryLayoutingColor;
}
.popover.bottom > .arrow:after {
  top: 1px;
  margin-left: -10px;
  content: " ";
  border-top-width: 0;
  border-bottom-color: @White;
}
.popover.left > .arrow {
  top: 50%;
  right: -11px;
  margin-top: -11px;
  border-right-width: 0;
  border-left-color: @PrimaryLayoutingColor;
}
.popover.left > .arrow:after {
  right: 1px;
  bottom: -10px;
  content: " ";
  border-right-width: 0;
  border-left-color: @White;
}
 .btn {
	 cursor: pointer;
	 -webkit-user-select: none;
	 -moz-user-select: none;
	 -ms-user-select: none;
	 user-select: none;
	 background-image: none;
	 border: 1px solid transparent;

 }

 //noinspection CssOverwrittenProperties
 .btn:focus,
 .btn:active:focus,
 .btn.active:focus {
	 outline: thin dotted;
	 outline: 5px auto -webkit-focus-ring-color;
	 outline-offset: -2px;
 }

 .btn:hover,
 .btn:focus {
	 color: @PrimaryLayoutingColor;
	 text-decoration: none;
}
.btn:active,
.btn.active {
  background-image: none;
  outline: 0;
}
.btn-default {
  color: @PrimaryLayoutingColor;
  background-color: @White;
  border-color: @PrimaryLayoutingColor;
}
.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default {
  color: @PrimaryLayoutingColor;
  background-color: @SecondaryLayoutingColor;
  border-color: @PrimaryLayoutingColor;
}
.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default {
  background-image: none;
}
.btn-block {
  display: block;
  width: 100%;
}
.text-primary {
  color: @SecondaryCiColor;
}

/*!
 * ClockPicker v{package.version} for Bootstrap (http://weareoutman.github.io/clockpicker/)
 * Copyright 2014 Wang Shenwei.
 * Licensed under MIT (https://github.com/weareoutman/clockpicker/blob/gh-pages/LICENSE)
 */

.clockpicker .input-group-addon {
	cursor: pointer;
}
.clockpicker-moving {
	cursor: move;
}
.clockpicker-align-left.popover > .arrow {
	left: 25px;
}
.clockpicker-align-top.popover > .arrow {
	top: 17px;
}
.clockpicker-align-right.popover > .arrow {
	left: auto;
	right: 25px;
}
.clockpicker-align-bottom.popover > .arrow {
	top: auto;
	bottom: 6px;
}
.clockpicker-popover .popover-title {
	background-color: @White;
	color: @PrimaryFontColor;
	font-size: 24px;
	font-weight: bold;
	line-height: 30px;
	text-align: center;
}
.clockpicker-popover .popover-title span {
	cursor: pointer;
}
.clockpicker-popover .popover-content {
	background-color: @TertiaryLayoutingColor;
	padding: 12px;
}
.popover-content:last-child {
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}
.clockpicker-plate {
	background-color: @White;
	border: 1px solid @PrimaryLayoutingColor;
	border-radius: 50%;
	width: 200px;
	height: 200px;
	overflow: visible;
	position: relative;
	/* Disable text selection highlighting. Thanks to Hermanya */
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.clockpicker-canvas,
.clockpicker-dial {
	width: 200px;
	height: 200px;
	position: absolute;
	left: -1px;
	top: -1px;
}
.clockpicker-minutes {
	visibility: hidden;
}
.clockpicker-tick {
	border-radius: 50%;
	color: @PrimaryFontColor;
	line-height: 26px;
	text-align: center;
	width: 26px;
	height: 26px;
	position: absolute;
	cursor: pointer;
	&.inner {
		font-size:100%;
	}
	&.outer {
		font-size:120%;
	}
}
.clockpicker-tick-blk {
	border-radius: 50%;
	color: @SecondaryFontColor;
	line-height: 26px;
	text-align: center;
	width: 26px;
	height: 26px;
	position: absolute;
	&.inner {
		font-size:100%;
	}
	&.outer {
		font-size:120%;
	}
}
.clockpicker-tick.active,
.clockpicker-tick:hover {
	background-color: var(--color--ci--secondary--alpha-25);
}
.clockpicker-button {
	background-image: none;
	background-color: @White;
	border-width: 1px 0 0;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	margin: 0;
	padding: 10px 0;
}
.clockpicker-button:hover {
	background-image: none;
	background-color: @SecondaryLayoutingColor;
}
.clockpicker-button:focus {
	outline: none!important;
}
.clockpicker-dial {
	-webkit-transition: -webkit-transform 350ms, opacity 350ms;
	-moz-transition: -moz-transform 350ms, opacity 350ms;
	-ms-transition: -ms-transform 350ms, opacity 350ms;
	-o-transition: -o-transform 350ms, opacity 350ms;
	transition: transform 350ms, opacity 350ms;
}
.clockpicker-dial-out {
	opacity: 0;
}
.clockpicker-hours.clockpicker-dial-out {
	-webkit-transform: scale(1.2, 1.2);
	-moz-transform: scale(1.2, 1.2);
	-ms-transform: scale(1.2, 1.2);
	-o-transform: scale(1.2, 1.2);
	transform: scale(1.2, 1.2);
}
.clockpicker-minutes.clockpicker-dial-out {
	-webkit-transform: scale(.8, .8);
	-moz-transform: scale(.8, .8);
	-ms-transform: scale(.8, .8);
	-o-transform: scale(.8, .8);
	transform: scale(.8, .8);
}
.clockpicker-canvas {
	-webkit-transition: opacity 175ms;
	-moz-transition: opacity 175ms;
	-ms-transition: opacity 175ms;
	-o-transition: opacity 175ms;
	transition: opacity 175ms;
}
.clockpicker-canvas-out {
	opacity: 0.25;
}
.clockpicker-canvas-bearing,
.clockpicker-canvas-fg {
	stroke: none;
	fill: @SecondaryCiColor;
}
.clockpicker-canvas-bg {
	stroke: none;
	fill: @SecondaryCiColor;
}
.clockpicker-canvas-bg-trans {
	fill: var(--color--ci--secondary--alpha-25);
}
.clockpicker-canvas line {
	stroke: @SecondaryCiColor;
	stroke-width: 1;
	stroke-linecap: round;
	/*shape-rendering: crispEdges;*/
}
.clockpicker-button.am-button {
	margin: 1px;
	padding: 5px;
	border: 1px solid @PrimaryLayoutingColor;
	border-radius: 4px;
	
}
.clockpicker-button.pm-button {
	margin: 1px 1px 1px 136px;
	padding: 5px;
	border: 1px solid @PrimaryLayoutingColor;
	border-radius: 4px;
}
