@import '../utilities/lib.less';
select[multiple] , select[size] {
	&:not(.noCustomStyling) {
		visibility: hidden;
		position: absolute;
	}
}



select.cke_dialog_ui_input_select , .cke_dialog tbody select {
	visibility: visible;
	position: inherit;
}

div.selectBox {
	div.selectBoxHit {
		border: solid 1px @VisualLayoutingColor;
		height: 20px;
		line-height: 20px;
		background-color: #fff;
		overflow: hidden;
		span.hitBoxArrow {
			height: 20px;
			width: 19px;
			background: @AudiWeiss url("../../img/forms/liste-pfeil.png") right center no-repeat;
			float: right;
			z-index: @Index_SelectTrigger;
		}

		ul.selectOptionContainer {
			border: 0 none;
			position: absolute;
		}

		li.selectOption {
			&:hover , &.selected {
				color: @PrimaryFontColor;
			}
		}
	}

	div.item.horizontal {
		display: table;
		div.itemBox {
			display: table-cell;
			float: none;
			padding-right: 0;
		}
	}

	li.selectOption {
		padding: 0 3px 0;
		height: 20px;
	}

	&.default {
		ul.selectOptionContainer {
			visibility: hidden;
		}

		li.selectOption.selected {
			color: @PrimaryCiColor;
		}
	}

	&.disabled {
		div.selectBoxHit {
			span.hitBoxArrow {
				background: @AudiWeiss url("../../img/forms/liste-pfeil-inaktiv.png") right center no-repeat;
			}

			li.selectOption {
				&:hover , &.selected {
					color: @SecondaryFontColor;
				}
			}
		}

		div.selectBoxHit , div.selectBoxHit span.hitBoxArrow , &.multiple .selectOptionContainer {
			background-color: @HellGrau30;
		}
	}

	li.selectOption.selected {
		color: @PrimaryCiColor;
	}

	li.selectOption , &.default li.selectOption.selected {
		&:hover {
			color: @PrimaryCiColor;
		}
	}

	ul.selectOptionContainer {
		background: #fff;
		border: solid 1px @VisualLayoutingColor;
		overflow-y: auto;
		li {
			display: none;
		}

		&.toggled {
			outline: 0 none;
			li {
				display: block;
				border-collapse: collapse;
			}
		}
	}

	> ul.selectOptionContainer {
		z-index: @Index_SelectOptions;
	}
}

div.selectBox.disabled li.selectOption , div.selectBox.disabled li.selectOption:hover , div.selectBox li.selectOption.disable , div.selectBox li.selectOption.disable:hover {
	color: @SecondaryFontColor;
}

div.fileUpload {
	input[type="file"] {
		width: 460px;
		max-width: 460px;
	}

	div.fakeFileUpload input {
		width: 429px;
		height: 26px;
	}
}

div.fileUploadButton {
	background: url("../../img/btn/suchen.png") 0 0 no-repeat;
	height: 26px;
	width: 26px;
	&.inactive {
		background: url("../../img/btn/suchen-inaktiv.png") 0 0 no-repeat;
		cursor: default;
	}

	&.hover {
		background-image: url("../../img/btn/suchen-rollover.png");
	}
}

div.fileUploadButton.inactive .fileUpload input:disabled {
	cursor: default;
}

select.dynamicTreeNodeFilterOption , select.dynamicTreeNodeAttribute {
	position: relative;
	visibility: inherit;
	display: block;
}

