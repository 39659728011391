@import '../utilities/lib.less';

.img-button.detail {
	border-color: @HellGrau60;
	
	&:hover, &.selected {
		border-color: @PrimaryCiColor;
	}
	
	.preview {
		img {
			background: @Schwarz;
			border-color: @HellGrau60;
		}
	}
	
	.desciption {
		color: @Schwarz;
	}
}