@import "../../utilities/lib";

.chosen-container-single, .chosen-container-multi {
	.chosen-single, .chosen-choices {
		font-size: 13px;
		line-height: 16px;
		border: 1px solid @VisualLayoutingColor;
		border-radius: 0;
		background: @AudiWeiss;
		box-shadow: none;
		color: @AudiSchwarz;

		div b {
			background: @AudiWeiss url("../../../img/forms/liste-pfeil.png") right center no-repeat;
		}

		> span {
			min-height: 18px;
			margin-right: 0;
			padding-top: 1px;
			padding-bottom: 1px;
			padding-left: 2px;
		}

		li.search-field input[type="text"] {
			padding-left: 3px;
			height: 21px;
		}

		li.search-choice {
			span {
				line-height: 17px;
				margin-right: 1px;
			}

			line-height: 17px;
			padding: 0 20px 0 5px;
			margin: 2px 0 0 3px;
			color: @AudiSchwarz;
			background: @AudiWeiss;
			border-radius: 0;

			&:hover {
				border-color: @AudiRot;
				color: @AudiRot;

				.search-choice-close {
					background-position: -37px 1px;
					background-color: @AudiRot;
					border-color: @AudiRot;
				}
			}

			.search-choice-close {
				width: 16px;
				height: 17px;
				top: 0;
				right: 0;
				background: @AudiSchwarz url("chosen-sprite-audi.png") -37px 1px no-repeat;
			}
		}
	}

	.chosen-drop {
		border-radius: 0;
		box-shadow: none;
		border: 1px solid @VisualLayoutingColor;
	}
}

.chosen-container-single .chosen-single {
	padding: 0 0 0 3px;
}

.chosen-container-multi .chosen-choices {
	padding: 0;
}

.chosen-container-multi .chosen-drop {
	border-top: none;
}

.chosen-container-active {
	.chosen-single > span {
		color: @AudiRot;
	}

	&.chosen-with-drop {
		.chosen-single {
			background-image: none;
			border: 1px solid @VisualLayoutingColor;

			div b {
				background-position: right center;
			}

			.result-selected {
				color: @AudiRot;
			}
		}

		.chosen-drop input {
			border: 1px solid @VisualLayoutingColor;
		}

		.chosen-results {
			margin: 0 0 0 0;
			color: @PrimaryFontColor;

			li.no-results {
				color: @AudiSchwarz;
				display: list-item;
				background: @AudiWeiss;
			}
		}
	}
}

.chosen-container .chosen-results {
	margin: 0 4px 2px 0;
	padding: 0 0 0 5px;

	li {
		padding: 2px 0;
		white-space: nowrap;

		&.highlighted {
			background: @AudiWeiss;
			color: @AudiRot;
		}

		&.disabled-result {
			color: @SecondaryFontColor;
		}

		mark {
			color: inherit;
			background-color: inherit;
			font-weight: bold;
		}
	}
}

select:empty {
	display: block ! important;
	visibility: hidden;
	height: 0;
}

.chosen-container {
	position: relative;
	max-width: 100%;

	.chosen-drop {
		z-index: 10100;
	}
}

.chosen-disabled {
	opacity: 1 !important;

	.chosen-single {
		background: @HellGrau30;
		border: 1px solid @LichtGrau;

		span {
			color: @InputFontInactiveColor;
		}
	}
}
