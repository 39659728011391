@import "../utilities/lib.less";
@import "../../../../variableProvider";

div.bas-formBox, .field {
	padding: 5px 0;
}

.field.noLabel {
	display: table;
}

.bas-formSection {
	.bas-formBox.ym-grid {
		border-top: none;
		padding: 10px 0;
	}

	.verticalSeparator {
		background-color: @Schwarz;
		height: 20px;
		width: 1px;
		display: inline-block;
	}
}

div.bas-label {
	min-width: 160px;
	min-height: 20px;
	width: 160px;
	padding-right: 20px;
	line-height: 20px;
}

.bas-inputCheck label {
	line-height: 20px;
	margin-left: 0;

	&.bas-inputRadio {
		&.hint.tooltipTrigger.infoIcon {
			display: inline-block;
		}
	}
}

.layout.contentContainer {
	> .bas-formSection {
		margin-bottom: 25px;
	}

	.field.buttonline {
		padding-top: 5px;
	}

	.field > span {
		margin-right: 5px;
	}
}

.layout.contentContainer h2,
.group.subtitleRaw {
	margin-bottom: 10px;
}

div.cols1,
div.cols2,
div.cols3,
div.cols4,
div.cols5,
div.cols6 {
	display: table-cell;
	padding-right: 5px;

	&.charcounter {
		margin-right: 0;
		text-align: right;
	}

	&.inlineckeditor {
		display: block;
	}
}

div.cols1 {
	width: @ColsInput1;

	div.bas-inputSelect select.timeSelector {
		width: 45px;
	}

	&.processed_tooltip {
		width: 147px;
	}
}

div.cols2 {
	width: @ColsInput2;

	&.processed_tooltip {
		width: 304px;
	}

	&.urlCheckerContainer {
		padding-right: 0;
	}
}

div.cols3 {
	width: @ColsInput3;

	&.processed_tooltip {
		width: 461px;
	}
}

div.cols4 {
	width: @ColsInput4;

	&.processed_tooltip {
		width: 618px;
	}
}

div.cols5 {
	width: @ColsInput5;

	&.processed_tooltip {
		width: 739px;
	}
}

div.cols6 {
	width: @ColsInput6;
	padding: 0;

	&.processed_tooltip {
		width: 899px;
	}
}

.bas-inputArea.horizontal {
	.cols1, .cols2, .cols3, .cols4, .cols5 {
		vertical-align: top;
	}
}

input[type=text] {
	&.cols_tele_1 {
		width: 48px;
	}

	&.cols_tele_2 {
		width: 80px;
	}

	&.cols_tele_3 {
		width: 186px;
	}

	&.cols_tele_4 {
		width: 80px;
	}

	&.cols_tele_31 {
		width: 216px;
	}

	&.cols_tele_34 {
		width: 281px;
	}

	&.cols_kopra_price {
		width: 90px;
	}
}

input[type=text][class *= cols_tele] {
	margin-right: 0;
}

span.cols_days, select.cols_days {
	width: 150px;
}

span.cols_time {
	width: 124px;
	margin: 0 3px 0 27px;
}

select.cols_hours {
	width: 53px;
}

select.cols_minutes {
	width: 53px;
}

.uploadWithTooltip div.bas-inputArea {
	display: block;
}

div.bas-inputArea {
	display: block;
	width: auto;
	margin-left: 160px;

	.radioIndent {
		margin-left: 27px;
	}
}

.fullWidth .searchmask {
	div.bas-inputArea {
		width: 100%;
	}
}

.vertical.item > .itemBox > .horizontal.item {
	display: flex;
}

div.ym-grid, .noLabel {
	div.bas-inputArea {
		width: auto;
	}
}

html {
	&.lt-ie10, &.lt-ie9 {
		.cols1-3 {
			width: 465px;
		}
	}
}

.admin {
	background-color: @LichtGrau30;
}

.inputSeparator {
	background-color: @VisualLayoutingColor;
	height: 1px;
	border: 0 none;
	margin: 10px 0;
}

.urlChecker {
	display: inline-block;
}

div.bas-formBox img.statusIcon {
	margin-right: 15px;
}

.preferences-statusbox {
	padding: 5px 0;
	border-top: 1px solid @PrimaryCiColor;
	border-bottom: 1px solid @PrimaryCiColor;

	.bold {
		color: @PrimaryCiColor;
		padding-bottom: 5px;
	}

	> span.text {
		float: none;
	}
}

span.centered {
	display: inline-block;
	text-align: center;
}

form[name="loginmask"] .bas-formBox .bas-inputArea {
	width: auto;
}

.required {
	display: block;
	padding-top: 10px;
}

.sourceDestinationSet {
	> .controls {
		padding-top: 45px;

		.add a {
			margin-left: 8px;
		}

		.remove a {
			margin-left: -8px;
		}
	}
}

html {
	&.ie {
		.sourceDestinationSet {
			@selectHeight: 270px;

			.destinationSet select {
				height: @selectHeight + @InputHeight;
			}
		}
	}
}

