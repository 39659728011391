@import '../../utilities/lib.less';


div.dialog div.dialogWrapper.wmDialog {
	background: @DefaultBackgroundColor;
}

.bas-formBox.layout-66-33,
.bas-formBox.layout-80-20 {
	label[for]:hover {
		color: @InteractiveBackgroundColorHighlightHover;
	}
} 

.werbemittel-container,
.wmDialog {
	border: 1px solid @NormalGanttBackground;
	
	.werbemittel-vorschaubild{
		img{
			border: 1px solid @MittelGrau;
		}

		>.dummy{
			background-color: #d0d0d0;
		}
	}
	
	&.KONZERN .werbemittel-header,
	&.KONZERN .dialogHeader h3 {
			background-color: @NationalGanttBackground;
			color: @DefaultBackgroundColor;

			.icon:hover {
				border: 1px solid @PrimaryFontColor;
				box-sizing: border-box;
				padding-right: 0;
				margin-right: 10px;
				background-position: -1px -1px;

				&:last-child {
					margin-right: 0;
				}
			}
		}
	 
	
	&.SPERRFRIST .werbemittel-header,
	&.SPERRFRIST .dialogHeader h3 {
		background-color: @SperrfristGanttBackground;
		color: @DefaultBackgroundColor;

		.icon:hover {
			border: 1px solid @PrimaryFontColor;
			box-sizing: border-box;
			padding-right: 0;
			margin-right: 10px;
			background-position: -1px -1px;

			&:last-child {
				margin-right: 0;
			}
		}
	}


	&.OWN .werbemittel-header,
	&.OWN .dialogHeader h3 {
		background-color: @OwnGanttBackground;
		color: @PrimaryFontColor;
	}
	
	&.PARTNER.teilgenommen .werbemittel-header,
	&.PARTNER.teilgenommen .dialogHeader h3 {
		background: @NormalGanttBackground;
		color: @DefaultBackgroundColor;

		svg#changed_by_user	 {
			stroke: @DefaultBackgroundColor;
		}
	}
	
	.werbemittel-header,
	.dialogHeader h3 {
		background: @NormalNotFollowedGanttBackground;
		color: @PrimaryFontColor;
		border-bottom: 1px solid @GanttBorderColor;
		font-family: "Audi Type Extended Normal", serif;
	}

	.werbemittel-footer {
		div.arrow {
			background-color: @PrimaryFontColor;
		}
		
		&:hover {
			div.arrow {
				background-color: @PrimaryCiColor;
			}
		}
	}

	.werbemittel-item{
		&.gruppenplaner{
			width: 600px;

			.chosen-container{
				max-width: 600px;
			}
		}

		.gruppenMitglied {
			display: flex;	
		}

		.gruppenMitglieder {
			padding: 5px 5px 5px 0;
			position: relative;
		}

		.gruppenMitglieder > section:first-of-type {
			min-height:20px;
		}
		
		.gruppenbudget{
			font-weight: bold;

			.sum::before {
				content: "\2211";
				position: absolute;
				right: 136px;
			}
		}
	}
	
}