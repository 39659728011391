@import '../../utilities/lib.less';

@iconPath: '../../../img/icons';

.dummyLink {
	cursor: default;

	&.printElement {
		cursor: pointer;
	}
}

.defaultLink {
	&.highlight {
		color: @PrimaryCiColor;
	}

	&.noIcon, &.imageLink {
		background: none;
		padding-left: 0;
	}

	&.icon.text {
		width: auto;
		padding-left: 36px;
	}

	&.text.inline {
		padding-left: 16px;
		line-height: 20px;
		height: 20px;
		background-position: left center;
		margin-left: 5px;
		margin-right: 10px;
	}


	&.urlChecker {
		display: inline-block;
		font-size: 11px;
		background: url("@{iconPath}/linkpfeil_klein.png") no-repeat scroll 0 4px transparent;
	}

	&.incomplete {
		background: @AudiWeiss url('../../../img/mtpage_error.png') left center no-repeat;
		padding-left: 30px;
	}

	&.complete {
		background: @AudiWeiss url('../../../img/mtpage_customizable.png') left center no-repeat;
		padding-left: 30px;
		padding-bottom: 1px;
	}
}

span {
	a.defaultLink {
		margin-left: 10px;
	}

	> br + a.defaultLink {
		margin-left: 0;
	}
}

.buttonline {
	a.defaultLink {
		+ .defaultLink, + .bas-inputButton {
			margin-left: 20px;
		}
	}

	div.defaultLink + .defaultLink {
		margin-left: 30px;
	}

	div.dialog + .defaultLink {
		margin-left: 20px;
	}
}

.buttonline.top a.defaultLink {
	+ .defaultLink, + .bas-inputButton {
		margin-right: 10px;
		margin-left: 0;
	}
}

.tabulation {
	td.cmsLinkListFooter {
		.defaultLink.icon.text {
			margin-bottom: 5px;
		}
	}

	.defaultLink.imageLink {
		border: 1px solid @LichtGrau;
	}
}

#logout {
	background: url("@{iconPath}/logout.png") no-repeat right top;
	padding-left: 0;
	padding-right: 25px;
	margin-right: 20px;
	display: inline-block;
	height: 16px;
}

#logout:hover {
	background-image: url("@{iconPath}/logout-rollover.png");
}

span.showAsDisabledLink {
	padding-left: 16px;
	color: @SecondaryFontColor;
	background: url('@{iconPath}/linkpfeil_inaktiv.png') no-repeat 0 3px;
	display: inline-block;
}
