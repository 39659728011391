@import '../utilities/lib.less';
@compactImagePath: "../../img/icons";
div.resultSet.kompakt {
	padding: 0;
	.resultSetRow {
		display: flex;
	}
	.resultSetItem {
		position: relative;
		width: 236px;
		min-height: 210px;
		padding: 20px 20px 20px 16px;
		border-right: solid 1px @HellGrau60;
		border-bottom: solid 1px @HellGrau60;
		&.last {
			border-right: 0 none;
		}
	}

	.resultSetRow.last .resultSetItem {
		border-bottom: 0 none;
		margin-bottom: 20px;
	}

	.resultSetItem , .resultSetRow.last .resultSetItem {
		&:hover {
			background-color: @HellGrau60;
		}
	}

	.resultImage {
		width: 100%;
		margin-bottom: 20px;
		height: 100px;
		a {
			padding: 0;
			border: 0 none;
			display: inline-block;
			max-width: 165px\9;
		}

		img {
			max-width: 165px;
			max-height: 100px;
			width: auto;
		}

		> img {
			margin-left: 15px;
		}
	}

	.resultInfo {
		background: 0 none;
		width: 100%;
		padding: 0;
	}

	.resultInfoHeader {
		padding: 0;
		a {
			background-position: 0 2px;
		}

		> header > span {
			padding-left: 15px;
			display: inline-block;
			line-height: 16px;
			&.priceInfo {
				display: block;
			}
		}
	}

	.resultTemplateType , .resultImage a {
		margin-left: 15px;
	}

	.resultTemplateType.noIndent {
		padding: 0;
	}

	.resultAdditions {
		position: absolute;
		right: 10px;
		top: 10px;
		text-align: right;
		a {
			display: block;
			width: 19px;
			height: 16px;
			margin-bottom: 5px;
			padding: 0;
			background-repeat: no-repeat;
			&.superviewButton {
				background-image: url("@{compactImagePath}/vergroessern.png");
				&:hover {
					background-image: url("@{compactImagePath}/vergroessern-rollover.png");
				}
			}

			&.addToHomePage {
				background-image: url("@{compactImagePath}/campaign_startpage.png");
				background-position: center center;
				&:hover , &:focus {
					background-image: url("@{compactImagePath}/campaign_startpage-rollover.png");
				}
			}

			&.addToHomePageFromFamily {
				background-image: url("@{compactImagePath}/family_startpage.png");
				background-position: center center;
				&:hover , &:focus {
					background-image: url("@{compactImagePath}/family_startpage-rollover.png");
				}
			}
		}

		.tooltipTrigger {
			display: block;
			width: 20px;
			height: 20px;
			margin-bottom: 5px;
		}

		.infoForUser {
			margin-bottom: 5px;
		}
	}
}

div.resultSet.detail {
	background-color: @AudiWeiss;
	padding: 10px 0;
	div.resultTemplateType {
		font-size: 12px;
		margin-top: 3px;
	}

	header.resultTemplateTitle {
		position: relative;
		font-family: @AudiTypeExtendedBold;
	}

	p {
		margin: 2px 0;
	}

	.resultInfo {
		background: 0 none;
	}

	.resultInformation {
		position: relative;
	}

	.resultInfoHeader {
		padding: 0 5px 5px 25px;
	}

	div.resultSetSection.active div.resultTemplateInfo a {
		color: @PrimaryCiColor;
	}
}

.detail {
	.resultImage {
		width: 220px;
		position: relative;
		a.defaultLink {
			padding: 0;
		}

		.resultAdditions a {
			border: none;
		}
	}

	.resultTemplateInfo {
		display: flex;
		flex-direction: column;
		a {
			width: auto;
			height: 15px;
			padding-top: 3px;
			margin-bottom: 10px;
			&.defaultLink.icon {
				height: 26px;
				padding-top: 0;
			}
		}
	}
}

div.resultTemplateExpiryDateInfo {
	color: @PrimaryCiColor;
	font-size: 12px;
}

div.orderChangeButtons {
	margin-top: 5px;
	height: 26px;
}

.resultFormatGroup {
	margin: 10px 0;
	display: block;
	overflow: auto;
	.defaultLink {
		margin-bottom: 5px;
	}

	a.active {
		color: @PrimaryCiColor;
	}

	> a {
		float: left;
		clear: left;
	}
}

div.resultSet.superview {
	padding-top: 20px;
	.resultImage img {
		display: block;
		margin: 0 auto;
	}

	.resultAdditions {
		position: relative;
	}
}

.resultSet .resultAdditions {
	position: relative;
	top: 0;
	right: 0;
	float: right;
	a {
		display: block;
		width: 17px;
		height: 16px;
		margin-bottom: 5px;
		padding: 0;
		background-repeat: no-repeat;
	}
}

.resultSet.superview .resultAdditions , .resultAdditions {
	a.superviewButton {
		background-position: 2px 0;
		background-image: url("../../img/icons/vergroessern.png");
		&:hover {
			background-image: url("../../img/icons/vergroessern-rollover.png");
		}

		&[data-image=""] {
			display: none;
		}
	}
}

.current-templates {
	border-top: 1px solid @VisualLayoutingColor;
	overflow: hidden;
	padding: 10px 0 25px 0;
	word-wrap: break-word;
	img {
		max-width: 120px;
		max-height: 100px;
		margin-top: 25px;
	}

	.linkShortDescription {
		margin-left: 16px;
		display: inline-block;
	}

	.linkShortDescriptionProfilerNeeded {
		margin-left: 25px;
		display: inline-block;
	}
}

div.resultSet {
	background-color: #fff;
	padding: 10px;
}

div.resultInfoHeader , div.resultSetSection , div.resultInfoFooter {
	padding: 5px 5px 5px 25px;
}

div.resultInfoHeader span.shortdescription {
	display: block;
}

div.modificationInfo {
	margin-top: 4px;
}

div.modificationInfo span , div.modificationInfo {
	line-height: 10px ! important;
	font-size: 10px;
}

section.resultSetItem {
	border: none;
}

div.resultInfo {
	padding-left: 10px;
}

.resultSet .resultSetItem .resultImage.defaultpreview > a {
	border: none;
}

.resultSet .resultSetItem .resultImage > a {
	border: 1px solid @VisualLayoutingColor;
	line-height: 0;
	position: relative;
	.additionalTemplateNote , .additionalTemplateNoteCms {
		color: @PrimaryCiColor;
		width: 100%;
		position: absolute;
		top: 50%;
		left: 50%;
		text-align: center;
		.translate (-50% , -50%);
		> span {
			background: @AudiWeiss;
			font-family: @AudiTypeExtendedBold;
			font-size: 13px;
			display: inline-block;
			border: 1px solid @PrimaryCiColor;
			position: relative;
			padding: 5px 10px;
			white-space: pre;
			box-shadow: -2px 2px 4px -1px @MittelGrau;
			.rotate (-20deg);
		}
	}

	.additionalTemplateNoteCms {
		> span {
			font-size: 11px;
		}
	}
}

.resultSet .resultSetItem .resultImage > img {
	border: 1px solid @VisualLayoutingColor;
	line-height: 0;
}

header.resultTemplateTitle {
	color: @PrimaryFontColor;
	font-size: 16px;
	margin-bottom: 5px;
}

section.resultSetItem a {
	display: inline-block;
}

div.resultTemplateType {
	font-size: 11px;
	line-height: 16px;
	font-family: @AudiTypeNormal;
	font-weight: normal;
	color: @MittelGrau;
	> span {
		line-height: 16px;
		&.highlight {
			color: @AudiRot;
		}
	}
}

a.icon.save_small {
	position: absolute;
	top: 5px;
	right: 0;
}

#superviewimg {
	border: 1px solid @VisualLayoutingColor;
}

.choose-templates-per-page {
	padding-bottom: 25px;
	.bas-formSection , .bas-formSection .item {
		float: right;
	}

	.item.horizontal {
		div.itemBox:first-child {
			padding-right: 5px;
			width: auto;
		}

		div.itemBox:first-child , div.itemBox:first-child + div.itemBox {
			border-left: none;
		}
	}

	.item div.itemBox {
		padding-right: 0;
		height: 30px;
		width: 35px;
		line-height: 30px;
		text-align: center;
		border-left: dotted 1px @HellGrau60;
	}

	span span {
		font-family: @AudiTypeBold;
	}

	a.defaultLink {
		font-family: @AudiTypeNormal;
		background: none;
	}

	span span , a.defaultLink {
		line-height: 32px;
		height: 30px;
		min-width: 28px;
		text-align: center;
		vertical-align: top;
		padding: 0 2px;
	}
}

.resultTemplateDescription {
	font-size: 13px;
	margin: 10px 0;
}

.ribbon {
	span {
		background: @PrimaryCiColor;
		&:before {
			border-left: 3px solid var(--color--ci--primary--darken-5);
			border-bottom: 3px solid var(--color--ci--primary--darken-5);
		}
	}

	&:after {
		border-left: 3px solid var(--color--ci--primary--darken-5);
	}
}
