@import '../../utilities/lib.less';
.ui-autocomplete[id ^= "ui-id"] {
	max-height: 200px;
	overflow-x: hidden;
	overflow-y: auto;
	&.ui-menu {
		background: #fff;
		border: solid 1px @VisualLayoutingColor;
		color: @PrimaryFontColor;
		border-radius: 0;
	}

	.ui-menu-item {
		&.ui-state {
			&-hover , &-focus {
				cursor: pointer;
				background: #fff;
				border-color: transparent;
				border-left-color: @VisualLayoutingColor;
				border-right-color: @VisualLayoutingColor;
				&:first-child {
					border-color: @VisualLayoutingColor;
					border-bottom-color: transparent;
				}

				&:last-child {
					border-color: @VisualLayoutingColor;
					border-top-color: transparent;
				}
			}
		}

		&.ui-state-hover a {
			background: transparent;
			color: @PrimaryCiColor;
			border: none;
		}

		&.ui-state-focus a {
			border: 0 none;
			background: transparent;
			color: @PrimaryCiColor;
			font-weight: normal;
		}
	}
}

.ui-autocomplete[id ^= "ui-id"] , .ui-menu[id ^= "ui-id"] {
	.ui-menu-item {
		a.ui-state-hover , a.ui-state-active {
			margin: 0;
			color: @PrimaryCiColor;
		}
	}
}

.ui-helper-hidden-accessible {
	display: none;
}

