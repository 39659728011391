/* Customer-variable will be overwritten in build-process */
/* DO NOT AUTOFORMAT THIS FILE */
/* USE THE FOLLOWING VARS IN YOUR LESS FILES! */
/* Z-INDEX */
/* IMAGE SELECTIONS */
/* MISC */
/* Abstände für Angularkomponenten */
/**
 * 	Includes all files inside this theme folder, that are required by the theme
 */
@import "default/css/yaml/navigation/vlist.css";
@import "default/css/yaml/navigation/hlist.css";
.ym-col1 {
  width: 20%;
}
.ym-col3 {
  margin: 0;
  margin-left: 20%;
  position: relative;
}
/* Content Modules */
input[type=text],
input[type=password],
select,
textarea,
.inlineckeditor {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}
.bas-formBox,
.field.noLabel {
  display: table;
  width: 100%;
  table-layout: fixed;
  list-style-type: none;
}
.bas-label {
  width: 150px;
  float: left;
}
.bas-inputArea {
  width: auto;
  margin-left: 150px;
  position: relative;
}
.bas-inputArea .bas-inputText.multiAddons {
  display: flex;
  flex-direction: column;
}
.noLabel > .bas-inputArea,
#searchMask ~ .contentContainer .bas-inputArea {
  margin-left: 0;
  padding-left: 0;
}
#editMaskContainer input[type=text]:not([class*="cols"]),
#editMaskContainer select:not([class*="cols"]),
#editMaskContainer textarea:not([class*="cols"]),
#editMaskContainer .inlineckeditor:not([class*="cols"]) {
  width: auto;
}
.ym-vlist {
  border: 0 none;
}
.ym-vlist li a,
.ym-vlist li strong,
.ym-vlist li span {
  width: auto;
}
.ym-vlist a,
.ym-vlist strong,
.ym-vlist span {
  border: 0 none;
}
.ym-vlist li ul li a:focus,
.ym-vlist li ul li a:hover,
.ym-vlist li ul li a:active {
  color: inherit;
  background-color: inherit;
}
.ym-vlist li ul li ul li a:focus,
.ym-vlist li ul li ul li a:hover,
.ym-vlist li ul li ul li a:active {
  color: inherit;
  background-color: inherit;
}
.ym-vlist li ul li ul li ul li a:focus,
.ym-vlist li ul li ul li ul li a:hover,
.ym-vlist li ul li ul li ul li a:active {
  color: inherit;
  background-color: inherit;
}
.ym-vlist li ul li ul li ul li a,
.ym-vlist li ul li ul li ul li strong,
.ym-vlist li ul li ul li ul li span {
  width: auto;
}
.ym-vlist li ul li ul li a,
.ym-vlist li ul li ul li strong,
.ym-vlist li ul li ul li span {
  width: auto;
}
.ym-vlist li ul li a,
.ym-vlist li ul li strong,
.ym-vlist li ul li span {
  width: auto;
}
.ym-hlist {
  background: none;
  border: 0 none;
}
.ym-hlist ul {
  border: 0 none;
  padding: 0;
}
.ym-hlist ul li {
  border: 0 none;
}
.ym-hlist ul li a,
.ym-hlist ul li strong {
  padding: 0;
  text-shadow: 0 0 0 #000;
  color: transparent;
}
.ym-hlist ul li a:focus,
.ym-hlist ul li a:hover,
.ym-hlist ul li a:active {
  background: none;
  color: inherit;
}
.ym-hlist {
  overflow: visible;
}
.ym-hlist ul * ul {
  position: absolute;
}
.ym-hlist ul li {
  position: relative;
}
.ym-hlist ul li ul li {
  float: left;
  position: static;
}
.ym-hlist ul li.hover ul {
  display: block;
  position: absolute;
  top: 1.2em;
  padding-top: 25px;
  left: 0;
  width: 500px;
}
.ym-hlist ul li.hover ul li {
  float: left;
}
.ym-hlist ul li.hover ul li a,
.ym-hlist ul li a.hover ul li a {
  border: 0;
  margin: 0;
  height: auto;
  line-height: 1em;
  padding: 5px;
  width: 86px;
}
.ym-hlist ul li {
  position: static;
}
.ym-hlist ul li ul {
  position: absolute;
  background-color: #fff;
  display: block;
}
.ym-g625 {
  width: 625px;
}
.ym-g300 {
  width: 300px;
  padding-left: 20px;
}
.ym-g420 {
  width: 420px;
}
.ym-g460 {
  width: 452px;
  padding-left: 20px;
  padding-right: 8px;
}
.ym-g46 {
  width: 46px;
}
.ym-g17 {
  width: 17px;
}
/* DO NOT AUTOFORMAT THIS FILE */
/* GLOBAL VARIABLES */
/* Kpi Dashboard Diagramm-Farben*/
/* NAVIGATION */
/* COLS */
/* MISC */
/* FONTS */
/* Z-INDEX */
/* ERROR COLORS */
/* Abstände für Angularkomponenten */
/* DO NOT AUTOFORMAT THIS FILE */
.hasUrlChecker {
  display: block;
}
.browserOutdatedImg {
  float: left;
  margin-top: 40px;
  margin-left: 20px;
}
.browserOutdatedText {
  margin-top: 20px;
}
.browserOutdatedText div {
  margin-top: 20px;
}
.browserOutdatedWarn {
  color: var(--color--feedback--error);
  font-weight: bold;
  text-decoration: none;
}
.extraBottomSpacing {
  padding-bottom: 20px;
}
.ym-grid.layoutGrid.contentWidth input,
.ym-grid.layoutGrid.contentWidth textarea {
  margin-right: 10px;
}
.ym-equalize {
  overflow: auto;
}
.ym-equalize > [class*="ym-g"] > [class*="ym-gbox"] {
  margin-bottom: 0;
  padding-bottom: 0;
}
@media print {
  .navigationRow,
  #sidebar,
  #breadCrumb,
  .field.buttonline,
  #footer {
    display: none;
  }
  #header {
    width: auto !important;
  }
  #content {
    float: none;
    width: 100% !important;
    margin-left: 0 !important;
  }
}
.affiliationFrame {
  border: 1px solid var(--color--layout--visual);
  padding: 10px;
}
.affiliationFrame.hidden {
  border: 0 none;
  padding: 0;
}
.whitespace_bottom {
  margin-bottom: 30px;
}
div.bas-inputArea img.cols3 {
  width: auto;
  max-width: 466px;
}
div.bas-inputArea .pictureinfo.cols3 {
  width: 466px;
  box-sizing: border-box;
  padding: 10px 10px;
}
.layoutFooter {
  z-index: -1;
}
#page {
  width: 1280px;
  padding-top: 30px;
}
#main {
  width: 100%;
}
#content {
  float: right;
}
#content:not(.fullWidth) {
  width: 945px;
}
#content.fullWidth {
  width: 1180px;
}
.input-prompt {
  margin-top: 0;
}
div.placeholderpicture {
  background-color: var(--color--audi--hell-grau-30);
  margin-bottom: 0;
}
td div.placeholderpicture {
  padding: 0;
  margin: 0;
  background-color: var(--color--audi--weiss);
}
span + .dummyLink,
.dummyLink + span,
.cms-formattedlink span + .defaultLink,
.cms-formattedlink .defaultLink + span {
  margin-left: 5px;
}
.svg_info {
  height: 1.5em;
  enable-background: new 0 0 26 26;
}
.svg_info circle {
  fill: black;
}
.svg_info .i_icon {
  fill: white;
}
.mobile-login .bas-inputArea .cols2 {
  width: 140px;
}
/**
 * Theme Modules
 *
 * Importiere hier alle notwendigen Module die du für das Theme
 * customized oder erstellt hast.
 * Hier gehören keine Imports aus der Layout-Schicht rein!!!
 */
.flex .item.horizontal {
  display: flex;
}
.itemBox {
  overflow: visible;
  float: none;
}
.item.prev-container > .itemBox,
.item.next-container > .itemBox {
  vertical-align: middle;
}
.item.horizontal > .itemBox {
  padding-right: 5px;
}
.item.vertical > .itemBox > .bas-inputButton {
  margin-top: 5px;
}
.item.vertical > .itemBox.first > .bas-inputButton {
  margin-top: 0;
}
.item.linkGroup {
  margin: 5px 0;
}
.noLabel .item.horizontal > .itemBox {
  max-width: 720px;
}
.buttonline {
  padding: 10px 0 10px 0;
  border: 0 none;
  margin-top: -5px;
}
.buttonline .bas-inputButton {
  display: inline-block;
  margin-top: 5px;
}
.buttonline.item {
  padding: 10px 0;
}
.buttonline .buttonContainer {
  display: inline-block;
}
.buttonline.top {
  padding: 0;
  border-top: 1px solid var(--color--audi--hell-grau-30);
  border-bottom: none;
}
.buttonline.top .ui-datepicker-trigger {
  margin-right: 10px;
}
.buttonline.field {
  border: none;
  padding: 30px 0 10px 0;
}
div.item.buttonline.top > * {
  margin-right: 20px;
}
#editMask + .contentContainer .item.linkGroup {
  margin: -6px 0 25px 0;
}
.lightboxContainer {
  background-color: var(--color--audi--weiss);
  width: 853px;
  overflow: hidden;
  text-align: center;
}
.lightboxContainer .imageContainer {
  padding: 0 32px;
}
.lightboxContainer .imageContainer img {
  position: static;
  border: 1px solid var(--color--layout--visual);
  box-shadow: 0 0 5px 0 var(--color--layout--visual);
}
.lightboxContainer .controlContainer {
  height: 40px;
  margin-top: 5px;
  position: absolute;
}
.lightboxContainer .switchLeft,
.lightboxContainer .switchRight {
  width: 24px;
  height: 24px;
  cursor: pointer;
  position: absolute;
  bottom: 0;
}
.lightboxContainer .switchLeft.inactive,
.lightboxContainer .switchRight.inactive,
.lightboxContainer .switchLeft.inactive:hover,
.lightboxContainer .switchRight.inactive:hover {
  background: none;
  cursor: default;
}
.lightboxContainer .switchLeft {
  float: left;
  left: 0;
  background: url('./customer/audi/img/kalender/zurueck.png');
}
.lightboxContainer .switchLeft:hover {
  background-image: url('./customer/audi/img/kalender/zurueck-rollover.png');
}
.lightboxContainer .switchRight {
  float: right;
  right: 0;
  background-image: url('./customer/audi/img/kalender/vor.png');
}
.lightboxContainer .switchRight:hover {
  background: url('./customer/audi/img/kalender/vor-rollover.png');
}
.lightboxContainer .linkBox {
  top: 20px ! important;
}
.lightboxContainer span.imageDescription {
  line-height: 16px;
}
.lightboxContainer .header {
  height: 40px;
  margin-bottom: 5px;
}
.closeLightbox,
.scalePreview {
  height: 32px;
  width: 32px;
  position: relative;
}
.closeLightbox {
  background-image: url('./customer/audi/img/icons/layer-schliessen-passiv.png');
  background-repeat: no-repeat;
  background-position: center;
  float: right;
}
.closeLightbox:hover {
  background-image: url('./customer/audi/img/icons/layer-schliessen-rollover.png');
}
.scalePreview {
  background-image: url('./customer/audi/img/btn/scaleOut.png');
  background-repeat: no-repeat;
  background-position: center;
  float: right;
  margin-right: 2px;
}
.scalePreview:hover {
  background-image: url('./customer/audi/img/btn/scaleOut-rollover.png');
  cursor: pointer;
}
.scalePreview.undo {
  background: url("./customer/audi/img/btn/scaleOut-undo.png") no-repeat center;
}
.scalePreview.undo:hover {
  background-image: url("./customer/audi/img/btn/scaleOut-undo-rollover.png");
  cursor: pointer;
}
.scalePreview.inactive {
  background-image: url('./customer/audi/img/btn/scaleOut-inactive.png');
  cursor: default;
}
.headerWrapper {
  width: 100%;
  height: 120px;
  position: relative;
  top: 0;
  left: 0;
}
.headerWrapper .backgroundContainer {
  background: #E5EAEE;
  height: 100%;
  width: 50%;
  float: left;
  position: absolute;
  top: 0;
  left: 0;
}
#header {
  width: 1185px;
  margin: 0 auto;
  height: 120px;
  background: url("./customer/audi/img/header/verlauf.png") repeat-y;
}
#rings {
  position: absolute;
  right: 65px;
  top: 30px;
}
#logo {
  left: 58px;
  top: 40px;
  position: absolute;
}
#logo a.defaultLink {
  padding-left: 0;
}
.navigationRow {
  position: absolute;
  bottom: 0;
  width: 1185px;
  min-height: 17px;
}
.headerSubContainer {
  margin-right: 15px;
  float: right;
  position: absolute;
  right: 0;
  top: -3px;
}
.headerSubContainer a {
  font-size: 12px;
}
.legend {
  font-size: 11px;
  line-height: 18px;
  margin-top: 15px;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 5px;
}
.legend.vertical {
  width: auto;
}
.legend.vertical .legendHeader,
.legend.vertical .legendBody .legendItem {
  float: none;
}
.legend.horizontal {
  display: inherit;
}
.legendElement,
.legendText {
  display: table-cell;
  padding-left: 5px;
}
.legendElement.mtpage_current,
.legendText.mtpage_current {
  border-bottom: 2px solid var(--color--audi--rot);
}
.legendElement {
  padding-right: 3px;
  padding-left: 2px;
  vertical-align: top;
  text-align: center;
}
.legendElement .selectTreeLegendInvisible {
  position: relative;
}
.legendElement .selectTreeLegendInvisible:after {
  content: ' ';
  font-size: inherit;
  display: block;
  position: absolute;
  margin-left: 0;
  right: 0;
  left: 0;
  top: 30%;
  bottom: 40%;
  color: inherit;
  border-top: 1px solid #9f9f9f;
  border-bottom: 1px solid #9f9f9f;
}
.legendElement .selectTreeLegendEditTemplatesAll {
  position: relative;
}
.legendElement .selectTreeLegendEditTemplatesAll:after {
  content: ' ';
  font-size: inherit;
  display: block;
  position: absolute;
  margin-left: 0;
  right: 0;
  left: 0;
  top: 30%;
  bottom: 40%;
  color: inherit;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
}
.legendItem .legendElement.calendar_valid,
.legendItem .legendElement.calendar_invalid .legendItem .legendElement.calendar_today,
.legendItem .legendElement.calendar_early {
  height: 15px;
  width: 20px;
  font-weight: bold;
  background-color: var(--color--audi--weiss);
  border: 1px solid var(--color--base--black);
}
.legendItem .legendElement.calendar_today {
  color: var(--color--audi--weiss);
  background-color: var(--color--base--black);
}
.legendItem .legendElement.calendar_invalid {
  color: var(--color--audi--hell-grau-60);
  border: 1px solid var(--color--audi--hell-grau-60);
}
.legendItem .legendElement.calendar_early {
  color: var(--color--ci--primary);
}
.legendItem .legendElement.calendar_booked {
  width: 22px;
  height: 15px;
}
.legendGroup.horizontal .legend {
  width: auto;
}
.panel.general {
  margin-bottom: 2px;
}
.panel.searchmask {
  margin-bottom: 15px;
}
.panel.containsErrors {
  border: 1px solid var(--color--audi--rot);
}
.panel.containsErrors .panelHeader .panelLabel label,
.panel.containsErrors .panelHeader .counter {
  color: var(--color--audi--rot);
}
div.panel.selecttree select {
  visibility: inherit;
  height: auto;
}
.panelHeader {
  background-color: var(--color--audi--hell-grau-30);
  vertical-align: middle;
  padding: 5px 10px;
  font-family: var(--typography--audi--type-extended-bold);
  font-size: 15px;
  line-height: 20px;
}
.panelHeader .toggleIcon {
  margin-top: 5px;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-image: url("./customer/audi/img/icons/plus.png");
}
.panelHeader:hover .toggleIcon {
  background-image: url("./customer/audi/img/icons/plus_hover.png");
}
.panelHeader.expand .toggleIcon {
  background-image: url("./customer/audi/img/icons/minus.png");
}
.panelHeader.expand:hover .toggleIcon {
  background-image: url("./customer/audi/img/icons/minus_hover.png");
}
.panelHeader .counter {
  margin-left: 5px;
}
.toggle .panelHeader:hover {
  color: var(--color--ci--primary);
}
.toggleIcon {
  height: 10px;
  width: 10px;
  float: left;
  margin-right: 10px;
}
.panelContent {
  padding: 5px 10px;
}
.panelContent.searchmask {
  padding: 0 0 0 0;
}
.panelContent.searchmask .bas-formBox {
  padding: 5px 10px;
}
.panelContent .bas-formBox {
  border: none;
  display: block;
  width: auto;
}
.panelContent .field.buttonline {
  margin-top: 0;
  padding-top: 10px;
  border-top: 1px solid var(--color--layout--visual);
}
.lt-ie9 .panelContent .marginContainer {
  margin-bottom: 0;
}
.lt-ie9 .panelContent .marginContainer .chosen-container {
  position: relative;
}
.toggleAllPanelWrapper {
  height: 40px;
}
.toggleAllPanelWrapper .bas-inputButton > .button {
  position: absolute;
  right: 0;
  margin-right: 0;
  margin-bottom: 0;
  padding: 0 12px;
}
.panel .boxGrid {
  padding: 10px;
}
.panel .boxGrid .box a,
.panel .boxGrid .box div.inactive {
  display: block;
}
.boxRow .boxGrid {
  padding: 0;
}
.box .add,
.box .rename,
.box .delete {
  display: block;
}
.box .defaultLink {
  margin-bottom: 5px;
}
.dynamicSelectTree .ym-equalize .ym-gbox,
.dynamicAreaTree .ym-equalize .ym-gbox {
  margin: 0;
  padding: 0;
}
div.middle a.controls.moveLeft {
  margin-right: 0;
  float: none;
}
div.middle a.controls.moveRight {
  margin-left: 0;
}
div.ym-gbox.middle {
  text-align: center;
  padding-top: 140px;
}
.calendar-div {
  font-family: var(--typography--audi--type-bold);
  font-size: 13px;
  color: var(--color--font--primary);
  background-color: var(--color--layout--background);
  border: 1px solid var(--color--audi--hell-grau-60);
  width: 255px;
  padding: 0;
  line-height: 34px;
  display: table;
  position: relative;
}
.calendar-div .weekOfYearHeader,
.calendar-div .weekOfYear {
  color: var(--color--audi--hell-grau-60);
}
.calendar-div .weekOfYear {
  font-family: var(--typography--audi--type-normal);
  border: none;
}
.calendar-div .calendar-title {
  text-align: center;
  margin-top: 2px;
}
.calendar-div a.calendar-next,
.calendar-div a.calendar-prev {
  margin: 5px;
  width: 24px;
  height: 24px;
  padding: 0;
  background-repeat: no-repeat;
  position: absolute;
}
.calendar-div a.calendar-next {
  background-image: url("./customer/audi/img/kalender/vor.png");
  right: 0;
}
.calendar-div a.calendar-next:hover {
  background-image: url("./customer/audi/img/kalender/vor-rollover.png");
}
.calendar-div a.calendar-prev {
  background-image: url("./customer/audi/img/kalender/zurueck.png");
}
.calendar-div a.calendar-prev:hover {
  background-image: url("./customer/audi/img/kalender/zurueck-rollover.png");
}
table.calendar {
  margin-top: 5px;
}
table.calendar th,
table.calendar td {
  vertical-align: middle;
  text-align: center;
}
table.calendar thead th {
  border: none;
}
table.calendar tbody {
  background-image: url("./customer/audi/img/kalender/hg-tage.png");
  background-position: 30px 0;
  background-repeat: no-repeat;
}
table.calendar tbody td {
  border-top: 1px solid var(--color--audi--hell-grau-60);
  border-left: 1px solid var(--color--audi--hell-grau-60);
  font-size: 12px;
}
table.calendar tbody tr.row:last-child td {
  border-bottom: 1px solid transparent;
}
table.calendar td {
  width: 30px;
  max-width: 30px;
  height: 24px;
  font-family: var(--typography--audi--type-normal);
}
table.calendar td .item,
table.calendar td .itemBox,
table.calendar td .itemBox a.defaultLink {
  width: 100%;
  height: 100%;
}
table.calendar td .itemBox {
  justify-content: center;
}
table.calendar td a.defaultLink {
  padding: 0;
  background: none;
}
table.calendar td .itemBox span,
table.calendar td a.defaultLink span {
  font-size: 12px;
  line-height: 24px;
}
table.calendar td.today,
table.calendar td.selected,
table.calendar td.not-selected,
table.calendar td.early-start-not-selected {
  border: 1px solid var(--color--interactive--background) ! important;
  font-family: var(--typography--audi--type-normal);
  font-weight: bold;
}
table.calendar td.selected,
table.calendar td.selected a.defaultLink,
table.calendar td.selected a.defaultLink span {
  color: #fff;
  background-color: var(--color--ci--primary) ! important;
}
table.calendar td.early-start-not-selected a.defaultLink span {
  color: var(--color--ci--primary);
}
table.calendar td.selected:hover,
table.calendar td.not-selected:hover,
table.calendar td.early-start-not-selected:hover,
table.calendar td.selected:hover a.defaultLink,
table.calendar td.not-selected:hover a.defaultLink,
table.calendar td.early-start-not-selected:hover a.defaultLink,
table.calendar td.selected:hover a.defaultLink span,
table.calendar td.not-selected:hover a.defaultLink span,
table.calendar td.early-start-not-selected:hover a.defaultLink span,
table.calendar td.selected:hover a.defaultLink span:hover,
table.calendar td.not-selected:hover a.defaultLink span:hover,
table.calendar td.early-start-not-selected:hover a.defaultLink span:hover {
  color: var(--color--interactive--font);
  background-color: var(--color--interactive--background--hover) ! important;
}
table.calendar td.today,
table.calendar tr.row:hover td.today {
  color: var(--color--interactive--font);
  background-color: var(--color--interactive--background) ! important;
}
table.calendar .item.horizontal div.itemBox {
  display: inherit;
  float: none;
  padding: 0;
}
.preview-highlight {
  outline: solid 1px var(--color--ci--primary) ! important;
}
.preview-highlight:hover {
  cursor: pointer;
}
.rectanglesActive {
  outline: solid 1px var(--color--ci--primary);
}
.overlay .overviewButtons {
  background-color: var(--color--layout--visual);
}
div.overviewButton {
  cursor: pointer;
  margin-top: 5px;
  margin-right: 5px;
}
.overviewArea .bas-inputText {
  margin-right: 5px;
}
div.zoomIn {
  background: url("./customer/audi/img/icons/edit/lupe-vergroessern.png") no-repeat center center;
}
div.zoomIn:hover {
  background: url("./customer/audi/img/icons/edit/lupe-vergroessern-rollover.png") no-repeat center center;
}
div.zoomOut {
  background: url("./customer/audi/img/icons/edit/lupe-verkleinern.png") no-repeat center center;
}
div.zoomOut:hover {
  background: url("./customer/audi/img/icons/edit/lupe-verkleinern-rollover.png") no-repeat center center;
}
div.zoomDynamic {
  background: url("./customer/audi/img/icons/edit/ausschnittvergroessern.png") no-repeat center center;
}
div.zoomDynamic:hover {
  background: url("./customer/audi/img/icons/edit/ausschnittvergroessern-rollover.png") no-repeat center center;
}
div.zoomDynamic.active {
  background: url("./customer/audi/img/icons/edit/ausschnittvergroessern-rollover.png") no-repeat center center;
}
div.scaleX {
  background: url("./customer/audi/img/icons/edit/skalieren-breite.png") no-repeat center center;
}
div.scaleX:hover {
  background: url("./customer/audi/img/icons/edit/skalieren-breite-rollover.png") no-repeat center center;
}
div.scaleY {
  background: url("./customer/audi/img/icons/edit/skalieren-hoehe.png") no-repeat center center;
}
div.scaleY:hover {
  background: url("./customer/audi/img/icons/edit/skalieren-hoehe-rollover.png") no-repeat center center;
}
div.allrectangles {
  background: url("./customer/audi/img/icons/edit/individualisierung.png") no-repeat center center;
}
div.allrectangles.active,
div.allrectangles.active:hover,
div.allrectangles:hover {
  background: url("./customer/audi/img/icons/edit/individualisierung-rollover.png") no-repeat center center;
}
div.pdfDownload {
  background: url("./customer/audi/img/icons/edit/pdf.png") no-repeat center center;
}
div.pdfDownload:hover {
  background: url("./customer/audi/img/icons/edit/pdf-rollover.png") no-repeat center center;
}
div.pdfDownload_all {
  background: url("./customer/audi/img/icons/edit/pdf-alle.png") no-repeat center center;
}
div.pdfDownload_all:hover {
  background: url("./customer/audi/img/icons/edit/pdf-alle-rollover.png") no-repeat center center;
}
div.pdfsuperview,
div.pdfsuperview_all {
  background: url("./customer/audi/img/icons/edit/superview.png") no-repeat center center;
}
div.pdfsuperview:hover,
div.pdfsuperview_all:hover {
  background: url("./customer/audi/img/icons/edit/superview-rollover.png") no-repeat center center;
}
div.minimize {
  background: url("./customer/audi/img/icons/edit/superview_minimize.png") no-repeat center center;
}
div.minimize:hover {
  background: url("./customer/audi/img/icons/edit/superview_minimize-rollover.png") no-repeat center center;
}
div.reset {
  background: url("./customer/audi/img/icons/edit/reset.png") no-repeat center center;
}
div.reset:hover {
  background: url("./customer/audi/img/icons/edit/reset-rollover.png") no-repeat center center;
}
div.reload {
  background: url("./customer/audi/img/icons/edit/refresh.png") no-repeat center center;
}
div.reload:hover {
  background: url("./customer/audi/img/icons/edit/refresh-rollover.png") no-repeat center center;
}
a.defaultLink.icon.reload {
  width: 20px;
  height: 20px;
  background: url("./customer/audi/img/icons/edit/refresh.png") no-repeat center center;
}
a.defaultLink.icon.reload:hover {
  background: url("./customer/audi/img/icons/edit/refresh-rollover.png") no-repeat center center;
}
.overviewButtons input.zoomInput {
  margin-top: 7px;
  margin-right: 5px;
}
#editMask .superView .overviewButtons .bas-inputText input.zoomInput {
  margin-top: 2px;
}
a.icon.reload {
  background: url("./customer/audi/img/icons/edit/refresh.png") no-repeat;
  width: 20px;
  height: 20px;
  margin: 5px 5px 0 0;
  padding-right: 0;
}
a.icon.reload:hover {
  background: url("./customer/audi/img/icons/edit/refresh-rollover.png") no-repeat;
}
#editMask {
  margin-bottom: 30px;
}
#editMask #overviewArea {
  width: 100%;
}
#editMask div.groupSeparator:first-child {
  margin-top: 0;
}
#editMask .ym-gl #overviewContainer {
  padding-right: 10px;
}
#editMask .ym-gl #overviewContainer .field.noLabel {
  display: block;
}
#editMask #editMaskContainer #editMaskBox {
  padding-left: 10px;
}
#editMask #editMaskContainer .imgButton:not(.longImgButtonLine) {
  width: 175px;
}
#editMask .field .ym-gl {
  padding-right: 0;
}
#editMask .field .ym-gr {
  padding-left: 0;
}
#editMask .field .ym-gl,
#editMask .field .ym-gr {
  padding-bottom: 10px;
  clear: right;
}
#editMask .bas-inputArea > span {
  display: inline-block;
}
#editMask input,
#editMask textarea {
  margin-bottom: 3px;
}
#editMask div.bas-inputCheck span {
  line-height: 23px;
}
#editMask div.bas-inputCheck div.radio {
  background-position: left 4px;
  height: 21px;
}
#editMask .tooltipTrigger.infoIcon {
  display: inline-block;
  vertical-align: top;
}
#editMask .itemBox .bas-inputCheck {
  margin-right: 0;
}
.overviewArea {
  background: transparent;
}
.overviewImage {
  background: transparent;
  border: 0 none;
}
.overviewImage .image,
.overviewImage embed#overview {
  border: 1px solid var(--color--layout--visual);
}
.overviewImage.standard,
.overviewImage.pos,
.overviewImage.newsletter,
.overviewImage.newslettertm,
.overviewImage.some {
  height: 600px;
}
.overviewImage div.image {
  position: absolute;
}
.overviewImage.newsletter div.image {
  border: none;
}
.overviewImage.newslettertm {
  border: 1px solid var(--color--layout--visual);
}
.overviewImage.newslettertm div.image {
  border: none;
}
.overviewImage.some .image {
  width: calc(100% - 2px);
  height: calc(100% - 2px);
}
.overviewImage.some .image .videoNotReadyHint {
  border-bottom: 1px solid var(--color--layout--visual);
}
.overviewButtons {
  background: var(--color--audi--hell-grau-30);
  border: 0 none;
}
.overviewButtons div.verticalSeparator {
  margin-right: 5px;
}
.superviewContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 50px;
  padding: 30px 22px;
  background: #ffffff;
}
.superviewContainer .overviewArea {
  position: relative;
  width: 100%;
  height: 100% ! important;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.superviewContainer .overviewButtons {
  position: relative;
  bottom: auto;
  border-radius: 0;
  box-shadow: 0 0 0 transparent;
  height: 30px;
  text-align: left;
  margin-bottom: 10px;
}
.superviewContainer .hasMultiPagePreview .overviewButtons {
  margin-left: 150px;
}
.superviewContainer .close {
  background: url("./customer/audi/img/icons/layer-schliessen-passiv.png");
  width: 32px;
  height: 32px;
}
.superviewContainer .close:hover {
  background: url("./customer/audi/img/icons/layer-schliessen-rollover.png");
}
.overlay .superviewContainer .overviewImage {
  height: 100%;
  width: 100%;
  position: absolute;
  margin-left: 0;
}
.overlay .superviewContainer .hasMultiPagePreview .overviewImage {
  border-left: solid 150px transparent;
}
.overlay .superviewContainer .overviewArea {
  padding: 0;
}
.overlay .superviewContainer .jScrollPaneScrollable {
  position: absolute;
  top: 0;
  overflow: hidden;
}
.overlay .jspVerticalBar {
  width: 10px;
  background: transparent;
}
.overlay .jspTrack {
  background: transparent;
}
.overlay .jspDrag {
  border-radius: 5px;
  background: var(--color--audi--mittel-grau);
  opacity: 0.75;
}
.overlay .multiPageSelect {
  width: 130px;
  overflow: hidden;
}
.overlay .multiPageSelect.loading {
  background: var(--color--audi--hell-grau-30);
}
.overlay .multiPageSelect .imageContainer {
  margin: 0 14px 7px 0;
}
.overlay .multiPageSelect .imageContainer img {
  border: solid 1px var(--color--audi--mittel-grau);
  opacity: 0.75;
}
.overlay .multiPageSelect .imageContainer img:hover,
.overlay .multiPageSelect .imageContainer img.current {
  border-color: var(--color--ci--primary);
  opacity: 1;
}
.overviewContainer {
  height: 100%;
}
div.overlay div.superviewContainer div.multiPageSelect {
  bottom: 24px;
}
#templateTitle {
  font-family: var(--typography--audi--type-extended-bold);
  font-size: 18px;
  line-height: 25px;
}
.hasMultiPagePreview #templateTitle {
  margin-left: 150px;
}
#editMaskContainer .ym-grid select.co2-select-filter {
  width: 174px ! important;
}
#editMaskContainer .charcounter {
  width: 100%;
}
#editMaskContainer .bas-formSection .bas-label label div.item.horizontal.ym-clearfix {
  display: block;
}
#editMaskContainer input[type=text]:not([class*="cols"]),
#editMaskContainer textarea:not([class*="cols"]),
#editMaskContainer select.combo:not([class*="cols"]),
#editMaskContainer .inlineckeditor:not([class*="cols"]) {
  width: 100% ! important;
}
#editMaskContainer .item.horizontal input[type=text],
#editMaskContainer .item.horizontal textarea,
#editMaskContainer .item.horizontal .inlineckeditor {
  width: 100% ! important;
}
#editMaskContainer .item.horizontal input[type=text].cols3,
#editMaskContainer .item.horizontal select.cols3,
#editMaskContainer .item.horizontal textarea.cols3,
#editMaskContainer .item.horizontal .inlineckeditor.cols3 {
  width: 335px ! important;
}
#editMaskContainer .item.horizontal > .itemBox + .itemBox > .bas-inputCheck {
  margin-left: 15px;
}
#editMaskContainer input[type=text][size],
#editMaskContainer textarea[cols] {
  width: auto ! important;
}
#editMaskContainer .item.vertical .item.horizontal > .itemBox:not(.contains-tooltip) {
  padding-right: 0;
}
#editMaskContainer .item.vertical > .itemBox:not(.first) {
  margin-top: 15px;
}
#editMaskContainer .buttonline {
  padding-top: 0;
}
#editMaskContainer .buttonline .icon {
  padding-right: 0;
}
#editMaskContainer .buttonline .defaultLink.inactive.icon.text.lock_button_inactive.lockCheckboxes {
  margin-right: 10px;
}
#editMaskContainer .buttonline .defaultLink.inactive.icon.text.unlock_button_inactive.unlockCheckboxes {
  margin-left: 20px;
}
#editMaskContainer .bas-inputCheck.bas-inputRadio {
  margin-right: 0;
}
#editMaskContainer div.bas-formBox.admin {
  background-color: var(--color--layout--visual);
}
#editMaskContainer div.bas-formBox.familylibrary {
  background-color: #96ffff;
}
#editMaskContainer .itemBox:has(input[type="checkbox"]) + .itemBox.contains-input {
  flex-grow: 1;
}
.imgButton_container {
  width: auto;
  outline: 2px solid var(--color--interactive--background);
}
.imgButton_container img {
  background-color: var(--color--interactive--background);
  width: 100%;
}
.imgButton_container:hover {
  outline-color: var(--color--interactive--highlight--background--hover);
}
.imgButton_container:hover img {
  background-color: var(--color--interactive--highlight--background--hover);
}
.imgButton_container.selected,
.imgButton_container.selected[data-disabled]:hover {
  outline-color: var(--color--interactive--highlight--background--hover);
}
.imgButton_container.selected img,
.imgButton_container.selected[data-disabled]:hover img {
  background-color: var(--color--interactive--highlight--background--hover);
}
.imgButton_container .isSelected {
  background-color: var(--color--layout--background);
  border-color: var(--color--layout--background);
}
div.charcounter {
  font-size: 11px;
  padding-right: 0px !important;
}
div.charcounter.error {
  background: none;
}
#overviewContainer .overviewImage .overlay,
.overlay.editView .overlay {
  position: absolute;
}
span.label-after-trigger {
  margin-left: 5px;
}
div[id^="caret:"].active > div,
div[id^="caret:"].active div[id^="caret:"].active {
  border-color: var(--color--ci--primary);
}
div[id^="caret:"].active.focus > div,
div[id^="caret:"].active div[id^="caret:"].inactive:hover > div,
div[id^="caret:"].active:hover > div {
  border-color: var(--color--feedback--success);
}
#editMask.newsletter #title span.subtitle_normal {
  font-family: var(--typography--audi--type-extended-bold);
  font-size: 13px;
  margin: 10px 0;
  display: inline-block;
}
#editMask.newsletter.nltemplate .bas-formSection .inlineckeditor.cols3 {
  width: 317px ! important;
  max-width: 317px ! important;
}
#editMask.newsletter.nltemplate .bas-formSection .inlineckeditor.cols3.nolockable {
  width: 363px ! important;
  max-width: 363px ! important;
}
#editMask.newsletter.nluser .bas-formSection .inlineckeditor.cols3 {
  width: 363px ! important;
  max-width: 363px ! important;
}
#editMask.newsletter .bas-formSection .item.horizontal.ym-clearfix {
  display: table;
  width: 100%;
}
#editMask.newsletter .bas-formSection .item.horizontal.ym-clearfix .itemBox {
  float: none;
  display: table-cell;
  vertical-align: top;
}
#editMask.newsletter .bas-formSection .item.horizontal.ym-clearfix .itemBox.next-element-is-tooltip {
  width: 100%;
}
#editMask.newsletter .bas-formSection .item.horizontal.ym-clearfix .itemBox input[type=text].cols1,
#editMask.newsletter .bas-formSection .item.horizontal.ym-clearfix .itemBox .inlineckeditor.cols1 {
  width: 70px ! important;
}
#editMask.newsletter .bas-formSection .item.horizontal.ym-clearfix .itemBox input[type=text].cols12,
#editMask.newsletter .bas-formSection .item.horizontal.ym-clearfix .itemBox .inlineckeditor.cols12 {
  width: 285px ! important;
}
#editMask.newsletter .bas-formSection .item.horizontal.ym-clearfix .itemBox input[type=text].cols_tele_1,
#editMask.newsletter .bas-formSection .item.horizontal.ym-clearfix .itemBox .inlineckeditor.cols_tele_1 {
  width: 35px ! important;
}
#editMask.newsletter .bas-formSection .item.horizontal.ym-clearfix .itemBox input[type=text].cols_tele_2,
#editMask.newsletter .bas-formSection .item.horizontal.ym-clearfix .itemBox .inlineckeditor.cols_tele_2,
#editMask.newsletter .bas-formSection .item.horizontal.ym-clearfix .itemBox input[type=text].cols_tele_4,
#editMask.newsletter .bas-formSection .item.horizontal.ym-clearfix .itemBox .inlineckeditor.cols_tele_4 {
  width: 55px ! important;
}
#editMask.newsletter .bas-formSection .item.horizontal.ym-clearfix .itemBox .bas-inputCheck,
#editMask.newsletter .bas-formSection .item.horizontal.ym-clearfix .itemBox .bas-inputCheck .lockcheckbox {
  padding-right: 0;
  margin-right: 0;
}
#editMask.newsletter .bas-formSection .item.horizontal.ym-clearfix .itemBox .bas-inputCheck .lockcheckbox {
  background-position: left top;
  min-width: 26px;
}
#editMask.newsletter .bas-formSection .item.horizontal.ym-clearfix .itemBox select.tyre-labelling-select-filter {
  width: 168px ! important;
}
#editMask.newsletter .bas-formSection .item.horizontal.ym-clearfix .item.vertical .itemBox {
  display: block;
}
#editMask.newsletter .bas-formSection:first-child .groupSeparator {
  margin-top: 0;
}
#editMask.newsletter .bas-formSection div.cols3,
#editMask.newsletter .bas-formSection input[type=text].cols3,
#editMask.newsletter .bas-formSection select.cols3,
#editMask.newsletter .bas-formSection textarea.cols3 {
  width: 100% ! important;
}
#editMask.newsletter .bas-formSection div.urlCheckerContainer.cols3 {
  width: 466px ! important;
  padding-right: 0 ! important;
}
#editMask.newsletter .bas-formSection .footnoteIndex + .itemBox .bas-inputText {
  width: 300px;
}
#editMask.newsletter .bas-formSection .footnoteIndex ~ .itemBox a {
  margin-left: 5px;
  padding: 0;
}
#editMask.newsletter .bas-formSection.ym-clearfix select.selectCols3,
#editMask.newsletter .bas-formSection .item.vertical .itemBox select.selectCols3,
#editMask.newsletter .bas-formSection .item.horizontal .itemBox select.selectCols3 {
  width: 360px ! important;
}
#editMask.newsletter .footnoteIndex > sup {
  width: 12px;
  display: inline-table;
}
#editMask.newsletter div.radio {
  padding-right: 24px;
  width: auto;
  position: relative;
  height: 18px;
}
#editMask.newsletter .hasUrlChecker {
  display: inline-block;
}
#editMask.newsletter select.procurationSelection,
#editMask.newsletter input[type=text].behind-checkbox {
  width: 336px ! important;
}
#editMask.newsletter select.co2selection + div.selectBox {
  margin-bottom: 5px;
}
#editMask.newsletter .cke {
  width: 100% ! important;
}
#editMask.newsletter div.charcounter {
  display: inline-block ! important;
  text-align: right;
  padding-right: 0;
}
#editMask.newsletter img.tyrelabelling {
  position: absolute;
  height: 20px;
  margin-left: 5px;
}
.picturePreview {
  margin-left: 15px;
}
.picturePreview .itemBox {
  padding-right: 0;
}
.picturePreview .buttonline {
  border: 1px solid var(--color--layout--visual);
  border-top: 0 none;
}
.pictureinfo {
  border: 1px solid var(--color--layout--visual);
  padding: 10px;
}
.pictureinfo span {
  display: block;
}
.pictureinfo img.lightboxPreview,
.pictureinfo embed#overview {
  width: 100%;
  display: block;
  margin: 0 auto;
}
div.overlay.ajax {
  z-index: 10109 ! important;
  background-image: url("./default/img/overlay_background_dark.png");
}
div.overlay.superView {
  display: none;
}
div.overlay.superView.editView {
  display: block;
}
div.overlay .loader {
  background: url("./customer/audi/img/audi_loader.gif");
  width: 50px;
  height: 50px;
}
div.overlay .progressMonitor.upload {
  height: auto;
  background: #fff;
}
div.overlay .progressMonitor.upload .progressPercentageContainer {
  color: var(--color--font--primary);
  font-size: 14px;
  font-family: var(--typography--audi--type-normal);
  padding-top: 10px;
}
div.overlay .progressMonitor.upload .progressBarContainer {
  background: transparent;
}
div.overlay .progressMonitor.upload .progressBarContainer .progressBar {
  background: var(--color--font--primary);
}
.sliderContainer div.overlay .sliderLoader {
  background: url("./customer/audi/img/audi_loader.gif");
  width: 50px;
  height: 50px;
}
.overviewImage.banner,
.overviewImage.standard,
.overviewImage.pos,
.overviewArea .smallPreview,
.overviewImage.newsletter {
  margin-top: 5px;
  margin-bottom: 5px;
  border: 2px solid var(--color--audi--hell-grau-30);
}
div.imageLoaderOverlay {
  background-image: url("./default/img/overlay_background_dark.png");
}
div.imageLoaderOverlay .loader {
  background: url("./customer/audi/img/audi_loader.gif");
  width: 50px;
  height: 50px;
}
.flex.horizontal > .itemBox {
  padding-right: 5px;
}
.flex.vertical > .itemBox > .bas-inputButton {
  margin-top: 5px;
}
.flex.vertical > .itemBox.first > .bas-inputButton {
  margin-top: 0;
}
.flex.linkGroup {
  margin: 5px 0;
}
.noLabel .flex.horizontal > .itemBox {
  max-width: 720px;
}
.noLabel .flex.horizontal.column160 > .itemBox {
  max-width: 160px;
}
.ui-datepicker {
  background-color: var(--color--audi--weiss);
  border: 1px solid var(--color--audi--hell-grau-60);
  padding: 0;
  height: auto;
  width: 255px;
}
.ui-datepicker .ui-datepicker-header {
  padding: 0;
  margin-bottom: 10px;
}
.ui-datepicker .ui-datepicker-title {
  margin: 0;
  padding-top: 4px;
}
.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current {
  height: 24px;
  background: var(--color--interactive--background);
  color: var(--color--interactive--font);
  margin: 5px auto;
  font-family: var(--typography--audi--type-bold);
  font-size: 13px;
  font-weight: normal;
  padding: 0 5px 1px 5px;
  float: none;
}
.ui-datepicker table {
  margin: 0;
  font-size: 13px;
}
.ui-datepicker .ui-datepicker-buttonpane {
  margin: 0;
  padding: 0;
}
.ui-datepicker-trigger,
.clockpicker-trigger {
  opacity: 1 !important;
  margin-left: 10px;
}
.ui-datepicker-title span,
.ui-datepicker-calendar {
  font-family: var(--typography--audi--type-bold);
  font-size: 13px;
  color: var(--color--font--primary);
  margin-top: 12px;
}
.ui-datepicker-calendar {
  background-image: url("./customer/audi/img/kalender/hg-tage.png");
  background-position: 30px 20px;
  background-repeat: no-repeat;
}
.ui-datepicker-calendar thead .ui-datepicker-week-col,
.ui-datepicker-calendar tbody .ui-datepicker-week-col,
.ui-datepicker-calendar tbody td.ui-datepicker-week-col:hover,
.ui-datepicker-calendar tbody td.ui-datepicker-week-col:hover a {
  color: var(--color--audi--hell-grau-60);
}
.ui-datepicker-calendar tbody .ui-datepicker-other-month,
.ui-datepicker-calendar tbody .ui-datepicker-week-col {
  font-family: var(--typography--audi--type-normal);
  font-size: 13px;
}
.ui-datepicker-calendar tbody .ui-datepicker-week-col,
.ui-datepicker-calendar tbody tr:hover .ui-datepicker-week-col {
  border: none;
  background-color: var(--color--audi--weiss) ! important;
}
.ui-datepicker-calendar thead th {
  border: none;
  text-align: center;
  padding: 0 0 0 0;
}
.ui-datepicker-calendar td {
  width: 30px;
  max-width: 30px;
  height: 24px;
  border-top: 1px solid var(--color--audi--hell-grau-60);
  border-bottom: 1px solid var(--color--audi--hell-grau-60);
  border-left: 1px solid var(--color--audi--hell-grau-60);
  border-right: none;
  text-align: center;
  vertical-align: middle;
}
.ui-datepicker-calendar td.early-start-not-selected a {
  color: var(--color--ci--primary);
}
.ui-datepicker-calendar td.ui-datepicker-today a,
.ui-datepicker-calendar td.ui-datepicker-today span,
.ui-datepicker-calendar td.ui-datepicker-current-day a,
.ui-datepicker-calendar tbody td:hover,
.ui-datepicker-calendar tbody td:hover a,
.ui-datepicker-calendar tbody td a:hover {
  color: var(--color--audi--weiss);
}
.ui-datepicker-calendar td.ui-datepicker-unselectable {
  cursor: default !important;
}
.ui-datepicker-calendar td.ui-datepicker-unselectable.ui-datepicker-today span {
  color: var(--color--interactive--font);
}
.ui-datepicker-calendar td.ui-datepicker-unselectable.ui-datepicker-today:hover {
  background-color: var(--color--interactive--background);
}
.ui-datepicker-calendar td.ui-datepicker-unselectable span {
  color: var(--color--audi--hell-grau-60);
}
.ui-datepicker-calendar tbody tr:hover,
.ui-datepicker-calendar td.ui-datepicker-unselectable:hover,
.ui-datepicker-calendar td.ui-datepicker-week-col:hover {
  background-color: transparent;
}
.ui-datepicker-calendar tbody tr:hover td {
  background-color: initial;
}
.ui-datepicker-calendar tbody tr:hover td.ui-datepicker-today {
  background-color: var(--color--interactive--background);
}
.ui-datepicker-calendar tbody tr:hover td.ui-datepicker-today.ui-datepicker-days-cell-over {
  background-color: var(--color--ci--primary);
}
.ui-datepicker-calendar tbody tr:hover td.ui-datepicker-today:hover {
  background-color: var(--color--audi--mittel-grau);
}
.ui-datepicker-calendar td[data-handler='selectDay']:hover {
  background-color: var(--color--audi--mittel-grau);
}
.ui-datepicker-calendar -today,
.ui-datepicker-calendar td.ui-datepicker-today {
  background-color: var(--color--interactive--background);
}
.ui-datepicker-calendar tr:hover td.ui-datepicker-current-day,
.ui-datepicker-calendar td.ui-datepicker-current-day {
  background-color: var(--color--ci--primary);
}
.ui-state-default.ui-priority-secondary {
  color: var(--color--font--secondary);
}
.ui-datepicker-calendar tbody td:hover,
.ui-datepicker-calendar tbody td.ui-datepicker-today:hover,
.ui-datepicker-calendar tbody td.ui-datepicker-current-day:hover,
.ui-datepicker-buttonpane .ui-datepicker-current:hover {
  background-color: var(--color--audi--mittel-grau);
  cursor: pointer;
}
.ui-widget-header .ui-datepicker-prev,
.ui-widget-header .ui-datepicker-prev:hover,
.ui-widget-header .ui-datepicker-next,
.ui-widget-header .ui-datepicker-next:hover {
  top: 0;
}
.ui-widget-header .ui-datepicker-prev,
.ui-widget-header .ui-datepicker-prev:hover {
  left: 0;
}
.ui-widget-header .ui-datepicker-next,
.ui-widget-header .ui-datepicker-next:hover {
  right: 0;
}
.ui-widget-header .ui-datepicker-prev,
.ui-widget-header .ui-datepicker-next {
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  margin: 5px;
  cursor: pointer;
}
.ui-widget-header .ui-datepicker-prev.ui-state-disabled,
.ui-widget-header .ui-datepicker-next.ui-state-disabled,
.ui-widget-header .ui-datepicker-prev.ui-state-disabled:hover,
.ui-widget-header .ui-datepicker-next.ui-state-disabled:hover {
  cursor: default;
}
.ui-widget-header .ui-datepicker-prev span,
.ui-widget-header .ui-datepicker-next span {
  display: none;
}
.ui-widget-header .ui-datepicker-prev {
  background-image: url("./customer/audi/img/kalender/zurueck.png");
  float: left;
  cursor: pointer;
}
.ui-widget-header .ui-datepicker-prev:hover {
  background-image: url("./customer/audi/img/kalender/zurueck-rollover.png");
}
.ui-widget-header .ui-datepicker-prev.ui-state-disabled,
.ui-widget-header .ui-datepicker-prev.ui-state-disabled:hover {
  background-image: url("./customer/audi/img/kalender/zurueck-inactive.png");
}
.ui-widget-header .ui-datepicker-next {
  background-image: url("./customer/audi/img/kalender/vor.png");
  float: right;
}
.ui-widget-header .ui-datepicker-next:hover {
  background-image: url("./customer/audi/img/kalender/vor-rollover.png");
}
.ui-widget-header .ui-datepicker-next.ui-state-disabled,
.ui-widget-header .ui-datepicker-next.ui-state-disabled:hover {
  background-image: url("./customer/audi/img/kalender/vor-inactive.png");
}
.ui-corner-all {
  border-radius: unset;
}
.dummyLink {
  cursor: default;
}
.dummyLink.printElement {
  cursor: pointer;
}
.defaultLink.highlight {
  color: var(--color--ci--primary);
}
.defaultLink.noIcon,
.defaultLink.imageLink {
  background: none;
  padding-left: 0;
}
.defaultLink.icon.text {
  width: auto;
  padding-left: 36px;
}
.defaultLink.text.inline {
  padding-left: 16px;
  line-height: 20px;
  height: 20px;
  background-position: left center;
  margin-left: 5px;
  margin-right: 10px;
}
.defaultLink.urlChecker {
  display: inline-block;
  font-size: 11px;
  background: url("./customer/audi/img/icons/linkpfeil_klein.png") no-repeat scroll 0 4px transparent;
}
.defaultLink.incomplete {
  background: var(--color--audi--weiss) url('./customer/audi/img/mtpage_error.png') left center no-repeat;
  padding-left: 30px;
}
.defaultLink.complete {
  background: var(--color--audi--weiss) url('./customer/audi/img/mtpage_customizable.png') left center no-repeat;
  padding-left: 30px;
  padding-bottom: 1px;
}
span a.defaultLink {
  margin-left: 10px;
}
span > br + a.defaultLink {
  margin-left: 0;
}
.buttonline a.defaultLink + .defaultLink,
.buttonline a.defaultLink + .bas-inputButton {
  margin-left: 20px;
}
.buttonline div.defaultLink + .defaultLink {
  margin-left: 30px;
}
.buttonline div.dialog + .defaultLink {
  margin-left: 20px;
}
.buttonline.top a.defaultLink + .defaultLink,
.buttonline.top a.defaultLink + .bas-inputButton {
  margin-right: 10px;
  margin-left: 0;
}
.tabulation td.cmsLinkListFooter .defaultLink.icon.text {
  margin-bottom: 5px;
}
.tabulation .defaultLink.imageLink {
  border: 1px solid var(--color--audi--licht-grau);
}
#logout {
  background: url("./customer/audi/img/icons/logout.png") no-repeat right top;
  padding-left: 0;
  padding-right: 25px;
  margin-right: 20px;
  display: inline-block;
  height: 16px;
}
#logout:hover {
  background-image: url("./customer/audi/img/icons/logout-rollover.png");
}
span.showAsDisabledLink {
  padding-left: 16px;
  color: var(--color--font--secondary);
  background: url('./customer/audi/img/icons/linkpfeil_inaktiv.png') no-repeat 0 3px;
  display: inline-block;
}
.firefox div.bas-inputButton.withOptions .optionTrigger.icon > svg.preferences_scheduled .cog {
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}
.chrome div.bas-inputButton.withOptions .optionTrigger.icon > svg.preferences_scheduled .cog,
.ie div.bas-inputButton.withOptions .optionTrigger.icon > svg.preferences_scheduled .cog {
  -webkit-transform-origin: 16px 16px;
  -moz-transform-origin: 16px 16px;
  -ms-transform-origin: 16px 16px;
  transform-origin: 16px 16px;
}
div.bas-inputButton.withOptions .optionTrigger.icon > svg.preferences .cog {
  -webkit-transform-origin: 16px 16px;
  -moz-transform-origin: 16px 16px;
  -ms-transform-origin: 16px 16px;
  transform-origin: 16px 16px;
}
.button,
.button.dummy,
a.button,
a.button.dummy,
.button:focus,
.button.dummy:focus,
a.button:focus,
a.button.dummy:focus,
.button:active,
.button.dummy:active,
a.button:active,
a.button.dummy:active {
  background-color: var(--color--interactive--background);
  padding: 0 12px;
  margin-right: 10px;
  min-height: 32px;
  color: var(--color--interactive--font);
  display: inline-block;
  font-family: var(--typography--audi--type-bold);
  font-size: 13px;
  line-height: 32px;
  cursor: pointer;
}
.button:hover,
.button.dummy:hover,
a.button:hover,
a.button.dummy:hover,
.button:focus:hover,
.button.dummy:focus:hover,
a.button:focus:hover,
a.button.dummy:focus:hover,
.button:active:hover,
.button.dummy:active:hover,
a.button:active:hover,
a.button.dummy:active:hover {
  text-decoration: none;
  background-color: var(--color--interactive--background--hover);
  color: var(--color--interactive--font);
}
.button.defaultLink,
.button.dummy.defaultLink {
  background-image: none;
}
.bas-inputButton #login {
  background-image: url("./customer/audi/img/icons/doppelpfeil-weiss.png");
  background-repeat: no-repeat;
  background-position: right center;
  background-color: var(--color--interactive--background);
  padding-right: 23px;
  border-right: 12px solid var(--color--interactive--background);
}
.bas-inputButton #login:hover,
.bas-inputButton #login:focus {
  background-color: var(--color--interactive--background--hover);
  border-right: 12px solid var(--color--interactive--background--hover);
}
.bas-inputButton.inactive .button,
.bas-inputButton.inactive .button:focus,
.bas-inputButton.inactive .button:active,
.bas-inputButton.inactive .button:hover {
  background-color: var(--color--interactive--background--disabled);
  cursor: default;
}
.bas-inputButton.dummy .button:hover,
.bas-inputButton.dummy .button:focus,
.bas-inputButton.dummy .button:active {
  background-color: var(--color--interactive--background);
}
.bas-inputButton.withOptions .button.withOptions,
.bas-inputButton.withOptions .optionTrigger {
  border: 2px solid var(--color--interactive--background);
  background: var(--color--interactive--font);
  float: left;
  height: 32px;
}
.bas-inputButton.withOptions .button.withOptions {
  border-right: 0 solid transparent;
  color: var(--color--font--primary);
  line-height: 28px;
  margin-right: 0;
}
.bas-inputButton.withOptions.active .button.withOptions,
.bas-inputButton.withOptions.active .optionTrigger {
  border-color: var(--color--ci--primary);
}
.bas-inputButton.withOptions.active .button.withOptions {
  color: var(--color--ci--primary);
}
.bas-inputButton.withOptions .optionTrigger {
  border-left: 0 solid transparent;
  padding-right: 0;
}
.bas-inputButton.withOptions .optionTrigger.icon {
  width: 28px;
}
.bas-inputButton.withOptions .optionTrigger > svg.preferences .cog,
.bas-inputButton.withOptions .optionTrigger > svg.preferences_scheduled .cog {
  transition-duration: 0.8s;
  transition-property: transform;
}
.bas-inputButton.withOptions .optionTrigger:hover svg.preferences .cog,
.bas-inputButton.withOptions .optionTrigger:hover svg.preferences_scheduled .cog {
  -moz-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  transform: rotate(360deg);
}
.bas-inputButton.withOptions .optionTrigger:hover .hoverContainer {
  padding-bottom: 10px;
  bottom: 28px;
  left: -11px;
}
.bas-inputButton.withOptions .optionTrigger .hoverContainer .buttonOptions {
  background-color: var(--color--audi--weiss);
  border: 2px solid var(--color--ci--primary);
  z-index: 10108;
}
.bas-inputButton.withOptions .optionTrigger .hoverContainer .buttonOptions:before,
.bas-inputButton.withOptions .optionTrigger .hoverContainer .buttonOptions:after {
  width: 0;
  height: 0;
  z-index: 10108;
  border-style: solid;
}
.bas-inputButton.withOptions .optionTrigger .hoverContainer .buttonOptions:before {
  left: 2px;
  bottom: -10px;
  border-width: 10px 10px 0 10px;
  border-color: var(--color--ci--primary) transparent transparent transparent;
}
.bas-inputButton.withOptions .optionTrigger .hoverContainer .buttonOptions:after {
  left: 4px;
  bottom: -8px;
  border-width: 8px 8px 0 8px;
  border-color: var(--color--interactive--font) transparent transparent transparent;
}
.bas-inputButton.withOptions .optionTrigger .hoverContainer .buttonOptions .buttonOption {
  display: block;
  padding-right: 10px;
}
html.edge .bas-inputButton.withOptions .optionTrigger > svg.preferences {
  padding: 0;
}
.highlight .button,
.highlight .button:focus,
.highlight .button:active {
  background-color: var(--color--interactive--highlight--background);
  color: var(--color--interactive--font);
}
.highlight .button:hover,
.highlight .button:focus:hover,
.highlight .button:active:hover {
  background-color: var(--color--interactive--highlight--background--hover);
  color: var(--color--interactive--font);
}
.button.inactive,
.button.inactive:hover {
  background-color: var(--color--interactive--background--disabled);
}
:root,
::before {
  --svg-icon-color-normal: var(--color--audi--schwarz);
  --svg-icon-color-hover: var(--color--audi--rot);
  --svg-icon-color-inactive: #c2c2c2;
}
.defaultLink.icon,
div.icon {
  background-repeat: no-repeat;
  background-position: 0 0;
  display: inline-block;
  padding-left: 0;
  line-height: 26px;
  margin-right: 10px;
  cursor: pointer;
}
.defaultLink.icon.inactive,
div.icon.inactive {
  cursor: default;
}
.itemBox > .icon {
  margin-right: 0;
}
.icon,
.noIcon {
  width: 26px;
  height: 26px;
}
.inactive {
  cursor: default;
}
.defaultLink.icon.resetFilter {
  padding-right: 0;
  background-image: url("./customer/audi/img/icons/edit/filter-reset-btn.png");
}
.defaultLink.icon.resetFilter:hover,
.defaultLink.icon.resetFilter:focus {
  background-image: url("./customer/audi/img/icons/edit/filter-reset-btn-rollover.png");
}
.defaultLink.icon.rename,
.defaultLink.icon.edit_small,
.defaultLink.icon.edit,
.defaultLink.icon.edit_external {
  background-image: url("./customer/audi/img/btn/bearbeiten.png");
}
.defaultLink.icon.rename:hover,
.defaultLink.icon.edit_small:hover,
.defaultLink.icon.edit:hover,
.defaultLink.icon.edit_external:hover,
.defaultLink.icon.rename:focus,
.defaultLink.icon.edit_small:focus,
.defaultLink.icon.edit:focus,
.defaultLink.icon.edit_external:focus {
  background-image: url("./customer/audi/img/btn/bearbeiten-rollover.png");
}
.defaultLink.icon.favorite {
  background-image: url("./customer/audi/img/btn/favorite.png");
}
.defaultLink.icon.favorite:hover,
.defaultLink.icon.favorite:focus {
  background-image: url("./customer/audi/img/btn/favorite-rollover.png");
}
.defaultLink.icon.favorite_choosen {
  background-image: url("./customer/audi/img/btn/favorite-choosen.png");
}
.defaultLink.icon.favorite_choosen:hover,
.defaultLink.icon.favorite_choosen:focus {
  background-image: url("./customer/audi/img/btn/favorite-choosen-rollover.png");
}
.defaultLink.icon.edit_external {
  background-image: url("./customer/audi/img/btn/correction_external_site.png");
}
.defaultLink.icon.edit_external:hover,
.defaultLink.icon.edit_external:focus {
  background-image: url("./customer/audi/img/btn/correction_external_site_hover.png");
}
.defaultLink.icon.edit_small_metadata {
  background-image: url("./customer/audi/img/btn/metadaten_editieren.png");
}
.defaultLink.icon.edit_small_metadata:hover,
.defaultLink.icon.edit_small_metadata:focus {
  background-image: url("./customer/audi/img/btn/metadaten_editieren_rollover.png");
}
.defaultLink.icon.edit_small_metadata.inactive,
.defaultLink.icon.edit_small_metadata_inactive {
  background-image: url("./customer/audi/img/btn/metadaten_editieren_inaktiv.png");
}
.defaultLink.icon.copy_small,
.defaultLink.icon.copy {
  background-image: url("./customer/audi/img/btn/kopieren.png");
}
.defaultLink.icon.copy_small:hover,
.defaultLink.icon.copy:hover {
  background-image: url("./customer/audi/img/btn/kopieren-rollover.png");
}
.defaultLink.icon.lock_button_inactive {
  background-image: url("./customer/audi/img/btn/verschlossen-inaktiv.png");
}
.defaultLink.icon.lock_button_inactive:hover {
  background-image: url("./customer/audi/img/btn/verschlossen-inaktiv.png");
}
.defaultLink.icon.lock_small,
.defaultLink.icon.lock_button {
  background-image: url("./customer/audi/img/btn/verschlossen.png");
}
.defaultLink.icon.lock_small:hover,
.defaultLink.icon.lock_button:hover {
  background-image: url("./customer/audi/img/btn/verschlossen-rollover.png");
}
.defaultLink.icon.unlock_button_inactive {
  background-image: url("./customer/audi/img/btn/unverschlossen-inaktiv.png");
}
.defaultLink.icon.unlock_button_inactive:hover {
  background-image: url("./customer/audi/img/btn/unverschlossen-inaktiv.png");
}
.defaultLink.icon.unlock_small,
.defaultLink.icon.unlock_button {
  background-image: url("./customer/audi/img/btn/unverschlossen.png");
}
.defaultLink.icon.unlock_small:hover,
.defaultLink.icon.unlock_button:hover {
  background-image: url("./customer/audi/img/btn/unverschlossen-rollover.png");
}
.defaultLink.icon.visible_small {
  background-image: url("./customer/audi/img/btn/sichtbar.png");
}
.defaultLink.icon.visible_small:hover {
  background-image: url("./customer/audi/img/btn/sichtbar-rollover.png");
}
.defaultLink.icon.invisible_small {
  background-image: url("./customer/audi/img/btn/nicht-sichtbar.png");
}
.defaultLink.icon.invisible_small:hover {
  background-image: url("./customer/audi/img/btn/nicht-sichtbar-rollover.png");
}
.defaultLink.icon.assignment_small {
  background-image: url("./customer/audi/img/btn/zuordnen.png");
}
.defaultLink.icon.assignment_small:hover {
  background-image: url("./customer/audi/img/btn/zuordnen-rollover.png");
}
.defaultLink.icon.delete,
.defaultLink.icon.delete_small {
  background-image: url("./customer/audi/img/btn/loeschen.png");
}
.defaultLink.icon.delete:hover,
.defaultLink.icon.delete_small:hover,
.defaultLink.icon.delete:focus,
.defaultLink.icon.delete_small:focus {
  background-image: url("./customer/audi/img/btn/loeschen-rollover.png");
}
.defaultLink.icon.show,
.defaultLink.icon.show_small {
  background-image: url("./customer/audi/img/btn/vorschau.png");
}
.defaultLink.icon.show:hover,
.defaultLink.icon.show_small:hover,
.defaultLink.icon.show:focus,
.defaultLink.icon.show_small:focus {
  background-image: url("./customer/audi/img/btn/vorschau-rollover.png");
}
.defaultLink.icon.add,
.defaultLink.icon.new {
  background-image: url("./customer/audi/img/btn/neu-dokument.png");
}
.defaultLink.icon.add:hover,
.defaultLink.icon.new:hover {
  background-image: url("./customer/audi/img/btn/neu-dokument-rollover.png");
}
.defaultLink.icon.add_external {
  background-image: url("./customer/audi/img/btn/neu-dokument-extern.png");
}
.defaultLink.icon.add_external:hover {
  background-image: url("./customer/audi/img/btn/neu-dokument-extern-rollover.png");
}
.defaultLink.icon.add,
.defaultLink.icon.new {
  background-image: url("./customer/audi/img/btn/neu-dokument.png");
}
.defaultLink.icon.add:hover,
.defaultLink.icon.new:hover {
  background-image: url("./customer/audi/img/btn/neu-dokument-rollover.png");
}
.defaultLink.icon.cut {
  background-image: url("./customer/audi/img/btn/ausschneiden.png");
}
.defaultLink.icon.cut:hover {
  background-image: url("./customer/audi/img/btn/ausschneiden-rollover.png");
}
.defaultLink.icon.paste {
  background-image: url("./customer/audi/img/btn/einfuegen.png");
}
.defaultLink.icon.paste:hover {
  background-image: url("./customer/audi/img/btn/einfuegen-rollover.png");
}
.defaultLink.icon.openAll,
.defaultLink.icon.addNew {
  background-image: url("./customer/audi/img/btn/aufklappen.png");
}
.defaultLink.icon.openAll:hover,
.defaultLink.icon.addNew:hover {
  background-image: url("./customer/audi/img/btn/aufklappen-rollover.png");
}
.defaultLink.icon.closeAll {
  background-image: url("./customer/audi/img/btn/zuklappen.png");
}
.defaultLink.icon.closeAll:hover {
  background-image: url("./customer/audi/img/btn/zuklappen-rollover.png");
}
.defaultLink.icon.process {
  background-image: url("./customer/audi/img/kalender/vor.png");
}
.defaultLink.icon.process:hover {
  background-image: url("./customer/audi/img/kalender/vor-rollover.png");
}
.defaultLink.icon.take_small,
.defaultLink.icon .take {
  background-image: url("./customer/audi/img/btn/uebernehmen.png");
}
.defaultLink.icon.take_small:hover,
.defaultLink.icon .take:hover {
  background-image: url("./customer/audi/img/btn/uebernehmen-rollover.png");
}
.defaultLink.icon.expand_small {
  background-image: url("./customer/audi/img/btn/aufklappen.png");
}
.defaultLink.icon.expand_small:hover {
  background-image: url("./customer/audi/img/btn/aufklappen-rollover.png");
}
.defaultLink.icon.collapse_small {
  background-image: url("./customer/audi/img/btn/zuklappen.png");
}
.defaultLink.icon.collapse_small:hover {
  background-image: url("./customer/audi/img/btn/zuklappen-rollover.png");
}
.defaultLink.icon.aoz_neu {
  background-image: url("./customer/audi/img/icons/icon_aoz_neu.png");
}
.defaultLink.icon.aoz_neu:hover,
.defaultLink.icon.aoz_neu.selected {
  background-image: url("./customer/audi/img/icons/icon_aoz_neu_hover.png");
}
.defaultLink.icon.aoz_aenderungen {
  background-image: url("./customer/audi/img/icons/icon_aoz_aenderungen.png");
}
.defaultLink.icon.aoz_aenderungen:hover,
.defaultLink.icon.aoz_aenderungen.selected {
  background-image: url("./customer/audi/img/icons/icon_aoz_aenderungen_hover.png");
}
.defaultLink.icon.aoz_abgelaufen {
  background-image: url("./customer/audi/img/icons/icon_aoz_abgelaufen.png");
}
.defaultLink.icon.aoz_abgelaufen:hover,
.defaultLink.icon.aoz_abgelaufen.selected {
  background-image: url("./customer/audi/img/icons/icon_aoz_abgelaufen_hover.png");
}
.defaultLink.icon.aoz_status {
  background-image: url("./customer/audi/img/icons/icon_aoz_status.png");
}
.defaultLink.icon.aoz_status:hover,
.defaultLink.icon.aoz_status.selected {
  background-image: url("./customer/audi/img/icons/icon_aoz_status_hover.png");
}
.defaultLink.icon.aoz_alle {
  background-image: url("./customer/audi/img/icons/icon_aoz_alle.png");
}
.defaultLink.icon.aoz_alle:hover,
.defaultLink.icon.aoz_alle.selected {
  background-image: url("./customer/audi/img/icons/icon_aoz_alle_hover.png");
}
.defaultLink.icon.info {
  background-image: url("./customer/audi/img/icons/info.png");
}
.defaultLink.icon.info:hover {
  background-image: url("./customer/audi/img/icons/info-rollover.png");
}
.defaultLink.icon.move_first {
  background-image: url("./customer/audi/img/btn/move-top.png");
}
.defaultLink.icon.move_first:hover {
  background-image: url("./customer/audi/img/btn/move-top-rollover.png");
}
.defaultLink.icon.move_up,
.defaultLink.icon.move_up_small {
  background-image: url("./customer/audi/img/btn/move-up.png");
}
.defaultLink.icon.move_up:hover,
.defaultLink.icon.move_up_small:hover {
  background-image: url("./customer/audi/img/btn/move-up-rollover.png");
}
.defaultLink.icon.move_down,
.defaultLink.icon.move_down_small {
  background-image: url("./customer/audi/img/btn/move-down.png");
}
.defaultLink.icon.move_down:hover,
.defaultLink.icon.move_down_small:hover {
  background-image: url("./customer/audi/img/btn/move-down-rollover.png");
}
.defaultLink.icon.move_last {
  background-image: url("./customer/audi/img/btn/move-bottom.png");
}
.defaultLink.icon.move_last:hover {
  background-image: url("./customer/audi/img/btn/move-bottom-rollover.png");
}
.defaultLink.icon.history {
  background-image: url("./customer/audi/img/btn/history.png");
}
.defaultLink.icon.history:hover {
  background-image: url("./customer/audi/img/btn/history-rollover.png");
}
.defaultLink.icon.download {
  background-image: url("./customer/audi/img/btn/download.png");
}
.defaultLink.icon.download:hover {
  background-image: url("./customer/audi/img/btn/download-rollover.png");
}
.defaultLink.icon.download.inactive {
  background-image: url("./customer/audi/img/btn/download-inaktiv.png");
}
.defaultLink.icon.download_final {
  background-image: url("./customer/audi/img/btn/download-final.png");
}
.defaultLink.icon.download_final:hover,
.defaultLink.icon.download_final:focus {
  background-image: url("./customer/audi/img/btn/download-final-rollover.png");
}
.defaultLink.icon.download_final_inactive {
  background-image: url("./customer/audi/img/btn/download-final-inaktiv.png");
  cursor: default;
}
.defaultLink.icon.download_preview {
  background-image: url("./customer/audi/img/btn/download-preview.png");
}
.defaultLink.icon.download_preview:hover,
.defaultLink.icon.download_preview:focus {
  background-image: url("./customer/audi/img/btn/download-preview-rollover.png");
}
.defaultLink.icon.download_preview_inactive {
  background-image: url("./customer/audi/img/btn/download-preview-inaktiv.png");
}
.defaultLink.icon.create_preview {
  background-image: url("./customer/audi/img/btn/create-preview.png");
}
.defaultLink.icon.create_preview:hover,
.defaultLink.icon.create_preview:focus {
  background-image: url("./customer/audi/img/btn/create-preview-rollover.png");
}
.defaultLink.icon.create_preview_inactive {
  background-image: url("./customer/audi/img/btn/create-preview-inaktiv.png");
}
.defaultLink.icon.create_final {
  background-image: url("./customer/audi/img/btn/create-final.png");
}
.defaultLink.icon.create_final:hover,
.defaultLink.icon.create_final:focus {
  background-image: url("./customer/audi/img/btn/create-final-rollover.png");
}
.defaultLink.icon.create_final_inactive {
  background-image: url("./customer/audi/img/btn/create-final-inaktiv.png");
}
.defaultLink.icon.upload {
  background-image: url("./customer/audi/img/btn/upload.png");
}
.defaultLink.icon.upload:hover {
  background-image: url("./customer/audi/img/btn/upload-rollover.png");
}
.defaultLink.icon.desktopPreview,
.defaultLink.icon.mobilePreview,
.defaultLink.icon.pdfsuperview.archiveNewsletterPreview,
.defaultLink.icon.pdfsuperview.archiveNewsletterTmPreview {
  width: 20px;
  height: 20px;
  margin-top: 5px;
  margin-right: 5px;
  padding-right: 0;
  background-size: 20px;
}
.defaultLink.icon.desktopPreview {
  background-image: url("./customer/audi/img/icons/icon_desktop_black.png");
}
.defaultLink.icon.desktopPreview:hover,
.defaultLink.icon.desktopPreview:focus {
  background-image: url("./customer/audi/img/icons/icon_desktop_red.png");
}
.defaultLink.icon.mobilePreview {
  background-image: url("./customer/audi/img/icons/icon_mobile_black.png");
}
.defaultLink.icon.mobilePreview:hover,
.defaultLink.icon.mobilePreview:focus {
  background-image: url("./customer/audi/img/icons/icon_mobile_red.png");
}
.defaultLink.icon.pdfsuperview.archiveNewsletterPreview,
.defaultLink.icon.pdfsuperview.archiveNewsletterTmPreview {
  background-image: url("./customer/audi/img/icons/icon_vorschau_black.png");
}
.defaultLink.icon.pdfsuperview.archiveNewsletterPreview:hover,
.defaultLink.icon.pdfsuperview.archiveNewsletterTmPreview:hover,
.defaultLink.icon.pdfsuperview.archiveNewsletterPreview:focus,
.defaultLink.icon.pdfsuperview.archiveNewsletterTmPreview:focus {
  background-image: url("./customer/audi/img/icons/icon_vorschau_red.png");
}
.icon.rename_inactive,
.icon.rename.inactive,
.icon.edit.inactive,
.icon.edit_small_inactive.inactive,
.icon.edit_external_inactive.inactive,
.icon.edit_small.inactive {
  background-image: url("./customer/audi/img/btn/bearbeiten-inaktiv.png");
  cursor: default;
}
.icon.edit_external_inactive.inactive {
  background-image: url("./customer/audi/img/btn/correction_external_site_inactive.png");
  cursor: default;
}
.icon.copy_inactive.inactive,
.icon.copy_small_inactive.inactive,
.icon.copy_small.inactive {
  background-image: url("./customer/audi/img/btn/kopieren-inaktiv.png");
}
.icon.lock_small_inactive.inactive {
  background-image: url("./customer/audi/img/btn/verschlossen-inaktiv.png");
}
.icon.unlock_small_inactive.inactive {
  background-image: url("./customer/audi/img/btn/unverschlossen-inaktiv.png");
}
.icon.assignment_small_inactive.inactive {
  background-image: url("./customer/audi/img/btn/zuordnen-inaktiv.png");
}
.icon.contactEdit,
.icon.contactEdit_small {
  background-image: url("./customer/audi/img/btn/laenderkonfig_kontakt_bearbeiten.png");
}
.icon.contactEdit:hover,
.icon.contactEdit_small:hover {
  background-image: url("./customer/audi/img/btn/laenderkonfig_kontakt_bearbeiten_hover.png");
}
.icon.contactEdit_small_inactive,
.icon.contactEdit_inactive.inactive,
.icon.contactEdit_small.inactive,
.icon.contactEdit_inactive {
  background-image: url("./customer/audi/img/btn/laenderkonfig_kontakt_bearbeiten_inactiv.png");
}
.icon.languages,
.icon.languages_small {
  background-image: url("./customer/audi/img/btn/laenderkonfig_sprache_bearbeiten.png");
}
.icon.languages:hover,
.icon.languages_small:hover {
  background-image: url("./customer/audi/img/btn/laenderkonfig_sprache_bearbeiten_hover.png");
}
.icon.languages_small_inactive,
.icon.languages_inactive.inactive,
.icon.languages_small.inactive,
.icon.languages_inactive {
  background-image: url("./customer/audi/img/btn/laenderkonfig_sprache_bearbeiten_inactiv.png");
}
.icon.assignment_small_inactive,
.icon.assignment_inactive.inactive,
.icon.assignment_small.inactive,
.icon.assignment_inactive {
  background-image: url("./customer/audi/img/btn/zuordnen-inaktiv.png");
}
.icon.delete_inactive,
.icon.delete_small_inactive {
  background-image: url("./customer/audi/img/btn/loeschen-inaktiv.png");
  cursor: default;
}
.icon.show_small_inactive,
.icon.show_inactive.inactive,
.icon.show_small.inactive {
  background-image: url("./customer/audi/img/btn/vorschau-inaktiv.png");
}
.icon.add_inactive.inactive,
.icon.new_inactive.inactive {
  background-image: url("./customer/audi/img/btn/neu-dokument-inaktiv.png");
}
.icon.cut_inactive.inactive {
  background-image: url("./customer/audi/img/btn/ausschneiden-inaktiv.png");
}
.icon.paste_inactive.inactive {
  background-image: url("./customer/audi/img/btn/einfuegen-inaktiv.png");
}
.icon.openAll_inactive.inactive {
  background-image: url("./customer/audi/img/btn/aufklappen-inaktiv.png");
}
.icon.closeAll_inactive.inactive {
  background-image: url("./customer/audi/img/btn/zuklappen-inaktiv.png");
}
.icon.take_small_inactive {
  background-image: url("./customer/audi/img/btn/uebernehmen-inaktiv.png");
}
.icon.move_first_inactive {
  background-image: url("./customer/audi/img/btn/move-top-inactive.png");
}
.icon.move_last_inactive {
  background-image: url("./customer/audi/img/btn/move-bottom-inactive.png");
}
.icon:not(.pricelist-kompakt).pricelist,
.icon.pricelist_small {
  background-image: url("./customer/audi/img/btn/preisliste.png");
}
.icon:not(.pricelist-kompakt).pricelist:hover,
.icon.pricelist_small:hover {
  background-image: url("./customer/audi/img/btn/preisliste_rollover.png");
}
.icon:not(.pricelist-kompakt).pricelist.inactive,
.icon.pricelist_small {
  background-image: url("./customer/audi/img/btn/preisliste_inaktiv.png");
}
.icon:not(.pricelist-kompakt).pricelist.inactive:hover,
.icon.pricelist_small:hover {
  background-image: url("./customer/audi/img/btn/preisliste_inaktiv.png");
}
.icon.pricelist-kompakt.pricelist,
.icon.pricelist-kompakt.pricelist_inactive {
  width: 19px;
  height: 16px;
  background: unset;
}
.icon.pricelist-kompakt.pricelist:hover::before,
.icon.pricelist-kompakt.pricelist_inactive:hover::before {
  background-color: var(--svg-icon-color-hover);
}
.icon.pricelist-kompakt.pricelist::before,
.icon.pricelist-kompakt.pricelist_inactive::before {
  display: inline-block;
  width: 16px;
  height: 16px;
  content: "";
  mask-repeat: no-repeat;
  cursor: pointer;
  background-clip: padding-box;
  background-color: var(--svg-icon-color-normal);
  mask: url("./customer/audi/img/icons/icon_euro.svg");
  float: left;
}
.icon.pricelist-kompakt.pricelist_inactive::before {
  background-color: var(--svg-icon-color-inactive);
  cursor: default;
}
.icon.help_small {
  background-image: url("./customer/audi/img/icon_help_small.gif");
}
.icon.help_small:hover {
  background-image: url("./customer/audi/img/icon_help_small_mouseover.gif");
}
.icon.download_inactive {
  background-image: url("./customer/audi/img/btn/download-inaktiv.png");
}
.icon.moveUp {
  background-image: url("./customer/audi/img/btn/move-up.png");
}
.icon.moveUp:hover {
  background-image: url("./customer/audi/img/btn/move-up-rollover.png");
}
.icon.moveUp.inactive {
  background-image: url("./customer/audi/img/btn/move-up-inactive.png");
}
.icon.moveDown {
  background-image: url("./customer/audi/img/btn/move-down.png");
}
.icon.moveDown:hover {
  background-image: url("./customer/audi/img/btn/move-down-rollover.png");
}
.icon.moveDown.inactive {
  background-image: url("./customer/audi/img/btn/move-down-inactive.png");
}
.icon.prev {
  background-image: url("./customer/audi/img/btn/move-down.png");
  -moz-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
  padding-right: 0;
  display: block;
}
.icon.prev:hover {
  background-image: url("./customer/audi/img/btn/move-down-rollover.png");
}
.icon.prev.inactive {
  background-image: url("./customer/audi/img/btn/move-down-inactive.png");
}
.icon.next {
  background-image: url("./customer/audi/img/btn/move-down.png");
  -moz-transform: rotate(270deg);
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
  padding-right: 0;
  display: block;
}
.icon.next:hover {
  background-image: url("./customer/audi/img/btn/move-down-rollover.png");
}
.icon.next.inactive {
  background-image: url("./customer/audi/img/btn/move-down-inactive.png");
}
.icon.trash_bin_small {
  background-image: url("./customer/audi/img/btn/loeschen.png");
}
.icon.trash_bin_small:hover,
.icon.trash_bin_small:focus {
  background-image: url("./customer/audi/img/btn/loeschen-rollover.png");
}
.icon.controls.move_up_inactive {
  background-image: url("./customer/audi/img/jstree/blaetterpfeil-passiv-oben.png");
}
.icon.controls.move_down_inactive {
  background-image: url("./customer/audi/img/jstree/blaetterpfeil-passiv-unten.png");
}
.icon.controls.move_left_inactive,
.icon.controls.moveLeft.inactive {
  background-image: url("./customer/audi/img/jstree/blaetterpfeil-passiv-links.png");
}
.icon.controls.move_right_inactive,
.icon.controls.moveRight.inactive {
  background-image: url("./customer/audi/img/jstree/blaetterpfeil-passiv-rechts.png");
}
.icon.controls.defaultLink.moveUp {
  background-image: url("./customer/audi/img/jstree/blaetterpfeil-aktiv-oben.png");
}
.icon.controls.defaultLink.moveUp:hover {
  background-image: url("./customer/audi/img/jstree/blaetterpfeil-rollover-oben.png");
}
.icon.controls.defaultLink.moveDown {
  background-image: url("./customer/audi/img/jstree/blaetterpfeil-aktiv-unten.png");
}
.icon.controls.defaultLink.moveDown:hover {
  background-image: url("./customer/audi/img/jstree/blaetterpfeil-rollover-unten.png");
}
.icon.controls.defaultLink.moveLeft {
  background-image: url("./customer/audi/img/jstree/blaetterpfeil-aktiv-links.png");
}
.icon.controls.defaultLink.moveLeft:hover {
  background-image: url("./customer/audi/img/jstree/blaetterpfeil-rollover-links.png");
}
.icon.controls.defaultLink.moveRight {
  background-image: url("./customer/audi/img/jstree/blaetterpfeil-aktiv-rechts.png");
}
.icon.controls.defaultLink.moveRight:hover {
  background-image: url("./customer/audi/img/jstree/blaetterpfeil-rollover-rechts.png");
}
.icon.state {
  padding-right: 0;
}
.icon.bookable {
  background-image: url("./customer/audi/img/mtpage_complete.png");
}
.icon.notBookable {
  background-image: url("./customer/audi/img/mtpage_error.png");
}
.icon.clockpicker-trigger {
  padding-right: 0;
  background-image: url("./customer/audi/img/btn/clockpicker_normal.png");
}
.icon.clockpicker-trigger:hover {
  background-image: url("./customer/audi/img/btn/clockpicker_hover.png");
}
.icon.clockpicker-trigger.inactive {
  background-image: url("./customer/audi/img/btn/clockpicker_inactive.png");
}
.icon.favIcon svg.favorite_star {
  width: 15px;
}
.icon.favIcon.isFavorite svg.favorite_star .outer,
.icon.favIcon.isFavorite svg.favorite_star .inner {
  fill: var(--color--audi--rot);
}
.icon.favIcon.isFavorite:hover svg.favorite_star .outer {
  fill: var(--color--audi--schwarz);
}
.icon.favIcon.isFavorite:hover svg.favorite_star .inner {
  fill: transparent;
}
.icon.favIcon.isFavorite.manually svg.favorite_star .outer,
.icon.favIcon.isFavorite.manually:hover svg.favorite_star .outer,
.icon.favIcon.isFavorite.manually svg.favorite_star .inner,
.icon.favIcon.isFavorite.manually:hover svg.favorite_star .inner {
  fill: var(--color--audi--rot);
}
html.ie11 .icon.favIcon svg.favorite_star {
  margin-top: -2px;
}
.icon.move_up_inactive,
.icon.move_up_small_inactive.inactive,
.orderChangeButtons .icon.move_up_inactive {
  background-image: url("./customer/audi/img/btn/move-up-inactive.png");
}
.icon.move_down_inactive,
.icon.move_down_small_inactive.inactive,
.orderChangeButtons .icon.move_down_inactive {
  background-image: url("./customer/audi/img/btn/move-down-inactive.png");
}
div.profilerNeededWithText {
  padding-left: 25px;
}
div.profilerNeeded,
div.profilerNeededWithText {
  background: url("./customer/audi/img/icons/redTriangleWithExclamationMark.png") 0 0 no-repeat;
  display: inline-block;
}
div.infoForUser {
  background: url("./customer/audi/img/icons/info_small.png") 0 0 no-repeat;
  width: 17px;
  height: 17px;
}
div.infoForUser:hover {
  background-image: url("./customer/audi/img/icons/info_small-rollover.png");
}
div.profilerNeeded {
  width: 17px;
  height: 15px;
}
#archive_delete {
  padding: 0;
}
.controls.moveDown,
.controls.moveUp,
.controls.moveLeft,
.controls.moveRight,
.controls.move_down_inactive.text,
.controls.move_up_inactive.text,
.controls.move_left_inactive.text,
.controls.move_right_inactive.text {
  padding: 0;
  outline: 0 none;
}
.controls.moveDown,
.controls.moveUp,
.controls.move_down_inactive.text,
.controls.move_up_inactive.text,
.controls.move_down_inactive.icon,
.controls.move_up_inactive.icon {
  margin-left: 85px;
  width: 26px;
  height: 30px;
}
.controls.moveLeft,
.controls.moveRight,
.controls.move_left_inactive.text,
.controls.move_right_inactive.text,
.controls.move_left_inactive.icon,
.controls.move_right_inactive.icon {
  width: 30px;
  height: 26px;
}
.controls.moveLeft,
.controls.move_left_inactive.text {
  float: right;
  margin-right: 30px;
  padding-left: 0;
}
.controls.moveRight,
.controls.move_right_inactive.text {
  margin-left: 11px;
}
img.beraterState {
  height: 14px;
  width: 14px;
}
.statusIcon {
  margin-right: 5px;
}
.lightboxLinkInactive {
  display: none;
}
.buttonOption.icon {
  background-image: none;
  padding: 5px;
}
.buttonOption.icon .iconContainer {
  width: 26px;
  height: 26px;
  margin-right: 5px;
  background-color: var(--color--font--primary);
}
.buttonOption.icon:hover {
  color: var(--color--interactive--highlight--background);
}
.buttonOption.icon:hover .iconContainer {
  background-color: var(--color--interactive--highlight--background);
}
.buttonOption.icon.delete,
.buttonOption.icon.edit,
.buttonOption.icon.take {
  background-image: none;
}
.buttonOption.icon.delete .iconContainer {
  background-image: url("./customer/audi/img/btn/btnOption_delete.png");
}
.buttonOption.icon.edit .iconContainer {
  background-image: url("./customer/audi/img/btn/btnOption_edit.png");
}
.buttonOption.icon.take .iconContainer {
  background-image: url("./customer/audi/img/btn/btnOption_take.png");
}
#favoriteMark ~ div.checkbox.favoriteMark {
  background-image: url("./customer/audi/img/icons/star_icon_sprite.png");
  background-position: 0 0;
  cursor: pointer;
  width: 15px;
  height: 15px;
  padding: 0;
  margin: 3px 0 0 0;
  outline: 0;
  display: block;
}
#favoriteMark ~ div.checkbox.favoriteMark:hover {
  background-position: -15px 0;
}
#favoriteMark ~ div.checkbox.favoriteMark.checked {
  background-position: -30px 0;
}
#favoriteMark ~ div.checkbox.favoriteMark.checked:hover {
  background-position: 0 0;
}
.selectionGroup .selection input + label.selectionArea {
  background-position: 0 0;
  background-color: var(--color--audi--weiss);
}
.selectionGroup .selection input + label.selectionArea.layout_2020-vehicle_with_offer,
.selectionGroup .selection input:disabled:checked + label.selectionArea.layout_2020-vehicle_with_offer {
  background-image: url("./customer/audi/img/icons/selections/teaser_newsletter_article_selection_2020-vehicle_with_offer.png");
}
.selectionGroup .selection input + label.selectionArea.layout_2020-picture_with_text,
.selectionGroup .selection input:disabled:checked + label.selectionArea.layout_2020-picture_with_text {
  background-image: url("./customer/audi/img/icons/selections/teaser_newsletter_article_selection_2020-picture_with_text.png");
}
.selectionGroup .selection input + label.selectionArea.layout_2020-offers_with_pictures,
.selectionGroup .selection input:disabled:checked + label.selectionArea.layout_2020-offers_with_pictures {
  background-image: url("./customer/audi/img/icons/selections/teaser_newsletter_article_selection_2020-offers_with_pictures.png");
}
.selectionGroup .selection input + label.selectionArea.layout_2020-tyre_offers,
.selectionGroup .selection input:disabled:checked + label.selectionArea.layout_2020-tyre_offers {
  background-image: url("./customer/audi/img/icons/selections/teaser_newsletter_article_selection_2020-tyre_offers.png");
}
.selectionGroup .selection input + label.selectionArea.layout_2020-table,
.selectionGroup .selection input:disabled:checked + label.selectionArea.layout_2020-table {
  background-image: url("./customer/audi/img/icons/selections/teaser_newsletter_article_selection_2020-table.png");
}
.selectionGroup .selection input + label.selectionArea.layout_teaser,
.selectionGroup .selection input:disabled:checked + label.selectionArea.layout_teaser {
  background-image: url("./customer/audi/img/icons/selections/teaser_newsletter_article_selection_teaser.png");
}
.selectionGroup .selection input + label.selectionArea.layout_2020-boerse,
.selectionGroup .selection input:disabled:checked + label.selectionArea.layout_2020-boerse {
  background-image: url("./customer/audi/img/icons/selections/teaser_newsletter_article_selection_2020-boerse.png");
}
.selectionGroup .selection input + label.selectionArea:hover.layout_2020-vehicle_with_offer,
.selectionGroup .selection input:checked + label.selectionArea.layout_2020-vehicle_with_offer {
  background-image: url("./customer/audi/img/icons/selections/teaser_newsletter_article_selection_2020-vehicle_with_offer_hover.png");
}
.selectionGroup .selection input + label.selectionArea:hover.layout_2020-picture_with_text,
.selectionGroup .selection input:checked + label.selectionArea.layout_2020-picture_with_text {
  background-image: url("./customer/audi/img/icons/selections/teaser_newsletter_article_selection_2020-picture_with_text_hover.png");
}
.selectionGroup .selection input + label.selectionArea:hover.layout_2020-offers_with_pictures,
.selectionGroup .selection input:checked + label.selectionArea.layout_2020-offers_with_pictures {
  background-image: url("./customer/audi/img/icons/selections/teaser_newsletter_article_selection_2020-offers_with_pictures_hover.png");
}
.selectionGroup .selection input + label.selectionArea:hover.layout_2020-tyre_offers,
.selectionGroup .selection input:checked + label.selectionArea.layout_2020-tyre_offers {
  background-image: url("./customer/audi/img/icons/selections/teaser_newsletter_article_selection_2020-tyre_offers_hover.png");
}
.selectionGroup .selection input + label.selectionArea:hover.layout_2020-table,
.selectionGroup .selection input:checked + label.selectionArea.layout_2020-table {
  background-image: url("./customer/audi/img/icons/selections/teaser_newsletter_article_selection_2020-table_hover.png");
}
.selectionGroup .selection input + label.selectionArea:hover.layout_teaser,
.selectionGroup .selection input:checked + label.selectionArea.layout_teaser {
  background-image: url("./customer/audi/img/icons/selections/teaser_newsletter_article_selection_teaser_hover.png");
}
.selectionGroup .selection input + label.selectionArea:hover.layout_2020-boerse,
.selectionGroup .selection input:checked + label.selectionArea.layout_2020-boerse {
  background-image: url("./customer/audi/img/icons/selections/teaser_newsletter_article_selection_2020-boerse_hover.png");
}
.selectionGroup .selection input:disabled + label.selectionArea.layout_2020-vehicle_with_offer {
  background-image: url("./customer/audi/img/icons/selections/teaser_newsletter_article_selection_2020-vehicle_with_offer_disabled.png");
}
.selectionGroup .selection input:disabled + label.selectionArea.layout_2020-picture_with_text {
  background-image: url("./customer/audi/img/icons/selections/teaser_newsletter_article_selection_2020-picture_with_text_disabled.png");
}
.selectionGroup .selection input:disabled + label.selectionArea.layout_2020-offers_with_pictures {
  background-image: url("./customer/audi/img/icons/selections/teaser_newsletter_article_selection_2020-offers_with_pictures_disabled.png");
}
.selectionGroup .selection input:disabled + label.selectionArea.layout_2020-tyre_offers {
  background-image: url("./customer/audi/img/icons/selections/teaser_newsletter_article_selection_2020-tyre_offers_disabled.png");
}
.selectionGroup .selection input:disabled + label.selectionArea.layout_2020-table {
  background-image: url("./customer/audi/img/icons/selections/teaser_newsletter_article_selection_2020-table_disabled.png");
}
.selectionGroup .selection input:disabled + label.selectionArea.layout_teaser {
  background-image: url("./customer/audi/img/icons/selections/teaser_newsletter_article_selection_teaser_disabled.png");
}
.selectionGroup .selection input:disabled + label.selectionArea.layout_2020-boerse {
  background-image: url("./customer/audi/img/icons/selections/teaser_newsletter_article_selection_2020-boerse_disabled.png");
}
.selectionGroup .selection input:disabled + label.selectionArea svg#db-image .stroke,
.selectionGroup .selection input:disabled + label.selectionArea svg#upload-image .stroke,
.selectionGroup .selection input:disabled + label.selectionArea svg#default-image .stroke,
.selectionGroup .selection input:disabled + label.selectionArea svg#db-image:hover .stroke,
.selectionGroup .selection input:disabled + label.selectionArea svg#upload-image:hover .stroke,
.selectionGroup .selection input:disabled + label.selectionArea svg#default-image:hover .stroke {
  stroke: var(--color--interactive--background--disabled);
}
.selectionGroup .selection input:disabled + label.selectionArea svg#no-image .stroke,
.selectionGroup .selection input:disabled + label.selectionArea svg#no-image:hover .stroke,
.selectionGroup .selection input:disabled + label.selectionArea svg#no-image .picture,
.selectionGroup .selection input:disabled + label.selectionArea svg#no-image:hover .picture,
.selectionGroup .selection input:disabled + label.selectionArea svg#no-image .circle,
.selectionGroup .selection input:disabled + label.selectionArea svg#no-image:hover .circle {
  stroke: var(--color--interactive--background--disabled);
}
.box.error {
  background: none;
  color: var(--color--feedback--error);
}
.box.error > .errorContent > .box.error {
  margin-bottom: 5px;
}
.box.error.small {
  margin-bottom: 5px;
}
.box.notification {
  display: none;
}
.box.error > header {
  font-weight: bold;
  margin-bottom: 5px;
}
.tabulationContainer div.box.error {
  margin-bottom: 0;
}
input.error,
select.error,
textarea.error {
  background: var(--color--audi--weiss);
}
#searchfieldTrigger {
  background: url("./customer/audi/img/icons/lupe.png") no-repeat scroll 0 0;
  height: 15px;
  width: 15px;
  position: absolute;
  top: 0;
  right: 15px;
  cursor: pointer;
}
#searchfieldTrigger:hover {
  background-image: url("./customer/audi/img/icons/lupe-rollover.png");
}
#searchfieldTrigger.disabled {
  background: url("./customer/audi/img/icons/lupe_inactive.png") no-repeat scroll 0 0;
  cursor: default;
}
#searchfield[disabled],
input.formsearch[disabled],
#searchfieldTrigger.disabled {
  background-color: var(--color--audi--weiss);
}
.headerSubContainer .input-prompt {
  position: absolute;
  right: 105px;
  top: 0;
  color: var(--color--font--primary);
  font-size: 12px;
}
.headerSubContainer .input-prompt.disabled {
  color: #E1E1E1;
}
#searchfield {
  width: 155px;
}
#searchfield::-ms-clear {
  display: none;
}
input.formsearch,
#searchfield {
  height: 20px;
  border: none;
  border-bottom: 1px dotted var(--color--base--black);
  font-style: normal;
  font-size: 12px;
  font-family: var(--typography--audi--type-normal);
  padding-right: 35px;
  padding-left: 10px;
}
input.formsearch[disabled],
#searchfield[disabled] {
  border-bottom: 1px dotted #E1E1E1;
}
.itemHeadline {
  font-size: 14px;
  font-family: var(--typography--audi--type-bold);
}
.itemHeadline {
  margin-top: 3px;
}
.highlightFlash {
  box-shadow: 0 0 10px 0 var(--color--ci--primary);
}
.groupSeparator[data-toggleable="true"] {
  cursor: pointer;
  margin-top: 0;
}
.tooltipTrigger.infoIcon {
  margin-left: 5px;
  background: url('./customer/audi/img/icons/info.png') 0 0 no-repeat;
  height: 26px;
  width: 26px;
  cursor: pointer;
}
.tooltipTrigger.infoIcon:hover {
  background-image: url('./customer/audi/img/icons/info-rollover.png');
  background-position: 0 0;
}
.tooltipTrigger.infoIcon.tiny {
  background: url('./customer/audi/img/icons/info_small.png') 0 0 no-repeat;
  height: 17px;
  width: 17px;
}
.tooltipTrigger.infoIcon.tiny:hover {
  background-image: url('./customer/audi/img/icons/info_small-rollover.png');
  background-position: 0 0;
}
.tooltipTrigger.infoIcon.warning {
  background-image: url('./customer/audi/img/icons/redTriangleWithExclamationMark.png');
  background-position: 0 0;
  width: 17px;
  height: 15px;
  margin-top: 2px;
}
td.tooltipImg .tooltipTrigger img {
  margin-top: 2px;
}
.tooltip {
  border: 1px solid var(--color--layout--visual);
  background-color: var(--color--audi--weiss);
}
.tooltip ul.bas-unorderedList {
  padding-left: 25px;
}
.tooltip.informationBlock:not(.errorTooltip):before {
  content: "";
  height: 15px;
  display: block;
  position: absolute;
  width: 15px;
  border-right: 1px solid var(--color--layout--visual);
  border-bottom: 1px solid var(--color--layout--visual);
  background: #fff;
}
.tooltip.informationBlock:not(.errorTooltip).topright:before {
  bottom: -9px;
  left: 2px;
  transform: rotate(45deg);
}
.tooltip.informationBlock:not(.errorTooltip).topleft:before {
  bottom: -9px;
  right: 2px;
  transform: rotate(45deg);
}
.tooltip.informationBlock:not(.errorTooltip).bottomleft:before {
  top: -9px;
  right: 3px;
  transform: rotate(225deg);
}
.tooltip.informationBlock:not(.errorTooltip).bottomright:before {
  top: -9px;
  left: 2px;
  transform: rotate(225deg);
}
.tooltip.selectionTooltip {
  max-width: 600px;
}
.tooltip ul.bas-unorderedList,
.tooltip ul.bas-orderedList {
  padding: 10px 20px;
}
div.errorTooltip {
  max-width: 300px;
  display: flex !important;
  position: absolute;
  z-index: 10109 !important;
}
div.errorTooltip .errorcontainer {
  display: inline-block;
  max-width: 240px;
  min-width: 50px;
  text-align: left;
  overflow: hidden;
  padding: 0 10px;
  margin-bottom: 0;
  background-color: transparent;
}
div.errorTooltip .iconContainer {
  flex-shrink: 0;
  display: inline-block;
  width: 22px;
  height: 22px;
  border-radius: 100%;
  text-align: center;
  font-size: 20px;
  line-height: 22px;
  font-weight: bold;
}
div.errorTooltip.tt_hasWarnings .errorcontainer > div:first-child:not(:last-child),
div.errorTooltip.tt_hasErrors .errorcontainer > div:first-child:not(:last-child) {
  margin-bottom: 5px;
}
div.errorTooltip.tt_hasWarnings .errorcontainer > div:not(:first-child),
div.errorTooltip.tt_hasErrors .errorcontainer > div:not(:first-child) {
  margin-top: 10px;
}
div.errorTooltip.tt_hasWarnings .errorcontainer > div:not(:last-child),
div.errorTooltip.tt_hasErrors .errorcontainer > div:not(:last-child) {
  padding-bottom: 10px;
}
div.errorTooltip.tt_hasWarnings .errorcontainer {
  color: var(--color--audi--mittel-grau);
}
div.errorTooltip.tt_hasWarnings .errorcontainer > div:not(:last-child) {
  border-bottom: 1px solid var(--color--audi--mittel-grau);
}
div.errorTooltip.tt_hasWarnings .iconContainer {
  border: 1px solid var(--color--audi--mittel-grau);
  color: var(--color--audi--mittel-grau);
}
div.errorTooltip.tt_hasErrors {
  border: 1px solid var(--color--feedback--error);
}
div.errorTooltip.tt_hasErrors .errorcontainer {
  color: var(--color--feedback--error);
}
div.errorTooltip.tt_hasErrors .errorcontainer > div:not(:last-child) {
  border-bottom: 1px solid var(--color--feedback--error);
}
div.errorTooltip.tt_hasErrors .iconContainer {
  border: 1px solid var(--color--feedback--error);
  color: var(--color--feedback--error);
}
.ui-autocomplete[id^="ui-id"] {
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
}
.ui-autocomplete[id^="ui-id"].ui-menu {
  background: #fff;
  border: solid 1px var(--color--layout--visual);
  color: var(--color--font--primary);
  border-radius: 0;
}
.ui-autocomplete[id^="ui-id"] .ui-menu-item.ui-state-hover,
.ui-autocomplete[id^="ui-id"] .ui-menu-item.ui-state-focus {
  cursor: pointer;
  background: #fff;
  border-color: transparent;
  border-left-color: var(--color--layout--visual);
  border-right-color: var(--color--layout--visual);
}
.ui-autocomplete[id^="ui-id"] .ui-menu-item.ui-state-hover:first-child,
.ui-autocomplete[id^="ui-id"] .ui-menu-item.ui-state-focus:first-child {
  border-color: var(--color--layout--visual);
  border-bottom-color: transparent;
}
.ui-autocomplete[id^="ui-id"] .ui-menu-item.ui-state-hover:last-child,
.ui-autocomplete[id^="ui-id"] .ui-menu-item.ui-state-focus:last-child {
  border-color: var(--color--layout--visual);
  border-top-color: transparent;
}
.ui-autocomplete[id^="ui-id"] .ui-menu-item.ui-state-hover a {
  background: transparent;
  color: var(--color--ci--primary);
  border: none;
}
.ui-autocomplete[id^="ui-id"] .ui-menu-item.ui-state-focus a {
  border: 0 none;
  background: transparent;
  color: var(--color--ci--primary);
  font-weight: normal;
}
.ui-autocomplete[id^="ui-id"] .ui-menu-item a.ui-state-hover,
.ui-menu[id^="ui-id"] .ui-menu-item a.ui-state-hover,
.ui-autocomplete[id^="ui-id"] .ui-menu-item a.ui-state-active,
.ui-menu[id^="ui-id"] .ui-menu-item a.ui-state-active {
  margin: 0;
  color: var(--color--ci--primary);
}
.ui-helper-hidden-accessible {
  display: none;
}
.important,
.selected {
  color: var(--color--ci--primary);
}
.secondaryText {
  color: var(--color--font--secondary);
}
.bold {
  font-family: var(--typography--audi--type-bold);
}
span.requiredMarker:after {
  content: ' *)';
}
sup[data-footnote-id] > a {
  color: var(--color--ci--primary);
  text-decoration: none;
}
.hint.inText {
  margin-top: 0;
}
.inactiveText {
  color: var(--color--audi--mittel-grau);
}
hr {
  border-bottom: 1px solid var(--color--layout--visual);
}
.cke_panel_container *:focus {
  outline: none;
}
.cke_dialog_body {
  background: #ffffff ! important;
  border: none;
}
.cke_dialog_body * {
  filter: none ! important;
}
.cke_dialog_body .cke_dialog_tab {
  border: none;
  background: #000000;
  color: #ffffff;
  border-radius: 0;
  font-family: var(--typography--audi--type-extended-normal);
  font-size: 11px;
  height: 30px;
  line-height: 30px;
  margin: 0 1px 0 0;
}
.cke_dialog_body .cke_dialog_tab:hover,
.cke_dialog_body .cke_dialog_tab.cke_dialog_tab_selected:hover {
  background: var(--color--interactive--background--hover);
}
.cke_dialog_body .cke_dialog_tab.cke_dialog_tab_selected {
  font-family: var(--typography--audi--type-extended-bold);
  color: #000000;
  background: #ffffff;
  border: 1px solid var(--color--layout--visual);
  border-bottom: none;
}
.cke_dialog_body .cke_dialog_tab.cke_dialog_tab_selected:hover {
  background: #ffffff;
}
.cke_dialog_body .cke_dialog_close_button {
  height: 32px;
  width: 32px;
  display: inline-block;
  position: absolute;
  right: 0 ! important;
  top: 0;
  background: url("./customer/audi/img/icons/layer-schliessen-passiv.png") no-repeat center;
}
.cke_dialog_body .cke_dialog_close_button:hover {
  background-image: url("./customer/audi/img/icons/layer-schliessen-rollover.png");
}
.cke_dialog_body .cke_dialog_title {
  background: #ffffff;
  border: none;
  text-shadow: none;
  font-family: var(--typography--audi--type-extended-bold);
  color: var(--color--font--primary);
  padding-top: 15px;
}
.cke_dialog_body .cke_dialog_contents {
  margin-top: 43px;
  border-top: 1px solid var(--color--layout--visual);
}
.cke_dialog_body .cke_dialog_contents .cke_dialog_contents_body {
  padding-left: 22px;
  padding-right: 22px;
}
.cke_dialog_body .cke_dialog_contents .cke_dialog_contents_body .marginContainer {
  margin-bottom: 21px;
}
.cke_dialog_body .cke_dialog_contents .cke_dialog_contents_body .radioContainer {
  margin-bottom: 10px;
}
.cke_dialog_body .cke_dialog_contents .cke_dialog_contents_body .radioContainer:last-child {
  margin-bottom: 0;
}
.cke_dialog_body .cke_dialog_contents .cke_dialog_contents_body .groupSeparator {
  font-size: 14px;
  font-family: var(--typography--audi--type-bold);
  margin: 0;
  padding: 0;
  padding-bottom: 3px;
  border-bottom: 1px solid var(--color--layout--visual);
}
.cke_dialog_body .cke_dialog_contents .cke_dialog_contents_body select:not([multiple]):not([size]) {
  display: block ! important;
  box-shadow: none;
}
.cke_dialog_body .cke_dialog_contents .cke_dialog_contents_body .chosen-container {
  position: absolute;
}
.cke_dialog_body .cke_dialog_contents .cke_dialog_contents_body .chosen-container .chosen-drop {
  z-index: 10100;
}
.cke_dialog_body .cke_dialog_contents .cke_dialog_contents_body .chosen-container-single .chosen-single span {
  cursor: pointer;
}
.cke_dialog_body .cke_dialog_contents .cke_dialog_contents_body textarea {
  white-space: normal;
}
.cke_dialog_body .alignmentRow {
  width: 100%;
  text-align: right;
}
.cke_dialog_body .alignmentRow .cke_dialog_reset_button {
  background: var(--color--interactive--background);
  padding: 0;
  margin-right: 0;
  margin-top: 5px;
  border: 0;
  border-radius: 0;
}
.cke_dialog_body .alignmentRow .cke_dialog_reset_button,
.cke_dialog_body .alignmentRow .cke_dialog_reset_button:focus,
.cke_dialog_body .alignmentRow .cke_dialog_reset_button:active {
  background-color: var(--color--interactive--background);
  padding: 0 12px;
  margin-right: 10px;
  min-height: 32px;
  color: var(--color--interactive--font);
  display: inline-block;
  font-family: var(--typography--audi--type-bold);
  font-size: 13px;
  line-height: 32px;
  cursor: pointer;
}
.cke_dialog_body .alignmentRow .cke_dialog_reset_button:hover,
.cke_dialog_body .alignmentRow .cke_dialog_reset_button:focus:hover,
.cke_dialog_body .alignmentRow .cke_dialog_reset_button:active:hover {
  text-decoration: none;
  background-color: var(--color--interactive--background--hover);
  color: var(--color--interactive--font);
}
.cke_dialog_body .alignmentRow .cke_dialog_reset_button > span {
  color: var(--color--interactive--font);
  text-shadow: none;
  padding: 0 12px;
  font-size: 13px;
  font-weight: bold;
}
.cke_dialog_body .alignmentRow .cke_dialog_reset_button.cke_disabled,
.cke_dialog_body .alignmentRow .cke_dialog_reset_button.cke_disabled:hover,
.cke_dialog_body .alignmentRow .cke_dialog_reset_button.cke_disabled:focus,
.cke_dialog_body .alignmentRow .cke_dialog_reset_button.cke_disabled:active {
  background-color: var(--color--interactive--background--disabled);
  cursor: default;
  box-shadow: none;
}
.cke_dialog_body .alignmentRow .cke_dialog_reset_button.cke_disabled > span,
.cke_dialog_body .alignmentRow .cke_dialog_reset_button.cke_disabled:hover > span,
.cke_dialog_body .alignmentRow .cke_dialog_reset_button.cke_disabled:focus > span,
.cke_dialog_body .alignmentRow .cke_dialog_reset_button.cke_disabled:active > span {
  cursor: default;
}
.cke_dialog_body .alignmentRow .cke_dialog_reset_button:hover,
.cke_dialog_body .alignmentRow .cke_dialog_reset_button:focus,
.cke_dialog_body .alignmentRow .cke_dialog_reset_button:active {
  text-decoration: none;
  background-color: var(--color--interactive--background--hover);
}
.cke_dialog_body .alignmentRow .cke_dialog_reset_button:hover span,
.cke_dialog_body .alignmentRow .cke_dialog_reset_button:focus span,
.cke_dialog_body .alignmentRow .cke_dialog_reset_button:active span {
  color: var(--color--interactive--font);
}
.cke_dialog_body .cke_dialog_footer {
  padding-top: 5px;
}
.cke_dialog_body .cke_dialog_footer .cke_dialog_footer_buttons a.cke_dialog_ui_button {
  margin-right: 5px;
  background: var(--color--interactive--background);
}
.cke_dialog_body .cke_dialog_footer .cke_dialog_footer_buttons a.cke_dialog_ui_button,
.cke_dialog_body .cke_dialog_footer .cke_dialog_footer_buttons a.cke_dialog_ui_button:focus,
.cke_dialog_body .cke_dialog_footer .cke_dialog_footer_buttons a.cke_dialog_ui_button:active {
  background-color: var(--color--interactive--background);
  padding: 0 12px;
  margin-right: 10px;
  min-height: 32px;
  color: var(--color--interactive--font);
  display: inline-block;
  font-family: var(--typography--audi--type-bold);
  font-size: 13px;
  line-height: 32px;
  cursor: pointer;
}
.cke_dialog_body .cke_dialog_footer .cke_dialog_footer_buttons a.cke_dialog_ui_button:hover,
.cke_dialog_body .cke_dialog_footer .cke_dialog_footer_buttons a.cke_dialog_ui_button:focus:hover,
.cke_dialog_body .cke_dialog_footer .cke_dialog_footer_buttons a.cke_dialog_ui_button:active:hover {
  text-decoration: none;
  background-color: var(--color--interactive--background--hover);
  color: var(--color--interactive--font);
}
.cke_dialog_body .cke_dialog_footer .cke_dialog_footer_buttons a.cke_dialog_ui_button.cke_dialog_ui_button_ok {
  padding: 0;
  border: 0;
  border-radius: 0;
}
.cke_dialog_body .cke_dialog_footer .cke_dialog_footer_buttons a.cke_dialog_ui_button.cke_dialog_ui_button_ok,
.cke_dialog_body .cke_dialog_footer .cke_dialog_footer_buttons a.cke_dialog_ui_button.cke_dialog_ui_button_ok:focus,
.cke_dialog_body .cke_dialog_footer .cke_dialog_footer_buttons a.cke_dialog_ui_button.cke_dialog_ui_button_ok:active {
  background-color: var(--color--interactive--highlight--background);
  color: var(--color--interactive--font);
}
.cke_dialog_body .cke_dialog_footer .cke_dialog_footer_buttons a.cke_dialog_ui_button.cke_dialog_ui_button_ok:hover,
.cke_dialog_body .cke_dialog_footer .cke_dialog_footer_buttons a.cke_dialog_ui_button.cke_dialog_ui_button_ok:focus:hover,
.cke_dialog_body .cke_dialog_footer .cke_dialog_footer_buttons a.cke_dialog_ui_button.cke_dialog_ui_button_ok:active:hover {
  background-color: var(--color--interactive--highlight--background--hover);
  color: var(--color--interactive--font);
}
.cke_dialog_body .cke_dialog_footer .cke_dialog_footer_buttons a.cke_dialog_ui_button.cke_dialog_ui_button_ok.cke_disabled,
.cke_dialog_body .cke_dialog_footer .cke_dialog_footer_buttons a.cke_dialog_ui_button.cke_dialog_ui_button_ok.cke_disabled:hover,
.cke_dialog_body .cke_dialog_footer .cke_dialog_footer_buttons a.cke_dialog_ui_button.cke_dialog_ui_button_ok.cke_disabled:focus,
.cke_dialog_body .cke_dialog_footer .cke_dialog_footer_buttons a.cke_dialog_ui_button.cke_dialog_ui_button_ok.cke_disabled:active {
  background-color: var(--color--interactive--background--disabled);
  cursor: default;
  box-shadow: none;
}
.cke_dialog_body .cke_dialog_footer .cke_dialog_footer_buttons a.cke_dialog_ui_button.cke_dialog_ui_button_ok.cke_disabled > span,
.cke_dialog_body .cke_dialog_footer .cke_dialog_footer_buttons a.cke_dialog_ui_button.cke_dialog_ui_button_ok.cke_disabled:hover > span,
.cke_dialog_body .cke_dialog_footer .cke_dialog_footer_buttons a.cke_dialog_ui_button.cke_dialog_ui_button_ok.cke_disabled:focus > span,
.cke_dialog_body .cke_dialog_footer .cke_dialog_footer_buttons a.cke_dialog_ui_button.cke_dialog_ui_button_ok.cke_disabled:active > span {
  cursor: default;
}
.cke_dialog_body .cke_dialog_footer .cke_dialog_footer_buttons a.cke_dialog_ui_button.cke_dialog_ui_button_ok > span {
  text-shadow: none;
  padding: 0 12px;
  font-size: 13px;
}
.cke_dialog_body .cke_dialog_footer .cke_dialog_footer_buttons a.cke_dialog_ui_button.cke_dialog_ui_button_cancel {
  padding: 0;
  border: 0;
  border-radius: 0;
}
.cke_dialog_body .cke_dialog_footer .cke_dialog_footer_buttons a.cke_dialog_ui_button.cke_dialog_ui_button_cancel > span {
  text-shadow: none;
  padding: 0 12px;
  font-size: 13px;
}
.cke_dialog tbody tr td,
.cke_dialog tbody tr:hover td,
.cke_dialog tbody th td,
.cke_dialog tbody th:hover td {
  background: #ffffff;
}
.cke_dialog tbody tr td input[type="radio"],
.cke_dialog tbody tr:hover td input[type="radio"],
.cke_dialog tbody th td input[type="radio"],
.cke_dialog tbody th:hover td input[type="radio"],
.cke_dialog tbody tr td input[type="checkbox"],
.cke_dialog tbody tr:hover td input[type="checkbox"],
.cke_dialog tbody th td input[type="checkbox"],
.cke_dialog tbody th:hover td input[type="checkbox"] {
  display: none;
}
.cke_dialog tbody tr td div.radio,
.cke_dialog tbody tr:hover td div.radio,
.cke_dialog tbody th td div.radio,
.cke_dialog tbody th:hover td div.radio,
.cke_dialog tbody tr td div.checkbox,
.cke_dialog tbody tr:hover td div.checkbox,
.cke_dialog tbody th td div.checkbox,
.cke_dialog tbody th:hover td div.checkbox {
  float: left;
  position: relative;
}
.cke_dialog tbody tr td .cke_dialog_ui_checkbox_input ~ label[for],
.cke_dialog tbody tr:hover td .cke_dialog_ui_checkbox_input ~ label[for],
.cke_dialog tbody th td .cke_dialog_ui_checkbox_input ~ label[for],
.cke_dialog tbody th:hover td .cke_dialog_ui_checkbox_input ~ label[for] {
  top: 3px;
  position: relative;
}
.inlineckeditor {
  word-wrap: break-word;
  line-height: normal;
}
.bas-inputArea .cms-container span,
.inlineckeditor {
  margin: 0 0 3px 0;
}
.bas-inputArea .cms-container span ol,
.inlineckeditor ol,
.bas-inputArea .cms-container span ul,
.inlineckeditor ul {
  padding-left: 20px;
  margin: 12px 0 12px 0;
}
.bas-inputArea .cms-container span ul > li,
.inlineckeditor ul > li {
  display: list-item;
  list-style-type: disc;
}
.bas-inputArea .cms-container span ol > li,
.inlineckeditor ol > li {
  display: list-item;
  list-style-type: decimal;
}
.bas-inputArea .cms-container span p,
.inlineckeditor p {
  margin: 0;
}
.bas-inputArea .cms-container span.newslettertext ul,
.inlineckeditor.newslettertext ul,
.bas-inputArea .cms-container span.newslettertext ol,
.inlineckeditor.newslettertext ol {
  padding: 0;
  margin: 0 0 0 41px;
}
.bas-inputArea .cms-container span.newslettertext li,
.inlineckeditor.newslettertext li {
  margin: 0;
  padding: 0 0 0 7px;
}
div.cke_inner .cke {
  border: 1px solid var(--color--layout--visual);
  box-shadow: none;
}
div.cke_inner .cke * {
  filter: none ! important;
}
div.cke_inner .cke_combo {
  margin-top: 2px;
}
div.cke_inner .cke_toolgroup {
  background: none;
  border: 1px solid var(--color--layout--visual);
  border-radius: 0;
  box-shadow: none;
}
div.cke_inner .cke_toolgroup .cke_button {
  border-radius: 0;
}
div.cke_inner .cke_combo_off a.cke_combo_button {
  border-radius: 0;
  background: #ffffff;
  cursor: pointer;
  border: 1px solid var(--color--layout--visual);
  height: 20px;
}
div.cke_inner .cke_combo_off a.cke_combo_button:hover,
div.cke_inner .cke_combo_off a.cke_combo_button:active {
  background: #ffffff;
  box-shadow: none;
}
div.cke_inner .cke_combo_on a.cke_combo_button {
  box-shadow: none;
  border: 1px solid var(--color--layout--visual);
  background: #ffffff;
  border-radius: 0;
}
div.cke_inner .cke_combo_on span.cke_combo_text {
  color: var(--color--ci--primary);
}
div.cke_inner .cke_combo_open {
  width: 20px;
  margin: 0;
}
div.cke_inner .cke_combo_open .cke_combo_arrow {
  background: var(--color--audi--weiss) url("./customer/audi/img/forms/liste-pfeil.png") right center no-repeat;
  border: none;
  width: 20px;
  height: 20px;
  margin: 0;
}
div.cke_inner .cke_combo_text {
  line-height: 20px;
  cursor: pointer;
  padding-top: 1px;
  font-family: var(--typography--audi--type-normal);
}
div.cke_inner .cke_top {
  background: var(--color--audi--hell-grau-30);
  box-shadow: none;
  border-bottom: 1px solid var(--color--layout--visual);
}
.cke_dialog select:not([multiple]),
.cke_dialog select:not([size]) {
  display: block ! important;
  height: 20px;
  position: relative;
}
.cke_dialog select:not([multiple])[size],
.cke_dialog select:not([size])[size] {
  visibility: visible;
  height: auto;
}
.cke_dialog .chosen-container {
  position: relative;
}
.cke_dialog_background_cover {
  z-index: 10001 ! important;
}
.cke_dialog div.footnoteContainer {
  max-height: 500px;
  overflow: auto;
}
/*!
 * ClockPicker v0.0.7 for jQuery (http://weareoutman.github.io/clockpicker/)
 * Copyright 2014 Wang Shenwei.
 * Licensed under MIT (https://github.com/weareoutman/clockpicker/blob/gh-pages/LICENSE)
 * 
 * Bootstrap v3.1.1 (http://getbootstrap.com)
 * Copyright 2011-2014 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
/* Picked from bootstrap: .popover, .btn, .text-primary */
.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1010;
  display: none;
  max-width: 276px;
  padding: 1px;
  text-align: left;
  white-space: normal;
  background-color: var(--color--audi--weiss);
  background-clip: padding-box;
  border: 1px solid var(--color--base--black);
  border-radius: 6px;
}
.popover.top {
  margin-top: -10px;
}
.popover.right {
  margin-left: 10px;
}
.popover.bottom {
  margin-top: 10px;
}
.popover.left {
  margin-left: -10px;
}
.popover-title {
  padding: 8px 14px;
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
  background-color: var(--color--audi--weiss);
  border-bottom: 1px solid var(--color--base--black);
  border-radius: 5px 5px 0 0;
}
.popover-content {
  padding: 9px 14px;
}
.popover > .arrow,
.popover > .arrow:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  /* The following are set in WordPress (wp-admin/css/revisions.css) - reset them to initial values */
  overflow: visible;
  margin: 0;
  padding: 0;
  z-index: auto;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  bottom: auto;
  left: auto;
  right: auto;
  top: auto;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}
.popover > .arrow {
  border-width: 11px;
}
.popover > .arrow:after {
  content: "";
  border-width: 10px;
}
.popover.top > .arrow {
  bottom: -11px;
  left: 50%;
  margin-left: -11px;
  border-top-color: var(--color--base--black);
  border-bottom-width: 0;
}
.popover.top > .arrow:after {
  bottom: 1px;
  margin-left: -10px;
  content: " ";
  border-top-color: var(--color--audi--weiss);
  border-bottom-width: 0;
}
.popover.right > .arrow {
  top: 50%;
  left: -11px;
  margin-top: -11px;
  border-right-color: var(--color--base--black);
  border-left-width: 0;
}
.popover.right > .arrow:after {
  bottom: -10px;
  left: 1px;
  content: " ";
  border-right-color: var(--color--audi--weiss);
  border-left-width: 0;
}
.popover.bottom > .arrow {
  top: -11px;
  left: 50%;
  margin-left: -11px;
  border-top-width: 0;
  border-bottom-color: var(--color--base--black);
}
.popover.bottom > .arrow:after {
  top: 1px;
  margin-left: -10px;
  content: " ";
  border-top-width: 0;
  border-bottom-color: var(--color--audi--weiss);
}
.popover.left > .arrow {
  top: 50%;
  right: -11px;
  margin-top: -11px;
  border-right-width: 0;
  border-left-color: var(--color--base--black);
}
.popover.left > .arrow:after {
  right: 1px;
  bottom: -10px;
  content: " ";
  border-right-width: 0;
  border-left-color: var(--color--audi--weiss);
}
.btn {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
}
.btn:focus,
.btn:active:focus,
.btn.active:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.btn:hover,
.btn:focus {
  color: var(--color--base--black);
  text-decoration: none;
}
.btn:active,
.btn.active {
  background-image: none;
  outline: 0;
}
.btn-default {
  color: var(--color--base--black);
  background-color: var(--color--audi--weiss);
  border-color: var(--color--base--black);
}
.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default {
  color: var(--color--base--black);
  background-color: var(--color--layout--visual);
  border-color: var(--color--base--black);
}
.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default {
  background-image: none;
}
.btn-block {
  display: block;
  width: 100%;
}
.text-primary {
  color: var(--color--ci--primary);
}
/*!
 * ClockPicker v{package.version} for Bootstrap (http://weareoutman.github.io/clockpicker/)
 * Copyright 2014 Wang Shenwei.
 * Licensed under MIT (https://github.com/weareoutman/clockpicker/blob/gh-pages/LICENSE)
 */
.clockpicker .input-group-addon {
  cursor: pointer;
}
.clockpicker-moving {
  cursor: move;
}
.clockpicker-align-left.popover > .arrow {
  left: 25px;
}
.clockpicker-align-top.popover > .arrow {
  top: 17px;
}
.clockpicker-align-right.popover > .arrow {
  left: auto;
  right: 25px;
}
.clockpicker-align-bottom.popover > .arrow {
  top: auto;
  bottom: 6px;
}
.clockpicker-popover .popover-title {
  background-color: var(--color--audi--weiss);
  color: var(--color--font--primary);
  font-size: 24px;
  font-weight: bold;
  line-height: 30px;
  text-align: center;
}
.clockpicker-popover .popover-title span {
  cursor: pointer;
}
.clockpicker-popover .popover-content {
  background-color: var(--color--layout--visual);
  padding: 12px;
}
.popover-content:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.clockpicker-plate {
  background-color: var(--color--audi--weiss);
  border: 1px solid var(--color--base--black);
  border-radius: 50%;
  width: 200px;
  height: 200px;
  overflow: visible;
  position: relative;
  /* Disable text selection highlighting. Thanks to Hermanya */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.clockpicker-canvas,
.clockpicker-dial {
  width: 200px;
  height: 200px;
  position: absolute;
  left: -1px;
  top: -1px;
}
.clockpicker-minutes {
  visibility: hidden;
}
.clockpicker-tick {
  border-radius: 50%;
  color: var(--color--font--primary);
  line-height: 26px;
  text-align: center;
  width: 26px;
  height: 26px;
  position: absolute;
  cursor: pointer;
}
.clockpicker-tick.inner {
  font-size: 100%;
}
.clockpicker-tick.outer {
  font-size: 120%;
}
.clockpicker-tick-blk {
  border-radius: 50%;
  color: var(--color--font--secondary);
  line-height: 26px;
  text-align: center;
  width: 26px;
  height: 26px;
  position: absolute;
}
.clockpicker-tick-blk.inner {
  font-size: 100%;
}
.clockpicker-tick-blk.outer {
  font-size: 120%;
}
.clockpicker-tick.active,
.clockpicker-tick:hover {
  background-color: var(--color--ci--secondary--alpha-25);
}
.clockpicker-button {
  background-image: none;
  background-color: var(--color--audi--weiss);
  border-width: 1px 0 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin: 0;
  padding: 10px 0;
}
.clockpicker-button:hover {
  background-image: none;
  background-color: var(--color--layout--visual);
}
.clockpicker-button:focus {
  outline: none!important;
}
.clockpicker-dial {
  -webkit-transition: -webkit-transform 350ms, opacity 350ms;
  -moz-transition: -moz-transform 350ms, opacity 350ms;
  -ms-transition: -ms-transform 350ms, opacity 350ms;
  -o-transition: -o-transform 350ms, opacity 350ms;
  transition: transform 350ms, opacity 350ms;
}
.clockpicker-dial-out {
  opacity: 0;
}
.clockpicker-hours.clockpicker-dial-out {
  -webkit-transform: scale(1.2, 1.2);
  -moz-transform: scale(1.2, 1.2);
  -ms-transform: scale(1.2, 1.2);
  -o-transform: scale(1.2, 1.2);
  transform: scale(1.2, 1.2);
}
.clockpicker-minutes.clockpicker-dial-out {
  -webkit-transform: scale(0.8, 0.8);
  -moz-transform: scale(0.8, 0.8);
  -ms-transform: scale(0.8, 0.8);
  -o-transform: scale(0.8, 0.8);
  transform: scale(0.8, 0.8);
}
.clockpicker-canvas {
  -webkit-transition: opacity 175ms;
  -moz-transition: opacity 175ms;
  -ms-transition: opacity 175ms;
  -o-transition: opacity 175ms;
  transition: opacity 175ms;
}
.clockpicker-canvas-out {
  opacity: 0.25;
}
.clockpicker-canvas-bearing,
.clockpicker-canvas-fg {
  stroke: none;
  fill: var(--color--ci--primary);
}
.clockpicker-canvas-bg {
  stroke: none;
  fill: var(--color--ci--primary);
}
.clockpicker-canvas-bg-trans {
  fill: var(--color--ci--secondary--alpha-25);
}
.clockpicker-canvas line {
  stroke: var(--color--ci--primary);
  stroke-width: 1;
  stroke-linecap: round;
  /*shape-rendering: crispEdges;*/
}
.clockpicker-button.am-button {
  margin: 1px;
  padding: 5px;
  border: 1px solid var(--color--base--black);
  border-radius: 4px;
}
.clockpicker-button.pm-button {
  margin: 1px 1px 1px 136px;
  padding: 5px;
  border: 1px solid var(--color--base--black);
  border-radius: 4px;
}
#breadcrumb {
  border-bottom: 1px solid var(--color--layout--visual);
  height: 30px;
  white-space: nowrap;
  margin-bottom: 30px;
}
#breadcrumb a span,
#breadcrumb div.currentNode span {
  line-height: 30px;
}
#breadcrumb div.currentNode {
  font-family: var(--typography--audi--type-extended-bold);
}
#breadcrumb div.currentNode div.leftEdge {
  background: url('./customer/audi/img/breadcrumb/b_leftEdge_white.png');
}
#breadcrumb div.currentNode div.centerPiece {
  background: url('./customer/audi/img/breadcrumb/centerPiece_white.png');
}
#breadcrumb div.currentNode div.rightEdge {
  background: url('./customer/audi/img/breadcrumb/rightEdge_white.png');
  width: 23px;
}
#breadcrumb a {
  background: none;
  color: #fff;
  padding: 0;
  font-family: var(--typography--audi--type-extended-normal);
}
#breadcrumb a:hover div.leftEdge {
  background: url('./customer/audi/img/breadcrumb/b_leftEdge_hover.png');
}
#breadcrumb a:hover div.centerPiece {
  background: url('./customer/audi/img/breadcrumb/centerPiece_hover.png');
}
#breadcrumb a:hover div.rightEdge {
  background: url('./customer/audi/img/breadcrumb/rightEdge_hover.png');
}
#breadcrumb a.first div.leftEdge {
  background: url('./customer/audi/img/breadcrumb/leftEdge_first_black.png');
  margin-left: 0;
}
#breadcrumb a.first div.centerPiece {
  padding-left: 0;
}
#breadcrumb a.first:hover div.leftEdge {
  background: url('./customer/audi/img/breadcrumb/leftEdge_first_hover.png');
}
#breadcrumb div.leftEdge {
  margin-left: -9px;
  background: url('./customer/audi/img/breadcrumb/b_leftEdge_black.png');
  width: 12px;
}
#breadcrumb div.leftEdge,
#breadcrumb div.rightEdge,
#breadcrumb div.centerPiece {
  float: left;
}
#breadcrumb div.leftEdge,
#breadcrumb div.rightEdge,
#breadcrumb div.centerPiece,
#breadcrumb div.currentNode {
  height: 30px;
  display: inline-block;
}
#breadcrumb div.leftEdge,
#breadcrumb div.rightEdge {
  background-repeat: no-repeat;
}
#breadcrumb div.centerPiece {
  background: url("./customer/audi/img/breadcrumb/centerPiece_black.png") repeat-x;
  padding-left: 12px;
}
#breadcrumb div.rightEdge {
  background: url('./customer/audi/img/breadcrumb/rightEdge_black.png');
  width: 23px;
}
#breadcrumb .scrollable div.currentNode div.rightEdge {
  background: url('./customer/audi/img/breadcrumb/rightEdge_last_white.png');
  width: 12px;
}
#breadcrumb .control.next,
#breadcrumb .control.previous {
  width: 30px;
  height: 30px;
  background-position: left center;
  cursor: pointer;
}
#breadcrumb .control.next {
  background-image: url('./customer/audi/img/breadcrumb/pfad-rechts-blaettern.png');
}
#breadcrumb .control.next:hover {
  background-image: url('./customer/audi/img/breadcrumb/pfad-rechts-blaettern-rollover.png');
}
#breadcrumb .control.next:hover.inactive {
  background-image: url('./customer/audi/img/breadcrumb/pfad-rechts-blaettern-inaktiv.png');
}
#breadcrumb .control.next.inactive {
  background-image: url('./customer/audi/img/breadcrumb/pfad-rechts-blaettern-inaktiv.png');
}
#breadcrumb .control.previous {
  background-image: url('./customer/audi/img/breadcrumb/pfad-links-blaettern.png');
}
#breadcrumb .control.previous:hover {
  background-image: url('./customer/audi/img/breadcrumb/pfad-links-blaettern-rollover.png');
}
#breadcrumb .control.previous:hover.inactive {
  background-image: url('./customer/audi/img/breadcrumb/pfad-links-blaettern-inaktiv.png');
}
#breadcrumb .control.previous.inactive {
  background-image: url('./customer/audi/img/breadcrumb/pfad-links-blaettern-inaktiv.png');
}
#breadcrumb .breadcrumbContainer {
  overflow: hidden;
}
#breadcrumb .controlPane.left {
  padding-right: 5px;
}
#breadcrumb .controlPane.right {
  padding-left: 5px;
}
#breadcrumb .ym-col1,
#breadcrumb .ym-col2 {
  width: 35px;
}
#breadcrumb .ym-col3 {
  margin: 0 35px;
}
#breadcrumb,
#breadcrumb a {
  font-size: 11px;
}
.ym-vlist a,
.ym-vlist strong,
.ym-vlist span {
  display: inline-block;
}
.paginationFastJump,
.paginationPageJump,
.paginationCurrentPage,
.paginationDots {
  height: 30px;
  min-width: 32px;
  line-height: 30px;
  display: inline-block;
  text-align: center;
  vertical-align: top;
  padding: 0 2px;
  background: none;
}
.paginationFastJump {
  background-repeat: no-repeat;
}
.paginationFastJump.previous {
  padding-right: 1px;
  background-image: url('./customer/audi/img/btn/pfad-links-blaettern.png');
}
.paginationFastJump.previous:hover {
  background-image: url('./customer/audi/img/btn/pfad-links-blaettern-rollover.png');
}
.paginationFastJump.previous.disabled,
.paginationFastJump.previous.disabled:hover {
  background-image: url('./customer/audi/img/btn/pfad-links-blaettern-inaktiv.png');
}
.paginationFastJump.next {
  margin-left: 1px;
  background-image: url('./customer/audi/img/btn/pfad-rechts-blaettern.png');
}
.paginationFastJump.next:hover {
  background-image: url('./customer/audi/img/btn/pfad-rechts-blaettern-rollover.png');
}
.paginationFastJump.next.disabled,
.paginationFastJump.next.disabled:hover {
  background-image: url('./customer/audi/img/btn/pfad-rechts-blaettern-inaktiv.png');
}
.paginationPageJump.predecessorToCurrent,
.paginationPageJump.last {
  border-right: none;
}
.paginationPageJump,
.paginationFastJump.first,
.paginationDots {
  border-right: dotted 1px var(--color--audi--hell-grau-60);
  font-family: var(--typography--audi--type-normal);
}
.paginationCurrentPage {
  font-family: var(--typography--audi--type-bold);
  background-color: var(--color--audi--licht-grau-30);
}
.tablePagination,
.pagination {
  border-bottom: 1px solid var(--color--audi--hell-grau-30);
  padding: 5px 5px;
  height: 50px;
  font-weight: bold;
}
.tablePagination div.linkGroup,
.pagination div.linkGroup {
  display: inline-block;
  float: left;
}
.tablePagination .resultsPerPageWrapper,
.pagination .resultsPerPageWrapper {
  float: right;
  display: inline-block;
  margin: 10px 0;
}
.tablePagination .resultsPerPageWrapper > *,
.pagination .resultsPerPageWrapper > * {
  display: inline-block;
  vertical-align: top;
}
.tablePagination .resultsPerPageWrapper .bas-inputSelect,
.pagination .resultsPerPageWrapper .bas-inputSelect {
  margin-left: 5px;
}
.tablePagination .pagelength,
.pagination .pagelength {
  width: 55px;
}
.item.linkGroup .editPage {
  background: var(--color--audi--weiss);
  border-bottom: 2px solid var(--color--audi--hell-grau-60);
  padding: 8px 5px 2px 28px;
  margin: 0 6px 5px 0;
}
.item.linkGroup .editPage.notCustomizable {
  background: var(--color--audi--weiss) url('./customer/audi/img/mtpage_notcustomizable.png') 0 2px no-repeat;
}
.item.linkGroup .editPage.complete {
  background: var(--color--audi--weiss) url('./customer/audi/img/mtpage_customizable.png') 0 2px no-repeat;
}
.item.linkGroup .editPage.complete_accepted {
  background: var(--color--audi--weiss) url('./customer/audi/img/mtpage_complete.png') 0 2px no-repeat;
}
.item.linkGroup .mtPageCurrent {
  border-bottom: 2px solid var(--color--ci--primary);
  cursor: default;
  display: inline-block;
}
.item.linkGroup .editPage.incomplete,
.item.linkGroup span.mtPageCurrentError {
  background: var(--color--audi--weiss) url('./customer/audi/img/mtpage_error.png') 0 2px no-repeat;
}
div.bas-formBox,
.field {
  padding: 5px 0;
}
.field.noLabel {
  display: table;
}
.bas-formSection .bas-formBox.ym-grid {
  border-top: none;
  padding: 10px 0;
}
.bas-formSection .verticalSeparator {
  background-color: var(--color--base--black);
  height: 20px;
  width: 1px;
  display: inline-block;
}
div.bas-label {
  min-width: 160px;
  min-height: 20px;
  width: 160px;
  padding-right: 20px;
  line-height: 20px;
}
.bas-inputCheck label {
  line-height: 20px;
  margin-left: 0;
}
.bas-inputCheck label.bas-inputRadio.hint.tooltipTrigger.infoIcon {
  display: inline-block;
}
.layout.contentContainer > .bas-formSection {
  margin-bottom: 25px;
}
.layout.contentContainer .field.buttonline {
  padding-top: 5px;
}
.layout.contentContainer .field > span {
  margin-right: 5px;
}
.layout.contentContainer h2,
.group.subtitleRaw {
  margin-bottom: 10px;
}
div.cols1,
div.cols2,
div.cols3,
div.cols4,
div.cols5,
div.cols6 {
  display: table-cell;
  padding-right: 5px;
}
div.cols1.charcounter,
div.cols2.charcounter,
div.cols3.charcounter,
div.cols4.charcounter,
div.cols5.charcounter,
div.cols6.charcounter {
  margin-right: 0;
  text-align: right;
}
div.cols1.inlineckeditor,
div.cols2.inlineckeditor,
div.cols3.inlineckeditor,
div.cols4.inlineckeditor,
div.cols5.inlineckeditor,
div.cols6.inlineckeditor {
  display: block;
}
div.cols1 {
  width: 152px;
}
div.cols1 div.bas-inputSelect select.timeSelector {
  width: 45px;
}
div.cols1.processed_tooltip {
  width: 147px;
}
div.cols2 {
  width: 309px;
}
div.cols2.processed_tooltip {
  width: 304px;
}
div.cols2.urlCheckerContainer {
  padding-right: 0;
}
div.cols3 {
  width: 466px;
}
div.cols3.processed_tooltip {
  width: 461px;
}
div.cols4 {
  width: 623px;
}
div.cols4.processed_tooltip {
  width: 618px;
}
div.cols5 {
  width: 780px;
}
div.cols5.processed_tooltip {
  width: 739px;
}
div.cols6 {
  width: 945px;
  padding: 0;
}
div.cols6.processed_tooltip {
  width: 899px;
}
.bas-inputArea.horizontal .cols1,
.bas-inputArea.horizontal .cols2,
.bas-inputArea.horizontal .cols3,
.bas-inputArea.horizontal .cols4,
.bas-inputArea.horizontal .cols5 {
  vertical-align: top;
}
input[type=text].cols_tele_1 {
  width: 48px;
}
input[type=text].cols_tele_2 {
  width: 80px;
}
input[type=text].cols_tele_3 {
  width: 186px;
}
input[type=text].cols_tele_4 {
  width: 80px;
}
input[type=text].cols_tele_31 {
  width: 216px;
}
input[type=text].cols_tele_34 {
  width: 281px;
}
input[type=text].cols_kopra_price {
  width: 90px;
}
input[type=text][class*=cols_tele] {
  margin-right: 0;
}
span.cols_days,
select.cols_days {
  width: 150px;
}
span.cols_time {
  width: 124px;
  margin: 0 3px 0 27px;
}
select.cols_hours {
  width: 53px;
}
select.cols_minutes {
  width: 53px;
}
.uploadWithTooltip div.bas-inputArea {
  display: block;
}
div.bas-inputArea {
  display: block;
  width: auto;
  margin-left: 160px;
}
div.bas-inputArea .radioIndent {
  margin-left: 27px;
}
.fullWidth .searchmask div.bas-inputArea {
  width: 100%;
}
.vertical.item > .itemBox > .horizontal.item {
  display: flex;
}
div.ym-grid div.bas-inputArea,
.noLabel div.bas-inputArea {
  width: auto;
}
html.lt-ie10 .cols1-3,
html.lt-ie9 .cols1-3 {
  width: 465px;
}
.admin {
  background-color: var(--color--audi--licht-grau-30);
}
.inputSeparator {
  background-color: var(--color--layout--visual);
  height: 1px;
  border: 0 none;
  margin: 10px 0;
}
.urlChecker {
  display: inline-block;
}
div.bas-formBox img.statusIcon {
  margin-right: 15px;
}
.preferences-statusbox {
  padding: 5px 0;
  border-top: 1px solid var(--color--ci--primary);
  border-bottom: 1px solid var(--color--ci--primary);
}
.preferences-statusbox .bold {
  color: var(--color--ci--primary);
  padding-bottom: 5px;
}
.preferences-statusbox > span.text {
  float: none;
}
span.centered {
  display: inline-block;
  text-align: center;
}
form[name="loginmask"] .bas-formBox .bas-inputArea {
  width: auto;
}
.required {
  display: block;
  padding-top: 10px;
}
.sourceDestinationSet > .controls {
  padding-top: 45px;
}
.sourceDestinationSet > .controls .add a {
  margin-left: 8px;
}
.sourceDestinationSet > .controls .remove a {
  margin-left: -8px;
}
html.ie .sourceDestinationSet .destinationSet select {
  height: 290px;
}
input[type="radio"],
input[type="checkbox"] {
  visibility: hidden;
  position: absolute;
}
.webtm-attributeTupel input[type="radio"],
.webtm-attributeTupel input[type="checkbox"] {
  visibility: visible;
  position: relative;
}
.lt-ie9 input[type="radio"],
.lt-ie9 input[type="checkbox"] {
  visibility: visible;
  opacity: 0;
  z-index: -1;
}
.lt-ie9 input[type="checkbox"].dynamicTreeNodeAttribute {
  opacity: 1;
  z-index: auto;
}
input[type="checkbox"].dynamicTreeNodeAttribute {
  visibility: inherit;
  position: relative;
  opacity: 1;
  margin-top: 3px;
}
input[type="text"],
textarea,
input[type="password"],
select,
.inlineckeditor {
  font-family: var(--typography--audi--type-normal);
  border: 1px solid var(--color--layout--visual);
  padding-top: 1px;
  padding-bottom: 1px;
}
input[type="password"] {
  font-family: "Verdana", sans-serif;
}
input[type="text"],
input[type="password"] {
  height: 20px;
}
div.checkbox,
div.radio {
  background-repeat: no-repeat;
  padding-right: 10px;
}
div.checkbox:focus,
div.radio:focus {
  outline: 0;
}
div.checkbox.error,
div.radio.error {
  background-color: transparent;
}
div.checkbox,
div.radio,
div.checkbox + label,
div.radio + label {
  cursor: pointer;
}
div.checkbox.inactive,
div.radio.inactive,
div.checkbox.inactive.checked,
div.radio.inactive.checked,
div.checkbox.inactive + label,
div.radio.inactive + label,
div.checkbox.inactive.checked + label,
div.radio.inactive.checked + label {
  color: var(--input--font-color--disabled);
  cursor: default;
}
select.noCustomStyling option {
  padding: 2px;
}
div.radio {
  background-image: url("./customer/audi/img/forms/radio_unchecked.png");
  background-position: left 3px;
  width: 26px;
  height: 17px;
}
div.radio.checked {
  background-image: url("./customer/audi/img/forms/radio.png");
}
div.radio.inactive {
  background-image: url("./customer/audi/img/forms/radio_unchecked_inactive.png");
}
div.radio.inactive.checked {
  background-image: url("./customer/audi/img/forms/radio_inactive.png");
}
div.checkbox {
  background-image: url("./customer/audi/img/forms/checkbox_unchecked.png");
  background-position: 0 2px;
  width: 26px;
  height: 18px;
}
div.checkbox.noLabel {
  padding-right: 5px;
  width: 16px;
}
div.checkbox.checked {
  background-image: url("./customer/audi/img/forms/checkbox.png");
}
div.checkbox.inactive {
  background-image: url("./customer/audi/img/forms/checkbox_unchecked_inactive.png");
}
div.checkbox.inactive.checked {
  background-image: url("./customer/audi/img/forms/checkbox_inactive.png");
}
div.checkbox.lockcheckbox {
  background-image: url("./customer/audi/img/btn/unverschlossen.png");
  background-position: 0 0;
  height: 26px;
  width: 26px;
  padding-right: 0;
}
div.checkbox.lockcheckbox:hover,
div.checkbox.lockcheckboxunchecked:hover {
  background-image: url("./customer/audi/img/btn/unverschlossen-rollover.png");
  cursor: pointer;
}
div.checkbox.order {
  height: 20px;
}
div.lockcheckbox.inactive:hover,
div.lockcheckbox.inactive {
  background-image: url("./customer/audi/img/btn/unverschlossen-inaktiv.png");
  cursor: default;
}
div.lockcheckbox.inactive.checked,
div.lockcheckbox.inactive.checked:hover {
  background-image: url("./customer/audi/img/btn/verschlossen-inaktiv.png");
  cursor: default;
}
div.lockcheckbox.lockcheckboxchecked,
div.lockcheckbox.checked {
  background-image: url("./customer/audi/img/btn/verschlossen.png");
}
div.lockcheckbox.lockcheckboxchecked:hover,
div.lockcheckbox.checked:hover {
  background-image: url("./customer/audi/img/btn/verschlossen-rollover.png");
  cursor: pointer;
}
input[type=text],
input[type=password],
textarea,
.inlineckeditor {
  width: 466px;
}
select {
  max-height: 270px;
}
input.colsQuarter,
select.colsQuarter,
textarea.colsQuarter,
.inlineckeditor.colsQuarter {
  width: 34px;
}
input.colsThird,
select.colsThird,
textarea.colsThird,
.inlineckeditor.colsThird {
  width: 48px;
}
input.colsTwoThird,
select.colsTwoThird,
textarea.colsTwoThird,
.inlineckeditor.colsTwoThird {
  width: 99px;
}
input.colsHalf,
select.colsHalf,
textarea.colsHalf,
.inlineckeditor.colsHalf {
  width: 73px;
}
bwc-date-picker.colsTwoThird {
  width: 124px;
}
input.cols1,
select.cols1,
textarea.cols1,
.inlineckeditor.cols1,
bwc-date-picker.cols1 {
  width: 152px;
}
input.cols2,
select.cols2,
textarea.cols2,
.inlineckeditor.cols2,
bwc-date-picker.cols2 {
  width: 309px;
}
input.cols3,
select.cols3,
textarea.cols3,
.inlineckeditor.cols3,
bwc-date-picker.cols3 {
  width: 466px;
}
input.cols4,
select.cols4,
textarea.cols4,
.inlineckeditor.cols4,
bwc-date-picker.cols4 {
  width: 623px;
}
input.cols5,
select.cols5,
textarea.cols5,
.inlineckeditor.cols5,
bwc-date-picker.cols5 {
  width: 780px;
}
input.cols6,
select.cols6,
textarea.cols6,
.inlineckeditor.cols6,
bwc-date-picker.cols6 {
  width: 945px;
}
input.cols12,
select.cols12,
textarea.cols12,
.inlineckeditor.cols12,
bwc-date-picker.cols12 {
  width: 309px;
}
input[disabled],
select[disabled],
textarea[disabled],
.inlineckeditor[disabled],
bwc-date-picker[disabled],
input[disabled]:hover,
select[disabled]:hover,
textarea[disabled]:hover,
.inlineckeditor[disabled]:hover,
bwc-date-picker[disabled]:hover,
input[disabled]:focus,
select[disabled]:focus,
textarea[disabled]:focus,
.inlineckeditor[disabled]:focus,
bwc-date-picker[disabled]:focus {
  color: var(--input--font-color--disabled);
}
.ym-g66 input.cols3,
.ym-g66 select.cols3,
.ym-g66 textarea.cols3,
.ym-g66 .inlineckeditor.cols3 {
  width: 426px;
}
.ym-g66 input.cols3.mediapool-contact,
.ym-g66 select.cols3.mediapool-contact,
.ym-g66 textarea.cols3.mediapool-contact,
.ym-g66 .inlineckeditor.cols3.mediapool-contact {
  width: 463px;
}
input.datepicker.cols1,
input.clockpicker.cols1 {
  margin-right: 0;
  width: 88px;
}
input.datepicker.cols2,
input.clockpicker.cols2 {
  margin-right: 0;
  width: 266px;
}
input.datepicker.cols3,
input.clockpicker.cols3 {
  margin-right: 0;
  width: 428px;
}
input.datepicker.cols4,
input.clockpicker.cols4 {
  margin-right: 0;
  width: 573px;
}
input.datepicker.cols5,
input.clockpicker.cols5 {
  margin-right: 0;
  width: 738px;
}
div.bas-inputCheck {
  margin-right: 10px;
}
div.bas-inputCheck.noPadding {
  margin-right: 0;
  padding-right: 0;
}
div.bas-inputCheck.noPadding div.noPadding,
div.bas-inputCheck.noPadding input.noPadding {
  margin-right: 0;
  padding-right: 0;
}
div.bas-inputCheck:hover {
  color: var(--color--interactive--highlight--background--hover);
  cursor: pointer;
}
div.bas-inputCheck a.defaultLink {
  margin-left: 10px;
}
div.bas-inputCheck.bas-lockCheckbox {
  overflow: hidden;
  width: 26px;
  height: 26px;
  display: inline-block ! important;
}
div.bas-inputCheck input[type="checkbox"].cols1,
div.bas-inputCheck input[type="radio"].cols1,
div.bas-inputCheck input[type="checkbox"].cols2,
div.bas-inputCheck input[type="radio"].cols2,
div.bas-inputCheck input[type="checkbox"].cols3,
div.bas-inputCheck input[type="radio"].cols3,
div.bas-inputCheck input[type="checkbox"].cols4,
div.bas-inputCheck input[type="radio"].cols4,
div.bas-inputCheck input[type="checkbox"].cols5,
div.bas-inputCheck input[type="radio"].cols5,
div.bas-inputCheck input[type="checkbox"].cols6,
div.bas-inputCheck input[type="radio"].cols6 {
  width: auto;
}
div.bas-inputCheck.containsSVG:hover svg {
  background-color: var(--color--interactive--highlight--background--hover);
}
div.bas-inputCheck.containsSVG span {
  vertical-align: middle;
  line-height: 25px;
}
div.bas-inputCheck.containsSVG svg {
  background-color: var(--color--interactive--background);
}
div.bas-inputCheck.containsSVG input.checkbox[disabled] ~ label svg,
div.bas-inputCheck.containsSVG input.radio[disabled] ~ label svg,
div.bas-inputCheck.containsSVG input.checkbox[disabled] ~ label svg:hover,
div.bas-inputCheck.containsSVG input.radio[disabled] ~ label svg:hover {
  background-color: var(--color--interactive--background--disabled);
  cursor: default;
}
div.bas-inputCheck.containsSVG input.checkbox[disabled] ~ label span,
div.bas-inputCheck.containsSVG input.radio[disabled] ~ label span,
div.bas-inputCheck.containsSVG input.checkbox[disabled] ~ label span:hover,
div.bas-inputCheck.containsSVG input.radio[disabled] ~ label span:hover {
  color: var(--color--interactive--background--disabled);
}
span.fromTo {
  margin-right: 10px;
}
div.searchmask .ui-datepicker-trigger {
  margin-right: 30px;
}
.selectionTabulation div.bas-inputCheck > * {
  display: table;
}
.ym-g50 .panel input.datepicker.cols2,
.ym-g50 .panel input.clockpicker.cols2 {
  width: 256px;
}
.ym-g50 .panel input.cols1,
.ym-g50 .panel select.cols1,
.ym-g50 .panel textarea.cols1,
.ym-g50 .panel input.cols2,
.ym-g50 .panel select.cols2,
.ym-g50 .panel textarea.cols2 {
  width: 292px;
}
.selectionGroup.budget-select .selection label.selectionArea {
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 191px;
  height: auto;
}
.selectionGroup.budget-select .selection label.selectionArea .name {
  font-family: var(--typography--audi--type-bold);
}
.selectionGroup.budget-select .selection label.selectionArea .name,
.selectionGroup.budget-select .selection label.selectionArea .type {
  color: var(--color--font--primary);
  margin: 0;
}
.selectionGroup.budget-select .selection label.desc {
  max-width: 215px;
}
.selectionGroup > .selection {
  margin: 0 25px 20px 0;
}
.selectionGroup > .selection:last-of-type {
  margin-right: 0;
}
.selectionGroup > .tooltipTrigger {
  margin: 0 20px 20px 0;
}
.selectionGroup .selection input.selectionInput.selectionArea {
  visibility: visible;
  opacity: 0;
  z-index: 1;
}
.selectionGroup .selection input:hover ~ label.selectionArea,
.selectionGroup .selection input:hover ~ label.desc {
  color: var(--color--audi--rot);
}
.selectionGroup .selection input:hover ~ label.selectionArea:not([svg]) {
  border: 2px solid var(--color--audi--rot);
}
.selectionGroup .selection input:checked ~ label.selectionArea,
.selectionGroup .selection input:checked:hover ~ label.selectionArea,
.selectionGroup .selection input:checked ~ label.desc,
.selectionGroup .selection input:checked:hover ~ label.desc {
  color: var(--color--audi--rot);
}
.selectionGroup .selection input:checked ~ label.selectionArea:not([svg]),
.selectionGroup .selection input:checked:hover ~ label.selectionArea:not([svg]) {
  border: 2px solid var(--color--audi--rot);
}
.selectionGroup .selection input:checked ~ label.selectionArea,
.selectionGroup .selection input:checked:hover ~ label.selectionArea {
  position: relative;
}
.selectionGroup .selection input:checked ~ label.selectionArea:after,
.selectionGroup .selection input:checked:hover ~ label.selectionArea:after {
  content: "";
  width: 40px;
  height: 40px;
  background: transparent url("./default/img/icons/selections/icon_selection_selected.png") 0 0 no-repeat;
}
.selectionGroup .selection input:disabled ~ label.selectionArea,
.selectionGroup .selection input:disabled ~ label.desc,
.selectionGroup .selection input:disabled ~ label.selectionArea:hover,
.selectionGroup .selection input:disabled ~ label.desc:hover {
  color: var(--color--audi--licht-grau);
  cursor: default;
}
.selectionGroup .selection input:disabled ~ label.selectionArea:not([svg]),
.selectionGroup .selection input:disabled ~ label.selectionArea:not([svg]):hover {
  border: 2px solid var(--color--audi--licht-grau);
}
.selectionGroup .selection input:disabled:checked ~ label.selectionArea,
.selectionGroup .selection input:disabled:checked ~ label.desc {
  color: var(--color--audi--mittel-grau);
}
.selectionGroup .selection input:disabled:checked ~ label.selectionArea:not([svg]) {
  border: 2px solid var(--color--audi--mittel-grau);
}
.selectionGroup .selection label:not([svg]).selectionArea {
  border: 2px solid var(--color--audi--schwarz);
}
.selectionGroup .selection label.selectionArea {
  background-color: var(--color--audi--weiss);
  color: var(--color--audi--schwarz);
  width: 158px;
  height: 108px;
}
.selectionGroup .selection label.selectionArea.portrait {
  width: 108px;
  height: 158px;
}
.selectionGroup .selection label.desc {
  display: block;
  font-weight: bold;
  padding-top: 5px;
  max-width: 158px;
}
.selectionGroup .selection label.desc:hover {
  color: var(--color--audi--rot);
}
.selectionGroup .selection label.desc.portrait {
  max-width: 108px;
}
.ie .selectionGroup .selectionArea > span {
  display: inline-block;
  margin: 6px 0 0;
}
.selectionPreview {
  width: 250px;
  height: 300px;
  background-color: #ffffff;
  border: 1px solid red;
  margin: 0 10px;
}
.selectionTooltip {
  max-width: 1000px;
}
.item.setControls .itemBox .moveLeft {
  margin-left: -8px;
}
.item.setControls .itemBox .moveRight {
  margin-left: 8px;
}
.jumpedAtElement:focus {
  -webkit-animation-duration: 1s;
  -webkit-animation-name: onFocusBlinking;
  animation-name: onFocusBlinking;
  animation-duration: 1s;
}
@keyframes onFocusBlinking {
  100% {
    background: white;
  }
  0% {
    background: var(--color--audi--mittel-grau);
  }
}
div.dialog {
  text-align: left;
}
div.dialog div.dialogWrapper {
  background: var(--color--audi--weiss);
  background: -moz-linear-gradient(top, var(--color--audi--weiss) 0%, var(--color--layout--visual) 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, var(--color--audi--weiss)), color-stop(100%, var(--color--layout--visual)));
  background: -webkit-linear-gradient(top, var(--color--audi--weiss) 0%, var(--color--layout--visual) 100%);
  background: -o-linear-gradient(top, var(--color--audi--weiss) 0%, var(--color--layout--visual) 100%);
  background: -ms-linear-gradient(top, var(--color--audi--weiss) 0%, var(--color--layout--visual) 100%);
  background: linear-gradient(to bottom, var(--color--audi--weiss) 0%, var(--color--layout--visual) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='var(--color--audi--weiss)', endColorstr='var(--color--layout--visual)',GradientType=0 );
}
div.dialog div.dialogWrapper.previewSwitch {
  height: 95%;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='var(--color--audi--weiss)', endColorstr='var(--color--audi--weiss)',GradientType=0 );
}
div.dialog div.dialogWrapper.previewSwitch .dialogContainer {
  margin-top: 0;
  background: transparent;
}
div.dialog div.dialogWrapper.previewSwitch .dialogContent {
  margin-top: 0;
}
div.dialog div.dialogWrapper.previewSwitch .dialogContent .tabContentContainer {
  top: 124px;
  border-top: 1px solid var(--color--layout--visual);
}
div.dialog div.dialogWrapper .dialogHeader h3 {
  margin: 32px 40px 0 25px;
}
div.dialog div.dialogWrapper .dialogHeader div.closeDialog,
div.dialog div.dialogWrapper .dialogHeader div.destroyDialog {
  right: 0;
  top: 0;
  position: absolute;
  width: 32px;
  height: 32px;
}
div.dialog div.dialogWrapper .dialogHeader div.closeDialog > .iconContainer,
div.dialog div.dialogWrapper .dialogHeader div.destroyDialog > .iconContainer {
  background: url("./customer/audi/img/icons/layer-schliessen-passiv.png") 0 0 no-repeat;
  width: 32px;
  height: 32px;
  border-radius: 0;
  border: none;
}
div.dialog div.dialogWrapper .dialogHeader div.closeDialog:hover > .iconContainer,
div.dialog div.dialogWrapper .dialogHeader div.destroyDialog:hover > .iconContainer {
  background: url("./customer/audi/img/icons/layer-schliessen-rollover.png") 0 0 no-repeat;
}
div.dialog div.dialogWrapper.browsersupportDialog .dialogContainer {
  padding: 0 40px 0 0;
}
div.dialog div.dialogWrapper.browsersupportDialog .dialogContainer .dialogContent {
  margin-top: 15px;
}
div.dialog div.dialogWrapper.browsersupportDialog .dialogContainer .dialogContent .browserSupportScreenContent .left {
  margin-left: 0;
  height: 76px;
}
div.dialog div.dialogWrapper.browsersupportDialog .dialogContainer .dialogContent .browserSupportScreenContent .left img {
  top: 0;
}
div.dialog div.dialogWrapper.browsersupportDialog .dialogContainer .dialogContent .browserSupportScreenContent .right {
  width: 360px;
  margin-top: 0;
  padding-right: 0;
}
div.dialog div.dialogWrapper.browsersupportDialog .dialogContainer .dialogContent .browserSupportScreenContent .right .downloadList {
  list-style: none;
}
div.dialog div.dialogWrapper.browsersupportDialog .dialogFooter {
  padding: 25px 25px 20px;
  background: transparent;
}
div.dialog div.dialogWrapper.browsersupportDialog .dialogFooter .dialogButton {
  position: relative;
}
div.dialog div.dialogWrapper.browsersupportDialog .dialogFooter .dialogButton,
div.dialog div.dialogWrapper.browsersupportDialog .dialogFooter .dialogButton:focus,
div.dialog div.dialogWrapper.browsersupportDialog .dialogFooter .dialogButton:active {
  background-color: var(--color--interactive--background);
  padding: 0 12px;
  margin-right: 10px;
  min-height: 32px;
  color: var(--color--interactive--font);
  display: inline-block;
  font-family: var(--typography--audi--type-bold);
  font-size: 13px;
  line-height: 32px;
  cursor: pointer;
}
div.dialog div.dialogWrapper.browsersupportDialog .dialogFooter .dialogButton:hover,
div.dialog div.dialogWrapper.browsersupportDialog .dialogFooter .dialogButton:focus:hover,
div.dialog div.dialogWrapper.browsersupportDialog .dialogFooter .dialogButton:active:hover {
  text-decoration: none;
  background-color: var(--color--interactive--background--hover);
  color: var(--color--interactive--font);
}
div.dialog div.dialogWrapper.browsersupportDialog .dialogFooter .dialogButton.highlight,
div.dialog div.dialogWrapper.browsersupportDialog .dialogFooter .dialogButton.highlight:focus,
div.dialog div.dialogWrapper.browsersupportDialog .dialogFooter .dialogButton.highlight:active {
  background-color: var(--color--interactive--highlight--background);
  color: var(--color--interactive--font);
}
div.dialog div.dialogWrapper.browsersupportDialog .dialogFooter .dialogButton.highlight:hover,
div.dialog div.dialogWrapper.browsersupportDialog .dialogFooter .dialogButton.highlight:focus:hover,
div.dialog div.dialogWrapper.browsersupportDialog .dialogFooter .dialogButton.highlight:active:hover {
  background-color: var(--color--interactive--highlight--background--hover);
  color: var(--color--interactive--font);
}
div.dialog.newsletterPreview {
  width: 900px;
}
div.dialog.newsletterPreview div.dialogContainer h3 {
  margin-top: 0;
}
div.dialog.newsletterPreview .dialogContent {
  top: 60px;
}
div.dialog .dialogButtonline .bas-inputButton {
  display: inline-block;
}
div.dialog div.closeDialog.button,
div.dialog div.destroyDialog.button {
  padding-right: 12px;
}
div.dialogContainer {
  padding: 0 40px 20px 25px;
  background: transparent;
  filter: none;
  border: none;
  float: none;
}
div.dialogContainer span {
  font-family: var(--typography--audi--type-normal);
  font-weight: normal;
  white-space: normal;
}
div.dialogContainer span.bold,
div.dialogContainer .tabulation td span {
  font-family: var(--typography--audi--type-bold);
}
div.dialogContainer table.tabulation tr.even span,
div.dialogContainer table.tabulation tr.odd span {
  white-space: nowrap;
}
div.dialogContainer .tabRowContainer > div.tab {
  border: none;
  background: #000;
  color: #fff;
  border-radius: 0;
  font-family: var(--typography--audi--type-extended-normal);
  font-size: 11px;
  height: 30px;
  line-height: 30px;
  margin: 0 1px 0 0;
  display: inline-block;
  padding: 5px 10px;
  cursor: pointer;
}
div.dialogContainer .tabRowContainer > div.tab:hover,
div.dialogContainer .tabRowContainer > div.tab.active:hover {
  background: var(--color--interactive--background--hover);
}
div.dialogContainer .tabRowContainer > div.tab.active {
  font-family: var(--typography--audi--type-extended-bold);
  color: #000;
  background: #fff;
  border: 1px solid var(--color--layout--visual);
  border-bottom: none;
  cursor: default;
}
div.dialogContainer .tabRowContainer > div.tab.active:hover {
  background: #fff;
}
div.dialogContainer .tabContentContainer {
  border-top: 1px solid var(--color--layout--visual);
  margin-top: -1px;
  padding-top: 10px;
  position: absolute;
  top: 108px;
  bottom: 30px;
  left: 30px;
  right: 30px;
}
div.dialogContainer .dialogContent .item.horizontal > .itemBox {
  padding-right: 0;
}
div.dialogContainer .dialogContent .field.noLabel {
  display: block;
  padding-left: 0;
}
div.dialogContainer .dialogContent .field.buttonline {
  padding: 15px 0 0 0;
}
div.dialogContainer .dialogContent .field.buttonline .button {
  margin-bottom: 0;
}
div.dialogWrapper {
  position: fixed;
}
div.dialogWrapper table.tabulation tr.even span,
div.dialogWrapper table.tabulation tr.odd span {
  white-space: nowrap;
}
div.dialogWrapper.newsletterPreview {
  width: 830px;
  height: 80%;
  background: #fff;
  overflow: hidden;
}
div.dialogWrapper.newsletterPreview .destroyDialog {
  right: 0;
}
div.dialogWrapper.newsletterPreview div.dialogContainer {
  float: none;
  background: #fff;
  filter: none;
  border: none;
  padding: 0 30px 30px 30px;
  height: 100%;
}
div.dialogWrapper.newsletterPreview div.dialogContainer div.dialogContent {
  bottom: 30px;
  left: 30px;
  position: absolute;
  right: 30px;
  top: 40px;
}
div.dialogWrapper.newsletterPreview div.dialogContainer h3 {
  margin-top: 30px;
}
div.dialogWrapper.previewSwitch {
  width: 1150px;
  height: 80%;
  background: #fff;
  overflow: hidden;
  filter: none;
}
div.dialogWrapper.previewSwitch.single .dialogContent {
  position: absolute;
  bottom: 30px;
  top: 50px;
  left: 30px;
  right: 30px;
}
div.dialogWrapper.previewSwitch .destroyDialog {
  right: 0;
}
div.dialogWrapper.previewSwitch iframe {
  border: 0;
}
div.dialogWrapper.previewSwitch div.dialogHeader {
  float: none;
  min-height: 20px;
  margin-bottom: 15px;
  margin-top: 32px;
}
div.dialogWrapper.previewSwitch div.dialogContainer {
  float: none;
  background: #fff;
  filter: none;
  border: none;
  padding: 0 30px 30px 30px;
}
div.dialogWrapper.previewSwitch .tabs-list > li {
  border: none;
  background: #000;
  color: #fff;
  border-radius: 0;
  font-size: 11px;
  height: 30px;
  line-height: 30px;
  margin: 0 1px 0 0;
  display: inline-block;
  padding: 5px 10px;
  cursor: pointer;
}
div.dialogWrapper.previewSwitch .tabs-list > li.current {
  color: #000;
  background: #fff;
  cursor: default;
}
div.dialogWrapper.previewSwitch .tabs-list > li.current:hover {
  background: #fff;
}
div.dialogWrapper.previewSwitch .tabbody {
  margin-top: -1px;
  padding-top: 10px;
}
div.dialogWrapper.previewSwitch .tabbody .iframePreviewContainer {
  position: relative;
  overflow: auto;
  height: 100%;
}
div.dialogWrapper.previewSwitch .tabbody .iframePreviewContainer > div {
  position: relative;
  display: block;
}
div.dialogWrapper.previewSwitch .tabbody .iframePreviewContainer > div > iframe,
div.dialogWrapper.previewSwitch .tabbody .iframePreviewContainer > div > div.image {
  border: 0;
  position: absolute;
}
div.dialogWrapper.previewSwitch .tabbody .iframePreviewContainer iframe {
  background: #fff;
  border: 1px solid var(--color--layout--visual);
  box-sizing: border-box;
}
div.dialogWrapper.previewSwitch .tabbody .iframePreviewContainer.tablet div.image {
  display: none;
}
div.dialogWrapper.previewSwitch .tabbody .iframePreviewContainer.tablet iframe {
  width: 1024px;
  height: 500px;
  top: 0;
}
div.dialogWrapper.previewSwitch .tabbody .iframePreviewContainer.tablet object {
  width: 1024px;
}
div.dialogWrapper.previewSwitch .tabbody .iframePreviewContainer.mobile div.image {
  height: 745px;
  width: 414px;
  background: transparent url("./customer/audi/img/preview/preview_mobile.png") no-repeat;
}
div.dialogWrapper.previewSwitch .tabbody .iframePreviewContainer.mobile iframe {
  width: 346px;
  height: 475px;
  top: 139px;
  transform: scale(1);
  transform-origin: 50% 0;
  -webkit-transform: scale(1);
  -webkit-transform-origin: 50% 0;
  -ms-transform: scale(1);
  -ms-transform-origin: 50% 0;
}
div.dialogWrapper.previewSwitch .tabbody .iframePreviewContainer.mobile object {
  width: 370px;
  height: 490px;
  border: 1px solid var(--color--layout--visual);
}
div.dialogWrapper.browsersupportDialog {
  width: 550px;
}
div.dialogWrapper.browsersupportDialog .dialogFooter {
  padding: 30px;
  padding-bottom: 10px;
}
div.dialogWrapper.browsersupportDialog .dialogContainer {
  overflow: auto;
}
div.dialogWrapper.browsersupportDialog .dialogContainer .dialogContent .browserSupportScreenContent .container.left {
  width: 150px;
  text-align: center;
  height: 150px;
  margin-left: 30px;
  float: left;
}
div.dialogWrapper.browsersupportDialog .dialogContainer .dialogContent .browserSupportScreenContent .container.left img {
  top: 40px;
  position: relative;
}
div.dialogWrapper.browsersupportDialog .dialogContainer .dialogContent .browserSupportScreenContent .container.right {
  position: relative;
  width: 350px;
  padding-right: 40px;
  margin-top: 40px;
  box-sizing: border-box;
  float: right;
}
div.dialogWrapper.browsersupportDialog .dialogContainer .dialogContent .browserSupportScreenContent .container.right .headline {
  margin-bottom: 15px;
}
div.dialogWrapper.browsersupportDialog .dialogContainer .dialogContent .browserSupportScreenContent .container.right .downloadList {
  margin-top: 20px;
  margin-bottom: 0;
}
div.dialogWrapper .dialogFooter {
  background: #fff;
}
select[multiple]:not(.noCustomStyling),
select[size]:not(.noCustomStyling) {
  visibility: hidden;
  position: absolute;
}
select.cke_dialog_ui_input_select,
.cke_dialog tbody select {
  visibility: visible;
  position: inherit;
}
div.selectBox div.selectBoxHit {
  border: solid 1px var(--color--layout--visual);
  height: 20px;
  line-height: 20px;
  background-color: #fff;
  overflow: hidden;
}
div.selectBox div.selectBoxHit span.hitBoxArrow {
  height: 20px;
  width: 19px;
  background: var(--color--audi--weiss) url("./customer/audi/img/forms/liste-pfeil.png") right center no-repeat;
  float: right;
  z-index: 4000;
}
div.selectBox div.selectBoxHit ul.selectOptionContainer {
  border: 0 none;
  position: absolute;
}
div.selectBox div.selectBoxHit li.selectOption:hover,
div.selectBox div.selectBoxHit li.selectOption.selected {
  color: var(--color--font--primary);
}
div.selectBox div.item.horizontal {
  display: table;
}
div.selectBox div.item.horizontal div.itemBox {
  display: table-cell;
  float: none;
  padding-right: 0;
}
div.selectBox li.selectOption {
  padding: 0 3px 0;
  height: 20px;
}
div.selectBox.default ul.selectOptionContainer {
  visibility: hidden;
}
div.selectBox.default li.selectOption.selected {
  color: var(--color--ci--primary);
}
div.selectBox.disabled div.selectBoxHit span.hitBoxArrow {
  background: var(--color--audi--weiss) url("./customer/audi/img/forms/liste-pfeil-inaktiv.png") right center no-repeat;
}
div.selectBox.disabled div.selectBoxHit li.selectOption:hover,
div.selectBox.disabled div.selectBoxHit li.selectOption.selected {
  color: var(--color--font--secondary);
}
div.selectBox.disabled div.selectBoxHit,
div.selectBox.disabled div.selectBoxHit span.hitBoxArrow,
div.selectBox.disabled.multiple .selectOptionContainer {
  background-color: var(--color--audi--hell-grau-30);
}
div.selectBox li.selectOption.selected {
  color: var(--color--ci--primary);
}
div.selectBox li.selectOption:hover,
div.selectBox.default li.selectOption.selected:hover {
  color: var(--color--ci--primary);
}
div.selectBox ul.selectOptionContainer {
  background: #fff;
  border: solid 1px var(--color--layout--visual);
  overflow-y: auto;
}
div.selectBox ul.selectOptionContainer li {
  display: none;
}
div.selectBox ul.selectOptionContainer.toggled {
  outline: 0 none;
}
div.selectBox ul.selectOptionContainer.toggled li {
  display: block;
  border-collapse: collapse;
}
div.selectBox > ul.selectOptionContainer {
  z-index: 4999;
}
div.selectBox.disabled li.selectOption,
div.selectBox.disabled li.selectOption:hover,
div.selectBox li.selectOption.disable,
div.selectBox li.selectOption.disable:hover {
  color: var(--color--font--secondary);
}
div.fileUpload input[type="file"] {
  width: 460px;
  max-width: 460px;
}
div.fileUpload div.fakeFileUpload input {
  width: 429px;
  height: 26px;
}
div.fileUploadButton {
  background: url("./customer/audi/img/btn/suchen.png") 0 0 no-repeat;
  height: 26px;
  width: 26px;
}
div.fileUploadButton.inactive {
  background: url("./customer/audi/img/btn/suchen-inaktiv.png") 0 0 no-repeat;
  cursor: default;
}
div.fileUploadButton.hover {
  background-image: url("./customer/audi/img/btn/suchen-rollover.png");
}
div.fileUploadButton.inactive .fileUpload input:disabled {
  cursor: default;
}
select.dynamicTreeNodeFilterOption,
select.dynamicTreeNodeAttribute {
  position: relative;
  visibility: inherit;
  display: block;
}
div.resultSet.kompakt {
  padding: 0;
}
div.resultSet.kompakt .resultSetRow {
  display: flex;
}
div.resultSet.kompakt .resultSetItem {
  position: relative;
  width: 236px;
  min-height: 210px;
  padding: 20px 20px 20px 16px;
  border-right: solid 1px var(--color--audi--hell-grau-60);
  border-bottom: solid 1px var(--color--audi--hell-grau-60);
}
div.resultSet.kompakt .resultSetItem.last {
  border-right: 0 none;
}
div.resultSet.kompakt .resultSetRow.last .resultSetItem {
  border-bottom: 0 none;
  margin-bottom: 20px;
}
div.resultSet.kompakt .resultSetItem:hover,
div.resultSet.kompakt .resultSetRow.last .resultSetItem:hover {
  background-color: var(--color--audi--hell-grau-60);
}
div.resultSet.kompakt .resultImage {
  width: 100%;
  margin-bottom: 20px;
  height: 100px;
}
div.resultSet.kompakt .resultImage a {
  padding: 0;
  border: 0 none;
  display: inline-block;
  max-width: 165px\9;
}
div.resultSet.kompakt .resultImage img {
  max-width: 165px;
  max-height: 100px;
  width: auto;
}
div.resultSet.kompakt .resultImage > img {
  margin-left: 15px;
}
div.resultSet.kompakt .resultInfo {
  background: 0 none;
  width: 100%;
  padding: 0;
}
div.resultSet.kompakt .resultInfoHeader {
  padding: 0;
}
div.resultSet.kompakt .resultInfoHeader a {
  background-position: 0 2px;
}
div.resultSet.kompakt .resultInfoHeader > header > span {
  padding-left: 15px;
  display: inline-block;
  line-height: 16px;
}
div.resultSet.kompakt .resultInfoHeader > header > span.priceInfo {
  display: block;
}
div.resultSet.kompakt .resultTemplateType,
div.resultSet.kompakt .resultImage a {
  margin-left: 15px;
}
div.resultSet.kompakt .resultTemplateType.noIndent {
  padding: 0;
}
div.resultSet.kompakt .resultAdditions {
  position: absolute;
  right: 10px;
  top: 10px;
  text-align: right;
}
div.resultSet.kompakt .resultAdditions a {
  display: block;
  width: 19px;
  height: 16px;
  margin-bottom: 5px;
  padding: 0;
  background-repeat: no-repeat;
}
div.resultSet.kompakt .resultAdditions a.superviewButton {
  background-image: url("./customer/audi/img/icons/vergroessern.png");
}
div.resultSet.kompakt .resultAdditions a.superviewButton:hover {
  background-image: url("./customer/audi/img/icons/vergroessern-rollover.png");
}
div.resultSet.kompakt .resultAdditions a.addToHomePage {
  background-image: url("./customer/audi/img/icons/campaign_startpage.png");
  background-position: center center;
}
div.resultSet.kompakt .resultAdditions a.addToHomePage:hover,
div.resultSet.kompakt .resultAdditions a.addToHomePage:focus {
  background-image: url("./customer/audi/img/icons/campaign_startpage-rollover.png");
}
div.resultSet.kompakt .resultAdditions a.addToHomePageFromFamily {
  background-image: url("./customer/audi/img/icons/family_startpage.png");
  background-position: center center;
}
div.resultSet.kompakt .resultAdditions a.addToHomePageFromFamily:hover,
div.resultSet.kompakt .resultAdditions a.addToHomePageFromFamily:focus {
  background-image: url("./customer/audi/img/icons/family_startpage-rollover.png");
}
div.resultSet.kompakt .resultAdditions .tooltipTrigger {
  display: block;
  width: 20px;
  height: 20px;
  margin-bottom: 5px;
}
div.resultSet.kompakt .resultAdditions .infoForUser {
  margin-bottom: 5px;
}
div.resultSet.detail {
  background-color: var(--color--audi--weiss);
  padding: 10px 0;
}
div.resultSet.detail div.resultTemplateType {
  font-size: 12px;
  margin-top: 3px;
}
div.resultSet.detail header.resultTemplateTitle {
  position: relative;
  font-family: var(--typography--audi--type-extended-bold);
}
div.resultSet.detail p {
  margin: 2px 0;
}
div.resultSet.detail .resultInfo {
  background: 0 none;
}
div.resultSet.detail .resultInformation {
  position: relative;
}
div.resultSet.detail .resultInfoHeader {
  padding: 0 5px 5px 25px;
}
div.resultSet.detail div.resultSetSection.active div.resultTemplateInfo a {
  color: var(--color--ci--primary);
}
.detail .resultImage {
  width: 220px;
  position: relative;
}
.detail .resultImage a.defaultLink {
  padding: 0;
}
.detail .resultImage .resultAdditions a {
  border: none;
}
.detail .resultTemplateInfo {
  display: flex;
  flex-direction: column;
}
.detail .resultTemplateInfo a {
  width: auto;
  height: 15px;
  padding-top: 3px;
  margin-bottom: 10px;
}
.detail .resultTemplateInfo a.defaultLink.icon {
  height: 26px;
  padding-top: 0;
}
div.resultTemplateExpiryDateInfo {
  color: var(--color--ci--primary);
  font-size: 12px;
}
div.orderChangeButtons {
  margin-top: 5px;
  height: 26px;
}
.resultFormatGroup {
  margin: 10px 0;
  display: block;
  overflow: auto;
}
.resultFormatGroup .defaultLink {
  margin-bottom: 5px;
}
.resultFormatGroup a.active {
  color: var(--color--ci--primary);
}
.resultFormatGroup > a {
  float: left;
  clear: left;
}
div.resultSet.superview {
  padding-top: 20px;
}
div.resultSet.superview .resultImage img {
  display: block;
  margin: 0 auto;
}
div.resultSet.superview .resultAdditions {
  position: relative;
}
.resultSet .resultAdditions {
  position: relative;
  top: 0;
  right: 0;
  float: right;
}
.resultSet .resultAdditions a {
  display: block;
  width: 17px;
  height: 16px;
  margin-bottom: 5px;
  padding: 0;
  background-repeat: no-repeat;
}
.resultSet.superview .resultAdditions a.superviewButton,
.resultAdditions a.superviewButton {
  background-position: 2px 0;
  background-image: url("./customer/audi/img/icons/vergroessern.png");
}
.resultSet.superview .resultAdditions a.superviewButton:hover,
.resultAdditions a.superviewButton:hover {
  background-image: url("./customer/audi/img/icons/vergroessern-rollover.png");
}
.resultSet.superview .resultAdditions a.superviewButton[data-image=""],
.resultAdditions a.superviewButton[data-image=""] {
  display: none;
}
.current-templates {
  border-top: 1px solid var(--color--layout--visual);
  overflow: hidden;
  padding: 10px 0 25px 0;
  word-wrap: break-word;
}
.current-templates img {
  max-width: 120px;
  max-height: 100px;
  margin-top: 25px;
}
.current-templates .linkShortDescription {
  margin-left: 16px;
  display: inline-block;
}
.current-templates .linkShortDescriptionProfilerNeeded {
  margin-left: 25px;
  display: inline-block;
}
div.resultSet {
  background-color: #fff;
  padding: 10px;
}
div.resultInfoHeader,
div.resultSetSection,
div.resultInfoFooter {
  padding: 5px 5px 5px 25px;
}
div.resultInfoHeader span.shortdescription {
  display: block;
}
div.modificationInfo {
  margin-top: 4px;
}
div.modificationInfo span,
div.modificationInfo {
  line-height: 10px ! important;
  font-size: 10px;
}
section.resultSetItem {
  border: none;
}
div.resultInfo {
  padding-left: 10px;
}
.resultSet .resultSetItem .resultImage.defaultpreview > a {
  border: none;
}
.resultSet .resultSetItem .resultImage > a {
  border: 1px solid var(--color--layout--visual);
  line-height: 0;
  position: relative;
}
.resultSet .resultSetItem .resultImage > a .additionalTemplateNote,
.resultSet .resultSetItem .resultImage > a .additionalTemplateNoteCms {
  color: var(--color--ci--primary);
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.resultSet .resultSetItem .resultImage > a .additionalTemplateNote > span,
.resultSet .resultSetItem .resultImage > a .additionalTemplateNoteCms > span {
  background: var(--color--audi--weiss);
  font-family: var(--typography--audi--type-extended-bold);
  font-size: 13px;
  display: inline-block;
  border: 1px solid var(--color--ci--primary);
  position: relative;
  padding: 5px 10px;
  white-space: pre;
  box-shadow: -2px 2px 4px -1px var(--color--audi--mittel-grau);
  -moz-transform: rotate(-20deg);
  -webkit-transform: rotate(-20deg);
  -ms-transform: rotate(-20deg);
  -o-transform: rotate(-20deg);
  transform: rotate(-20deg);
}
.resultSet .resultSetItem .resultImage > a .additionalTemplateNoteCms > span {
  font-size: 11px;
}
.resultSet .resultSetItem .resultImage > img {
  border: 1px solid var(--color--layout--visual);
  line-height: 0;
}
header.resultTemplateTitle {
  color: var(--color--font--primary);
  font-size: 16px;
  margin-bottom: 5px;
}
section.resultSetItem a {
  display: inline-block;
}
div.resultTemplateType {
  font-size: 11px;
  line-height: 16px;
  font-family: var(--typography--audi--type-normal);
  font-weight: normal;
  color: var(--color--audi--mittel-grau);
}
div.resultTemplateType > span {
  line-height: 16px;
}
div.resultTemplateType > span.highlight {
  color: var(--color--audi--rot);
}
a.icon.save_small {
  position: absolute;
  top: 5px;
  right: 0;
}
#superviewimg {
  border: 1px solid var(--color--layout--visual);
}
.choose-templates-per-page {
  padding-bottom: 25px;
}
.choose-templates-per-page .bas-formSection,
.choose-templates-per-page .bas-formSection .item {
  float: right;
}
.choose-templates-per-page .item.horizontal div.itemBox:first-child {
  padding-right: 5px;
  width: auto;
}
.choose-templates-per-page .item.horizontal div.itemBox:first-child,
.choose-templates-per-page .item.horizontal div.itemBox:first-child + div.itemBox {
  border-left: none;
}
.choose-templates-per-page .item div.itemBox {
  padding-right: 0;
  height: 30px;
  width: 35px;
  line-height: 30px;
  text-align: center;
  border-left: dotted 1px var(--color--audi--hell-grau-60);
}
.choose-templates-per-page span span {
  font-family: var(--typography--audi--type-bold);
}
.choose-templates-per-page a.defaultLink {
  font-family: var(--typography--audi--type-normal);
  background: none;
}
.choose-templates-per-page span span,
.choose-templates-per-page a.defaultLink {
  line-height: 32px;
  height: 30px;
  min-width: 28px;
  text-align: center;
  vertical-align: top;
  padding: 0 2px;
}
.resultTemplateDescription {
  font-size: 13px;
  margin: 10px 0;
}
.ribbon span {
  background: var(--color--ci--primary);
}
.ribbon span:before {
  border-left: 3px solid var(--color--ci--primary--darken-5);
  border-bottom: 3px solid var(--color--ci--primary--darken-5);
}
.ribbon:after {
  border-left: 3px solid var(--color--ci--primary--darken-5);
}
div.item.buttonline.top {
  padding: 5px 10px;
  vertical-align: middle;
  font-family: var(--typography--audi--type-bold);
  min-height: 22px;
  background-color: var(--color--audi--hell-grau-30);
  font-size: 13px;
  line-height: 22px;
  margin-top: 1px;
}
div.buttonline.mySet_footer {
  border-top: 0 none;
  border-right: 1px solid var(--color--layout--visual);
  border-bottom: 1px solid var(--color--layout--visual);
  border-left: 1px solid var(--color--layout--visual);
  padding: 10px;
}
.mySet {
  border: 1px solid var(--color--layout--visual);
  height: 270px;
  overflow: auto;
}
.mySet tr {
  display: block;
}
.mySet .row {
  padding: 10px;
  padding-bottom: 12px;
}
.mySet .row ~ .row {
  border-top: 1px solid var(--color--layout--visual);
}
.mySet .row div.col .icon {
  margin: 0;
}
.mySet .row div.col * {
  vertical-align: middle;
}
.mySet .row div.col span span {
  vertical-align: initial;
}
.mySet .row div.col + div.col,
.mySet .row div.col .icon + span {
  padding-left: 10px;
}
.mySet .row div.col + div.col.text {
  padding-left: 25px;
  width: 100%;
}
.mySet .row div.col.text ~ div.col.last {
  width: auto;
}
.mySet .col {
  vertical-align: middle;
  border-right: none;
  font-family: var(--typography--audi--type-normal);
}
.mySet .col .icon {
  float: left;
}
.mySet .col span {
  display: inline-block;
  margin-top: 2px;
}
.image-border {
  border: 1px solid var(--color--audi--licht-grau);
}
.cms-edit-image-preview {
  border: 1px solid var(--color--audi--licht-grau);
}
.cms-edit-image-preview.cols5.pictureinfo {
  padding-right: 10px;
}
.cms-formattedlink {
  margin: 3px 0;
}
div.greyText {
  font-family: var(--typography--audi--type-normal);
  font-weight: normal;
  color: var(--color--audi--mittel-grau);
  padding-left: 16px;
}
#text-and-links {
  padding-top: 15px;
}
#text-and-links .two-columns-left {
  padding: 0 20px 10px 5px;
  width: 50%;
  border-right: 1px solid var(--color--audi--licht-grau);
}
#text-and-links .two-columns-right {
  padding: 0 20px 10px 25px;
  width: 50%;
}
#text-and-links .two-columns-right td {
  border-bottom: 0 none;
}
#budgetOverview .budgetTypePicture {
  width: 150px;
}
#budgetTypeOverview td {
  vertical-align: middle;
}
#budgetTypeOverview .budgetTypePicture {
  width: 85px;
}
#costPartitioningTable {
  border-spacing: 0;
}
#costPartitioningTable td.col {
  padding: 5px 0 5px 0;
  border: none;
  font-weight: normal;
}
#costPartitioningTable td.col.last {
  padding-left: 10px;
}
table.tabulation {
  width: 100%;
  border-collapse: collapse;
}
table.tabulation.half-size {
  width: 380px;
}
table.tabulation.fixed {
  table-layout: fixed;
}
table.tabulation.kopra .header td,
table.tabulation.budgetOverviewTable .header td {
  white-space: nowrap;
}
table.tabulation.newsletter .header .options,
table.tabulation.eventnewsletter .header .options,
table.tabulation.articles .header .options {
  width: 120px;
}
table.tabulation.newsletter .header .articlescount,
table.tabulation.eventnewsletter .header .articlescount,
table.tabulation.articles .header .articlescount,
table.tabulation.newsletter .header .state,
table.tabulation.eventnewsletter .header .state,
table.tabulation.articles .header .state,
table.tabulation.newsletter .header .category,
table.tabulation.eventnewsletter .header .category,
table.tabulation.articles .header .category {
  width: 50px;
}
table.tabulation.newsletter .header .creationDate,
table.tabulation.eventnewsletter .header .creationDate,
table.tabulation.articles .header .creationDate,
table.tabulation.newsletter .header .calculatedValidFrom,
table.tabulation.eventnewsletter .header .calculatedValidFrom,
table.tabulation.articles .header .calculatedValidFrom,
table.tabulation.newsletter .header .calculatedValidTo,
table.tabulation.eventnewsletter .header .calculatedValidTo,
table.tabulation.articles .header .calculatedValidTo {
  width: 95px;
}
table.tabulation#budgetTypeOverview .header .options {
  width: 50px;
}
table.tabulation#budgetTypeOverview .header .picture {
  width: 80px;
}
table.tabulation#budgetTypeOverview .header .id {
  width: 35px;
}
table.tabulation#budgetTypeOverview .header .kind {
  width: 120px;
}
table.tabulation#budgetTypeOverview .header .validTo {
  width: 85px;
}
table.tabulation#budgetTypeOverview .header .percentage {
  width: 105px;
}
table.tabulation thead td {
  border-right: 5px solid #fff;
}
table.tabulation thead td.last {
  border-right: 0;
}
table.tabulation th {
  border-bottom: none;
}
table.tabulation td {
  border-top: none;
  line-height: 20px;
  font-family: var(--typography--audi--type-normal);
  font-weight: bold;
  border-bottom: 1px solid var(--color--audi--hell-grau-30);
  padding: 5px;
}
table.tabulation td,
table.tabulation td a,
table.tabulation td a.defaultLink,
table.tabulation td a.defaultLink span {
  font-size: 12px;
}
table.tabulation td[align="center"] {
  text-align: center;
}
table.tabulation td[align="center"] > .defaultLink.icon,
table.tabulation td[align="center"] > .infoIcon,
table.tabulation td[align="center"] > .inactive {
  margin: 0;
  padding-right: 0;
  display: inline-block;
  float: none;
}
table.tabulation td[align="center"] img {
  margin: 0 auto;
}
table.tabulation td a.icon,
table.tabulation td img,
table.tabulation td .icon.inactive {
  vertical-align: middle;
}
table.tabulation td a.formSubmit.button {
  font-size: 13px;
}
table.tabulation td.last {
  border-right: none;
}
table.tabulation td.cols1 {
  width: 152px;
}
table.tabulation td.colsHalf {
  width: 73px;
}
table.tabulation td.colsThird {
  width: 48px;
}
table.tabulation td.colsQuarter {
  width: 34px;
}
table.tabulation td.cols2 {
  width: 273px;
}
table.tabulation td.options a.icon:last-of-type,
table.tabulation td.options1 a.icon:last-of-type,
table.tabulation td.options2 a.icon:last-of-type,
table.tabulation td.options3 a.icon:last-of-type,
table.tabulation td.options4 a.icon:last-of-type,
table.tabulation td.options5 a.icon:last-of-type {
  padding-right: 0;
}
table.tabulation td.options1 {
  width: 61px;
}
table.tabulation td.options2 {
  width: 97px;
}
table.tabulation td.options3 {
  width: 133px;
}
table.tabulation td.options4 {
  width: 169px;
}
table.tabulation td.options5 {
  width: 205px;
}
table.tabulation td.breakword {
  word-wrap: break-word;
}
table.tabulation td.archive_date {
  width: 90px;
}
table.tabulation td span + img {
  margin-left: 5px;
}
table.tabulation td img {
  display: block;
}
table.tabulation td.tooltipImg div.bas-inputCheck {
  margin-right: 0;
}
table.tabulation td .item.horizontal > .itemBox:last-of-type {
  padding-right: 0;
}
table.tabulation td > div.bas-inputCheck,
table.tabulation td > div > .bas-inputCheck {
  margin-right: 0;
}
table.tabulation td > div.bas-inputCheck > div.checkbox,
table.tabulation td > div > .bas-inputCheck > div.checkbox {
  display: block;
  padding-right: 0;
}
table.tabulation td > div.bas-inputCheck > div.checkbox + label,
table.tabulation td > div > .bas-inputCheck > div.checkbox + label {
  padding-left: 10px;
}
table.tabulation tr.intermediateHeader td {
  padding-top: 20px;
}
table.tabulation .footer.row .col {
  border-bottom: 0 none;
}
table.tabulation .footer.row .bas-inputButton {
  margin-top: 10px;
  margin-bottom: 3px;
}
table.tabulation .footer.row.subTotal td {
  background-color: var(--color--audi--licht-grau-30);
}
table.tabulation .footer.row.total td {
  background-color: var(--color--audi--hell-grau-30);
  padding-top: 15px;
  padding-bottom: 15px;
  border-right: 5px solid var(--color--audi--weiss);
}
table.tabulation .footer.row.total td.last {
  border-right: 0;
}
table.tabulation .header td,
table.tabulation .footer.total td {
  height: 22px;
  background-color: var(--color--audi--hell-grau-30) ! important;
  border-right: 5px solid #fff;
  vertical-align: middle;
}
table.tabulation .header td > .horizontal,
table.tabulation .footer.total td > .horizontal {
  display: block;
}
table.tabulation .header td.last,
table.tabulation .footer.total td.last {
  padding-right: 5px;
  border-right: 0;
}
table.tabulation .header .item.horizontal .itemBox,
table.tabulation .footer.total .item.horizontal .itemBox {
  margin-top: 5px;
}
table.tabulation .header.row > .col.filter > *,
table.tabulation .footer.total.row > .col.filter > * {
  display: inline-block;
}
table.tabulation .header.row > .col.filter > * + *,
table.tabulation .footer.total.row > .col.filter > * + * {
  margin-left: 20px;
}
table.tabulation .header.row span.inactive,
table.tabulation .footer.total.row span.inactive {
  background: url("./customer/audi/img/tabulation/sortieren_inaktiv.png") no-repeat left center;
  display: inline-block;
}
table.tabulation .header + .header,
table.tabulation .footer.total + .header {
  border-top: 5px solid #fff;
}
table.tabulation a.ascending,
table.tabulation a.descending,
table.tabulation .header.row span.inactive {
  padding: 0;
  padding-left: 15px;
  display: inline-block;
}
table.tabulation a.ascending:hover,
table.tabulation a.descending:hover {
  background: url("./customer/audi/img/tabulation/sortieren_hover.png") no-repeat left center;
}
table.tabulation a.ascending {
  background: url("./customer/audi/img/tabulation/sortieren.png") no-repeat left center;
}
table.tabulation a.ascending.active {
  background: url("./customer/audi/img/tabulation/sortieren_aufsteigend_aktiv.png") no-repeat left center;
}
table.tabulation a.descending.active {
  background: url("./customer/audi/img/tabulation/sortieren_absteigend_aktiv.png") no-repeat left center;
}
table.tabulation td[align="center"] .bas-inputCheck {
  margin: 0 auto;
}
table.tabulation .datepicker.cols1 {
  width: 82px;
}
table.tabulation div.radio {
  float: left;
}
table.tabulation tr:hover td,
table.tabulation tbody tr:hover td {
  background-color: var(--color--audi--licht-grau-30);
}
table.tabulation tbody tr.header:hover td,
table.tabulation tbody tr.header td:hover {
  background-color: var(--color--audi--hell-grau-30);
}
table.tabulation tbody tr.footer:hover td,
table.tabulation tbody tr.footer td:hover {
  background-clip: padding-box;
  border-bottom: 1px solid var(--color--audi--hell-grau-30);
}
table.tabulation tbody tr.even.rowGroup td,
table.tabulation tbody tr.odd.rowGroup td {
  border-bottom: 0 none;
}
table.tabulation tbody tr.even.rowGroup.last td,
table.tabulation tbody tr.odd.rowGroup.last td {
  border-bottom: 1px solid var(--color--audi--hell-grau-30);
}
table.tabulation td[align='right'] {
  text-align: right;
}
table.tabulation td.actioncolumn .tooltipTrigger {
  display: inline-block;
}
table.tabulation td.actioncolumn .tooltipTrigger.infoIcon {
  margin-left: 0;
}
table.tabulation td.actioncolumn .tooltipTrigger.elementTrigger > * {
  padding-right: 5px;
}
table.tabulation .stinkyHeader {
  background-color: white;
}
table.tabulation .stinkyHeader .stinkyCol {
  background-color: red;
  padding: 5px;
  float: left;
  margin-right: 5px;
}
div.tabulationContainer ~ div.footer {
  padding: 5px;
}
div.tabulationContainer ~ div.footer.row .bas-inputButton {
  margin-top: 10px;
  margin-bottom: 3px;
}
td.col.col-state {
  width: 14px;
}
.budgetTypePicture {
  border: 1px solid var(--color--audi--licht-grau);
  max-width: 150px;
  max-height: 60px;
}
div.pictureinfo.budgetTypePlaceholder {
  width: 130px;
}
div.pictureinfo.budgetTypePlaceholder div.placeholderpicture {
  height: 40px;
  width: 130px;
  padding: 0 0;
  margin-bottom: 0;
}
section.filter {
  padding-bottom: 5px;
  display: flex;
  flex-wrap: wrap;
}
section.filter:before,
section.filter:after {
  content: none;
}
div.panel.smartFilter .panelContent {
  width: 100%;
  box-sizing: border-box;
}
div.panel.smartFilter .panelContent .filter {
  margin: 0;
}
div.panel.smartFilter .panelContent .filter input.hasDatepicker {
  width: 94px;
}
div.panel.smartFilter .panelContent .filter .bas-inputArea {
  width: auto;
}
div.panel.smartFilter .panelContent .filter .filterGroup.button {
  background: transparent;
  bottom: 20px;
  left: -2px;
  cursor: default;
}
div.panel.smartFilter .panelContent .filter .toggleButtonContainer {
  background: transparent;
}
div.panel.smartFilter .panelContent .filter .filterGroup.separator {
  border-top: 1px solid var(--color--audi--hell-grau-30);
}
div.panel.smartFilter .panelContent .filter:not(:first-of-type) {
  margin-left: 20px;
}
div.panel.smartFilter .panelContent .filter:not(:first-of-type):before {
  content: '';
  height: 100%;
  width: 1px;
  position: absolute;
  left: -10px;
  top: 0;
  background-color: var(--color--audi--hell-grau-30);
}
div.panel.smartFilter .panelContent .filter[data-active],
div.panel.smartFilter .panelContent .filter:hover {
  box-shadow: 0 0 4px 0 var(--color--interactive--highlight--background);
}
div.panel.smartFilter .panelContent .filter a[data-toggle-target] span {
  line-height: 18px;
}
.fullWidth .bas-filterComponent {
  width: 100%;
}
.fullWidth .bas-filterComponent.forceNewLine {
  width: 100%;
}
.fullWidth .bas-filterComponent.separateLine {
  width: 100%;
}
.bas-filterComponent {
  padding: 10px 10px 0 10px;
  width: 50%;
  box-sizing: border-box;
  display: flex;
}
.bas-filterComponent.forceNewLine {
  width: 925px;
  border-top: 1px solid var(--color--layout--visual);
  margin-top: 10px;
  padding-top: 10px;
}
.bas-filterComponent.separateLine {
  width: 940px;
}
.bas-filterComponent label,
.bas-filterComponent .bas-inputCheck,
.bas-filterComponent .bas-inputSelect,
.bas-filterComponent .bas-inputText,
.bas-filterComponent .bas-checkboxSubContainer {
  display: flex;
  flex-wrap: wrap;
}
.bas-filterComponent div.bas-inputSelect div.selectBox {
  padding-top: 5px;
}
.bas-filterComponent .selectBox {
  position: absolute;
}
.bas-filterComponent label ~ label {
  padding-left: 23px;
  width: 163px;
  min-width: 163px;
}
.bas-filterComponent .bas-checkboxSubContainer .bas-inputCheck {
  display: flex !important;
}
.bas-filterComponent .bas-checkboxSubContainer .bas-inputCheck > *:not(.tooltip) {
  display: initial;
}
.bas-filterComponent .bas-checkboxSubContainer .bas-inputCheck label {
  padding-right: 0;
  width: 120px;
  min-width: 120px;
}
.bas-filterComponent label,
div.searchmask div.bas-label {
  width: 140px;
  padding-right: 20px;
  min-width: 140px;
}
.searchButtonPosition {
  flex-direction: row-reverse;
}
.admin .bas-filterComponent .selectBox {
  background-color: var(--color--audi--licht-grau-30);
}
div.searchmask .bas-inputArea {
  margin-left: 140px;
}
div.searchmask .bas-inputArea.horizontal > .cols1:first-child + .cols1 {
  padding-right: 20px;
  width: 172px;
}
div.searchmask .bas-inputArea.horizontal > .cols1:first-child + .cols1 + .cols1 {
  width: 141px;
}
div.searchmask .item.horizontal .itemBox {
  padding: 0;
}
div.searchmask .item.horizontal .itemBox .bas-inputCheck {
  margin: 0;
  padding: 0 10px 0 0;
  height: 20px;
}
div.searchmask .item.horizontal .itemBox .bas-inputCheck .checkbox {
  padding: 0;
}
div.searchmask div.buttonline {
  text-align: right;
}
div.searchmask .bas-formBox div.cols1 + div.cols1 {
  vertical-align: bottom;
}
.jstree-default.jstree-focused {
  background: var(--color--audi--licht-grau-30) ! important;
}
.jstree-hovered,
.jstree-default .jstree-clicked {
  background-color: var(--color--ci--primary) ! important;
  color: var(--color--audi--weiss) ! important;
  border: 1px solid var(--color--ci--primary) ! important;
}
.jstree {
  margin-right: 20px;
}
#vakata-contextmenu.jstree-default-context ul li > a {
  text-align: left;
}
#vakata-contextmenu.jstree-default-context ul li ins.jsTreeIcon {
  display: none;
}
#vakata-contextmenu.jstree-default-context ul li.jstree-contextmenu-disabled a,
#vakata-contextmenu.jstree-default-context ul li.jstree-contextmenu-disabled a:hover {
  padding: 1px 6px;
}
.reportList {
  margin-bottom: 10px;
  padding-top: 25px;
}
.reportList span {
  margin-left: 15px;
}
.reportList .descriptionKey {
  margin-bottom: 7px;
  margin-top: 7px;
}
.reportList .reportItem:hover {
  background-color: var(--color--audi--licht-grau-30);
}
.reportList .reportItem .mainRow .reportName .defaultLink {
  margin-bottom: 0;
  line-height: 20px;
  background-position: 0 5px;
}
.reportList .reportItem .mainRow > div {
  height: 22px;
}
.reportList .reportItem .mainRow > div.favIcon {
  margin-top: 2px;
  width: 22px;
}
.reportList .reportItem .mainRow > div.favIcon:first-child {
  margin-left: 10px;
  margin-right: 15px;
}
.tabbody.favorites > section {
  padding: 10px;
}
.sliderContainer {
  box-sizing: content-box;
  margin-bottom: 20px;
}
.sliderContainer ul.anythingSlider {
  list-style-type: none;
}
.sliderContainer .hideBeforeInit {
  display: none;
}
.sliderContainer .anythingSlider-default.activeSlider .anythingWindow {
  border: none;
}
.sliderContainer .anythingSlider-default.activeSlider .anythingWindow .panel .sliderItemContainer {
  margin: 20px;
  border: 1px solid var(--color--audi--licht-grau);
  width: 90% ! important;
  height: 90% ! important;
  overflow: hidden;
}
.sliderContainer .anythingSlider-default .arrow {
  top: 115px;
}
.sliderContainer .anythingSlider-default .arrow a {
  display: block;
  width: 34px;
  height: 210px;
  text-align: center;
  outline: 0;
  background: url('./customer/audi/img/slider/slider_arrows.png') no-repeat;
}
.sliderContainer .anythingSlider-default .arrow.back:hover a {
  background-position: 0px -210px;
}
.sliderContainer .anythingSlider-default .arrow.forward a {
  background-position: -34px 0;
}
.sliderContainer .anythingSlider-default .arrow.forward:hover a {
  background-position: -34px -210px;
}
.sliderContainer .anythingSlider-default .anythingControls {
  display: block;
}
.sliderContainer .anythingSlider-default .anythingControls .start-stop {
  display: none;
}
.sliderContainer .anythingSlider-default .anythingControls .thumbNav {
  position: absolute;
  top: -15px;
  left: 66px;
}
.sliderContainer .anythingSlider-default .anythingControls .thumbNav li {
  position: relative;
}
.sliderContainer .anythingSlider-default .anythingControls .thumbNav a {
  border-radius: 0;
  background: var(--color--audi--weiss);
  border: 2px solid var(--color--audi--licht-grau);
  width: 10px;
  height: 10px;
  padding: 0;
}
.sliderContainer .anythingSlider-default .anythingControls .thumbNav a:hover,
.sliderContainer .anythingSlider-default .anythingControls .thumbNav a.hover {
  border: 2px solid var(--color--audi--rot);
}
.sliderContainer .anythingSlider-default .anythingControls .thumbNav a.cur {
  background: var(--color--audi--rot);
  border: 2px solid var(--color--audi--rot);
}
.sliderContainer .anythingSlider-default .anythingControls .thumbNav a span {
  display: none;
}
.thumbnail {
  width: 100px;
  position: absolute;
  top: 7px;
  left: 7px;
  display: none;
  padding: 5px;
  background-color: var(--color--audi--weiss);
  border: 1px solid var(--color--audi--licht-grau);
  z-index: 200;
  cursor: pointer;
}
.thumbnail img {
  vertical-align: middle;
  height: 100%;
  width: 100%;
}
.lt-ie9 .sliderContainer .anythingSlider-default .arrow {
  top: 45px;
}
.jquery_tabs ul.tabs-list {
  top: 1px;
  overflow: hidden;
}
.jquery_tabs ul.tabs-list li {
  display: table-cell;
  border-radius: 0;
  margin: 0 1px -50px 0;
  background: var(--color--audi--schwarz);
  border: 1px solid var(--color--audi--schwarz);
  max-width: 235px;
  /* I FEEL SO DIRTY */
  padding-bottom: 54px;
  overflow: hidden;
}
.jquery_tabs ul.tabs-list li:hover,
.jquery_tabs ul.tabs-list li:active,
.jquery_tabs ul.tabs-list li:focus {
  background: var(--color--interactive--background--hover);
  color: var(--color--audi--weiss);
  border-color: var(--color--interactive--background--hover);
}
.jquery_tabs ul.tabs-list li.current {
  border: 1px solid var(--color--layout--visual);
  border-bottom-color: var(--color--audi--weiss);
  border-radius: 0;
  background-color: var(--color--audi--weiss);
}
.jquery_tabs ul.tabs-list li.current > a,
.jquery_tabs ul.tabs-list li.current > a > *,
.jquery_tabs ul.tabs-list li.current > a:hover,
.jquery_tabs ul.tabs-list li.current > a:active,
.jquery_tabs ul.tabs-list li.current > a:focus {
  border: 0;
  color: var(--color--audi--schwarz);
  border-radius: 0;
  font-family: var(--typography--audi--type-extended-bold);
  font-weight: bold;
  cursor: default;
}
.jquery_tabs ul.tabs-list li > a {
  padding: 5px 12px 25px;
  display: inline-block;
  cursor: pointer;
  border-radius: 0;
  border: 0;
  /* I FEEL SO DIRTY */
  margin-bottom: -25px;
}
.jquery_tabs ul.tabs-list li > a,
.jquery_tabs ul.tabs-list li > a > * {
  font-family: var(--typography--audi--type-extended-normal);
  line-height: 20px;
  color: var(--color--audi--weiss);
  font-size: 11px;
}
.jquery_tabs ul.tabs-list li > a:hover,
.jquery_tabs ul.tabs-list li > a:active,
.jquery_tabs ul.tabs-list li > a:focus {
  background: var(--color--interactive--background--hover);
  color: var(--color--audi--weiss);
  border-color: var(--color--interactive--background--hover);
}
.jquery_tabs .tabContent {
  border-top: 1px solid var(--color--layout--visual);
}
#newsletterPreview,
#newsletterPreview.responsive {
  margin: 0 auto;
}
#newsletterPreview.largePreview,
#newsletterPreview.responsive.largePreview {
  width: 774px;
}
#newsletterPreview table,
#newsletterPreview.responsive table {
  border-collapse: collapse;
}
#newsletterPreview table.footer,
#newsletterPreview.responsive table.footer {
  width: auto;
}
#newsletterPreview table.artPreview td.artHeader,
#newsletterPreview.responsive table.artPreview td.artHeader,
#newsletterPreview table.artPreview td.artImage,
#newsletterPreview.responsive table.artPreview td.artImage,
#newsletterPreview table.artPreview td.artContent,
#newsletterPreview.responsive table.artPreview td.artContent,
#newsletterPreview table.artPreview td.artLinks,
#newsletterPreview.responsive table.artPreview td.artLinks,
#newsletterPreview table.artPreview td.toTop,
#newsletterPreview.responsive table.artPreview td.toTop,
#newsletterPreview table.artPreview td.artFootnoteWrapper,
#newsletterPreview.responsive table.artPreview td.artFootnoteWrapper {
  border-left: 15px solid #ddd ! important;
  border-right: 15px solid #ddd ! important;
  padding-left: 20px ! important;
  padding-right: 20px ! important;
}
#newsletterPreview td,
#newsletterPreview.responsive td {
  border: 0 none;
  padding: 0;
}
#newsletterPreview #template table.artPreview td,
#newsletterPreview.responsive #template table.artPreview td {
  border-left: 0 none ! important;
  border-right: 0 none ! important;
  padding-left: 0 ! important;
  padding-right: 0 ! important;
}
#newsletterPreview #template table.artPreview td.toTop,
#newsletterPreview.responsive #template table.artPreview td.toTop {
  padding-bottom: 0 ! important;
  padding-right: 18px ! important;
}
#newsletterPreview #template table.artPreview .footnoteDescription,
#newsletterPreview.responsive #template table.artPreview .footnoteDescription {
  padding: 2px 0 5px 3px ! important;
}
#newsletterPreview #template table.artPreview .artHeader,
#newsletterPreview.responsive #template table.artPreview .artHeader {
  padding-top: 0 ! important;
}
#newsletterPreview table.artPreview .artHeader,
#newsletterPreview.responsive table.artPreview .artHeader {
  padding-top: 15px ! important;
}
#newsletterPreview table.artPreview .artHeader td.toTop,
#newsletterPreview.responsive table.artPreview .artHeader td.toTop {
  padding-bottom: 30px ! important;
  padding-right: 38px ! important;
}
#newsletterPreview .linkSpacer,
#newsletterPreview.responsive .linkSpacer {
  height: 8px;
}
#newsletterPreview .linkContainer > span,
#newsletterPreview.responsive .linkContainer > span {
  line-height: 18px;
}
#newsletterPreview .headlineFiveSpacer,
#newsletterPreview.responsive .headlineFiveSpacer {
  height: 5px;
}
#newsletterPreview .content,
#newsletterPreview.responsive .content {
  width: 470px;
}
#newsletterPreview .linkColumn,
#newsletterPreview.responsive .linkColumn {
  width: 184px;
}
#newsletterPreview .toTop > table,
#newsletterPreview.responsive .toTop > table {
  width: 81px;
  float: right;
}
#newsletterPreview .editablerichtext ul,
#newsletterPreview.responsive .editablerichtext ul,
#newsletterPreview .editablerichtext ol,
#newsletterPreview.responsive .editablerichtext ol {
  margin: 0 0 0 0;
  padding: 0;
  list-style-position: inside;
}
#newsletterPreview .editablerichtext ul li,
#newsletterPreview.responsive .editablerichtext ul li,
#newsletterPreview .editablerichtext ol li,
#newsletterPreview.responsive .editablerichtext ol li {
  margin: 0;
  padding: 0 0 0 0;
  list-style-position: inside;
}
#newsletterPreview .editablerichtext ul,
#newsletterPreview.responsive .editablerichtext ul {
  list-style-type: disc;
}
#newsletterPreview .editablerichtext ol,
#newsletterPreview.responsive .editablerichtext ol {
  list-style-type: decimal;
}
#newsletterPreview .footnoteDescription,
#newsletterPreview.responsive .footnoteDescription {
  width: 100%;
}
#newsletterPreview .footnoteDescription img,
#newsletterPreview.responsive .footnoteDescription img {
  width: inherit\9;
}
table.artPreview {
  width: 774px;
}
#newsletterPreview sup,
#newsletterPreview sub {
  line-height: 10px;
}
#template table {
  width: 100%;
}
#newsletterPreview tbody tr:hover th,
#newsletterPreview tbody tr:hover td {
  background-color: inherit;
}
.teaser .preview-highlight {
  outline: solid 1px var(--color--layout--background) ! important;
}
.teaser .preview-highlight:hover {
  cursor: pointer;
}
.teaser .rectanglesActive {
  outline: solid 1px var(--color--layout--background);
}
.red .preview-highlight {
  outline: solid 1px var(--color--layout--background) ! important;
}
.red .preview-highlight:hover {
  cursor: pointer;
}
.red .rectanglesActive {
  outline: solid 1px var(--color--layout--background);
}
#basket {
  right: 203px;
  top: -11px;
  position: absolute;
}
.lt-ie9 #basket {
  top: -13px;
}
.lt-ie9 #basketTrigger {
  height: 32px;
  background: #ffffff url("./customer/audi/img/icons/icon_basket.png") no-repeat center 11px;
}
.lt-ie9 #basketContent {
  top: 33px;
}
#basketTrigger {
  position: relative;
  height: 30px;
  width: 50px;
  border: 1px solid transparent;
  background: #ffffff url("./customer/audi/img/icons/icon_basket.png") no-repeat center 9px;
  z-index: 10007;
}
#basketTrigger.hover,
#basketTrigger:hover {
  border-left-color: var(--color--layout--visual);
  border-top-color: var(--color--layout--visual);
  border-right-color: var(--color--layout--visual);
  background-image: url("./customer/audi/img/icons/icon_basket_hover.png");
  box-shadow: 0 -2px 5px -3px var(--color--layout--visual);
  z-index: 10009;
  border-bottom-right-radius: 2px;
}
#basketTrigger.hover + #basketContent,
#basketTrigger:hover + #basketContent {
  border-top: 1px solid var(--color--layout--visual);
  z-index: 10007 !important;
}
#basketTrigger:not(.isFilled),
#basketTrigger:not(.isFilled):hover {
  background-image: url("./customer/audi/img/icons/icon_basket_inactive.png");
  opacity: 0.4;
  border: 1px solid transparent;
  color: var(--color--font--primary);
}
#basketContent {
  top: 31px;
  right: 0;
  display: none;
  border: 1px solid var(--color--layout--visual);
  background-color: #ffffff;
  z-index: 10006 !important;
  padding: 0 10px;
  box-shadow: 0 -2px 5px -3px var(--color--layout--visual);
}
#basketContent .basketElement {
  padding: 5px;
  border-bottom: 1px solid var(--color--layout--visual);
  min-width: 150px;
}
#basketContent .basketElement span {
  font-weight: bold;
  font-size: 11px;
}
#basketContent .basketElement:last-of-type {
  border-bottom: none;
}
#notification_selected .circle {
  fill: var(--color--layout--background);
}
svg.favorite_star .outer {
  fill: var(--color--audi--schwarz);
}
svg.favorite_star .inner {
  fill: transparent;
}
svg.favorite_star:hover .outer {
  fill: var(--color--audi--rot);
}
svg.svg_scalable_template {
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  -o-transform: scale(1.3);
  transform: scale(1.3);
}
svg.svg_scalable_template:hover {
  fill: var(--color--audi--rot);
}
svg.svg_info circle:hover {
  fill: var(--color--audi--rot);
}
svg#mp_calendar,
svg#changed_by_user,
svg#home {
  width: 22px;
  height: 22px;
  padding: 2px;
}
svg#mp_calendar rect,
svg#changed_by_user rect,
svg#home rect {
  width: inherit;
  height: inherit;
}
svg#mp_calendar .line,
svg#changed_by_user .line,
svg#home .line,
svg#mp_calendar .arrow,
svg#changed_by_user .arrow,
svg#home .arrow {
  fill: none;
  stroke-miterlimit: 10;
}
svg#mp_calendar {
  stroke: var(--color--interactive--font);
}
svg#mp_calendar .line {
  stroke-width: 2px;
}
svg#mp_calendar .cross {
  display: none;
}
svg#changed_by_user {
  stroke: var(--color--audi--schwarz);
  stroke-width: 2px;
  margin-bottom: -9px;
  margin-top: -4px;
}
svg#changed_by_user:hover {
  stroke: var(--color--interactive--highlight--background--hover);
}
svg#changed_by_user .arrow {
  stroke-linecap: round;
}
svg#home {
  margin-top: -5px;
  margin-left: -5px;
}
svg#home path {
  fill: none;
  stroke: var(--color--interactive--background--hover);
}
svg#upload-image .stroke,
svg#default-image .stroke,
svg#db-image .stroke,
svg#cancel:hover .stroke,
svg#cancel-large:hover .stroke {
  fill: none;
  stroke: var(--color--audi--schwarz);
  stroke-miterlimit: 10;
}
svg#upload-image:hover .stroke,
svg#default-image:hover .stroke,
svg#db-image:hover .stroke,
svg#cancel:hover:hover .stroke,
svg#cancel-large:hover:hover .stroke {
  stroke: var(--color--ci--primary);
}
svg#no-image .stroke {
  fill: none;
  stroke: var(--color--audi--schwarz);
  stroke-miterlimit: 10;
}
svg#no-image .picture {
  fill: none;
  stroke: var(--color--audi--mittel-grau);
  stroke-miterlimit: 10;
}
svg#no-image .circle {
  fill: var(--color--audi--weiss);
  stroke: var(--color--audi--schwarz);
  stroke-miterlimit: 10;
}
svg#no-image:hover .stroke,
svg#no-image:hover .circle {
  stroke: var(--color--ci--primary);
}
.bas-inputCheck div.checkbox.checked + label svg#mp_calendar .plus {
  display: none;
}
.bas-inputCheck div.checkbox.checked + label svg#mp_calendar .cross {
  display: block;
}
.optionTrigger svg.preferences {
  width: 26px;
  padding: 3px;
}
.optionTrigger svg.preferences_scheduled {
  width: 27px;
}
.optionTrigger svg.preferences path,
.optionTrigger svg.preferences_scheduled path,
.optionTrigger svg.preferences line,
.optionTrigger svg.preferences_scheduled line {
  fill: var(--color--interactive--background);
}
.optionTrigger svg.preferences:hover path,
.optionTrigger svg.preferences_scheduled:hover path,
.optionTrigger svg.preferences:hover line,
.optionTrigger svg.preferences_scheduled:hover line {
  fill: var(--color--ci--primary);
}
.optionTrigger svg.preferences .blocker,
.optionTrigger svg.preferences_scheduled .blocker {
  fill: var(--color--layout--background);
}
.optionerror .optionTrigger svg.preferences_scheduled path {
  fill: var(--color--feedback--error);
}
html.ie11 .optionTrigger svg {
  padding: 1px;
}
html.ie11 .optionTrigger svg.preferences,
html.ie11 .optionTrigger svg.preferences_scheduled {
  padding: 0;
}
.jstree-default li.jstree-checked a.jstree-hovered ins.jstree-checkbox {
  background-position: -38px -37px;
}
.jstree-default ins.jstree-checkbox,
.jstree-default ins.jstree-icon {
  background-image: url("./customer/audi/img/audi-jstree-icons-sprite.png");
}
#wofWrapper {
  overflow: hidden;
  z-index: 10000;
}
#wofWrapper.open .wofSectionGroup .wofElement:before {
  border-right-width: 0 ! important;
  left: -23px;
}
#wofWrapper.open .wofSectionGroup.wofSeparator .wofElement:before,
#wofWrapper.open .wofSectionGroup:hover.wofSeparator .wofElement:before,
#wofWrapper.open .wofSectionGroup.wofSeparator.inviewport .wofElement:before,
#wofWrapper.open .wofSectionGroup:hover.wofSeparator.inviewport .wofElement:before,
#wofWrapper.open .wofSectionGroup.wofSeparator.containsErrors .wofElement:before,
#wofWrapper.open .wofSectionGroup:hover.wofSeparator.containsErrors .wofElement:before {
  left: -12px;
}
#wofWrapper .wofSectionGroup {
  width: 24px;
  height: 30px;
  margin-bottom: 2px;
  overflow: visible;
}
#wofWrapper .wofSectionGroup:not(:last-of-type) .wofElement:after {
  bottom: -3px;
  content: '';
  position: absolute;
  right: -1px;
  height: 2px;
  width: 109%;
  background-color: var(--color--layout--background);
}
#wofWrapper .wofSectionGroup .wofElement {
  background-color: var(--color--interactive--background);
  border: 1px solid var(--color--interactive--background);
  border-left-width: 0;
  color: var(--color--interactive--font);
  line-height: 28px;
  padding: 0 15px 0 0;
  margin-left: 24px;
  position: relative;
}
#wofWrapper .wofSectionGroup .wofElement:before {
  background-image: url('./customer/audi/img/quickfinder/leftEdge_black.png');
  background-repeat: no-repeat;
  line-height: 30px;
  width: 23px;
  height: 30px;
  left: -24px;
  top: -1px;
  border-right: 1px solid var(--color--interactive--background);
}
#wofWrapper .wofSectionGroup.wofSeparator,
#wofWrapper .wofSectionGroup:hover.wofSeparator {
  margin-top: 10px;
}
#wofWrapper .wofSectionGroup.wofSeparator:before,
#wofWrapper .wofSectionGroup:hover.wofSeparator:before {
  top: -10px;
  content: '';
  position: absolute;
  right: -1px;
  height: 10px;
  width: 94%;
  background-color: var(--color--layout--background);
}
#wofWrapper .wofSectionGroup.wofSeparator .wofElement,
#wofWrapper .wofSectionGroup:hover.wofSeparator .wofElement,
#wofWrapper .wofSectionGroup.wofSeparator.inviewport .wofElement,
#wofWrapper .wofSectionGroup:hover.wofSeparator.inviewport .wofElement,
#wofWrapper .wofSectionGroup.wofSeparator.containsErrors .wofElement,
#wofWrapper .wofSectionGroup:hover.wofSeparator.containsErrors .wofElement {
  background-color: var(--color--interactive--background);
  border-color: var(--color--interactive--background);
  color: var(--color--interactive--font);
}
#wofWrapper .wofSectionGroup.wofSeparator .wofElement:before,
#wofWrapper .wofSectionGroup:hover.wofSeparator .wofElement:before,
#wofWrapper .wofSectionGroup.wofSeparator.inviewport .wofElement:before,
#wofWrapper .wofSectionGroup:hover.wofSeparator.inviewport .wofElement:before,
#wofWrapper .wofSectionGroup.wofSeparator.containsErrors .wofElement:before,
#wofWrapper .wofSectionGroup:hover.wofSeparator.containsErrors .wofElement:before {
  background: var(--color--interactive--background);
  border-right: 1px solid var(--color--interactive--background);
  width: 12px;
  left: -13px;
}
#wofWrapper .wofSectionGroup.inviewport .wofElement,
#wofWrapper .wofSectionGroup.inviewport:hover .wofElement {
  background-color: var(--color--layout--background);
  border-color: var(--color--layout--visual);
  color: var(--color--font--primary);
}
#wofWrapper .wofSectionGroup.inviewport .wofElement:before,
#wofWrapper .wofSectionGroup.inviewport:hover .wofElement:before {
  background-image: url('./customer/audi/img/quickfinder/leftEdge_white.png');
  border-right: 1px solid var(--color--layout--visual);
}
#wofWrapper .wofSectionGroup.inviewport:hover .wofElement {
  color: var(--color--ci--primary);
}
#wofWrapper .wofSectionGroup.containsErrors .wofElement,
#wofWrapper .wofSectionGroup.containsErrors:hover .wofElement {
  background-color: var(--color--interactive--highlight--background);
  border-color: var(--color--interactive--highlight--background);
  color: var(--color--interactive--font);
}
#wofWrapper .wofSectionGroup.containsErrors .wofElement:before,
#wofWrapper .wofSectionGroup.containsErrors:hover .wofElement:before {
  border-right: 1px solid var(--color--interactive--highlight--background);
  background-image: url('./customer/audi/img/quickfinder/leftEdge_darkred.png');
}
#wofWrapper .wofSectionGroup.containsErrors:hover .wofElement {
  background: var(--color--interactive--highlight--background--hover);
  border-color: var(--color--interactive--highlight--background--hover);
}
#wofWrapper .wofSectionGroup.containsErrors:hover .wofElement:before {
  border-right: 1px solid var(--color--interactive--highlight--background--hover);
  background-image: url('./customer/audi/img/quickfinder/leftEdge_red.png');
}
#wofWrapper .wofSectionGroup:hover .wofElement {
  background-color: var(--color--interactive--background--hover);
  border-color: var(--color--interactive--background--hover);
}
#wofWrapper .wofSectionGroup:hover .wofElement:before {
  border-right: 1px solid var(--color--interactive--background--hover);
  background-image: url('./customer/audi/img/quickfinder/leftEdge_hover.png');
}
div.wofArrow {
  height: 15px;
  width: 15px;
  background-image: url('./customer/audi/img/icons/linkpfeil-rot-double.png');
}
div.wofArrow.wofArrowTop {
  transform: rotate(180deg);
}
div.wofArrow.max {
  background-image: url('./customer/audi/img/icons/linkpfeil-schwarz-double.png');
}
.jq-toast-wrap {
  display: block;
  position: fixed;
  width: 500px;
  pointer-events: none ! important;
  margin: 0;
  padding: 0;
  letter-spacing: normal;
  z-index: 10110 ! important;
}
.jq-toast-wrap * {
  margin: 0;
  padding: 0;
}
.jq-toast-wrap.bottom-left {
  bottom: 20px;
  left: 20px;
}
.jq-toast-wrap.bottom-right {
  bottom: 20px;
  right: 40px;
}
.jq-toast-wrap.top-left {
  top: 20px;
  left: 20px;
}
.jq-toast-wrap.top-right {
  top: 20px;
  right: 40px;
}
.jq-toast-single {
  display: block;
  padding: 10px;
  margin: 0 0 5px;
  border-radius: 4px;
  font-size: 14px;
  position: relative;
  pointer-events: all ! important;
  cursor: pointer;
  background-color: #444444;
  color: white;
}
.jq-toast-single h2 {
  font-family: arial, sans-serif;
  font-size: 14px;
  margin: 0 0 7px;
  background: none;
  color: inherit;
  line-height: inherit;
  letter-spacing: normal;
}
.jq-toast-single a {
  color: #eee;
  text-decoration: none;
  font-weight: bold;
  border-bottom: 1px solid white;
  padding-bottom: 3px;
  font-size: 12px;
}
.jq-toast-single ul {
  margin: 0 0 0 15px;
  background: none;
  padding: 0;
}
.jq-toast-single ul li {
  list-style-type: disc ! important;
  line-height: 17px;
  background: none;
  margin: 0;
  padding: 0;
  letter-spacing: normal;
}
.jq-toast-single span {
  line-height: 23px;
  font-color: var(--color--font--primary);
}
.close-jq-toast-single {
  position: absolute;
  top: 3px;
  right: 7px;
  font-size: 14px;
  cursor: pointer;
}
.jq-toast-loader {
  display: block;
  position: absolute;
  bottom: 0;
  height: 5px;
  width: 0;
  left: 0;
  background: var(--button--background-color);
}
.jq-toast-loaded {
  width: 100%;
}
.jq-toast-has-icon {
  padding: 27px 20px 30px 80px;
  background-repeat: no-repeat;
  background-position: 20px 20px;
}
.jq-toast-icon-success {
  border: 1px solid var(--color--feedback--success);
  background-color: var(--color--layout--background);
  -webkit-box-shadow: 0 5px 10px #ABABAB;
  box-shadow: 0 5px 10px #ABABAB;
  color: var(--color--font--primary);
}
.jq-toast-icon-success .jq-toast-loader {
  background: var(--color--feedback--success);
}
.jq-toast-icon-success i.toast-icon {
  background-image: url("./default/img/toast_notification.png");
  background-color: var(--color--feedback--success);
  height: 40px;
  width: 40px;
  position: absolute;
  top: 20px;
  left: 20px;
  background-size: 40px;
}
.img-button.detail {
  border-color: var(--color--audi--hell-grau-60);
}
.img-button.detail:hover,
.img-button.detail.selected {
  border-color: var(--color--ci--primary);
}
.img-button.detail .preview img {
  background: var(--color--base--black);
  border-color: var(--color--audi--hell-grau-60);
}
.img-button.detail .desciption {
  color: var(--color--base--black);
}
.marketing-planer-kalender.sticky .cal_header_sticky {
  background-color: var(--color--layout--background);
  border-bottom: 1px solid grey;
}
.marketing-planer-kalender .cal_header .next {
  background-position: 0 0;
}
.marketing-planer-kalender .cal_header .cal_row.header.rough {
  font-family: var(--typography--audi--type-extended-bold);
}
.marketing-planer-kalender .cal_header .cal_row.header.rough .cal_cell {
  border-left-color: var(--color--font--secondary);
}
.marketing-planer-kalender .cal_today_font {
  color: var(--color--ci--primary);
  font-family: var(--typography--audi--type-extended-bold);
}
.marketing-planer-kalender .cal_today_indicator {
  border-right-color: var(--color--ci--primary);
}
.marketing-planer-kalender .calender {
  border-color: var(--color--font--secondary);
}
.marketing-planer-kalender .calender .expand.icon.openAll {
  background-image: url("./customer/audi/img/icons/plus.png");
}
.marketing-planer-kalender .calender .expand.icon.openAll:hover {
  background-image: url("./customer/audi/img/icons/plus_hover.png");
}
.marketing-planer-kalender .calender .expand.icon.closeAll {
  background-image: url("./customer/audi/img/icons/minus.png");
}
.marketing-planer-kalender .calender .expand.icon.closeAll:hover {
  background-image: url("./customer/audi/img/icons/minus_hover.png");
}
.marketing-planer-kalender .calender .cal_row .cal_campaign,
.marketing-planer-kalender .calender .cal_row .cal_werbemittel {
  border-color: var(--color--font--secondary);
}
.marketing-planer-kalender .calender .cal_row .cal_campaign.info .defaultLink:not(.icon),
.marketing-planer-kalender .calender .cal_row .cal_werbemittel.info .defaultLink:not(.icon) {
  padding-left: 0;
  background: transparent;
}
.marketing-planer-kalender .calender .cal_row .cal_campaign > div,
.marketing-planer-kalender .calender .cal_row .cal_werbemittel > div {
  border-color: var(--color--font--secondary);
}
.marketing-planer-kalender .calender .cal_row .cal_cell {
  border-color: var(--color--font--secondary);
}
.cal_legende:not(.cannot_follow) .wm.normal:not(.teilgenommen),
.marketing-planer-kalender:not(.cannot_follow) .wm.normal:not(.teilgenommen),
.cal_legende:not(.cannot_follow) .kampagne.normal:not(.teilgenommen),
.marketing-planer-kalender:not(.cannot_follow) .kampagne.normal:not(.teilgenommen),
.cal_legende:not(.cannot_follow) .cal_legende_type .cal_legende_type_preview.normal:not(.teilgenommen),
.marketing-planer-kalender:not(.cannot_follow) .cal_legende_type .cal_legende_type_preview.normal:not(.teilgenommen) {
  background: var(--gantt--normal-not-followed--background-color);
}
.cal_legende .kampagne.own,
.marketing-planer-kalender .kampagne.own {
  border-style: solid;
  border-color: #1a1a1a;
  background-color: var(--gantt--own--background-color);
}
.cal_legende .wm.werbezeitraum,
.marketing-planer-kalender .wm.werbezeitraum,
.cal_legende .cal_legende_type .cal_legende_type_preview.werbezeitraum,
.marketing-planer-kalender .cal_legende_type .cal_legende_type_preview.werbezeitraum {
  border-color: black;
  background-color: transparent !important;
}
.cal_legende .wm.normal,
.marketing-planer-kalender .wm.normal,
.cal_legende .cal_legende_type .cal_legende_type_preview.normal,
.marketing-planer-kalender .cal_legende_type .cal_legende_type_preview.normal {
  background-color: var(--gantt--normal--background-color);
}
.cal_legende .wm.national,
.marketing-planer-kalender .wm.national,
.cal_legende .cal_legende_type .cal_legende_type_preview.national,
.marketing-planer-kalender .cal_legende_type .cal_legende_type_preview.national {
  background-color: var(--gantt--national--background-color);
}
.cal_legende .wm.sperrfrist,
.marketing-planer-kalender .wm.sperrfrist,
.cal_legende .cal_legende_type .cal_legende_type_preview.sperrfrist,
.marketing-planer-kalender .cal_legende_type .cal_legende_type_preview.sperrfrist {
  background-color: var(--gantt--sperrfrist--background-color);
}
.cal_legende .wm.own,
.marketing-planer-kalender .wm.own,
.cal_legende .cal_legende_type .cal_legende_type_preview.own,
.marketing-planer-kalender .cal_legende_type .cal_legende_type_preview.own {
  border-style: solid;
  border-color: #1a1a1a;
  background-color: var(--gantt--own--background-color);
}
.cal_legende .wm.erscheinungstermin,
.marketing-planer-kalender .wm.erscheinungstermin,
.cal_legende .cal_legende_type .cal_legende_type_preview.erscheinungstermin,
.marketing-planer-kalender .cal_legende_type .cal_legende_type_preview.erscheinungstermin {
  background-color: var(--color--base--yellow);
}
.marketing-planer-kalender .calender .expand.icon.openAll,
.marketing-planer-kalender .calender .expand.icon.closeAll {
  margin-left: -33px;
}
div.dialog div.dialogWrapper.wmDialog {
  background: var(--color--layout--background);
}
.bas-formBox.layout-66-33 label[for]:hover,
.bas-formBox.layout-80-20 label[for]:hover {
  color: var(--color--interactive--highlight--background--hover);
}
.werbemittel-container,
.wmDialog {
  border: 1px solid var(--gantt--normal--background-color);
}
.werbemittel-container .werbemittel-vorschaubild img,
.wmDialog .werbemittel-vorschaubild img {
  border: 1px solid var(--color--audi--mittel-grau);
}
.werbemittel-container .werbemittel-vorschaubild > .dummy,
.wmDialog .werbemittel-vorschaubild > .dummy {
  background-color: #d0d0d0;
}
.werbemittel-container.KONZERN .werbemittel-header,
.wmDialog.KONZERN .werbemittel-header,
.werbemittel-container.KONZERN .dialogHeader h3,
.wmDialog.KONZERN .dialogHeader h3 {
  background-color: var(--gantt--national--background-color);
  color: var(--color--layout--background);
}
.werbemittel-container.KONZERN .werbemittel-header .icon:hover,
.wmDialog.KONZERN .werbemittel-header .icon:hover,
.werbemittel-container.KONZERN .dialogHeader h3 .icon:hover,
.wmDialog.KONZERN .dialogHeader h3 .icon:hover {
  border: 1px solid var(--color--font--primary);
  box-sizing: border-box;
  padding-right: 0;
  margin-right: 10px;
  background-position: -1px -1px;
}
.werbemittel-container.KONZERN .werbemittel-header .icon:hover:last-child,
.wmDialog.KONZERN .werbemittel-header .icon:hover:last-child,
.werbemittel-container.KONZERN .dialogHeader h3 .icon:hover:last-child,
.wmDialog.KONZERN .dialogHeader h3 .icon:hover:last-child {
  margin-right: 0;
}
.werbemittel-container.SPERRFRIST .werbemittel-header,
.wmDialog.SPERRFRIST .werbemittel-header,
.werbemittel-container.SPERRFRIST .dialogHeader h3,
.wmDialog.SPERRFRIST .dialogHeader h3 {
  background-color: var(--gantt--sperrfrist--background-color);
  color: var(--color--layout--background);
}
.werbemittel-container.SPERRFRIST .werbemittel-header .icon:hover,
.wmDialog.SPERRFRIST .werbemittel-header .icon:hover,
.werbemittel-container.SPERRFRIST .dialogHeader h3 .icon:hover,
.wmDialog.SPERRFRIST .dialogHeader h3 .icon:hover {
  border: 1px solid var(--color--font--primary);
  box-sizing: border-box;
  padding-right: 0;
  margin-right: 10px;
  background-position: -1px -1px;
}
.werbemittel-container.SPERRFRIST .werbemittel-header .icon:hover:last-child,
.wmDialog.SPERRFRIST .werbemittel-header .icon:hover:last-child,
.werbemittel-container.SPERRFRIST .dialogHeader h3 .icon:hover:last-child,
.wmDialog.SPERRFRIST .dialogHeader h3 .icon:hover:last-child {
  margin-right: 0;
}
.werbemittel-container.OWN .werbemittel-header,
.wmDialog.OWN .werbemittel-header,
.werbemittel-container.OWN .dialogHeader h3,
.wmDialog.OWN .dialogHeader h3 {
  background-color: var(--gantt--own--background-color);
  color: var(--color--font--primary);
}
.werbemittel-container.PARTNER.teilgenommen .werbemittel-header,
.wmDialog.PARTNER.teilgenommen .werbemittel-header,
.werbemittel-container.PARTNER.teilgenommen .dialogHeader h3,
.wmDialog.PARTNER.teilgenommen .dialogHeader h3 {
  background: var(--gantt--normal--background-color);
  color: var(--color--layout--background);
}
.werbemittel-container.PARTNER.teilgenommen .werbemittel-header svg#changed_by_user,
.wmDialog.PARTNER.teilgenommen .werbemittel-header svg#changed_by_user,
.werbemittel-container.PARTNER.teilgenommen .dialogHeader h3 svg#changed_by_user,
.wmDialog.PARTNER.teilgenommen .dialogHeader h3 svg#changed_by_user {
  stroke: var(--color--layout--background);
}
.werbemittel-container .werbemittel-header,
.wmDialog .werbemittel-header,
.werbemittel-container .dialogHeader h3,
.wmDialog .dialogHeader h3 {
  background: var(--gantt--normal-not-followed--background-color);
  color: var(--color--font--primary);
  border-bottom: 1px solid var(--gantt--border-color);
  font-family: "Audi Type Extended Normal", serif;
}
.werbemittel-container .werbemittel-footer div.arrow,
.wmDialog .werbemittel-footer div.arrow {
  background-color: var(--color--font--primary);
}
.werbemittel-container .werbemittel-footer:hover div.arrow,
.wmDialog .werbemittel-footer:hover div.arrow {
  background-color: var(--color--ci--primary);
}
.werbemittel-container .werbemittel-item.gruppenplaner,
.wmDialog .werbemittel-item.gruppenplaner {
  width: 600px;
}
.werbemittel-container .werbemittel-item.gruppenplaner .chosen-container,
.wmDialog .werbemittel-item.gruppenplaner .chosen-container {
  max-width: 600px;
}
.werbemittel-container .werbemittel-item .gruppenMitglied,
.wmDialog .werbemittel-item .gruppenMitglied {
  display: flex;
}
.werbemittel-container .werbemittel-item .gruppenMitglieder,
.wmDialog .werbemittel-item .gruppenMitglieder {
  padding: 5px 5px 5px 0;
  position: relative;
}
.werbemittel-container .werbemittel-item .gruppenMitglieder > section:first-of-type,
.wmDialog .werbemittel-item .gruppenMitglieder > section:first-of-type {
  min-height: 20px;
}
.werbemittel-container .werbemittel-item .gruppenbudget,
.wmDialog .werbemittel-item .gruppenbudget {
  font-weight: bold;
}
.werbemittel-container .werbemittel-item .gruppenbudget .sum::before,
.wmDialog .werbemittel-item .gruppenbudget .sum::before {
  content: "\2211";
  position: absolute;
  right: 136px;
}
.budget-overview .werbeformbudgets .description-container .description-wrapper.main,
.budget-overview .kategorienbudgets .description-container .description-wrapper.main,
.budget-overview .kategoriewerbeformbudgets .description-container .description-wrapper.main {
  font-family: var(--typography--audi--type-bold);
}
.budget-overview .werbeformbudgets .diagram-container,
.budget-overview .kategorienbudgets .diagram-container,
.budget-overview .kategoriewerbeformbudgets .diagram-container {
  background: repeating-linear-gradient(90deg, var(--color--audi--hell-grau), var(--color--audi--hell-grau) 1px, transparent 0px, transparent 10%);
  border-right: solid 1px var(--color--audi--hell-grau);
}
.budget-overview .werbeformbudgets .diagram-container .diagram-wrapper,
.budget-overview .kategorienbudgets .diagram-container .diagram-wrapper,
.budget-overview .kategoriewerbeformbudgets .diagram-container .diagram-wrapper {
  background: var(--color--audi--mittel-grau);
}
.floatingPageHeader {
  background-color: var(--color--layout--background);
}
.floatingPageHeader .ym-gl {
  border-right-color: var(--color--layout--visual);
}
.floatingPageHeader .ym-gr input[type=text].deeplink {
  width: 309px;
}
.floatingPageHeader .ym-gr div.bas-inputCheck {
  display: flex !important;
}
.floatingPageHeader .ym-gr div.bas-inputCheck .fieldsavedicon {
  position: unset;
}
.floatingPageHeader .ym-gr div.bas-inputCheck .checkbox {
  order: -1;
}
.floatingPageHeader .gruppenbudget {
  background: var(--color--layout--visual);
}
.floatingPageHeader .negative-groupmember-budget {
  border: 1px solid var(--color--feedback--error);
}
.monthpicker .monthpicker-calendar,
.dayspicker .monthpicker-calendar,
.monthpicker .dayspicker-calendar,
.dayspicker .dayspicker-calendar {
  border-color: var(--color--audi--schwarz);
}
.monthpicker .monthpicker-calendar .monthpicker-body .month-cell.disabled,
.dayspicker .monthpicker-calendar .monthpicker-body .month-cell.disabled,
.monthpicker .dayspicker-calendar .monthpicker-body .month-cell.disabled,
.dayspicker .dayspicker-calendar .monthpicker-body .month-cell.disabled,
.monthpicker .monthpicker-calendar .dayspicker-body .month-cell.disabled,
.dayspicker .monthpicker-calendar .dayspicker-body .month-cell.disabled,
.monthpicker .dayspicker-calendar .dayspicker-body .month-cell.disabled,
.dayspicker .dayspicker-calendar .dayspicker-body .month-cell.disabled,
.monthpicker .monthpicker-calendar .monthpicker-body .day-cell.disabled,
.dayspicker .monthpicker-calendar .monthpicker-body .day-cell.disabled,
.monthpicker .dayspicker-calendar .monthpicker-body .day-cell.disabled,
.dayspicker .dayspicker-calendar .monthpicker-body .day-cell.disabled,
.monthpicker .monthpicker-calendar .dayspicker-body .day-cell.disabled,
.dayspicker .monthpicker-calendar .dayspicker-body .day-cell.disabled,
.monthpicker .dayspicker-calendar .dayspicker-body .day-cell.disabled,
.dayspicker .dayspicker-calendar .dayspicker-body .day-cell.disabled {
  color: var(--color--interactive--background--disabled);
}
.monthpicker .monthpicker-calendar .monthpicker-body .month-cell:hover:not(.disabled),
.dayspicker .monthpicker-calendar .monthpicker-body .month-cell:hover:not(.disabled),
.monthpicker .dayspicker-calendar .monthpicker-body .month-cell:hover:not(.disabled),
.dayspicker .dayspicker-calendar .monthpicker-body .month-cell:hover:not(.disabled),
.monthpicker .monthpicker-calendar .dayspicker-body .month-cell:hover:not(.disabled),
.dayspicker .monthpicker-calendar .dayspicker-body .month-cell:hover:not(.disabled),
.monthpicker .dayspicker-calendar .dayspicker-body .month-cell:hover:not(.disabled),
.dayspicker .dayspicker-calendar .dayspicker-body .month-cell:hover:not(.disabled),
.monthpicker .monthpicker-calendar .monthpicker-body .day-cell:hover:not(.disabled),
.dayspicker .monthpicker-calendar .monthpicker-body .day-cell:hover:not(.disabled),
.monthpicker .dayspicker-calendar .monthpicker-body .day-cell:hover:not(.disabled),
.dayspicker .dayspicker-calendar .monthpicker-body .day-cell:hover:not(.disabled),
.monthpicker .monthpicker-calendar .dayspicker-body .day-cell:hover:not(.disabled),
.dayspicker .monthpicker-calendar .dayspicker-body .day-cell:hover:not(.disabled),
.monthpicker .dayspicker-calendar .dayspicker-body .day-cell:hover:not(.disabled),
.dayspicker .dayspicker-calendar .dayspicker-body .day-cell:hover:not(.disabled),
.monthpicker .monthpicker-calendar .monthpicker-body .month-cell.selected-month,
.dayspicker .monthpicker-calendar .monthpicker-body .month-cell.selected-month,
.monthpicker .dayspicker-calendar .monthpicker-body .month-cell.selected-month,
.dayspicker .dayspicker-calendar .monthpicker-body .month-cell.selected-month,
.monthpicker .monthpicker-calendar .dayspicker-body .month-cell.selected-month,
.dayspicker .monthpicker-calendar .dayspicker-body .month-cell.selected-month,
.monthpicker .dayspicker-calendar .dayspicker-body .month-cell.selected-month,
.dayspicker .dayspicker-calendar .dayspicker-body .month-cell.selected-month,
.monthpicker .monthpicker-calendar .monthpicker-body .day-cell.selected-month,
.dayspicker .monthpicker-calendar .monthpicker-body .day-cell.selected-month,
.monthpicker .dayspicker-calendar .monthpicker-body .day-cell.selected-month,
.dayspicker .dayspicker-calendar .monthpicker-body .day-cell.selected-month,
.monthpicker .monthpicker-calendar .dayspicker-body .day-cell.selected-month,
.dayspicker .monthpicker-calendar .dayspicker-body .day-cell.selected-month,
.monthpicker .dayspicker-calendar .dayspicker-body .day-cell.selected-month,
.dayspicker .dayspicker-calendar .dayspicker-body .day-cell.selected-month,
.monthpicker .monthpicker-calendar .monthpicker-body .month-cell.selected-day,
.dayspicker .monthpicker-calendar .monthpicker-body .month-cell.selected-day,
.monthpicker .dayspicker-calendar .monthpicker-body .month-cell.selected-day,
.dayspicker .dayspicker-calendar .monthpicker-body .month-cell.selected-day,
.monthpicker .monthpicker-calendar .dayspicker-body .month-cell.selected-day,
.dayspicker .monthpicker-calendar .dayspicker-body .month-cell.selected-day,
.monthpicker .dayspicker-calendar .dayspicker-body .month-cell.selected-day,
.dayspicker .dayspicker-calendar .dayspicker-body .month-cell.selected-day,
.monthpicker .monthpicker-calendar .monthpicker-body .day-cell.selected-day,
.dayspicker .monthpicker-calendar .monthpicker-body .day-cell.selected-day,
.monthpicker .dayspicker-calendar .monthpicker-body .day-cell.selected-day,
.dayspicker .dayspicker-calendar .monthpicker-body .day-cell.selected-day,
.monthpicker .monthpicker-calendar .dayspicker-body .day-cell.selected-day,
.dayspicker .monthpicker-calendar .dayspicker-body .day-cell.selected-day,
.monthpicker .dayspicker-calendar .dayspicker-body .day-cell.selected-day,
.dayspicker .dayspicker-calendar .dayspicker-body .day-cell.selected-day {
  color: var(--color--audi--weiss);
}
.monthpicker .monthpicker-calendar .monthpicker-body .month-cell.selected-month,
.dayspicker .monthpicker-calendar .monthpicker-body .month-cell.selected-month,
.monthpicker .dayspicker-calendar .monthpicker-body .month-cell.selected-month,
.dayspicker .dayspicker-calendar .monthpicker-body .month-cell.selected-month,
.monthpicker .monthpicker-calendar .dayspicker-body .month-cell.selected-month,
.dayspicker .monthpicker-calendar .dayspicker-body .month-cell.selected-month,
.monthpicker .dayspicker-calendar .dayspicker-body .month-cell.selected-month,
.dayspicker .dayspicker-calendar .dayspicker-body .month-cell.selected-month,
.monthpicker .monthpicker-calendar .monthpicker-body .day-cell.selected-month,
.dayspicker .monthpicker-calendar .monthpicker-body .day-cell.selected-month,
.monthpicker .dayspicker-calendar .monthpicker-body .day-cell.selected-month,
.dayspicker .dayspicker-calendar .monthpicker-body .day-cell.selected-month,
.monthpicker .monthpicker-calendar .dayspicker-body .day-cell.selected-month,
.dayspicker .monthpicker-calendar .dayspicker-body .day-cell.selected-month,
.monthpicker .dayspicker-calendar .dayspicker-body .day-cell.selected-month,
.dayspicker .dayspicker-calendar .dayspicker-body .day-cell.selected-month,
.monthpicker .monthpicker-calendar .monthpicker-body .month-cell.selected-day,
.dayspicker .monthpicker-calendar .monthpicker-body .month-cell.selected-day,
.monthpicker .dayspicker-calendar .monthpicker-body .month-cell.selected-day,
.dayspicker .dayspicker-calendar .monthpicker-body .month-cell.selected-day,
.monthpicker .monthpicker-calendar .dayspicker-body .month-cell.selected-day,
.dayspicker .monthpicker-calendar .dayspicker-body .month-cell.selected-day,
.monthpicker .dayspicker-calendar .dayspicker-body .month-cell.selected-day,
.dayspicker .dayspicker-calendar .dayspicker-body .month-cell.selected-day,
.monthpicker .monthpicker-calendar .monthpicker-body .day-cell.selected-day,
.dayspicker .monthpicker-calendar .monthpicker-body .day-cell.selected-day,
.monthpicker .dayspicker-calendar .monthpicker-body .day-cell.selected-day,
.dayspicker .dayspicker-calendar .monthpicker-body .day-cell.selected-day,
.monthpicker .monthpicker-calendar .dayspicker-body .day-cell.selected-day,
.dayspicker .monthpicker-calendar .dayspicker-body .day-cell.selected-day,
.monthpicker .dayspicker-calendar .dayspicker-body .day-cell.selected-day,
.dayspicker .dayspicker-calendar .dayspicker-body .day-cell.selected-day {
  background-color: var(--color--audi--rot);
}
.monthpicker .monthpicker-calendar .monthpicker-body .month-cell:hover:not(.disabled),
.dayspicker .monthpicker-calendar .monthpicker-body .month-cell:hover:not(.disabled),
.monthpicker .dayspicker-calendar .monthpicker-body .month-cell:hover:not(.disabled),
.dayspicker .dayspicker-calendar .monthpicker-body .month-cell:hover:not(.disabled),
.monthpicker .monthpicker-calendar .dayspicker-body .month-cell:hover:not(.disabled),
.dayspicker .monthpicker-calendar .dayspicker-body .month-cell:hover:not(.disabled),
.monthpicker .dayspicker-calendar .dayspicker-body .month-cell:hover:not(.disabled),
.dayspicker .dayspicker-calendar .dayspicker-body .month-cell:hover:not(.disabled),
.monthpicker .monthpicker-calendar .monthpicker-body .day-cell:hover:not(.disabled),
.dayspicker .monthpicker-calendar .monthpicker-body .day-cell:hover:not(.disabled),
.monthpicker .dayspicker-calendar .monthpicker-body .day-cell:hover:not(.disabled),
.dayspicker .dayspicker-calendar .monthpicker-body .day-cell:hover:not(.disabled),
.monthpicker .monthpicker-calendar .dayspicker-body .day-cell:hover:not(.disabled),
.dayspicker .monthpicker-calendar .dayspicker-body .day-cell:hover:not(.disabled),
.monthpicker .dayspicker-calendar .dayspicker-body .day-cell:hover:not(.disabled),
.dayspicker .dayspicker-calendar .dayspicker-body .day-cell:hover:not(.disabled) {
  background-color: var(--color--audi--hell-grau);
}
.bas-inputText.copy-error {
  box-shadow: 0 0 5px 1px var(--color--feedback--error);
}
.bas-inputText.copy-success {
  box-shadow: 0 0 5px 1px var(--color--feedback--success);
}
.chrome .hasErrors.select::after,
.firefox .hasErrors.select::after,
.ie .hasErrors.select::after,
.chrome .hasWarnings.select::after,
.firefox .hasWarnings.select::after,
.ie .hasWarnings.select::after {
  right: 23px;
}
.ie.ie11 .hasErrors.input.fileError::after,
.ie.ie10 .hasErrors.input.fileError::after,
.ie.ie11 .hasWarnings.input.fileError::after,
.ie.ie10 .hasWarnings.input.fileError::after {
  right: 123px;
}
#editMask div.hasErrors.div.ckeditorError,
#editMask table.hasErrors.div.ckeditorError,
#editMask div.hasWarnings.div.ckeditorError,
#editMask table.hasWarnings.div.ckeditorError {
  display: inline-block;
  width: 100%;
}
#editMask div.hasErrors.verification-container,
#editMask table.hasErrors.verification-container,
#editMask div.hasWarnings.verification-container,
#editMask table.hasWarnings.verification-container {
  width: 100% !important;
}
div.tabs ul.tabs-list a.error {
  position: relative;
}
div.tabs ul.tabs-list a.error:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 0;
  border-color: transparent var(--color--audi--weiss) transparent transparent;
}
div.hasErrors.triggerBoxSvg,
table.hasErrors.triggerBoxSvg,
div.hasWarnings.triggerBoxSvg,
table.hasWarnings.triggerBoxSvg {
  height: 100%;
}
div.hasErrors::after,
table.hasErrors::after,
div.hasWarnings::after,
table.hasWarnings::after {
  width: 0;
  height: 0;
  border-style: solid;
  display: inline;
  border-width: 0 10px 10px 0;
  border-color: transparent #9d9d9d transparent transparent;
  content: "";
  position: absolute;
  right: 0;
  top: 0;
}
div.hasErrors.textarea.ckeditorError::after,
table.hasErrors.textarea.ckeditorError::after,
div.hasWarnings.textarea.ckeditorError::after,
table.hasWarnings.textarea.ckeditorError::after {
  right: -1px;
}
div.hasErrors.textarea.ckeditorError .cke,
table.hasErrors.textarea.ckeditorError .cke,
div.hasWarnings.textarea.ckeditorError .cke,
table.hasWarnings.textarea.ckeditorError .cke {
  box-shadow: none;
  border-color: #9d9d9d;
}
div.hasErrors.textarea:not(.ckeditorError)::after,
table.hasErrors.textarea:not(.ckeditorError)::after,
div.hasWarnings.textarea:not(.ckeditorError)::after,
table.hasWarnings.textarea:not(.ckeditorError)::after {
  right: 1px;
  top: 1px;
}
div.hasErrors.input.datepickerError img.ui-datepicker-trigger,
table.hasErrors.input.datepickerError img.ui-datepicker-trigger,
div.hasWarnings.input.datepickerError img.ui-datepicker-trigger,
table.hasWarnings.input.datepickerError img.ui-datepicker-trigger,
div.hasErrors.input.datepickerError div.clockpicker-trigger,
table.hasErrors.input.datepickerError div.clockpicker-trigger,
div.hasWarnings.input.datepickerError div.clockpicker-trigger,
table.hasWarnings.input.datepickerError div.clockpicker-trigger {
  position: absolute;
  top: 0;
  right: 0;
  border-left: 0;
}
div.hasErrors.input.datepickerError img.ui-datepicker-trigger:hover,
table.hasErrors.input.datepickerError img.ui-datepicker-trigger:hover,
div.hasWarnings.input.datepickerError img.ui-datepicker-trigger:hover,
table.hasWarnings.input.datepickerError img.ui-datepicker-trigger:hover,
div.hasErrors.input.datepickerError div.clockpicker-trigger:hover,
table.hasErrors.input.datepickerError div.clockpicker-trigger:hover,
div.hasWarnings.input.datepickerError div.clockpicker-trigger:hover,
table.hasWarnings.input.datepickerError div.clockpicker-trigger:hover {
  border-left: 0;
}
div.hasErrors.input.datepickerError::after,
table.hasErrors.input.datepickerError::after,
div.hasWarnings.input.datepickerError::after,
table.hasWarnings.input.datepickerError::after {
  right: 38px;
  top: 0;
}
div.hasErrors.input.checkboxError::after,
table.hasErrors.input.checkboxError::after,
div.hasWarnings.input.checkboxError::after,
table.hasWarnings.input.checkboxError::after {
  right: 5px;
}
div.hasErrors.input::after,
table.hasErrors.input::after,
div.hasWarnings.input::after,
table.hasWarnings.input::after {
  right: 1px;
  top: 1px;
}
div.hasErrors.select::after,
table.hasErrors.select::after,
div.hasWarnings.select::after,
table.hasWarnings.select::after {
  right: 25px;
  top: 1px;
}
div.hasErrors.section.item::after,
table.hasErrors.section.item::after,
div.hasWarnings.section.item::after,
table.hasWarnings.section.item::after,
div.hasErrors.div.item::after,
table.hasErrors.div.item::after,
div.hasWarnings.div.item::after,
table.hasWarnings.div.item::after {
  visibility: visible;
}
div.hasErrors.section.blockContainer,
table.hasErrors.section.blockContainer,
div.hasWarnings.section.blockContainer,
table.hasWarnings.section.blockContainer,
div.hasErrors.div.blockContainer,
table.hasErrors.div.blockContainer,
div.hasWarnings.div.blockContainer,
table.hasWarnings.div.blockContainer,
div.hasErrors.section.block,
table.hasErrors.section.block,
div.hasWarnings.section.block,
table.hasWarnings.section.block,
div.hasErrors.div.block,
table.hasErrors.div.block,
div.hasWarnings.div.block,
table.hasWarnings.div.block {
  margin-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}
div.hasErrors.section.blockContainer > *,
table.hasErrors.section.blockContainer > *,
div.hasWarnings.section.blockContainer > *,
table.hasWarnings.section.blockContainer > *,
div.hasErrors.div.blockContainer > *,
table.hasErrors.div.blockContainer > *,
div.hasWarnings.div.blockContainer > *,
table.hasWarnings.div.blockContainer > *,
div.hasErrors.section.block > *,
table.hasErrors.section.block > *,
div.hasWarnings.section.block > *,
table.hasWarnings.section.block > *,
div.hasErrors.div.block > *,
table.hasErrors.div.block > *,
div.hasWarnings.div.block > *,
table.hasWarnings.div.block > * {
  padding-left: 0;
}
div.hasErrors.section.blockContainer > *:last-child,
table.hasErrors.section.blockContainer > *:last-child,
div.hasWarnings.section.blockContainer > *:last-child,
table.hasWarnings.section.blockContainer > *:last-child,
div.hasErrors.div.blockContainer > *:last-child,
table.hasErrors.div.blockContainer > *:last-child,
div.hasWarnings.div.blockContainer > *:last-child,
table.hasWarnings.div.blockContainer > *:last-child,
div.hasErrors.section.block > *:last-child,
table.hasErrors.section.block > *:last-child,
div.hasWarnings.section.block > *:last-child,
table.hasWarnings.section.block > *:last-child,
div.hasErrors.div.block > *:last-child,
table.hasErrors.div.block > *:last-child,
div.hasWarnings.div.block > *:last-child,
table.hasWarnings.div.block > *:last-child {
  padding-bottom: 0;
}
div.hasErrors.section.blockContainer::after,
table.hasErrors.section.blockContainer::after,
div.hasWarnings.section.blockContainer::after,
table.hasWarnings.section.blockContainer::after,
div.hasErrors.div.blockContainer::after,
table.hasErrors.div.blockContainer::after,
div.hasWarnings.div.blockContainer::after,
table.hasWarnings.div.blockContainer::after,
div.hasErrors.section.block::after,
table.hasErrors.section.block::after,
div.hasWarnings.section.block::after,
table.hasWarnings.section.block::after,
div.hasErrors.div.block::after,
table.hasErrors.div.block::after,
div.hasWarnings.div.block::after,
table.hasWarnings.div.block::after {
  visibility: visible;
}
div.hasErrors.tabulation,
table.hasErrors.tabulation,
div.hasWarnings.tabulation,
table.hasWarnings.tabulation {
  display: table;
  outline: 1px solid #9d9d9d;
}
div.hasErrors input,
table.hasErrors input,
div.hasErrors input[type=text],
table.hasErrors input[type=text],
div.hasErrors select,
table.hasErrors select,
div.hasErrors textarea,
table.hasErrors textarea,
div.hasErrors.triggerBox,
table.hasErrors.triggerBox,
div.hasErrors .emojionearea,
table.hasErrors .emojionearea,
div.hasErrors input:active,
table.hasErrors input:active,
div.hasErrors input[type=text]:active,
table.hasErrors input[type=text]:active,
div.hasErrors select:active,
table.hasErrors select:active,
div.hasErrors textarea:active,
table.hasErrors textarea:active,
div.hasErrors.triggerBox:active,
table.hasErrors.triggerBox:active,
div.hasErrors .emojionearea:active,
table.hasErrors .emojionearea:active,
div.hasErrors input:focus,
table.hasErrors input:focus,
div.hasErrors input[type=text]:focus,
table.hasErrors input[type=text]:focus,
div.hasErrors select:focus,
table.hasErrors select:focus,
div.hasErrors textarea:focus,
table.hasErrors textarea:focus,
div.hasErrors.triggerBox:focus,
table.hasErrors.triggerBox:focus,
div.hasErrors .emojionearea:focus,
table.hasErrors .emojionearea:focus,
div.hasErrors input:hover,
table.hasErrors input:hover,
div.hasErrors input[type=text]:hover,
table.hasErrors input[type=text]:hover,
div.hasErrors select:hover,
table.hasErrors select:hover,
div.hasErrors textarea:hover,
table.hasErrors textarea:hover,
div.hasErrors.triggerBox:hover,
table.hasErrors.triggerBox:hover,
div.hasErrors .emojionearea:hover,
table.hasErrors .emojionearea:hover {
  box-shadow: 0 0 4px 0 var(--color--feedback--error);
}
div.hasErrors:not(.triggerBox) svg,
table.hasErrors:not(.triggerBox) svg,
div.hasErrors:not(.triggerBox) svg:active,
table.hasErrors:not(.triggerBox) svg:active,
div.hasErrors:not(.triggerBox) svg:focus,
table.hasErrors:not(.triggerBox) svg:focus,
div.hasErrors:not(.triggerBox) svg:hover,
table.hasErrors:not(.triggerBox) svg:hover {
  box-shadow: 0 0 4px 0 var(--color--feedback--error);
  border: 1px solid var(--color--feedback--error);
}
div.hasErrors input,
table.hasErrors input,
div.hasErrors input[type=text],
table.hasErrors input[type=text],
div.hasErrors select,
table.hasErrors select,
div.hasErrors textarea,
table.hasErrors textarea,
div.hasErrors .inlineckeditor,
table.hasErrors .inlineckeditor,
div.hasErrors.triggerBox,
table.hasErrors.triggerBox,
div.hasErrors .emojionearea,
table.hasErrors .emojionearea,
div.hasErrors input:active,
table.hasErrors input:active,
div.hasErrors input[type=text]:active,
table.hasErrors input[type=text]:active,
div.hasErrors select:active,
table.hasErrors select:active,
div.hasErrors textarea:active,
table.hasErrors textarea:active,
div.hasErrors .inlineckeditor:active,
table.hasErrors .inlineckeditor:active,
div.hasErrors.triggerBox:active,
table.hasErrors.triggerBox:active,
div.hasErrors .emojionearea:active,
table.hasErrors .emojionearea:active,
div.hasErrors input:focus,
table.hasErrors input:focus,
div.hasErrors input[type=text]:focus,
table.hasErrors input[type=text]:focus,
div.hasErrors select:focus,
table.hasErrors select:focus,
div.hasErrors textarea:focus,
table.hasErrors textarea:focus,
div.hasErrors .inlineckeditor:focus,
table.hasErrors .inlineckeditor:focus,
div.hasErrors.triggerBox:focus,
table.hasErrors.triggerBox:focus,
div.hasErrors .emojionearea:focus,
table.hasErrors .emojionearea:focus,
div.hasErrors input:hover,
table.hasErrors input:hover,
div.hasErrors input[type=text]:hover,
table.hasErrors input[type=text]:hover,
div.hasErrors select:hover,
table.hasErrors select:hover,
div.hasErrors textarea:hover,
table.hasErrors textarea:hover,
div.hasErrors .inlineckeditor:hover,
table.hasErrors .inlineckeditor:hover,
div.hasErrors.triggerBox:hover,
table.hasErrors.triggerBox:hover,
div.hasErrors .emojionearea:hover,
table.hasErrors .emojionearea:hover {
  border: 1px solid var(--color--feedback--error);
  outline: 0;
}
div.hasErrors.input.datepickerError img.ui-datepicker-trigger,
table.hasErrors.input.datepickerError img.ui-datepicker-trigger,
div.hasErrors.input.datepickerError div.clockpicker-trigger,
table.hasErrors.input.datepickerError div.clockpicker-trigger {
  box-shadow: 1px 0 3px 0 var(--color--feedback--error);
}
div.hasErrors.input.datepickerError img.ui-datepicker-trigger,
table.hasErrors.input.datepickerError img.ui-datepicker-trigger,
div.hasErrors.input.datepickerError div.clockpicker-trigger,
table.hasErrors.input.datepickerError div.clockpicker-trigger,
div.hasErrors.input.datepickerError img.ui-datepicker-trigger:hover,
table.hasErrors.input.datepickerError img.ui-datepicker-trigger:hover,
div.hasErrors.input.datepickerError div.clockpicker-trigger:hover,
table.hasErrors.input.datepickerError div.clockpicker-trigger:hover {
  border: 1px solid var(--color--feedback--error);
}
div.hasErrors::after,
table.hasErrors::after {
  border-color: transparent var(--color--feedback--error) transparent transparent;
}
div.hasErrors.section,
table.hasErrors.section,
div.hasErrors.div,
table.hasErrors.div,
div.hasErrors.div.ckeditorError .cke,
table.hasErrors.div.ckeditorError .cke,
div.hasErrors.tabulation,
table.hasErrors.tabulation {
  box-shadow: 0 0 4px 0 var(--color--feedback--error);
}
div.hasWarnings.input.datepickerError img.ui-datepicker-trigger,
table.hasWarnings.input.datepickerError img.ui-datepicker-trigger,
div.hasWarnings.input.datepickerError div.clockpicker-trigger,
table.hasWarnings.input.datepickerError div.clockpicker-trigger {
  border: 1px solid var(--color--audi--weiss);
}
div.hasWarnings.input.datepickerError img.ui-datepicker-trigger:hover,
table.hasWarnings.input.datepickerError img.ui-datepicker-trigger:hover,
div.hasWarnings.input.datepickerError div.clockpicker-trigger:hover,
table.hasWarnings.input.datepickerError div.clockpicker-trigger:hover {
  border: 1px solid var(--color--audi--weiss);
}
div.hasWarnings.section,
table.hasWarnings.section,
div.hasWarnings.div,
table.hasWarnings.div,
div.hasWarnings.div.ckeditorError .cke,
table.hasWarnings.div.ckeditorError .cke,
div.hasWarnings.tabulation,
table.hasWarnings.tabulation,
div.hasWarnings.triggerBox,
table.hasWarnings.triggerBox {
  box-shadow: 0 0 4px 1px var(--color--audi--mittel-grau);
}
div.hasWarnings::after,
table.hasWarnings::after {
  border-color: transparent var(--color--audi--mittel-grau) transparent transparent;
}
fieldset[bas-form-error]:not([ng-reflect-error-ref]).hasErrors,
fieldset[bas-form-error]:not([ng-reflect-error-ref]).hasErrors:active,
fieldset[bas-form-error]:not([ng-reflect-error-ref]).hasErrors:focus,
fieldset[bas-form-error]:not([ng-reflect-error-ref]).hasErrors:hover {
  box-shadow: 0 0 4px 0 var(--color--feedback--error);
  border: 1px solid var(--color--feedback--error);
  outline: 0;
}
fieldset[bas-form-error]:not([ng-reflect-error-ref]).hasErrors::after {
  width: 0;
  height: 0;
  border-style: solid;
  display: inline;
  border-width: 0 10px 10px 0;
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  border-color: transparent var(--color--feedback--error) transparent transparent;
}
.jahresbudget.negative-budget,
.restbudget.negative-budget,
.jahresbudget .negative-budget,
.restbudget .negative-budget {
  color: var(--color--feedback--error);
}
.jahresbudget.positive-budget,
.restbudget.positive-budget,
.jahresbudget .positive-budget,
.restbudget .positive-budget {
  color: var(--color--feedback--success);
}
.webtm .webtm-attributeTupel {
  display: table;
  padding: 5px 0;
  width: 100%;
}
.webtm .webtm-attributeTupel label {
  width: 150px;
}
.webtm .webtm-attributeTupel > * {
  display: table-cell;
}
.webtm div.bas-formBox,
.webtm .field {
  padding: 0 0 0 0;
  margin: 5px 0 5px 10px;
}
.webtm #editMaskContainer div.bas-formBox {
  position: relative;
}
.webtm #editMaskContainer .bas-formSection .hover {
  background: var(--color--audi--licht-grau-30);
  box-shadow: 0px -1px 0 4px var(--color--audi--licht-grau-30);
}
.webtm #editMaskContainer .bas-formSection .hover > *:not(.webtm-iconContainer) {
  opacity: 0.5;
}
.webtm #editMaskContainer .bas-formSection .locked {
  background: var(--input--background-color--disabled);
  box-shadow: 0px -1px 0 4px var(--input--background-color--disabled);
}
.webtm #editMaskContainer .bas-formSection .locked > *:not(.webtm-iconContainer) {
  opacity: 0.5;
}
.webtm #editMaskContainer .bas-formSection .locked:not(.hover) .item.horizontal.ym-clearfix.webtm-iconContainer {
  display: block;
}
.webtm #editMaskContainer .bas-formSection .locked:not(.hover) .item.horizontal.ym-clearfix.webtm-iconContainer .edit_small {
  display: none;
}
.webtm #editMaskContainer .bas-formSection .item.horizontal.ym-clearfix.webtm-iconContainer {
  display: none;
  position: absolute;
  top: -2px;
  right: -2px;
  transition: all 0.1s linear;
  width: auto;
}
.webtm.dialogWrapper {
  overflow-y: hidden;
  overflow-x: hidden;
}
.webtm.dialogWrapper input[type=text],
.webtm.dialogWrapper input[type=password],
.webtm.dialogWrapper select:not([size]),
.webtm.dialogWrapper textarea,
.webtm.dialogWrapper .inlineckeditor {
  margin-bottom: 0;
  width: 346px;
}
.webtm.dialogWrapper input[type="file"] {
  display: block;
}
.webtm.dialogWrapper input[type=checkbox] {
  vertical-align: bottom;
}
.webtm.dialogWrapper div.inputSeparator {
  margin-bottom: 5px;
}
.webtm.dialogWrapper div.inputSeparator.last {
  display: none;
}
.webtm .dialogHeader .destroyDialog {
  right: 0;
}
*[data-webtmid].hover .webtm-iconContainer {
  display: block ! important;
}
.childrenAttrEditArea {
  display: inline-block;
}
.childrenAttrEditArea > * {
  display: block;
}
.chosen-container-single .chosen-single,
.chosen-container-multi .chosen-single,
.chosen-container-single .chosen-choices,
.chosen-container-multi .chosen-choices {
  font-size: 13px;
  line-height: 16px;
  border: 1px solid var(--color--layout--visual);
  border-radius: 0;
  background: var(--color--audi--weiss);
  box-shadow: none;
  color: var(--color--audi--schwarz);
}
.chosen-container-single .chosen-single div b,
.chosen-container-multi .chosen-single div b,
.chosen-container-single .chosen-choices div b,
.chosen-container-multi .chosen-choices div b {
  background: var(--color--audi--weiss) url("./customer/audi/img/forms/liste-pfeil.png") right center no-repeat;
}
.chosen-container-single .chosen-single > span,
.chosen-container-multi .chosen-single > span,
.chosen-container-single .chosen-choices > span,
.chosen-container-multi .chosen-choices > span {
  min-height: 18px;
  margin-right: 0;
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 2px;
}
.chosen-container-single .chosen-single li.search-field input[type="text"],
.chosen-container-multi .chosen-single li.search-field input[type="text"],
.chosen-container-single .chosen-choices li.search-field input[type="text"],
.chosen-container-multi .chosen-choices li.search-field input[type="text"] {
  padding-left: 3px;
  height: 21px;
}
.chosen-container-single .chosen-single li.search-choice,
.chosen-container-multi .chosen-single li.search-choice,
.chosen-container-single .chosen-choices li.search-choice,
.chosen-container-multi .chosen-choices li.search-choice {
  line-height: 17px;
  padding: 0 20px 0 5px;
  margin: 2px 0 0 3px;
  color: var(--color--audi--schwarz);
  background: var(--color--audi--weiss);
  border-radius: 0;
}
.chosen-container-single .chosen-single li.search-choice span,
.chosen-container-multi .chosen-single li.search-choice span,
.chosen-container-single .chosen-choices li.search-choice span,
.chosen-container-multi .chosen-choices li.search-choice span {
  line-height: 17px;
  margin-right: 1px;
}
.chosen-container-single .chosen-single li.search-choice:hover,
.chosen-container-multi .chosen-single li.search-choice:hover,
.chosen-container-single .chosen-choices li.search-choice:hover,
.chosen-container-multi .chosen-choices li.search-choice:hover {
  border-color: var(--color--audi--rot);
  color: var(--color--audi--rot);
}
.chosen-container-single .chosen-single li.search-choice:hover .search-choice-close,
.chosen-container-multi .chosen-single li.search-choice:hover .search-choice-close,
.chosen-container-single .chosen-choices li.search-choice:hover .search-choice-close,
.chosen-container-multi .chosen-choices li.search-choice:hover .search-choice-close {
  background-position: -37px 1px;
  background-color: var(--color--audi--rot);
  border-color: var(--color--audi--rot);
}
.chosen-container-single .chosen-single li.search-choice .search-choice-close,
.chosen-container-multi .chosen-single li.search-choice .search-choice-close,
.chosen-container-single .chosen-choices li.search-choice .search-choice-close,
.chosen-container-multi .chosen-choices li.search-choice .search-choice-close {
  width: 16px;
  height: 17px;
  top: 0;
  right: 0;
  background: var(--color--audi--schwarz) url("customer/audi/css/plugins/chosen/chosen-sprite-audi.png") -37px 1px no-repeat;
}
.chosen-container-single .chosen-drop,
.chosen-container-multi .chosen-drop {
  border-radius: 0;
  box-shadow: none;
  border: 1px solid var(--color--layout--visual);
}
.chosen-container-single .chosen-single {
  padding: 0 0 0 3px;
}
.chosen-container-multi .chosen-choices {
  padding: 0;
}
.chosen-container-multi .chosen-drop {
  border-top: none;
}
.chosen-container-active .chosen-single > span {
  color: var(--color--audi--rot);
}
.chosen-container-active.chosen-with-drop .chosen-single {
  background-image: none;
  border: 1px solid var(--color--layout--visual);
}
.chosen-container-active.chosen-with-drop .chosen-single div b {
  background-position: right center;
}
.chosen-container-active.chosen-with-drop .chosen-single .result-selected {
  color: var(--color--audi--rot);
}
.chosen-container-active.chosen-with-drop .chosen-drop input {
  border: 1px solid var(--color--layout--visual);
}
.chosen-container-active.chosen-with-drop .chosen-results {
  margin: 0 0 0 0;
  color: var(--color--font--primary);
}
.chosen-container-active.chosen-with-drop .chosen-results li.no-results {
  color: var(--color--audi--schwarz);
  display: list-item;
  background: var(--color--audi--weiss);
}
.chosen-container .chosen-results {
  margin: 0 4px 2px 0;
  padding: 0 0 0 5px;
}
.chosen-container .chosen-results li {
  padding: 2px 0;
  white-space: nowrap;
}
.chosen-container .chosen-results li.highlighted {
  background: var(--color--audi--weiss);
  color: var(--color--audi--rot);
}
.chosen-container .chosen-results li.disabled-result {
  color: var(--color--font--secondary);
}
.chosen-container .chosen-results li mark {
  color: inherit;
  background-color: inherit;
  font-weight: bold;
}
select:empty {
  display: block ! important;
  visibility: hidden;
  height: 0;
}
.chosen-container {
  position: relative;
  max-width: 100%;
}
.chosen-container .chosen-drop {
  z-index: 10100;
}
.chosen-disabled {
  opacity: 1 !important;
}
.chosen-disabled .chosen-single {
  background: var(--color--audi--hell-grau-30);
  border: 1px solid var(--color--audi--licht-grau);
}
.chosen-disabled .chosen-single span {
  color: var(--input--font-color--disabled);
}
