@import '../utilities/lib';
@import "jstree";

.jstree-default.jstree-focused {
	background: @LichtGrau30 ! important;
}

.jstree-hovered , .jstree-default .jstree-clicked {
	background-color: @PrimaryCiColor ! important;
	color: @AudiWeiss ! important;
	border: 1px solid @PrimaryCiColor ! important;
}

.jstree {
	margin-right: 20px;
}

#vakata-contextmenu.jstree-default-context {
	ul li {
		> a {
			text-align: left;
		}

		ins.jsTreeIcon {
			display: none;
		}
		&.jstree-contextmenu-disabled {
			a,  a:hover {
				padding: 1px 6px;
			}
		}
	}
}