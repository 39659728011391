@import '../utilities/lib.less';
div.dialog {
	text-align: left;
	div.dialogWrapper {
		background: @AudiWeiss;
		background: -moz-linear-gradient(top, @AudiWeiss 0%, @VisualLayoutingColor 100%);
		background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, @AudiWeiss), color-stop(100%, @VisualLayoutingColor));
		background: -webkit-linear-gradient(top, @AudiWeiss 0%, @VisualLayoutingColor 100%);
		background: -o-linear-gradient(top, @AudiWeiss 0%, @VisualLayoutingColor 100%);
		background: -ms-linear-gradient(top, @AudiWeiss 0%, @VisualLayoutingColor 100%);
		background: linear-gradient(to bottom, @AudiWeiss 0%, @VisualLayoutingColor 100%);
		filter: ~"progid:DXImageTransform.Microsoft.gradient( startColorstr='@{AudiWeiss}', endColorstr='@{VisualLayoutingColor}',GradientType=0 )";
		&.previewSwitch {
			height: 95%;
			filter: ~"progid:DXImageTransform.Microsoft.gradient( startColorstr='@{AudiWeiss}', endColorstr='@{AudiWeiss}',GradientType=0 )";
			.dialogContainer {
				margin-top: 0;
				background: transparent;
			}

			.dialogContent {
				margin-top: 0;
				.tabContentContainer {
					top: 124px;
					border-top: 1px solid @VisualLayoutingColor;
				}
			}
		}

		.dialogHeader {
			h3 {
				margin: 32px 40px 0 25px;
			}

			div.closeDialog , div.destroyDialog {
				right: 0;
				top: 0;
				position: absolute;
				width: 32px;
				height: 32px;
				> .iconContainer {
					background: url("../../img/icons/layer-schliessen-passiv.png") 0 0 no-repeat;
					width: 32px;
					height: 32px;
					border-radius: 0;
					border: none;
				}

				&:hover > .iconContainer {
					background: url("../../img/icons/layer-schliessen-rollover.png") 0 0 no-repeat;
				}
			}
		}

		&.browsersupportDialog {
			.dialogContainer {
				padding: 0 40px 0 0;
				.dialogContent {
					margin-top: 15px;
					.browserSupportScreenContent {
						.left {
							margin-left: 0;
							height: 76px;
							img {
								top: 0;
							}
						}

						.right {
							width: 360px;
							margin-top: 0;
							padding-right: 0;
							.downloadList {
								list-style: none;
							}
						}
					}
				}
			}

			.dialogFooter {
				padding: 25px 25px 20px;
				background: transparent;
				.dialogButton {
					position: relative;
					.defaultButton ();
					&.highlight {
						.defaultButtonHighlight ();
					}
				}
			}
		}
	}

	&.newsletterPreview {
		width: 900px;
		div.dialogContainer h3 {
			margin-top: 0;
		}

		.dialogContent {
			top: 60px;
		}
	}
	
	.dialogButtonline .bas-inputButton {
		display: inline-block;
	}
	div.closeDialog , div.destroyDialog {
		&.button {
			padding-right: 12px;
		}
	}
}

div.dialogContainer {
	padding: 0 40px 20px 25px;
	background: transparent;
	filter: none;
	border: none;
	float: none;
	span {
		font-family: @AudiTypeNormal;
		font-weight: normal;
		white-space: normal;
	}

	span.bold , .tabulation td span {
		font-family: @AudiTypeBold;
	}

	table.tabulation {
		tr.even span , tr.odd span {
			white-space: nowrap;
		}
	}

	.tabRowContainer {
		> div.tab {
			border: none;
			background: #000;
			color: #fff;
			border-radius: 0;
			font-family: @AudiTypeExtendedNormal;
			font-size: 11px;
			height: 30px;
			line-height: 30px;
			margin: 0 1px 0 0;
			display: inline-block;
			padding: 5px 10px;
			cursor: pointer;
			&:hover , &.active:hover {
				background: @InteractiveBackgroundColorHover;
			}

			&.active {
				font-family: @AudiTypeExtendedBold;
				color: #000;
				background: #fff;
				border: 1px solid @VisualLayoutingColor;
				border-bottom: none;
				cursor: default;
				&:hover {
					background: #fff;
				}
			}
		}
	}

	.tabContentContainer {
		border-top: 1px solid @VisualLayoutingColor;
		margin-top: -1px;
		padding-top: 10px;
		position: absolute;
		top: 108px;
		bottom: 30px;
		left: 30px;
		right: 30px;
	}

	.dialogContent {
		.item.horizontal > .itemBox {
			padding-right: 0;
		}

		.field {
			&.noLabel {
				display: block;
				padding-left: 0;
			}

			&.buttonline {
				padding: 15px 0 0 0;
				.button {
					margin-bottom: 0;
				}
			}
		}
	}
}

div.dialogWrapper {
	position: fixed;
	table.tabulation {
		tr.even span , tr.odd span {
			white-space: nowrap;
		}
	}

	&.newsletterPreview {
		width: 830px;
		height: 80%;
		background: #fff;
		overflow: hidden;
		.destroyDialog {
			right: 0;
		}

		div.dialogContainer {
			float: none;
			background: #fff;
			filter: none;
			border: none;
			padding: 0 30px 30px 30px;
			height: 100%;
			div.dialogContent {
				bottom: 30px;
				left: 30px;
				position: absolute;
				right: 30px;
				top: 40px;
			}

			h3 {
				margin-top: 30px;
			}
		}
	}

	&.previewSwitch {
		width: 1150px;
		height: 80%;
		background: #fff;
		overflow: hidden;
		filter: none;
		&.single {
			.dialogContent {
				position: absolute;
				bottom: 30px;
				top: 50px;
				left: 30px;
				right: 30px;
			}
		}

		.destroyDialog {
			right: 0;
		}

		iframe {
			border: 0;
		}

		div.dialogHeader {
			float: none;
			min-height: 20px;
			margin-bottom: 15px;
			margin-top: 32px;
		}

		div.dialogContainer {
			float: none;
			background: #fff;
			filter: none;
			border: none;
			padding: 0 30px 30px 30px;
		}

		.tabs-list {
			> li {
				border: none;
				background: #000;
				color: #fff;
				border-radius: 0;
				font-size: 11px;
				height: 30px;
				line-height: 30px;
				margin: 0 1px 0 0;
				display: inline-block;
				padding: 5px 10px;
				cursor: pointer;
				&.current {
					color: #000;
					background: #fff;
					cursor: default;
					&:hover {
						background: #fff;
					}
				}
			}
		}

		.tabbody {
			margin-top: -1px;
			padding-top: 10px;
			.iframePreviewContainer {
				position: relative;
				overflow: auto;
				height: 100%;
				> div {
					position: relative;
					display: block;
					> iframe , > div.image {
						border: 0;
						position: absolute;
					}
				}

				iframe {
					background: #fff;
					border: 1px solid @VisualLayoutingColor;
					box-sizing: border-box;
				}

				&.tablet {
					div.image {
						display: none;
					}
					
					iframe {
						width: 1024px;
						height: 500px;
						top: 0;
					}

					object {
						width: 1024px;
					}
				}

				&.mobile {
					div.image {
						height: 745px;
						width: 414px;
						background: transparent url("../../img/preview/preview_mobile.png") no-repeat;
					}
					
					iframe {
						width: 346px;
						height: 475px;
						top: 139px;
						.transformScale (1.0);
					}

					object {
						width: 370px;
						height: 490px;
						border: 1px solid @VisualLayoutingColor;
					}
				}
			}
		}
	}

	&.browsersupportDialog {
		width: 550px;
		.dialogFooter {
			padding: 30px;
			padding-bottom: 10px;
		}

		.dialogContainer {
			overflow: auto;
			.dialogContent {
				.browserSupportScreenContent {
					.container {
						&.left {
							width: 150px;
							text-align: center;
							height: 150px;
							margin-left: 30px;
							float: left;
							img {
								top: 40px;
								position: relative;
							}
						}

						&.right {
							position: relative;
							width: 350px;
							padding-right: 40px;
							margin-top: 40px;
							box-sizing: border-box;
							float: right;
							.headline {
								margin-bottom: 15px;
							}

							.downloadList {
								margin-top: 20px;
								margin-bottom: 0;
							}
						}
					}
				}
			}
		}
	}

	.dialogFooter {
		background: #fff;
	}
}