@import '../utilities/lib.less';

.reportList {
  margin-bottom: 10px;
  padding-top: 25px;
  span {
    margin-left: 15px;
  }

  .descriptionKey {
    margin-bottom: 7px;
    margin-top: 7px;
  }

  .reportItem {
    &:hover {
      background-color: @LichtGrau30;
    }

    .mainRow {
      .reportName {
        .defaultLink {
          margin-bottom: 0;
          line-height: 20px;
          background-position: 0 5px;
        }
      }

      > div {
        height: 22px;
        &.favIcon {
          margin-top: 2px;
          width: 22px;
          &:first-child {
            margin-left: 10px;
            margin-right: 15px;
          }
        }
      }
    }
  }
}

.tabbody.favorites {
  > section {
    padding: 10px;
  }
}

