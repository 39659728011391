@import '../utilities/lib';
.sliderContainer {
	box-sizing: content-box;
	ul.anythingSlider {
		list-style-type: none;
	}

	margin-bottom: 20px;
	.hideBeforeInit {
		display: none;
	}

	.anythingSlider-default {
		&.activeSlider .anythingWindow {
			border: none;
			.panel {
				.sliderItemContainer {
					margin: 20px;
					border: 1px solid @LichtGrau;
					width: 90% ! important;
					height: 90% ! important;
					overflow: hidden;
				}
			}
		}

		.arrow {
			top: 115px;
			a {
				display: block;
				width: 34px;
				height: 210px;
				text-align: center;
				outline: 0;
				background: url('../../img/slider/slider_arrows.png') no-repeat;
			}

			&.back {
				&:hover a {
					background-position: 0px -210px;
				}
			}

			&.forward {
				a {
					background-position: -34px 0;
				}

				&:hover a {
					background-position: -34px -210px;
				}
			}
		}

		.anythingControls {
			display: block;
			.start-stop {
				display: none;
			}

			.thumbNav {
				position: absolute;
				top: -15px;
				left: 66px;
				li {
					position: relative;
				}
				a {
					border-radius: 0;
					background: @AudiWeiss;
					border: 2px solid @LichtGrau;
					width: 10px;
					height: 10px;
					padding: 0;
					&:hover, &.hover {
						border: 2px solid @AudiRot;
					}

					&.cur {
						background: @AudiRot;
						border: 2px solid @AudiRot;
					}

					span {
						display: none;
					}
				}
			}
		}
	}
}

.thumbnail {
	width: 100px;
	position: absolute;
	top: 7px;
	left: 7px;
	display: none;
	padding: 5px;
	background-color: @AudiWeiss;
	border: 1px solid @LichtGrau;
	z-index: 200;
	cursor: pointer;
	img {
		vertical-align: middle;
		height: 100%;
		width: 100%;
	}
}

.lt-ie9 .sliderContainer .anythingSlider-default .arrow {
	top: 45px;
}

