@import '../../utilities/lib.less';
#editMask.newsletter {
	#title span.subtitle_normal {
		font-family: @AudiTypeExtendedBold;
		font-size: 13px;
		margin: 10px 0;
		display: inline-block;
	}

	&.nltemplate .bas-formSection {
		.inlineckeditor.cols3 {
			width: 317px ! important;
			max-width: 317px ! important;
			&.nolockable {
				width: 363px ! important;
				max-width: 363px ! important;
			}
		}
	}

	&.nluser .bas-formSection {
		.inlineckeditor.cols3 {
			width: 363px ! important;
			max-width: 363px ! important;
		}
	}

	.bas-formSection {
		.item.horizontal.ym-clearfix {
			display: table;
			width: 100%;
			.itemBox {
				float: none;
				display: table-cell;
				vertical-align: top;
				&.next-element-is-tooltip {
					width: 100%;
				}
				input[type=text] , .inlineckeditor {
					&.cols1 {
						width: 70px ! important;
					}

					&.cols12 {
						width: 285px ! important;
					}

					&.cols_tele_1 {
						width: 35px ! important;
					}

					&.cols_tele_2 , &.cols_tele_4 {
						width: 55px ! important;
					}
				}

				.bas-inputCheck , .bas-inputCheck .lockcheckbox {
					padding-right: 0;
					margin-right: 0;
				}

				.bas-inputCheck .lockcheckbox {
					background-position: left top;
					min-width: 26px;
				}

				select.tyre-labelling-select-filter {
					width: 168px ! important;
				}
			}

			.item.vertical .itemBox {
				display: block;
			}
		}

		&:first-child .groupSeparator {
			margin-top: 0;
		}

		div.cols3 , input[type=text].cols3 , select.cols3 , textarea.cols3 {
			width: 100% ! important;
		}

		div.urlCheckerContainer.cols3 {
			width: @ColsInput3 ! important;
			padding-right: 0 ! important;
		}

		.footnoteIndex {
			+ .itemBox .bas-inputText {
				width: 300px;
			}

			~ .itemBox a {
				margin-left: 5px;
				padding: 0;
			}
		}

		&.ym-clearfix , .item.vertical .itemBox , .item.horizontal .itemBox {
			select.selectCols3 {
				width: 360px ! important;
			}
		}
	}

	.footnoteIndex > sup {
		width: 12px;
		display: inline-table;
	}

	div.radio {
		padding-right: 24px;
		width: auto;
		position: relative;
		height: 18px;
	}

	.hasUrlChecker {
		display: inline-block;
	}

	select.procurationSelection , input[type=text].behind-checkbox {
		width: 336px ! important;
	}

	select.co2selection + div.selectBox {
		margin-bottom: 5px;
	}

	.cke {
		width: 100% ! important;
	}

	div.charcounter {
		display: inline-block ! important;
		text-align: right;
		padding-right: 0;
	}

	img.tyrelabelling {
		position: absolute;
		height: 20px;
		margin-left: 5px;
	}
}

