@import '../utilities/lib.less';
section.filter {
	padding-bottom: 5px;
	display: flex;
	flex-wrap: wrap;
	&:before, &:after {
		content: none;
	}
}

div.panel.smartFilter .panelContent {
	width: 100%;
	box-sizing: border-box;
	.filter {
		margin: 0;
		input.hasDatepicker {
			width: 94px;
		}
		
		.bas-inputArea {
			width: auto;
		}

		.filterGroup.button {
			background: transparent;
			bottom: 20px;
			left: -2px;
			cursor: default;
		}

		.toggleButtonContainer {
			background: transparent;
		}
		
		.filterGroup.separator {
			border-top: 1px solid @HellGrau30;
		}

		&:not(:first-of-type) {
			margin-left: 20px;
			&:before {
				content: '';
				height: 100%;
				width: 1px;
				position: absolute;
				left: -10px;
				top: 0;
				background-color: @HellGrau30
			}
		}

		&[data-active] , &:hover {
			box-shadow: 0 0 4px 0 @InteractiveBackgroundColorHighlight;
		}

		a[data-toggle-target] span {
			line-height: 18px;
		}
	}
}

.fullWidth{
	.bas-filterComponent {
		width: 100%;
		&.forceNewLine {
			width: 100%;
		}
		&.separateLine {
			width: 100%;
		}
	}
}

.bas-filterComponent {
	padding: 10px 10px 0 10px;
	width: 50%;
	box-sizing: border-box;
	display: flex;
	&.forceNewLine {
		width: 925px;
		border-top: 1px solid @VisualLayoutingColor;
		margin-top: 10px;
		padding-top: 10px;
	}
	&.separateLine {
		width: 940px;
	}

	label , .bas-inputCheck , .bas-inputSelect , .bas-inputText, .bas-checkboxSubContainer {
		display: flex;
		flex-wrap: wrap;
	}

	div.bas-inputSelect div.selectBox {
		padding-top: 5px;
	}

	.selectBox {
		position: absolute;
	}

	label ~ label {
		padding-left: 23px;
		width: 163px;
		min-width: 163px;
	}

	.bas-checkboxSubContainer .bas-inputCheck {
		display: flex !important;
		>*:not(.tooltip){
			display: initial;
		}
		label {
			padding-right: 0;
			width: 120px;
			min-width: 120px;
		}
	}
}

.bas-filterComponent label,
div.searchmask div.bas-label {
	width: 140px;
	padding-right: 20px;
	min-width: 140px;
}

.searchButtonPosition {
	flex-direction: row-reverse;
}

.admin .bas-filterComponent .selectBox {
	background-color: @LichtGrau30;
}

div.searchmask {
	.bas-inputArea {
		margin-left: 140px;
		&.horizontal > .cols1:first-child + .cols1 {
			padding-right: 20px;
			width: 172px;
			+ .cols1 {
				width: 141px;
			}
		}
	}

	.item.horizontal .itemBox {
		padding: 0;
		.bas-inputCheck {
			margin: 0;
			padding: 0 10px 0 0;
			height: 20px;
			.checkbox {
				padding: 0;
			}
		}
	}

	div.buttonline {
		text-align: right;
	}

	.bas-formBox div.cols1 + div.cols1 {
		vertical-align: bottom;
	}
}
