@import '../../utilities/lib';

.preview-highlight {
	outline: solid 1px @PrimaryCiColor ! important;
	&:hover {
		cursor: pointer;
	}
}

.rectanglesActive {
	outline: solid 1px @PrimaryCiColor;
}

