@import '../../utilities/lib';
.calendar-div {
	font-family: @AudiTypeBold;
	font-size: 13px;
	color: @PrimaryFontColor;
	background-color: @DefaultBackgroundColor;
	border: 1px solid @HellGrau60;
	width: 255px;
	padding: 0;
	line-height: 34px;
	display: table;
	position: relative;
	.weekOfYearHeader , .weekOfYear {
		color: @HellGrau60;
	}

	.weekOfYear {
		font-family: @AudiTypeNormal;
		border: none;
	}

	.calendar-title {
		text-align: center;
		margin-top: 2px;
	}

	a.calendar-next , a.calendar-prev {
		margin: 5px;
		width: 24px;
		height: 24px;
		padding: 0;
		background-repeat: no-repeat;
		position: absolute;
	}

	a.calendar-next {
		background-image: url("../../../img/kalender/vor.png");
		right: 0;
		&:hover {
			background-image: url("../../../img/kalender/vor-rollover.png");
		}
	}

	a.calendar-prev {
		background-image: url("../../../img/kalender/zurueck.png");
		&:hover {
			background-image: url("../../../img/kalender/zurueck-rollover.png");
		}
	}
}

table.calendar {
	margin-top: 5px;
	th , td {
		vertical-align: middle;
		text-align: center;
	}

	thead th {
		border: none;
	}

	tbody {
		background-image: url("../../../img/kalender/hg-tage.png");
		background-position: 30px 0;
		background-repeat: no-repeat;
		td {
			border-top: 1px solid @HellGrau60;
			border-left: 1px solid @HellGrau60;
			font-size: 12px;
		}

		tr.row:last-child td {
			border-bottom: 1px solid transparent;
		}
	}

	td {
		width: 30px;
		max-width: 30px;
		height: 24px;
		font-family: @AudiTypeNormal;
		.item , .itemBox , .itemBox a.defaultLink {
			width: 100%;
			height: 100%;
		}

		.itemBox {
			justify-content: center;
		}

		a.defaultLink {
			padding: 0;
			background: none;
		}

		.itemBox span , a.defaultLink span {
			font-size: 12px;
			line-height: 24px;
		}

		&.today , &.selected , &.not-selected , &.early-start-not-selected {
			border: 1px solid @InteractiveBackgroundColor ! important;
			font-family: @AudiTypeNormal;
			font-weight: bold;
		}

		&.selected , &.selected a.defaultLink , &.selected a.defaultLink span {
			color: #fff;
			background-color: @PrimaryCiColor ! important;
		}

		&.early-start-not-selected a.defaultLink span {
			color: @PrimaryCiColor;
		}

		&.selected , &.not-selected , &.early-start-not-selected {
			&:hover , &:hover a.defaultLink , &:hover a.defaultLink span , &:hover a.defaultLink span:hover {
				color: @InteractiveFontColor;
				background-color: @InteractiveBackgroundColorHover ! important;
			}
		}
	}

	td.today , tr.row:hover td.today {
		color: @InteractiveFontColor;
		background-color: @InteractiveBackgroundColor ! important;
	}

	.item.horizontal div.itemBox {
		display: inherit;
		float: none;
		padding: 0;
	}
}
