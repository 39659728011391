@import "../utilities/lib.less";

input[type="radio"], input[type="checkbox"] {
	visibility: hidden;
	position: absolute;
}

.webtm-attributeTupel {
	input[type="radio"], input[type="checkbox"] {
		visibility: visible;
		position: relative;
	}
}

.lt-ie9 {
	input[type="radio"], input[type="checkbox"] {
		visibility: visible;
		opacity: 0;
		z-index: -1;
	}

	input[type="checkbox"].dynamicTreeNodeAttribute {
		opacity: 1;
		z-index: auto;
	}
}

input[type="checkbox"].dynamicTreeNodeAttribute {
	visibility: inherit;
	position: relative;
	opacity: 1;
	margin-top: 3px;
}

input[type="text"], textarea, input[type="password"], select, .inlineckeditor {
	font-family: @AudiTypeNormal;
	border: 1px solid @VisualLayoutingColor;
	padding-top: 1px;
	padding-bottom: 1px;
}

input[type="password"] {
	font-family: "Verdana", sans-serif;
}

input[type="text"], input[type="password"] {
	height: @InputHeight;
}

div.checkbox, div.radio {
	&:focus {
		outline: 0;
	}

	background-repeat: no-repeat;
	padding-right: 10px;

	&.error {
		background-color: transparent;
	}

	&, + label {
		cursor: pointer;
	}

	&.inactive, &.inactive.checked {
		&, + label {
			color: @InputFontInactiveColor;
			cursor: default;
		}
	}
}

select.noCustomStyling {
	option {
		padding: 2px;
	}
}

div.radio {
	background-image: url("../../img/forms/radio_unchecked.png");
	background-position: left 3px;
	width: 26px;
	height: 17px;

	&.checked {
		background-image: url("../../img/forms/radio.png");
	}

	&.inactive {
		background-image: url("../../img/forms/radio_unchecked_inactive.png");
	}

	&.inactive.checked {
		background-image: url("../../img/forms/radio_inactive.png");
	}
}

div.checkbox {
	background-image: url("../../img/forms/checkbox_unchecked.png");
	background-position: 0 2px;
	width: 26px;
	height: 18px;

	&.noLabel {
		padding-right: 5px;
		width: 16px;
	}

	&.checked {
		background-image: url("../../img/forms/checkbox.png");
	}

	&.inactive {
		background-image: url("../../img/forms/checkbox_unchecked_inactive.png");
	}

	&.inactive.checked {
		background-image: url("../../img/forms/checkbox_inactive.png");
	}

	&.lockcheckbox {
		background-image: url("../../img/btn/unverschlossen.png");
		background-position: 0 0;
		height: 26px;
		width: 26px;
		padding-right: 0;
	}

	&.lockcheckbox:hover, &.lockcheckboxunchecked:hover {
		background-image: url("../../img/btn/unverschlossen-rollover.png");
		cursor: pointer;
	}

	&.order {
		height: 20px;
	}
}

div.lockcheckbox {
	&.inactive:hover, &.inactive {
		background-image: url("../../img/btn/unverschlossen-inaktiv.png");
		cursor: default;
	}

	&.inactive {
		&.checked, &.checked:hover {
			background-image: url("../../img/btn/verschlossen-inaktiv.png");
			cursor: default;
		}
	}

	&.lockcheckboxchecked, &.checked {
		background-image: url("../../img/btn/verschlossen.png");

		&:hover {
			background-image: url("../../img/btn/verschlossen-rollover.png");
			cursor: pointer;
		}
	}
}

input[type=text], input[type=password], textarea, .inlineckeditor {
	width: @ColsInput3;
}

select {
	max-height: 270px;
}

input, select, textarea, .inlineckeditor {
	&.colsQuarter {
		width: @ColsInputQuarter;
	}

	&.colsThird {
		width: @ColsInputThird;
	}

	&.colsTwoThird {
		width: @ColsInputTwoThird;
	}

	&.colsHalf {
		width: @ColsInputHalf;
	}
}

bwc-date-picker.colsTwoThird {
	// Sonderfall, da colsTwoThird zu schmal für bwc-date-picker
	width: @ColsInputTwoThird + @ColsDatepickerWidth;
}

input, select, textarea, .inlineckeditor, bwc-date-picker {

	&.cols1 {
		width: @ColsInput1
	}

	&.cols2 {
		width: @ColsInput2;
	}

	&.cols3 {
		width: @ColsInput3;
	}

	&.cols4 {
		width: @ColsInput4;
	}

	&.cols5 {
		width: @ColsInput5;
	}

	&.cols6 {
		width: @ColsInput6;
	}

	&.cols12 {
		width: @ColsInput12;
	}

	&[disabled] {
		&, &:hover, &:focus {
			color: @InputFontInactiveColor;
		}
	}
}

.ym-g66 {
	input, select, textarea, .inlineckeditor {
		&.cols3 {
			width: @ColsInput3 - 40px;

			&.mediapool-contact {
				width: @ColsInput3 - 3px;
			}
		}
	}
}

input {
	&.datepicker, &.clockpicker {
		&.cols1 {
			margin-right: 0;
			width: 88px;
		}

		&.cols2 {
			margin-right: 0;
			width: 266px;
		}

		&.cols3 {
			margin-right: 0;
			width: 428px;
		}

		&.cols4 {
			margin-right: 0;
			width: 573px;
		}

		&.cols5 {
			margin-right: 0;
			width: 738px;
		}
	}
}

div.bas-inputCheck {
	margin-right: 10px;

	&.noPadding {
		margin-right: 0;
		padding-right: 0;

		div.noPadding, input.noPadding {
			margin-right: 0;
			padding-right: 0;
		}

	}

	&:hover {
		color: @InteractiveBackgroundColorHighlightHover;
		cursor: pointer;
	}

	a.defaultLink {
		margin-left: 10px;
	}

	&.bas-lockCheckbox {
		overflow: hidden;
		width: 26px;
		height: 26px;
		display: inline-block ! important;
	}

	input {
		&[type="checkbox"], &[type="radio"] {
			&.cols1, &.cols2, &.cols3, &.cols4, &.cols5, &.cols6 {
				width: auto;
			}
		}
	}

	&.containsSVG {
		&:hover {
			svg {
				background-color: @InteractiveBackgroundColorHighlightHover;
			}
		}

		span {
			vertical-align: middle;
			line-height: 25px;
		}

		svg {
			background-color: @InteractiveBackgroundColor;
		}

		input.checkbox, input.radio {
			&[disabled] {
				~ label {
					svg {
						&, &:hover {
							background-color: @InteractiveBackgroundColorInactive;
							cursor: default;
						}
					}

					span {
						&, &:hover {
							color: @InteractiveBackgroundColorInactive;
						}
					}
				}

			}
		}
	}
}

span.fromTo {
	margin-right: 10px;
}

div.searchmask .ui-datepicker-trigger {
	margin-right: 30px;
}

.selectionTabulation div.bas-inputCheck > * {
	display: table;
}

.ym-g50 .panel {
	input.datepicker.cols2, input.clockpicker.cols2 {
		width: 256px;
	}

	input, select, textarea {
		&.cols1, &.cols2 {
			width: 292px;
		}
	}
}

.selectionGroup {
	&.budget-select {
		.selection {
			label.selectionArea {
				display: flex;
				flex-direction: column;
				padding: 10px;
				width: 191px;
				height: auto;

				.name {
					font-family: @AudiTypeBold;
				}

				.name, .type {
					color: @PrimaryFontColor;
					margin: 0;
				}
			}

			label.desc {
				max-width: 215px;
			}
		}
	}

	> .selection {
		margin: 0 25px 20px 0;

		&:last-of-type {
			margin-right: 0
		}
	}

	> .tooltipTrigger {
		margin: 0 20px 20px 0;
	}

	.selection {
		input {
			&.selectionInput.selectionArea {
				visibility: visible;
				opacity: 0;
				z-index: 1;
			}

			&:hover {
				~ label.selectionArea, ~ label.desc {
					color: @AudiRot;
				}

				~ label.selectionArea:not([svg]) {
					border: 2px solid @AudiRot;
				}
			}

			&:checked {
				&, &:hover {
					~ label.selectionArea, ~ label.desc {
						color: @AudiRot;
					}

					~ label.selectionArea:not([svg]) {
						border: 2px solid @AudiRot;
					}

					~ label.selectionArea {
						position: relative;

						&:after {
							content: "";
							width: 40px;
							height: 40px;
							background: transparent url("../../../../default/img/icons/selections/icon_selection_selected.png") 0 0 no-repeat;
						}
					}
				}
			}

			&:disabled {
				~ label.selectionArea, ~ label.desc {
					&, &:hover {
						color: @LichtGrau;
						cursor: default;
					}
				}

				~ label.selectionArea:not([svg]) {
					&, &:hover {
						border: 2px solid @LichtGrau;
					}
				}

				&:checked {
					~ label.selectionArea, ~ label.desc {
						color: @MittelGrau;
					}

					~ label.selectionArea:not([svg]) {
						border: 2px solid @MittelGrau;
					}
				}
			}
		}

		label:not([svg]) {
			&.selectionArea {
				border: 2px solid @AudiSchwarz;
			}
		}

		label {
			&.selectionArea {
				background-color: @AudiWeiss;
				color: @AudiSchwarz;
				width: 158px;
				height: 108px;

				&.portrait {
					width: 108px;
					height: 158px;
				}
			}

			&.desc {
				display: block;
				font-weight: bold;
				padding-top: 5px;
				max-width: 158px;

				&:hover {
					color: @AudiRot;
				}

				&.portrait {
					max-width: 108px;
				}
			}
		}
	}
}

.ie {
	.selectionGroup .selectionArea > span {
		display: inline-block;
		margin: 6px 0 0;
	}
}

.selectionPreview {
	width: 250px;
	height: 300px;
	background-color: #ffffff;
	border: 1px solid red;
	margin: 0 10px;
}

.selectionTooltip {
	max-width: 1000px;
}

.item.setControls {
	.itemBox .moveLeft {
		margin-left: -8px;
	}

	.itemBox .moveRight {
		margin-left: 8px;
	}
}

.jumpedAtElement:focus {
	-webkit-animation-duration: 1s;
	-webkit-animation-name: onFocusBlinking;
	animation-name: onFocusBlinking;
	animation-duration: 1s;
}

@keyframes onFocusBlinking {
	100% {
		background: white;
	}
	0% {
		background: @MittelGrau;
	}
}
