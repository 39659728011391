.panel .boxGrid {
	padding: 10px;
	.box {
		a , div.inactive {
			display: block;
		}
	}
}

.boxRow .boxGrid {
	padding: 0;
}

.box {
	.add , .rename , .delete {
		display: block;
	}

	.defaultLink {
		margin-bottom: 5px;
	}
}

.dynamicSelectTree, .dynamicAreaTree {
	.ym-equalize .ym-gbox {
		margin: 0;
		padding: 0;
	}
}

div.middle a.controls.moveLeft {
	margin-right: 0;
	float: none;
}
div.middle a.controls.moveRight {
	margin-left: 0;
}
div.ym-gbox.middle {
	text-align: center;
	padding-top: 140px;
}

