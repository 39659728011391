 @import '../../utilities/lib.less';
 .floatingPageHeader {
 	background-color: @DefaultBackgroundColor;
 	.ym-gl{
		border-right-color: @VisualLayoutingColor;
	}
	
	.ym-gr {
		input[type=text].deeplink {
			width: @ColsInput2;
		}
		div.bas-inputCheck{
			display: flex !important;
			.fieldsavedicon{
				position: unset;
			}
			.checkbox{
				order: -1;
			}
		} 
	}
		
	.gruppenbudget{
		background: @VisualLayoutingColor;
	}
	
	.negative-groupmember-budget {
		border: 1px solid @Color_Error;
	}
 }