@import '../utilities/lib.less';
.monthpicker, .dayspicker{
	.monthpicker-calendar, .dayspicker-calendar{
		border-color: @AudiSchwarz;
		.monthpicker-body, .dayspicker-body{
			.month-cell, .day-cell{
				&.disabled{
					color: @InteractiveBackgroundColorInactive;
				}
				&:hover:not(.disabled), &.selected-month, &.selected-day{
					color: @AudiWeiss;
				}
				&.selected-month, &.selected-day{
					background-color: @AudiRot;
				}
				&:hover:not(.disabled){
					background-color: @HellGrau;
				}
			}
		}
	}
}