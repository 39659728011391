@import '../utilities/lib';

.budgetTypePicture {
	border: 1px solid @LichtGrau;
	max-width: 150px; 
	max-height: 60px
}
div.pictureinfo.budgetTypePlaceholder {
	width: 130px;
}
div.pictureinfo.budgetTypePlaceholder div.placeholderpicture {
	height: 40px;
	width: 130px;
	padding: 0 0;
	margin-bottom: 0;
}
