@import '../utilities/lib.less';
@_imgBreadPath: '../../img/quickfinder';
@_iconPath: '../../img/icons';

#wofWrapper {
	overflow: hidden;
	z-index: @Index_Header + 1;
	&.open .wofSectionGroup {
		.wofElement:before {
			border-right-width: 0 ! important;
			left: -23px;
		}

		& , &:hover {
			&.wofSeparator {
				& , &.inviewport , &.containsErrors {
					.wofElement:before {
						left: -12px;
					}
				}
			}
		}
	}

	.wofSectionGroup {
		width: 24px;
		height: 30px;
		margin-bottom: 2px;
		overflow: visible;
		&:not(:last-of-type) .wofElement:after {
			bottom: -3px;
			content: '';
			position: absolute;
			right: -1px;
			height: 2px;
			width: 109%;
			background-color: @DefaultBackgroundColor;
		}

		.wofElement {
			background-color: @InteractiveBackgroundColor;
			border: 1px solid @InteractiveBackgroundColor;
			border-left-width: 0;
			color: @InteractiveFontColor;
			line-height: 28px;
			padding: 0 15px 0 0;
			margin-left: 24px;
			position: relative;
			&:before {
				background-image: url('@{_imgBreadPath}/leftEdge_black.png');
				background-repeat: no-repeat;
				line-height: 30px;
				width: 23px;
				height: 30px;
				left: -24px;
				top: -1px;
				border-right: 1px solid @InteractiveBackgroundColor;
			}
		}

		& , &:hover {
			&.wofSeparator {
				@SeparatorTop: 10px;
				margin-top: @SeparatorTop;
				&:before {
					top: -@SeparatorTop;
					content: '';
					position: absolute;
					right: -1px;
					height: @SeparatorTop;
					width: 94%;
					background-color: @DefaultBackgroundColor;
				}

				& , &.inviewport , &.containsErrors {
					.wofElement {
						background-color: @InteractiveBackgroundColor;
						border-color: @InteractiveBackgroundColor;
						color: @InteractiveFontColor;
						&:before {
							background: @InteractiveBackgroundColor;
							border-right: 1px solid @InteractiveBackgroundColor;
							width: 12px;
							left: -13px;
						}
					}
				}
			}
		}

		&.inviewport {
			& , &:hover {
				.wofElement {
					background-color: @DefaultBackgroundColor;
					border-color: @VisualLayoutingColor;
					color: @PrimaryFontColor;
					&:before {
						background-image: url('@{_imgBreadPath}/leftEdge_white.png');
						border-right: 1px solid @VisualLayoutingColor;
					}
				}
			}

			&:hover .wofElement {
				color: @PrimaryCiColor;
			}
		}

		&.containsErrors {
			& , &:hover {
				.wofElement {
					background-color: @InteractiveBackgroundColorHighlight;
					border-color: @InteractiveBackgroundColorHighlight;
					color: @InteractiveFontColor;
					&:before {
						border-right: 1px solid @InteractiveBackgroundColorHighlight;
						background-image: url('@{_imgBreadPath}/leftEdge_darkred.png');
					}
				}
			}

			&:hover .wofElement {
				background: @InteractiveBackgroundColorHighlightHover;
				border-color: @InteractiveBackgroundColorHighlightHover;
				&:before {
					border-right: 1px solid @InteractiveBackgroundColorHighlightHover;
					background-image: url('@{_imgBreadPath}/leftEdge_red.png');
				}
			}
		}

		&:hover .wofElement {
			background-color: @InteractiveBackgroundColorHover;
			border-color: @InteractiveBackgroundColorHover;
			&:before {
				border-right: 1px solid @InteractiveBackgroundColorHover;
				background-image: url('@{_imgBreadPath}/leftEdge_hover.png');
			}
		}
	}
}

div.wofArrow {
	height: 15px;
	width: 15px;
	background-image: url('@{_iconPath}/linkpfeil-rot-double.png');
	&.wofArrowTop {
		transform: rotate(180deg);
	}

	&.max {
		background-image: url('@{_iconPath}/linkpfeil-schwarz-double.png')
	}
}

