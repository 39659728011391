@import '../../utilities/lib';
@imagePath: '../../../img';
.lightboxContainer {
	background-color: @AudiWeiss;
	width: 853px;
	overflow: hidden;
	text-align: center;
	.imageContainer {
		padding: 0 32px;
		img {
			position: static;
			border: 1px solid @VisualLayoutingColor;
			box-shadow: 0 0 5px 0 @VisualLayoutingColor;
		}
	}

	.controlContainer {
		height: 40px;
		margin-top: 5px;
		position: absolute;
	}

	.switchLeft , .switchRight {
		width: 24px;
		height: 24px;
		cursor: pointer;
		position: absolute;
		bottom: 0;
		&.inactive , &.inactive:hover {
			background: none;
			cursor: default;
		}
	}

	.switchLeft {
		float: left;
		left: 0;
		background: url('@{imagePath}/kalender/zurueck.png');
		&:hover {
			background-image: url('@{imagePath}/kalender/zurueck-rollover.png');
		}
	}

	.switchRight {
		float: right;
		right: 0;
		background-image: url('@{imagePath}/kalender/vor.png');
		&:hover {
			background: url('@{imagePath}/kalender/vor-rollover.png');
		}
	}

	.linkBox {
		top: 20px ! important;
	}

	span.imageDescription {
		line-height: 16px;
	}

	.header {
		height: 40px;
		margin-bottom: 5px;
	}
}

.closeLightbox , .scalePreview {
	height: 32px;
	width: 32px;
	position: relative;
}

.closeLightbox {
	background-image: url('@{imagePath}/icons/layer-schliessen-passiv.png');
	background-repeat: no-repeat;
	background-position: center;
	float: right;
	&:hover {
		background-image: url('@{imagePath}/icons/layer-schliessen-rollover.png');
	}
}

.scalePreview {
	background-image: url('@{imagePath}/btn/scaleOut.png');
	background-repeat: no-repeat;
	background-position: center;
	float: right;
	margin-right: 2px;
	&:hover {
		background-image: url('@{imagePath}/btn/scaleOut-rollover.png');
		cursor: pointer;
	}

	&.undo {
		background: url("@{imagePath}/btn/scaleOut-undo.png") no-repeat center;

		&:hover {
			background-image: url("@{imagePath}/btn/scaleOut-undo-rollover.png");
			cursor: pointer;
		}
	}

	&.inactive {
		background-image: url('@{imagePath}/btn/scaleOut-inactive.png');
		cursor: default;
	}
}

