@import '../utilities/lib';
div.item.buttonline.top {
	padding: 5px 10px;
	vertical-align: middle;
	font-family: @AudiTypeBold;
	min-height: 22px;
	background-color: @HellGrau30;
	font-size: 13px;
	line-height: 22px;
	margin-top: 1px;
}

div.buttonline.mySet_footer {
	border-top: 0 none;
	border-right: 1px solid @VisualLayoutingColor;
	border-bottom: 1px solid @VisualLayoutingColor;
	border-left: 1px solid @VisualLayoutingColor;
	padding: 10px;
}

.mySet {
	border: 1px solid @VisualLayoutingColor;
	height: 270px;
	overflow: auto;
	tr {
		display: block;
	}

	.row {
		padding: 10px;
		padding-bottom: 12px;

		~ .row {
			border-top: 1px solid @VisualLayoutingColor;
		}

		div.col {
			.icon {
				margin: 0;
			}

			* {
				vertical-align: middle;
			}

			span {
				span {
					vertical-align: initial;
				}
			}

			+ div.col , .icon + span {
				padding-left: 10px;
			}

			+ div.col.text {
				padding-left: 25px;
				width: 100%;
			}

			&.text ~ div.col.last {
				width: auto;
			}
		}
	}

	.col {
		vertical-align: middle;
		border-right: none;
		font-family: @AudiTypeNormal;
		.icon {
			float: left;
		}

		span {
			display: inline-block;
			margin-top: 2px;
		}
	}
}

