@import "../../utilities/lib.less";
@import '../../variableProvider.less';
.tooltipTrigger.infoIcon {
	margin-left: 5px;
	background: url('../../../img/icons/info.png') 0 0 no-repeat;
	height: 26px;
	width: 26px;
	cursor: pointer;
	&:hover {
		background-image: url('../../../img/icons/info-rollover.png');
		background-position: 0 0;
	}

	&.tiny {
		background: url('../../../img/icons/info_small.png') 0 0 no-repeat;
		height: 17px;
		width: 17px;
		&:hover {
			background-image: url('../../../img/icons/info_small-rollover.png');
			background-position: 0 0;
		}
	}

	&.warning {
		background-image: url('../../../img/icons/redTriangleWithExclamationMark.png');
		background-position: 0 0;
		width: 17px;
		height: 15px;
		margin-top: 2px;
	}
}

td.tooltipImg .tooltipTrigger img {
	margin-top: 2px;
}

.tooltip {
	border: 1px solid @VisualLayoutingColor;
	background-color: @AudiWeiss;
	ul.bas-unorderedList {
		padding-left: 25px;
	}
	&.informationBlock:not(.errorTooltip) {
		&:before {
			content: "";
			height: 15px;
			display: block;
			position: absolute;
			width: 15px;
			border-right: 1px solid @VisualLayoutingColor;
			border-bottom: 1px solid @VisualLayoutingColor;
			background: #fff;
		}

		&.topright:before {
			bottom: -9px;
			left: 2px;
			transform: rotate(45deg);
		}

		&.topleft:before {
			bottom: -9px;
			right: 2px;
			transform: rotate(45deg);
		}

		&.bottomleft:before {
			top: -9px;
			right: 3px;
			transform: rotate(225deg);
		}

		&.bottomright:before {
			top: -9px;
			left: 2px;
			transform: rotate(225deg);
		}
	}
	&.selectionTooltip {
		max-width: 600px;
	}
	
	ul {
		&.bas-unorderedList, &.bas-orderedList {
			padding: 10px 20px;
		}
	}
}

div.errorTooltip {
	max-width: 300px;
	display: flex !important;
	position: absolute;
	z-index: @Index_Tooltip !important;
	
	.errorcontainer {
		display: inline-block;
		max-width: 240px;
		min-width: 50px;
		text-align: left;
		overflow: hidden;
		padding: 0 10px;
		margin-bottom: 0;
	    background-color: transparent;
	}
	
	.iconContainer {
		flex-shrink: 0;
		display: inline-block;
		width: 22px;
		height: 22px;
		border-radius: 100%;
		text-align: center;
		font-size: 20px;
		line-height: 22px;
		font-weight: bold;
	}
	
	&.tt_hasWarnings, &.tt_hasErrors {
		.errorcontainer {
			>div {
				&:first-child:not(:last-child) {
					margin-bottom: 5px;
				}
				&:not(:first-child) {
					margin-top: 10px;
				}
				&:not(:last-child) {
					padding-bottom: 10px;
				}
			}
		}
	}
	
	&.tt_hasWarnings {
		.errorcontainer {
			color: @MittelGrau;
			>div:not(:last-child) {
				border-bottom: 1px solid @MittelGrau;
			}
		}
		.iconContainer {
			border: 1px solid @MittelGrau;
			color: @MittelGrau;
		}
	}
	&.tt_hasErrors {
		border: 1px solid @Color_Error;
		
		.errorcontainer {
			>div:not(:last-child) {
				border-bottom: 1px solid @Color_Error;
			}
			color: @Color_Error;
		}
		.iconContainer {
			border: 1px solid @Color_Error;
			color: @Color_Error;
		}
	}
}