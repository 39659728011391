@import '../utilities/lib.less';
#budgetOverview {
	.budgetTypePicture {
		width: 150px;
	}
}

#budgetTypeOverview {
	td {
		vertical-align: middle;
	}

	.budgetTypePicture {
		width: 85px;
	}
}

#costPartitioningTable {
	border-spacing: 0;
	td.col {
		padding: 5px 0 5px 0;
		border: none;
		font-weight: normal;
		&.last {
			padding-left: 10px;
		}
	}
}

table.tabulation {
	width: 100%;
	border-collapse: collapse;
	&.half-size {
		width: 380px;
	}

	&.fixed {
		table-layout: fixed;
	}

	&.kopra , &.budgetOverviewTable {
		.header td {
			white-space: nowrap;
		}
	}

	&.newsletter , &.eventnewsletter , &.articles {
		.header {
			.options {
				width: 120px;
			}

			.articlescount , .state , .category {
				width: 50px;
			}

			.creationDate , .calculatedValidFrom , .calculatedValidTo {
				width: 95px;
			}
		}
	}

	&#budgetTypeOverview {
		.header {
			.options {
				width: 50px;
			}

			.picture {
				width: 80px;
			}

			.id {
				width: 35px;
			}

			.kind {
				width: 120px;
			}

			.validTo {
				width: 85px;
			}

			.percentage {
				width: 105px;
			}
		}
	}

	thead {
		td {
			border-right: 5px solid #fff;
			&.last {
				border-right: 0;
			}
		}
	}

	th {
		border-bottom: none;
	}

	td {
		border-top: none;
		line-height: 20px;
		font-family: @AudiTypeNormal;
		font-weight: bold;
		border-bottom: 1px solid @HellGrau30;
		padding: 5px;
		& , a , a.defaultLink , a.defaultLink span {
			font-size: 12px;
		}

		&[align="center"] {
			text-align: center;
			> .defaultLink.icon , > .infoIcon , > .inactive {
				margin: 0;
				padding-right: 0;
				display: inline-block;
				float: none;
			}

			img {
				margin: 0 auto;
			}
		}

		a.icon , img , .icon.inactive {
			vertical-align: middle;
		}

		a.formSubmit.button {
			font-size: 13px;
		}

		&.last {
			border-right: none;
		}

		&.cols1 {
			width: @ColsInput1;
		}

		&.colsHalf {
			width: @ColsInputHalf;
		}

		&.colsThird {
			width: @ColsInputThird;
		}

		&.colsQuarter {
			width: @ColsInputQuarter;
		}

		&.cols2 {
			width: 273px;
		}

		&.options , &.options1 , &.options2 , &.options3 , &.options4 , &.options5 {
			a.icon:last-of-type {
				padding-right: 0;
			}
		}

		&.options1 {
			width: 61px;
		}

		&.options2 {
			width: 97px;
		}

		&.options3 {
			width: 133px;
		}

		&.options4 {
			width: 169px;
		}

		&.options5 {
			width: 205px;
		}

		&.breakword {
			word-wrap: break-word;
		}

		&.archive_date {
			width: 90px;
		}

		span + img {
			margin-left: 5px;
		}

		img {
			display: block;
		}

		&.tooltipImg div.bas-inputCheck {
			margin-right: 0;
		}

		.item.horizontal > .itemBox:last-of-type {
			padding-right: 0;
		}

		> div {
			&.bas-inputCheck, > .bas-inputCheck {
				margin-right: 0;
				> div.checkbox {
					display: block;
					padding-right: 0;
					+ label {
						padding-left: 10px;
					}
				}
			}
		}
	}

	tr.intermediateHeader td {
		padding-top: 20px;
	}

	.footer.row {
		.col {
			border-bottom: 0 none;
		}

		.bas-inputButton {
			margin-top: 10px;
			margin-bottom: 3px;
		}

		&.subTotal td {
			background-color: @LichtGrau30
		}

		&.total td {
			background-color: @HellGrau30;
			padding-top: 15px;
			padding-bottom: 15px;
			border-right: 5px solid @AudiWeiss;
			&.last {
				border-right: 0;
			}
		}
	}

	.header , .footer.total {
		td {
			height: 22px;
			background-color: @HellGrau30 ! important;
			border-right: 5px solid #fff;
			vertical-align: middle;
			> .horizontal {
				display: block;
			}

			&.last {
				padding-right: 5px;
				border-right: 0;
			}
		}

		.item.horizontal .itemBox {
			margin-top: 5px;
		}

		&.row {
			> .col.filter > * {
				display: inline-block;
				+ * {
					margin-left: 20px;
				}
			}

			span.inactive {
				background: url("../../img/tabulation/sortieren_inaktiv.png") no-repeat left center;
				display: inline-block;
			}
		}

		+ .header {
			border-top: 5px solid #fff
		}
	}

	a.ascending , a.descending , .header.row span.inactive {
		padding: 0;
		padding-left: 15px;
		display: inline-block;
	}

	a.ascending , a.descending {
		&:hover {
			background: url("../../img/tabulation/sortieren_hover.png") no-repeat left center;
		}
	}

	a.ascending {
		background: url("../../img/tabulation/sortieren.png") no-repeat left center;
		&.active {
			background: url("../../img/tabulation/sortieren_aufsteigend_aktiv.png") no-repeat left center;
		}
	}

	a.descending.active {
		background: url("../../img/tabulation/sortieren_absteigend_aktiv.png") no-repeat left center;
	}

	td[align="center"] .bas-inputCheck {
		margin: 0 auto;
	}

	.datepicker.cols1 {
		width: 82px;
	}

	div.radio {
		float: left;
	}

	tr:hover td , tbody tr:hover td {
		background-color: @LichtGrau30;
	}

	tbody tr {
		&.header {
			&:hover td , td:hover {
				background-color: @HellGrau30;
			}
		}

		&.footer {
			&:hover td , td:hover {
				background-clip: padding-box;
				border-bottom: 1px solid @HellGrau30;
			}
		}

		&.even , &.odd {
			&.rowGroup {
				td {
					border-bottom: 0 none;
				}

				&.last td {
					border-bottom: 1px solid @HellGrau30;
				}
			}
		}
	}

	td[align='right'] {
		text-align: right;
	}

	td.actioncolumn {
		.tooltipTrigger {
			display: inline-block;
			&.infoIcon {
				margin-left: 0;
			}

			&.elementTrigger > * {
				padding-right: 5px;
			}
		}
	}

	.stinkyHeader {
		background-color: white;
		.stinkyCol {
			background-color: red;
			padding: 5px;
			float: left;
			margin-right: 5px;
		}
	}
}

div.tabulationContainer ~ div.footer {
	padding: 5px;
	&.row .bas-inputButton {
		margin-top: 10px;
		margin-bottom: 3px;
	}
}

td.col.col-state {
	width: 14px;
}
