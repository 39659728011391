@import '../../utilities/lib';

#newsletterPreview , #newsletterPreview.responsive {
	&.largePreview {
		width: 774px;
	}

	margin: 0 auto;
	
	table {
		border-collapse: collapse;
	}

	table.footer {
		width: auto;
	}

	table.artPreview {
		td.artHeader , td.artImage , td.artContent , td.artLinks , td.toTop , td.artFootnoteWrapper {
			border-left: 15px solid #ddd ! important;
			border-right: 15px solid #ddd ! important;
			padding-left: 20px ! important;
			padding-right: 20px ! important;
		}
	}

	td {
		border: 0 none;
		padding: 0;
	}

	#template table.artPreview td {
		border-left: 0 none ! important;
		border-right: 0 none ! important;
		padding-left: 0 ! important;
		padding-right: 0 ! important;
	}

	#template table.artPreview td.toTop {
		padding-bottom: 0 ! important;
		padding-right: 18px ! important;
	}

	#template table.artPreview .footnoteDescription {
		padding: 2px 0 5px 3px ! important;
	}

	#template table.artPreview .artHeader {
		padding-top: 0 ! important;
	}

	table.artPreview .artHeader {
		padding-top: 15px ! important;
	}

	table.artPreview .artHeader td.toTop {
		padding-bottom: 30px ! important;
		padding-right: 38px ! important;
	}

	.linkSpacer {
		height: 8px;
	}

	.linkContainer > span {
		line-height: 18px;
	}

	.headlineFiveSpacer {
		height: 5px;
	}

	.content {
		width: 470px;
	}

	.linkColumn {
		width: 184px;
	}

	.toTop > table {
		width: 81px;
		float: right;
	}

	.editablerichtext ul , .editablerichtext ol {
		margin: 0 0 0 0;
		padding: 0;
		list-style-position: inside;
	}

	.editablerichtext ul li , .editablerichtext ol li {
		margin: 0;
		padding: 0 0 0 0;
		list-style-position: inside;
	}

	.editablerichtext ul {
		list-style-type: disc;
	}

	.editablerichtext ol {
		list-style-type: decimal;
	}

	.footnoteDescription {
		width: 100%;
	}

	.footnoteDescription img {
		width: inherit\9;
	}
}

table.artPreview {
	width: 774px;
}

#newsletterPreview sup , #newsletterPreview sub {
	line-height: 10px;
}

#template table {
	width: 100%;
}

#newsletterPreview tbody tr:hover th,
#newsletterPreview tbody tr:hover td {
	background-color:inherit;
}

.teaser .preview-highlight {
	outline: solid 1px @DefaultBackgroundColor ! important;
	&:hover {
		cursor: pointer;
	}
}

.teaser .rectanglesActive {
	outline: solid 1px @DefaultBackgroundColor;
}

.red .preview-highlight {
	outline: solid 1px @DefaultBackgroundColor ! important;
	&:hover {
		cursor: pointer;
	}
}

.red .rectanglesActive {
	outline: solid 1px @DefaultBackgroundColor;
}

