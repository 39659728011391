@import '../../utilities/lib.less';
.ui-datepicker {
	background-color: @AudiWeiss;
	border: 1px solid @HellGrau60;
	padding: 0;
	height: auto;
	width: 255px;
	.ui-datepicker-header {
		padding: 0;
		margin-bottom: 10px;
	}

	.ui-datepicker-title {
		margin: 0;
		padding-top: 4px;
	}

	.ui-datepicker-buttonpane button.ui-datepicker-current {
		height: 24px;
		background: @InteractiveBackgroundColor;
		color: @InteractiveFontColor;
		margin: 5px auto;
		font-family: @AudiTypeBold;
		font-size: 13px;
		font-weight: normal;
		padding: 0 5px 1px 5px;
		float: none;
	}

	table {
		margin: 0;
		font-size: 13px;
	}

	.ui-datepicker-buttonpane {
		margin: 0;
		padding: 0;
	}
}

.ui-datepicker-trigger , .clockpicker-trigger {
	opacity: 1 !important;
	margin-left: 10px;
}

.ui-datepicker-title span , .ui-datepicker-calendar {
	font-family: @AudiTypeBold;
	font-size: 13px;
	color: @PrimaryFontColor;
	margin-top: 12px;
}

.ui-datepicker-calendar {
	background-image: url("../../../img/kalender/hg-tage.png");
	background-position: 30px 20px;
	background-repeat: no-repeat;
	thead .ui-datepicker-week-col , tbody .ui-datepicker-week-col , tbody td.ui-datepicker-week-col:hover , tbody td.ui-datepicker-week-col:hover a {
		color: @HellGrau60;
	}

	tbody {
		.ui-datepicker-other-month , .ui-datepicker-week-col {
			font-family: @AudiTypeNormal;
			font-size: 13px;
		}

		.ui-datepicker-week-col , tr:hover .ui-datepicker-week-col {
			border: none;
			background-color: @AudiWeiss ! important;
		}
	}

	thead th {
		border: none;
		text-align: center;
		padding: 0 0 0 0;
	}

	td {
		width: 30px;
		max-width: 30px;
		height: 24px;
		border-top: 1px solid @HellGrau60;
		border-bottom: 1px solid @HellGrau60;
		border-left: 1px solid @HellGrau60;
		border-right: none;
		text-align: center;
		vertical-align: middle;
		&.early-start-not-selected {
			a {
				color: @PrimaryCiColor;
			}
		}
	}

	td.ui-datepicker-today a , td.ui-datepicker-today span , td.ui-datepicker-current-day a , tbody td:hover , tbody td:hover a , tbody td a:hover {
		color: @AudiWeiss;
	}

	td.ui-datepicker-unselectable {
		cursor: default !important;
		&.ui-datepicker-today {
			span {
				color: @InteractiveFontColor;
			}
			&:hover {
				background-color: @InteractiveBackgroundColor;
			}
		}
		span {
			color: @HellGrau60;
		}
	}

	tbody tr:hover , td.ui-datepicker-unselectable:hover , td.ui-datepicker-week-col:hover {
		background-color: transparent;
	}

	tbody tr:hover td {
		background-color: initial;
		&.ui-datepicker-today {
			background-color: @InteractiveBackgroundColor;
			&.ui-datepicker-days-cell-over {
				background-color: @PrimaryCiColor;
			}

			&:hover {
				background-color: @MittelGrau;
			}
		}
	}

	td[data-handler='selectDay']:hover {
		background-color: @MittelGrau;
	}

	-today , td.ui-datepicker-today {
		background-color: @InteractiveBackgroundColor;
	}

	tr:hover td.ui-datepicker-current-day , td.ui-datepicker-current-day {
		background-color: @PrimaryCiColor;
	}
}

.ui-state-default.ui-priority-secondary {
	color: @SecondaryFontColor;
}

.ui-datepicker-calendar tbody td:hover , .ui-datepicker-calendar tbody td.ui-datepicker-today:hover , .ui-datepicker-calendar tbody td.ui-datepicker-current-day:hover , .ui-datepicker-buttonpane .ui-datepicker-current:hover {
	background-color: @MittelGrau;
	cursor: pointer;
}

.ui-widget-header {
	.ui-datepicker- {
		&prev , &prev:hover , &next , &next:hover {
			top: 0;
		}

		&prev , &prev:hover {
			left: 0;
		}

		&next , &next:hover {
			right: 0;
		}

		&prev , &next {
			background-repeat: no-repeat;
			width: 24px;
			height: 24px;
			margin: 5px;
			cursor: pointer;
			&.ui-state-disabled {
				&,&:hover {
					cursor: default;
				}
			}
			span {
				display: none;
			}
		}

		&prev {
			background-image: url("../../../img/kalender/zurueck.png");
			float: left;
			cursor: pointer;
			&:hover {
				background-image: url("../../../img/kalender/zurueck-rollover.png");
			}
			&.ui-state-disabled {
				&,&:hover {
					background-image: url("../../../img/kalender/zurueck-inactive.png");
				}
			}
		}

		&next {
			background-image: url("../../../img/kalender/vor.png");
			float: right;
			&:hover {
				background-image: url("../../../img/kalender/vor-rollover.png");
			}
			&.ui-state-disabled {
				&,&:hover {
					background-image: url("../../../img/kalender/vor-inactive.png");
				}
			}
		}
	}
}

.ui-corner-all {
	border-radius: unset;
}

