@import '../../utilities/lib.less';
div.overlay {
	&.ajax {
		z-index: @Index_AjaxOverlay ! important;
		background-image: url("../../../../../default/img/overlay_background_dark.png");
	}

	&.superView {
		display: none;
		&.editView {
			display: block;
		}
	}

	.loader {
		background: url("../../../img/audi_loader.gif");
		width: 50px;
		height: 50px;
	}

	.progressMonitor.upload {
		height: auto;
		background: #fff;
		.progressPercentageContainer {
			color: @PrimaryFontColor;
			font-size: 14px;
			font-family: @AudiTypeNormal;
			padding-top: 10px;
		}

		.progressBarContainer {
			background: transparent;
			.progressBar {
				background: @PrimaryFontColor;
			}
		}
	}
}

.sliderContainer {
	div.overlay {
		.sliderLoader {
			background: url("../../../img/audi_loader.gif");
			width: 50px;
			height: 50px;
		}
	}
}

.overviewImage.banner , .overviewImage.standard , .overviewImage.pos , .overviewArea .smallPreview, .overviewImage.newsletter {
	margin-top: 5px;
	margin-bottom: 5px;
	border: 2px solid @HellGrau30;
}

div.imageLoaderOverlay {
	background-image: url("../../../../../default/img/overlay_background_dark.png");
	.loader {
		background: url("../../../img/audi_loader.gif");
		width: 50px;
		height: 50px;
	}
}

