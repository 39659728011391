@import '../../utilities/lib.less';
.box {
	&.error {
		background: none;
		color: @Color_Error;
		> .errorContent > .box.error {
			margin-bottom: 5px;
		}

		&.small {
			margin-bottom: 5px;
		}
	}

	&.notification {
		display: none;
	}

	&.error {
		> header {
			font-weight: bold;
			margin-bottom: 5px;
		}
	}
}

.tabulationContainer div.box.error {
	margin-bottom: 0;
}

input , select , textarea {
	&.error {
		background: @AudiWeiss;
	}
}

