@import '../../utilities/lib.less';
.budget-overview{
	.werbeformbudgets, .kategorienbudgets, .kategoriewerbeformbudgets{
		.description-container{
			.description-wrapper{
				&.main {
					font-family: @AudiTypeBold;
				}
			}
		}
		.diagram-container{
			background: repeating-linear-gradient(
				90deg, @HellGrau, @HellGrau 1px, transparent 0px, transparent 10%
			);
			border-right: solid 1px @HellGrau;
			.diagram-wrapper{
				background: @MittelGrau;
			}
		}
	}
}