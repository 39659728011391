@import '../../utilities/lib.less';
#searchfieldTrigger {
	background: url("../../../img/icons/lupe.png") no-repeat scroll 0 0;
	height: 15px;
	width: 15px;
	position: absolute;
	top: 0;
	right: 15px;
	cursor: pointer;
	&:hover {
		background-image: url("../../../img/icons/lupe-rollover.png");
	}

	&.disabled {
		background: url("../../../img/icons/lupe_inactive.png") no-repeat scroll 0 0;
		cursor: default;
	}
}

#searchfield[disabled], input.formsearch[disabled] , #searchfieldTrigger.disabled {
	background-color: @AudiWeiss;
}

.headerSubContainer .input-prompt {
	position: absolute;
	right: 105px;
	top: 0;
	color: @PrimaryFontColor;
	font-size: 12px;
	&.disabled {
		color: #E1E1E1;
	}
}

#searchfield {
	width: 155px;
	&::-ms-clear {
	    display: none;
	}
}

input.formsearch, #searchfield {
	height: 20px;
	border: none;
	border-bottom: 1px dotted @Schwarz;
	font-style: normal;
	font-size: 12px;
	font-family: @AudiTypeNormal;
	padding-right: 35px;
	padding-left: 10px;
	&[disabled] {
		border-bottom: 1px dotted #E1E1E1;
	}
}

