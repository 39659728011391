@import "../../utilities/lib.less";

.cke_panel_container *:focus {
	outline: none;
}

.cke_dialog_body {
	background: #ffffff ! important;
	border: none;

	* {
		filter: none ! important;
	}

	.cke_dialog_tab {
		border: none;
		background: #000000;
		color: #ffffff;
		border-radius: 0;
		font-family: @AudiTypeExtendedNormal;
		font-size: 11px;
		height: 30px;
		line-height: 30px;
		margin: 0 1px 0 0;

		&:hover, &.cke_dialog_tab_selected:hover {
			background: @InteractiveBackgroundColorHover;
		}

		&.cke_dialog_tab_selected {
			font-family: @AudiTypeExtendedBold;
			color: #000000;
			background: #ffffff;
			border: 1px solid @VisualLayoutingColor;
			border-bottom: none;

			&:hover {
				background: #ffffff;
			}
		}
	}

	.cke_dialog_close_button {
		height: 32px;
		width: 32px;
		display: inline-block;
		position: absolute;
		right: 0 ! important;
		top: 0;
		background: url("../../../img/icons/layer-schliessen-passiv.png") no-repeat center;

		&:hover {
			background-image: url("../../../img/icons/layer-schliessen-rollover.png");
		}
	}

	.cke_dialog_title {
		background: #ffffff;
		border: none;
		text-shadow: none;
		font-family: @AudiTypeExtendedBold;
		color: @PrimaryFontColor;
		padding-top: 15px;
	}

	.cke_dialog_contents {
		margin-top: 43px;
		border-top: 1px solid @VisualLayoutingColor;

		.cke_dialog_contents_body {
			padding-left: 22px;
			padding-right: 22px;

			.marginContainer {
				margin-bottom: 21px;
			}

			.radioContainer {
				margin-bottom: 10px;

				&:last-child {
					margin-bottom: 0;
				}
			}

			.groupSeparator {
				font-size: 14px;
				font-family: @AudiTypeBold;
				margin: 0;
				padding: 0;
				padding-bottom: 3px;
				border-bottom: 1px solid @VisualLayoutingColor;
			}

			select:not([multiple]):not([size]) {
				display: block ! important;
				box-shadow: none;
			}

			.chosen-container {
				position: absolute;

				.chosen-drop {
					z-index: 10100;
				}
			}

			.chosen-container-single .chosen-single span {
				cursor: pointer;
			}

			textarea {
				white-space: normal;
			}
		}
	}

	.alignmentRow {
		width: 100%;
		text-align: right;

		.cke_dialog_reset_button {
			.defaultButton ();
			background: @InteractiveBackgroundColor;
			padding: 0;
			margin-right: 0;
			margin-top: 5px;
			border: 0;
			border-radius: 0;

			> span {
				color: @InteractiveFontColor;
				text-shadow: none;
				padding: 0 12px;
				font-size: 13px;
				font-weight: bold;
			}

			&.cke_disabled {
				&, &:hover, &:focus, &:active {
					background-color: @InteractiveBackgroundColorInactive;
					cursor: default;
					box-shadow: none;

					> span {
						cursor: default;
					}
				}
			}

			&:hover, &:focus, &:active {
				text-decoration: none;
				background-color: @InteractiveBackgroundColorHover;

				span {
					color: @InteractiveFontColor;
				}
			}
		}
	}

	.cke_dialog_footer {
		padding-top: 5px;

		.cke_dialog_footer_buttons {
			a.cke_dialog_ui_button {
				.defaultButton ();
				margin-right: 5px;
				background: @InteractiveBackgroundColor;

				&.cke_dialog_ui_button_ok {
					padding: 0;
					border: 0;
					border-radius: 0;
					.defaultButtonHighlight ();

					&.cke_disabled {
						&, &:hover, &:focus, &:active {
							background-color: @InteractiveBackgroundColorInactive;
							cursor: default;
							box-shadow: none;

							> span {
								cursor: default;
							}
						}
					}

					> span {
						text-shadow: none;
						padding: 0 12px;
						font-size: 13px;
					}
				}

				&.cke_dialog_ui_button_cancel {
					padding: 0;
					border: 0;
					border-radius: 0;

					> span {
						text-shadow: none;
						padding: 0 12px;
						font-size: 13px;
					}
				}
			}
		}
	}
}

.cke_dialog tbody {
	tr, tr:hover, th, th:hover {
		td {
			background: #ffffff;

			input[type="radio"], input[type="checkbox"] {
				display: none;
			}

			div {
				&.radio, &.checkbox {
					float: left;
					position: relative;
				}
			}

			.cke_dialog_ui_checkbox_input ~ label[for] {
				top: 3px;
				position: relative;
			}
		}
	}
}

.inlineckeditor {
	word-wrap: break-word;
	line-height: normal;
}

.bas-inputArea .cms-container span, .inlineckeditor {
	margin: 0 0 3px 0;

	ol, ul {
		padding-left: 20px;
		margin: 12px 0 12px 0;
	}

	ul > li {
		display: list-item;
		list-style-type: disc;
	}

	ol > li {
		display: list-item;
		list-style-type: decimal;
	}

	p {
		margin: 0;
	}

	&.newslettertext {
		ul, ol {
			padding: 0;
			margin: 0 0 0 41px;
		}

		li {
			margin: 0;
			padding: 0 0 0 7px;
		}
	}
}

div.cke_inner {
	.cke {
		border: 1px solid @VisualLayoutingColor;
		box-shadow: none;

		* {
			filter: none ! important;
		}
	}

	.cke_combo {
		margin-top: 2px;
	}

	.cke_toolgroup {
		background: none;
		border: 1px solid @VisualLayoutingColor;
		border-radius: 0;
		box-shadow: none;

		.cke_button {
			border-radius: 0;
		}
	}

	.cke_combo_off {
		a.cke_combo_button {
			border-radius: 0;
			background: #ffffff;
			cursor: pointer;
			border: 1px solid @VisualLayoutingColor;
			height: 20px;

			&:hover, &:active {
				background: #ffffff;
				box-shadow: none;
			}
		}
	}

	.cke_combo_on {
		a.cke_combo_button {
			box-shadow: none;
			border: 1px solid @VisualLayoutingColor;
			background: #ffffff;
			border-radius: 0;
		}

		span.cke_combo_text {
			color: @PrimaryCiColor;
		}
	}

	.cke_combo_open {
		width: 20px;
		margin: 0;

		.cke_combo_arrow {
			background: @AudiWeiss url("../../../img/forms/liste-pfeil.png") right center no-repeat;
			border: none;
			width: 20px;
			height: 20px;
			margin: 0;
		}
	}

	.cke_combo_text {
		line-height: 20px;
		cursor: pointer;
		padding-top: 1px;
		font-family: @AudiTypeNormal;
	}

	.cke_top {
		background: @HellGrau30;
		box-shadow: none;
		border-bottom: 1px solid @VisualLayoutingColor;
	}
}

.cke_dialog {
	select:not([multiple]), select:not([size]) {
		display: block ! important;
		height: 20px;
		position: relative;

		&[size] {
			visibility: visible;
			height: auto;
		}
	}

	.chosen-container {
		position: relative;
	}
}

.cke_dialog_background_cover {
	z-index: 10001 ! important;
}

.cke_dialog div.footnoteContainer {
	max-height: 500px;
	overflow: auto;
}

